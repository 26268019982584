import React, {useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import {useNavigate} from "react-router-dom";
import ProjectBoardIcon from '../assets/Project-Board-Icon.png';
import ClientBoardIcon from '../assets/Client-Board-Icon.png';
import ProgressBoardIcon from '../assets/Progress-Board-Icon.png';
import CommissionCardsIcon from '../assets/Commission-Cards-Icon.png';
import ArchiveBoardIcon from '../assets/Archive-Board-Icon.png';
import ProfileSettingsIcon from '../assets/Profile-Settings-Icon.png';
import PriceBreakdownIcon from '../assets/Price-Breakdown-Icon.png';
import ManageSubsIcon from '../assets/Manage-Subs-Icon.png';
import NightSkyOverlay from '../assets/night-sky-overlay.jpg';

interface IUserGuide {

}

const UserGuide: React.FC<IUserGuide> = (props) => {
    const navigate = useNavigate();
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideContainerRef}
            />
            <div className="user-guide-container" ref={userGuideContainerRef}>
                <img className='night-sky-overlay' src={NightSkyOverlay} alt="night sky background"/>
                <div className="guide-sky-container">
                    <div className="guide-star"></div>
                    <div className="guide-star"></div>
                    <div className="guide-star"></div>
                    <div className="guide-star"></div>
                    <div className="guide-star"></div>
                    <div className="guide-star"></div>
                </div>
                <div className='user-guide-content-wrapper'>
                    <h1>CommComm User Guide</h1>
                    <p className="btnInstructions">Click on a button to learn more about the topic.</p>
                    <div className='guideBtnContainer'>
                        <div className='guideRow'>
                            <button type="button" onClick={() => navigate(navPath.userGuideProjectBoards)}>
                                <img src={ProjectBoardIcon} alt="project board icon"/>
                                Project Boards
                            </button>
                            <button type="button" onClick={() => navigate(navPath.userGuideClientBoards)}>
                                <img src={ClientBoardIcon} alt="client board icon"/>
                                Client Boards
                            </button>
                            <button type="button" onClick={() => navigate(navPath.userGuideProgressBoards)}>
                                <img src={ProgressBoardIcon} alt="progress board icon"/>
                                Progress Boards
                            </button>
                            <button type="button" onClick={() => navigate(navPath.userGuideCommissionCards)}>
                                <img src={CommissionCardsIcon} alt="commission card icon"/>
                                Commission Cards
                            </button>
                        </div>
                        <div className='guideRow'>
                            <button type="button" onClick={() => navigate(navPath.userGuideArchiveBoards)}>
                                <img src={ArchiveBoardIcon} alt="archive board icon"/>
                                Archive Boards
                            </button>
                            <button type="button" onClick={() => navigate(navPath.userGuideProfileSettings)}>
                                <img src={ProfileSettingsIcon} alt="profile settings icon"/>
                                Profile Settings
                            </button>
                            <button type="button" onClick={() => navigate(navPath.userGuidePriceBreakdowns)}>
                                <img src={PriceBreakdownIcon} alt="project breakdown icon"/>
                                Price Breakdowns
                            </button>
                            <button type="button" onClick={() => navigate(navPath.userGuideManageSubscription)}>
                                <img src={ManageSubsIcon} alt="manage subscription icon"/>
                                Manage Subscription
                            </button>
                        </div>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuide;