import React, {useEffect} from 'react';
import CommCommFooter from "../components/Home/CommCommFooter";
import {ProjectProgressCard} from "../components/CommissionProgressTracking/ProjectProgressCard";
import {useParams} from "react-router-dom";
import {CardProgress} from "../data/CardProgress";
import LoadingOverlay from "../components/Home/LoadingOverlay";
import {Card} from "../data/Card";
import {ClientDto} from "../data/ClientDto";

interface ICommissionProgressTrackingBoard {
    progressCards: Card[];
    progressCardsDetails: CardProgress[];
    setUnprotectedAccount: (accountId: string) => void;
    setUnprotectedClient: (clientId: string) => void;
    isLoadingProgressTrackingBoard: boolean;
    updateCardClientComments: (card: Card, comment: string) => void;
    client: ClientDto;
    isClientCommentSaving: boolean;
}

export const CommissionProgressTrackingBoard: React.FC<ICommissionProgressTrackingBoard> = (props) => {
    const {accountId} = useParams();
    const {clientId} = useParams();

    useEffect(() => {
        if (accountId && clientId) {
            props.setUnprotectedAccount(accountId);
            props.setUnprotectedClient(clientId);
        }
    }, [accountId, clientId])

    return (
        <div className="client-progress-board-overview">
            {props.progressCardsDetails && props.progressCardsDetails.sort((a, b) => a.dueDate.localeCompare(b.dueDate))
                .map((card, index) => {
                    const isFirstCommission = index === 0;

                    let wrapperClassName = 'client-progress-board-content-wrapper';
                    wrapperClassName += isFirstCommission ? ' first-commission-style' : '';

                    // Conditionally add "few-commissions-height" class
                    wrapperClassName += props.progressCardsDetails.length === 1 ? ' few-commissions-height' : '';

                    return (
                        <div className={wrapperClassName.trim()} key={`${card.cardId}_${index}`}>
                            <ProjectProgressCard
                                client={props.client}
                                card={props.progressCards.find(c => c.id === card.cardId) ?? new Card()}
                                cardTitle={card.cardTitle}
                                projectTitle={card.projectTitle}
                                currentColumnId={card.cardCurrentColumnId}
                                columnNames={card.columnListName}
                                columnIds={card.columnList}
                                isArchived={card.isArchived}
                                venmo={card.venmo}
                                dueDate={card.dueDate}
                                completedDate={card.completedDate}
                                records={card.cardHistoryDetails}
                                updateCardClientComments={props.updateCardClientComments}
                                isClientCommentSaving={props.isClientCommentSaving}
                            />
                        </div>
                    )
                })}
            <LoadingOverlay isLoading={props.isLoadingProgressTrackingBoard}/>
            <CommCommFooter/>
        </div>
    );
};