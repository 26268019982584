import React, {useState} from "react";
import {Collapse} from "@mui/material";
import AddItem from "./AddItem";
import {AiOutlineArrowLeft} from "react-icons/ai";

interface AddColumnProps {
    addItem: (text: string) => void;
    type: string;
    isHidden: boolean
}

const AddColumn: React.FunctionComponent<AddColumnProps> = (props) => {
    const [show, setShow] = useState(false);

    return (
        <div hidden={props.isHidden}>
            <Collapse in={show}>
                <AddItem setShow={setShow} addItem={props.addItem} type={props.type}></AddItem>
            </Collapse>
            <Collapse in={!show}>
                <div className="addNewColumnBtnContainer">
                    <button className="blueBtn" type="button" style={{width: '86%'}}
                            onClick={() => setShow(!show)}
                    >
                        <AiOutlineArrowLeft/> Add New Column to the Left
                    </button>
                </div>
            </Collapse>
        </div>
    );
}

export default AddColumn