import {SVGIconType} from "./SVGIconType";
import {ReactComponent as CommCommDefault} from "../../../assets/SVGIcons/CommComm-Icon-Small.svg";
import {ReactComponent as Artisan1} from "../../../assets/SVGIcons/Artisan-Icon-Small-1.svg";
import {ReactComponent as Yarn1} from "../../../assets/SVGIcons/Yarn-Icon-Small-1.svg";
import {ReactComponent as Yarn2} from "../../../assets/SVGIcons/Yarn-Icon-Small-2.svg";
import {ReactComponent as Art1} from "../../../assets/SVGIcons/Art-Icon-Small-1.svg";
import {ReactComponent as Art2} from "../../../assets/SVGIcons/Art-Icon-Small-2.svg";
import {ReactComponent as Art3} from "../../../assets/SVGIcons/Art-Icon-Small-3.svg";
import {ReactComponent as ColorFan1} from "../../../assets/SVGIcons/ColorFan-Icon-Small-1.svg";
import {ReactComponent as Draw1} from "../../../assets/SVGIcons/Draw-Icon-Small-1.svg";
import {ReactComponent as Draw2} from "../../../assets/SVGIcons/Draw-Icon-Small-2.svg";
import {ReactComponent as Draw3} from "../../../assets/SVGIcons/Draw-Icon-Small-3.svg";
import {ReactComponent as Tailor1} from "../../../assets/SVGIcons/Tailors-Icon-Small-1.svg";
import {ReactComponent as Tshirt1} from "../../../assets/SVGIcons/Tshirt-Icon-Small-1.svg";
import {ReactComponent as Tshirt2} from "../../../assets/SVGIcons/Tshirt-Icon-Small-2.svg";
import {ReactComponent as Jacket1} from "../../../assets/SVGIcons/Jacket-Icon-Small-1.svg";
import {ReactComponent as Shorts1} from "../../../assets/SVGIcons/Shorts-Icon-Small-1.svg";
import {ReactComponent as Pants1} from "../../../assets/SVGIcons/Pants-Icon-Small-1.svg";
import {ReactComponent as Dress1} from "../../../assets/SVGIcons/Dress-Icon-Small-1.svg";
import {ReactComponent as Swimsuit1} from "../../../assets/SVGIcons/Swimsuit-Icon-Small-1.svg";
import {ReactComponent as Hat1} from "../../../assets/SVGIcons/Hat-Icon-Small-1.svg";
import {ReactComponent as SantaHat1} from "../../../assets/SVGIcons/Santa-Hat-Icon-Small-1.svg";
import {ReactComponent as Scarf1} from "../../../assets/SVGIcons/Scarf-Icon-Small-1.svg";
import {ReactComponent as Shoe1} from "../../../assets/SVGIcons/Shoe-Icon-Small-1.svg";
import {ReactComponent as Boot1} from "../../../assets/SVGIcons/Boot-Icon-Small-1.svg";
import {ReactComponent as Boot2} from "../../../assets/SVGIcons/Boot-Icon-Small-2.svg";
import {ReactComponent as Heel1} from "../../../assets/SVGIcons/Heel-Icon-Small-1.svg";
import {ReactComponent as Sandals1} from "../../../assets/SVGIcons/Sandals-Icon-Small-1.svg";
import {ReactComponent as Jewelry1} from "../../../assets/SVGIcons/Jewelry-Icon-Small-1.svg";
import {ReactComponent as Jewelry2} from "../../../assets/SVGIcons/Jewelry-Icon-Small-2.svg";
import {ReactComponent as Jewelry3} from "../../../assets/SVGIcons/Jewelry-Icon-Small-3.svg";
import {ReactComponent as Jewelry4} from "../../../assets/SVGIcons/Jewelry-Icon-Small-4.svg";
import {ReactComponent as Makeup1} from "../../../assets/SVGIcons/Makeup-Icon-Small-1.svg";
import {ReactComponent as Purse1} from "../../../assets/SVGIcons/Purse-Icon-Small-1.svg";
import {ReactComponent as Bag1} from "../../../assets/SVGIcons/Bag-Icon-Small-1.svg";
import {ReactComponent as Bag2} from "../../../assets/SVGIcons/Bag-Icon-Small-2.svg";
import {ReactComponent as Glasses1} from "../../../assets/SVGIcons/Glasses-Icon-Small-1.svg";
import {ReactComponent as Glasses2} from "../../../assets/SVGIcons/Glasses-Icon-Small-2.svg";
import {ReactComponent as Glasses3} from "../../../assets/SVGIcons/Glasses-Icon-Small-3.svg";
import {ReactComponent as Glasses4} from "../../../assets/SVGIcons/Glasses-Icon-Small-4.svg";
import {ReactComponent as Glasses5} from "../../../assets/SVGIcons/Glasses-Icon-Small-5.svg";
import {ReactComponent as Sunglasses1} from "../../../assets/SVGIcons/Sunglasses-Icon-Small-1.svg";
import {ReactComponent as Sunglasses2} from "../../../assets/SVGIcons/Sunglasses-Icon-Small-2.svg";
import {ReactComponent as Sunglasses3} from "../../../assets/SVGIcons/Sunglasses-Icon-Small-3.svg";
import {ReactComponent as Sunglasses4} from "../../../assets/SVGIcons/Sunglasses-Icon-Small-4.svg";
import {ReactComponent as Phone1} from "../../../assets/SVGIcons/Phone-Icon-Small-1.svg";
import {ReactComponent as Tablet1} from "../../../assets/SVGIcons/Tablet-Icon-Small-1.svg";
import {ReactComponent as Media1} from "../../../assets/SVGIcons/Media-Icon-Small-1.svg";
import {ReactComponent as TV1} from "../../../assets/SVGIcons/TV-Icon-Small-1.svg";
import {ReactComponent as Printer1} from "../../../assets/SVGIcons/Printer-Icon-Small-1.svg";
import {ReactComponent as ThreeDPrinter1} from "../../../assets/SVGIcons/3D-Printer-Icon-Small-1.svg";
import {ReactComponent as ThreeDPrinter2} from "../../../assets/SVGIcons/3D-Printer-Icon-Small-2.svg";
import {ReactComponent as Camera1} from "../../../assets/SVGIcons/Camera-Icon-Small-1.svg";
import {ReactComponent as Camera2} from "../../../assets/SVGIcons/Camera-Icon-Small-2.svg";
import {ReactComponent as Camera3} from "../../../assets/SVGIcons/Camera-Icon-Small-3.svg";
import {ReactComponent as Photo1} from "../../../assets/SVGIcons/Photo-Icon-Small-1.svg";
import {ReactComponent as Photo2} from "../../../assets/SVGIcons/Photo-Icon-Small-2.svg";
import {ReactComponent as Photo3} from "../../../assets/SVGIcons/Photo-Icon-Small-3.svg";
import {ReactComponent as Photo4} from "../../../assets/SVGIcons/Photo-Icon-Small-4.svg";
import {ReactComponent as Video1} from "../../../assets/SVGIcons/Video-Icon-Small-1.svg";
import {ReactComponent as Video2} from "../../../assets/SVGIcons/Video-Icon-Small-2.svg";
import {ReactComponent as Video3} from "../../../assets/SVGIcons/Video-Icon-Small-3.svg";
import {ReactComponent as Music1} from "../../../assets/SVGIcons/Music-Icon-Small-1.svg";
import {ReactComponent as Music2} from "../../../assets/SVGIcons/Music-Icon-Small-2.svg";
import {ReactComponent as Music3} from "../../../assets/SVGIcons/Music-Icon-Small-3.svg";
import {ReactComponent as Music4} from "../../../assets/SVGIcons/Music-Icon-Small-4.svg";
import {ReactComponent as Music5} from "../../../assets/SVGIcons/Music-Icon-Small-5.svg";
import {ReactComponent as Music6} from "../../../assets/SVGIcons/Music-Icon-Small-6.svg";
import {ReactComponent as Code1} from "../../../assets/SVGIcons/Code-Icon-Small-1.svg";
import {ReactComponent as Science1} from "../../../assets/SVGIcons/Science-Icon-Small-1.svg";
import {ReactComponent as Science2} from "../../../assets/SVGIcons/Science-Icon-Small-2.svg";
import {ReactComponent as Science3} from "../../../assets/SVGIcons/Science-Icon-Small-3.svg";
import {ReactComponent as Magnet1} from "../../../assets/SVGIcons/Magnet-Icon-Small-1.svg";
import {ReactComponent as Sun1} from "../../../assets/SVGIcons/Sun-Icon-Small-1.svg";
import {ReactComponent as Sun2} from "../../../assets/SVGIcons/Sun-Icon-Small-2.svg";
import {ReactComponent as Moon1} from "../../../assets/SVGIcons/Moon-Icon-Small-1.svg";
import {ReactComponent as Umbrella1} from "../../../assets/SVGIcons/Umbrella-Icon-Small-1.svg";
import {ReactComponent as Umbrella2} from "../../../assets/SVGIcons/Umbrella-Icon-Small-2.svg";
import {ReactComponent as Umbrella3} from "../../../assets/SVGIcons/Umbrella-Icon-Small-3.svg";
import {ReactComponent as Calender1} from "../../../assets/SVGIcons/Calendar-Icon-Small-1.svg";
import {ReactComponent as Ruler1} from "../../../assets/SVGIcons/Ruler-Icon-Small-1.svg";
import {ReactComponent as Calculator1} from "../../../assets/SVGIcons/Calculator-Icon-Small-1.svg";
import {ReactComponent as Calculator2} from "../../../assets/SVGIcons/Calculator-Icon-Small-2.svg";
import {ReactComponent as Award1} from "../../../assets/SVGIcons/Award-Icon-Small-1.svg";
import {ReactComponent as Award2} from "../../../assets/SVGIcons/Award-Icon-Small-2.svg";
import {ReactComponent as Award3} from "../../../assets/SVGIcons/Award-Icon-Small-3.svg";
import {ReactComponent as Award4} from "../../../assets/SVGIcons/Award-Icon-Small-4.svg";
import {ReactComponent as Award5} from "../../../assets/SVGIcons/Award-Icon-Small-5.svg";
import {ReactComponent as Certificate1} from "../../../assets/SVGIcons/Certificate-Icon-Small-1.svg";
import {ReactComponent as Certificate2} from "../../../assets/SVGIcons/Certificate-Icon-Small-2.svg";
import {ReactComponent as Certificate3} from "../../../assets/SVGIcons/Certificate-Icon-Small-3.svg";
import {ReactComponent as Sticker1} from "../../../assets/SVGIcons/Sticker-Icon-Small-1.svg";
import {ReactComponent as Sticker2} from "../../../assets/SVGIcons/Sticker-Icon-Small-2.svg";
import {ReactComponent as Notebook1} from "../../../assets/SVGIcons/Notebook-Icon-Small-1.svg";
import {ReactComponent as Notebook2} from "../../../assets/SVGIcons/Notebook-Icon-Small-2.svg";
import {ReactComponent as Notebook3} from "../../../assets/SVGIcons/Notebook-Icon-Small-3.svg";
import {ReactComponent as Book1} from "../../../assets/SVGIcons/Book-Icon-Small-1.svg";
import {ReactComponent as Book2} from "../../../assets/SVGIcons/Book-Icon-Small-2.svg";
import {ReactComponent as Book3} from "../../../assets/SVGIcons/Book-Icon-Small-3.svg";
import {ReactComponent as Book4} from "../../../assets/SVGIcons/Book-Icon-Small-4.svg";
import {ReactComponent as VideoGame1} from "../../../assets/SVGIcons/Video-Game-Icon-Small-1.svg";
import {ReactComponent as VideoGame2} from "../../../assets/SVGIcons/Video-Game-Icon-Small-2.svg";
import {ReactComponent as VideoGame3} from "../../../assets/SVGIcons/Video-Game-Icon-Small-3.svg";
import {ReactComponent as VideoGame4} from "../../../assets/SVGIcons/Video-Game-Icon-Small-4.svg";
import {ReactComponent as Chess1} from "../../../assets/SVGIcons/Chess-Icon-Small-1.svg";
import {ReactComponent as BoardGame1} from "../../../assets/SVGIcons/Board-Game-Icon-Small-1.svg";
import {ReactComponent as Puzzle1} from "../../../assets/SVGIcons/Puzzle-Icon-Small-1.svg";
import {ReactComponent as Puzzle2} from "../../../assets/SVGIcons/Puzzle-Icon-Small-2.svg";
import {ReactComponent as Puzzle3} from "../../../assets/SVGIcons/Puzzle-Icon-Small-3.svg";
import {ReactComponent as Puzzle4} from "../../../assets/SVGIcons/Puzzle-Icon-Small-4.svg";
import {ReactComponent as Lego1} from "../../../assets/SVGIcons/Lego-Icon-Small-1.svg";
import {ReactComponent as Lego2} from "../../../assets/SVGIcons/Lego-Icon-Small-1.svg";
import {ReactComponent as Plushie1} from "../../../assets/SVGIcons/Plushie-Icon-Small-1.svg";
import {ReactComponent as Ball1} from "../../../assets/SVGIcons/Ball-Icon-Small-1.svg";
import {ReactComponent as Tennis1} from "../../../assets/SVGIcons/Tennis-Icon-Small-1.svg";
import {ReactComponent as Cricket1} from "../../../assets/SVGIcons/Cricket-Icon-Small-1.svg";
import {ReactComponent as Golf1} from "../../../assets/SVGIcons/Golf-Icon-Small-1.svg";
import {ReactComponent as Basketball1} from "../../../assets/SVGIcons/Basketball-Icon-Small-1.svg";
import {ReactComponent as Soccer1} from "../../../assets/SVGIcons/Soccer-Icon-Small-1.svg";
import {ReactComponent as Pool1} from "../../../assets/SVGIcons/Pool-Icon-Small-1.svg";
import {ReactComponent as Bowling1} from "../../../assets/SVGIcons/Bowling-Icon-Small-1.svg";
import {ReactComponent as Baseball1} from "../../../assets/SVGIcons/Baseball-Icon-Small-1.svg";
import {ReactComponent as Volleyball1} from "../../../assets/SVGIcons/Volleyball-Icon-Small-1.svg";
import {ReactComponent as Badminton1} from "../../../assets/SVGIcons/Badminton-Icon-Small-1.svg";
import {ReactComponent as AmericanFootball1} from "../../../assets/SVGIcons/American-Football-Icon-Small-1.svg";
import {ReactComponent as Boxing1} from "../../../assets/SVGIcons/Boxing-Icon-Small-1.svg";
import {ReactComponent as Hockey1} from "../../../assets/SVGIcons/Hockey-Icon-Small-1.svg";
import {ReactComponent as Fitness1} from "../../../assets/SVGIcons/Fitness-Icon-Small-1.svg";
import {ReactComponent as Fitness2} from "../../../assets/SVGIcons/Fitness-Icon-Small-2.svg";
import {ReactComponent as Fish1} from "../../../assets/SVGIcons/Fish-Icon-Small-1.svg";
import {ReactComponent as Whale1} from "../../../assets/SVGIcons/Whale-Icon-Small-1.svg";
import {ReactComponent as Frog1} from "../../../assets/SVGIcons/Frog-Icon-Small-1.svg";
import {ReactComponent as Frog2} from "../../../assets/SVGIcons/Frog-Icon-Small-2.svg";
import {ReactComponent as Turtle1} from "../../../assets/SVGIcons/Turtle-Icon-Small-1.svg";
import {ReactComponent as Snake1} from "../../../assets/SVGIcons/Snake-Icon-Small-1.svg";
import {ReactComponent as Bird1} from "../../../assets/SVGIcons/Bird-Icon-Small-1.svg";
import {ReactComponent as Bird2} from "../../../assets/SVGIcons/Bird-Icon-Small-2.svg";
import {ReactComponent as Rat1} from "../../../assets/SVGIcons/Rat-Icon-Small-1.svg";
import {ReactComponent as Pig1} from "../../../assets/SVGIcons/Pig-Icon-Small-1.svg";
import {ReactComponent as Rabbit1} from "../../../assets/SVGIcons/Rabbit-Icon-Small-1.svg";
import {ReactComponent as Elephant1} from "../../../assets/SVGIcons/Elephant-Icon-Small-1.svg";
import {ReactComponent as Snail1} from "../../../assets/SVGIcons/Snail-Icon-Small-1.svg";
import {ReactComponent as Cat1} from "../../../assets/SVGIcons/Cat-Icon-Small-1.svg";
import {ReactComponent as Cat2} from "../../../assets/SVGIcons/Cat-Icon-Small-2.svg";
import {ReactComponent as Dog1} from "../../../assets/SVGIcons/Dog-Icon-Small-1.svg";
import {ReactComponent as Dog2} from "../../../assets/SVGIcons/Dog-Icon-Small-2.svg";
import {ReactComponent as Dog3} from "../../../assets/SVGIcons/Dog-Icon-Small-3.svg";
import {ReactComponent as Paw1} from "../../../assets/SVGIcons/Paw-Icon-Small-1.svg";
import {ReactComponent as Dinosaur1} from "../../../assets/SVGIcons/Dinosaur-Icon-Small-1.svg";
import {ReactComponent as Dinosaur2} from "../../../assets/SVGIcons/Dinosaur-Icon-Small-2.svg";
import {ReactComponent as Fossil1} from "../../../assets/SVGIcons/Fossil-Icon-Small-1.svg";
import {ReactComponent as Unicorn1} from "../../../assets/SVGIcons/Unicorn-Icon-Small-1.svg";
import {ReactComponent as Dragon1} from "../../../assets/SVGIcons/Dragon-Icon-Small-1.svg";
import {ReactComponent as Dragon2} from "../../../assets/SVGIcons/Dragon-Icon-Small-2.svg";
import {ReactComponent as Wood1} from "../../../assets/SVGIcons/Wood-Icon-Small-1.svg";
import {ReactComponent as Plant1} from "../../../assets/SVGIcons/Plant-Icon-Small-1.svg";
import {ReactComponent as Flower1} from "../../../assets/SVGIcons/Flower-Icon-Small-1.svg";
import {ReactComponent as Flower2} from "../../../assets/SVGIcons/Flower-Icon-Small-2.svg";
import {ReactComponent as Clover1} from "../../../assets/SVGIcons/Clover-Icon-Small-1.svg";
import {ReactComponent as Easter1} from "../../../assets/SVGIcons/Easter-Icon-Small-1.svg";
import {ReactComponent as Easter2} from "../../../assets/SVGIcons/Easter-Icon-Small-2.svg";
import {ReactComponent as Easter3} from "../../../assets/SVGIcons/Easter-Icon-Small-3.svg";
import {ReactComponent as Easter4} from "../../../assets/SVGIcons/Easter-Icon-Small-4.svg";
import {ReactComponent as Easter5} from "../../../assets/SVGIcons/Easter-Icon-Small-5.svg";
import {ReactComponent as America1} from "../../../assets/SVGIcons/America-Icon-Small-1.svg";
import {ReactComponent as America2} from "../../../assets/SVGIcons/America-Icon-Small-2.svg";
import {ReactComponent as Pumpkin1} from "../../../assets/SVGIcons/Pumpkin-Icon-Small-1.svg";
import {ReactComponent as Pumpkin2} from "../../../assets/SVGIcons/Pumpkin-Icon-Small-2.svg";
import {ReactComponent as Skeleton1} from "../../../assets/SVGIcons/Pumpkin-Icon-Small-1.svg";
import {ReactComponent as Grave1} from "../../../assets/SVGIcons/Grave-Icon-Small-1.svg";
import {ReactComponent as Menorah1} from "../../../assets/SVGIcons/Menorah-Icon-Small-1.svg";
import {ReactComponent as David1} from "../../../assets/SVGIcons/David-Icon-Small-1.svg";
import {ReactComponent as ChristmasTree1} from "../../../assets/SVGIcons/Christmas-Tree-Icon-Small-1.svg";
import {ReactComponent as ChristmasTree2} from "../../../assets/SVGIcons/Christmas-Tree-Icon-Small-2.svg";
import {ReactComponent as ChristmasTree3} from "../../../assets/SVGIcons/Christmas-Tree-Icon-Small-3.svg";
import {ReactComponent as ChristmasTree4} from "../../../assets/SVGIcons/Christmas-Tree-Icon-Small-4.svg";
import {ReactComponent as Ornament1} from "../../../assets/SVGIcons/Ornament-Icon-Small-1.svg";
import {ReactComponent as Ornament2} from "../../../assets/SVGIcons/Ornament-Icon-Small-2.svg";
import {ReactComponent as Ornament3} from "../../../assets/SVGIcons/Ornament-Icon-Small-3.svg";
import {ReactComponent as Santa1} from "../../../assets/SVGIcons/Santa-Icon-Small-1.svg";
import {ReactComponent as Snowman1} from "../../../assets/SVGIcons/Snowman-Icon-Small-1.svg";
import {ReactComponent as Stocking1} from "../../../assets/SVGIcons/Stocking-Icon-Small-1.svg";
import {ReactComponent as Wreath1} from "../../../assets/SVGIcons/Wreath-Icon-Small-1.svg";
import {ReactComponent as CandyCane1} from "../../../assets/SVGIcons/Candy-Cane-Icon-Small-1.svg";
import {ReactComponent as Star1} from "../../../assets/SVGIcons/Star-Icon-Small-1.svg";
import {ReactComponent as Bow1} from "../../../assets/SVGIcons/Bow-Icon-Small-1.svg";
import {ReactComponent as Heart1} from "../../../assets/SVGIcons/Heart-Icon-Small-1.svg";
import {ReactComponent as Heart2} from "../../../assets/SVGIcons/Heart-Icon-Small-2.svg";
import {ReactComponent as Party1} from "../../../assets/SVGIcons/Party-Icon-Small-1.svg";
import {ReactComponent as Present1} from "../../../assets/SVGIcons/Present-Icon-Small-1.svg";
import {ReactComponent as Candle1} from "../../../assets/SVGIcons/Candle-Icon-Small-1.svg";
import {ReactComponent as Candle2} from "../../../assets/SVGIcons/Candle-Icon-Small-2.svg";
import {ReactComponent as Food1} from "../../../assets/SVGIcons/Food-Icon-Small-1.svg";
import {ReactComponent as Food2} from "../../../assets/SVGIcons/Food-Icon-Small-2.svg";
import {ReactComponent as Fries1} from "../../../assets/SVGIcons/Fries-Icon-Small-1.svg";
import {ReactComponent as Hamburger1} from "../../../assets/SVGIcons/Hamburger-Icon-Small-1.svg";
import {ReactComponent as Pizza1} from "../../../assets/SVGIcons/Pizza-Icon-Small-1.svg";
import {ReactComponent as Taco1} from "../../../assets/SVGIcons/Taco-Icon-Small-1.svg";
import {ReactComponent as Sandwhich1} from "../../../assets/SVGIcons/Sandwhich-Icon-Small-1.svg";
import {ReactComponent as HotDog1} from "../../../assets/SVGIcons/Hot-Dog-Icon-Small-1.svg";
import {ReactComponent as Takeout1} from "../../../assets/SVGIcons/Takeout-Icon-Small-1.svg";
import {ReactComponent as Soda1} from "../../../assets/SVGIcons/Soda-Icon-Small-1.svg";
import {ReactComponent as Coffee1} from "../../../assets/SVGIcons/Coffee-Icon-Small-1.svg";
import {ReactComponent as Tea1} from "../../../assets/SVGIcons/Tea-Icon-Small-1.svg";
import {ReactComponent as Beer1} from "../../../assets/SVGIcons/Beer-Icon-Small-1.svg";
import {ReactComponent as Wine1} from "../../../assets/SVGIcons/Wine-Icon-Small-1.svg";
import {ReactComponent as Bread1} from "../../../assets/SVGIcons/Bread-Icon-Small-1.svg";
import {ReactComponent as Cake1} from "../../../assets/SVGIcons/Cake-Icon-Small-1.svg";
import {ReactComponent as Cake2} from "../../../assets/SVGIcons/Cake-Icon-Small-2.svg";
import {ReactComponent as Cupcake1} from "../../../assets/SVGIcons/Cupcake-Icon-Small-1.svg";
import {ReactComponent as Cupcake2} from "../../../assets/SVGIcons/Cupcake-Icon-Small-2.svg";
import {ReactComponent as Donut1} from "../../../assets/SVGIcons/Donut-Icon-Small-1.svg";
import {ReactComponent as Donut2} from "../../../assets/SVGIcons/Donut-Icon-Small-2.svg";
import {ReactComponent as Cookie1} from "../../../assets/SVGIcons/Cookie-Icon-Small-1.svg";
import {ReactComponent as Biscuit1} from "../../../assets/SVGIcons/Biscuit-Icon-Small-1.svg";
import {ReactComponent as Gingerbread1} from "../../../assets/SVGIcons/Gingerbread-Icon-Small-1.svg";
import {ReactComponent as Chocolate1} from "../../../assets/SVGIcons/Chocolate-Icon-Small-1.svg";
import {ReactComponent as Candy1} from "../../../assets/SVGIcons/Candy-Icon-Small-1.svg";
import {ReactComponent as Candy2} from "../../../assets/SVGIcons/Candy-Icon-Small-2.svg";
import {ReactComponent as Popsicle1} from "../../../assets/SVGIcons/Popsicle-Icon-Small-1.svg";
import {ReactComponent as IceCream1} from "../../../assets/SVGIcons/Ice-Cream-Icon-Small-1.svg";
import {ReactComponent as IceCream2} from "../../../assets/SVGIcons/Ice-Cream-Icon-Small-2.svg";
import {ReactComponent as Apple1} from "../../../assets/SVGIcons/Apple-Icon-Small-1.svg";
import {ReactComponent as Strawberry1} from "../../../assets/SVGIcons/Strawberry-Icon-Small-1.svg";
import {ReactComponent as Banana1} from "../../../assets/SVGIcons/Banana-Icon-Small-1.svg";
import {ReactComponent as Cherry1} from "../../../assets/SVGIcons/Cherry-Icon-Small-1.svg";
import {ReactComponent as Citrus1} from "../../../assets/SVGIcons/Citrus-Icon-Small-1.svg";
import {ReactComponent as Car1} from "../../../assets/SVGIcons/Car-Icon-Small-1.svg";
import {ReactComponent as Truck1} from "../../../assets/SVGIcons/Truck-Icon-Small-1.svg";
import {ReactComponent as Boat1} from "../../../assets/SVGIcons/Boat-Icon-Small-1.svg";
import {ReactComponent as Boat2} from "../../../assets/SVGIcons/Boat-Icon-Small-2.svg";
import {ReactComponent as Plane1} from "../../../assets/SVGIcons/Plane-Icon-Small-1.svg";
import {ReactComponent as Plane2} from "../../../assets/SVGIcons/Plane-Icon-Small-2.svg";
import {ReactComponent as Rocket1} from "../../../assets/SVGIcons/Rocket-Icon-Small-1.svg";
import {ReactComponent as Rocket2} from "../../../assets/SVGIcons/Rocket-Icon-Small-2.svg";
import {ReactComponent as Rocket3} from "../../../assets/SVGIcons/Rocket-Icon-Small-3.svg";
import {ReactComponent as Anchor1} from "../../../assets/SVGIcons/Anchor-Icon-Small-1.svg";
import {ReactComponent as Island1} from "../../../assets/SVGIcons/Island-Icon-Small-1.svg";
import {ReactComponent as Shell1} from "../../../assets/SVGIcons/Shell-Icon-Small-1.svg";
import {ReactComponent as Shell2} from "../../../assets/SVGIcons/Shell-Icon-Small-2.svg";
import {ReactComponent as Mountain1} from "../../../assets/SVGIcons/Mountain-Icon-Small-1.svg";
import {ReactComponent as Home1} from "../../../assets/SVGIcons/Home-Icon-Small-1.svg";
import {ReactComponent as Translation1} from "../../../assets/SVGIcons/Translation-Icon-Small-1.svg";
import {ReactComponent as Translation2} from "../../../assets/SVGIcons/Translation-Icon-Small-2.svg";
import {ReactComponent as Translation3} from "../../../assets/SVGIcons/Translation-Icon-Small-3.svg";
import {ReactComponent as Person1} from "../../../assets/SVGIcons/Person-Icon-Small-1.svg";
import {ReactComponent as Person2} from "../../../assets/SVGIcons/Person-Icon-Small-2.svg";
import {ReactComponent as Person3} from "../../../assets/SVGIcons/Person-Icon-Small-3.svg";
import {ReactComponent as Person4} from "../../../assets/SVGIcons/Person-Icon-Small-4.svg";
import {ReactComponent as Person5} from "../../../assets/SVGIcons/Person-Icon-Small-5.svg";
import {ReactComponent as Person6} from "../../../assets/SVGIcons/Person-Icon-Small-6.svg";
import {ReactComponent as Person7} from "../../../assets/SVGIcons/Person-Icon-Small-7.svg";
import {ReactComponent as Person8} from "../../../assets/SVGIcons/Person-Icon-Small-8.svg";
import {ReactComponent as Person9} from "../../../assets/SVGIcons/Person-Icon-Small-9.svg";
import {ReactComponent as Person10} from "../../../assets/SVGIcons/Person-Icon-Small-10.svg";
import {ReactComponent as Person11} from "../../../assets/SVGIcons/Person-Icon-Small-11.svg";
import {ReactComponent as Person12} from "../../../assets/SVGIcons/Person-Icon-Small-12.svg";
import {ReactComponent as Person13} from "../../../assets/SVGIcons/Person-Icon-Small-13.svg";
import {ReactComponent as Person14} from "../../../assets/SVGIcons/Person-Icon-Small-14.svg";
import {ReactComponent as Person15} from "../../../assets/SVGIcons/Person-Icon-Small-15.svg";
import {ReactComponent as Person16} from "../../../assets/SVGIcons/Person-Icon-Small-16.svg";
import {ReactComponent as HappyFace1} from "../../../assets/SVGIcons/Happy-Face-Icon-Small-1.svg";
import {ReactComponent as NeutralFace1} from "../../../assets/SVGIcons/Neutral-Face-Icon-Small-1.svg";
import {ReactComponent as UnhappyFace1} from "../../../assets/SVGIcons/Unhappy-Face-Icon-Small-1.svg";
import {ReactComponent as CheckMark1} from "../../../assets/SVGIcons/Check-Mark-Icon-Small-1.svg";
import {ReactComponent as XMark1} from "../../../assets/SVGIcons/X-Mark-Icon-Small-1.svg";
import {ReactComponent as Stop1} from "../../../assets/SVGIcons/Stop-Icon-Small-1.svg";
import {ReactComponent as Stop2} from "../../../assets/SVGIcons/Stop-Icon-Small-2.svg";
import {ReactComponent as Firewall1} from "../../../assets/SVGIcons/Firewall-Icon-Small-1.svg";

// export default SVGIcon;
//https://www.phind.com/search?cache=nr4itesw5knio3onpojsknwx
export const SVGIconList: SVGIconType[] = [
    // {title: 'CommCommDefault', icon: CommCommDefault},
    {title: 'CommCommDefault', searchText: 'commcomm art', icon: CommCommDefault},
    {title: 'Artisan1', searchText: 'artisan hand made crafter', icon: Artisan1},
    {title: 'Yarn1', searchText: 'yarn spool knitting crocheting', icon: Yarn1},
    {title: 'Yarn2', searchText: 'yarn spool knitting crocheting', icon: Yarn2},
    {title: 'Art1', searchText: 'painting art paintbrush', icon: Art1},
    {title: 'Art2', searchText: 'painting art paintbrush', icon: Art2},
    {title: 'Art3', searchText: 'painting art paintbrush', icon: Art3},
    {title: 'ColorFan1', searchText: 'color swatch fan graphic designer', icon: ColorFan1},
    {title: 'Draw1', searchText: 'measure ruler pencil draw art', icon: Draw1},
    {title: 'Draw1', searchText: 'graphic tablet digital draw art', icon: Draw2},
    {title: 'Draw1', searchText: 'ipad tablet digital draw art', icon: Draw3},
    {title: 'Tailor1', searchText: 'tailor sewing', icon: Tailor1},
    {title: 'Tshirt1', searchText: 'clothing tshirt shirt', icon: Tshirt1},
    {title: 'Tshirt2', searchText: 'clothing tshirt shirt', icon: Tshirt2},
    {title: 'Jacket1', searchText: 'clothing jacket hoodie', icon: Jacket1},
    {title: 'Shorts1', searchText: 'clothing shorts', icon: Shorts1},
    {title: 'Pants1', searchText: 'clothing pants jeans', icon: Pants1},
    {title: 'Dress1', searchText: 'dress gown clothing', icon: Dress1},
    {title: 'Swimsuit1', searchText: 'swimsuit bathing suit swimwear bikini clothing', icon: Swimsuit1},
    {title: 'Hat1', searchText: 'hat cap clothing', icon: Hat1},
    {title: 'SantaHat1', searchText: 'santa hat christmas clothing holiday', icon: SantaHat1},
    {title: 'Scarf1', searchText: 'scarf knitting crocheting winter clothing', icon: Scarf1},
    {title: 'Shoe1', searchText: 'shoes sneakers runners running', icon: Shoe1},
    {title: 'Boot1', searchText: 'boots hiking shoes', icon: Boot1},
    {title: 'Boot2', searchText: 'boots heels shoes', icon: Boot2},
    {title: 'Heel1', searchText: 'heels shoes', icon: Heel1},
    {title: 'Sandals1', searchText: 'sandals flip flops shoes', icon: Sandals1},
    {title: 'Jewelry1', searchText: 'diamond jewelry jewel', icon: Jewelry1},
    {title: 'Jewelry2', searchText: 'ring engagement marriage wedding jewelry', icon: Jewelry2},
    {title: 'Jewelry3', searchText: 'necklace jewelry', icon: Jewelry3},
    {title: 'Jewelry4', searchText: 'ring engagement marriage wedding jewelry', icon: Jewelry4},
    {title: 'Makeup1', searchText: 'makeup beauty lipstick', icon: Makeup1},
    {title: 'Purse1', searchText: 'purse bag hangbag', icon: Purse1},
    {title: 'Bag1', searchText: 'bookbag bag backpack school travel', icon: Bag1},
    {title: 'Bag2', searchText: 'beach bag tote travel', icon: Bag2},
    {title: 'Glasses1', searchText: 'glasses eyeglasses', icon: Glasses1},
    {title: 'Glasses2', searchText: 'glasses eyeglasses', icon: Glasses2},
    {title: 'Glasses3', searchText: 'glasses eyeglasses', icon: Glasses3},
    {title: 'Glasses4', searchText: 'glasses eyeglasses', icon: Glasses4},
    {title: 'Glasses5', searchText: 'glasses eyeglasses april fools', icon: Glasses5},
    {title: 'Sunglasses1', searchText: 'sunglasses glasses shades', icon: Sunglasses1},
    {title: 'Sunglasses2', searchText: 'sunglasses glasses shades', icon: Sunglasses2},
    {title: 'Sunglasses3', searchText: 'sunglasses glasses shades', icon: Sunglasses3},
    {title: 'Sunglasses4', searchText: 'sunglasses glasses shades', icon: Sunglasses4},
    {title: 'Phone1', searchText: 'phone smartphone technology', icon: Phone1},
    {title: 'Tablet1', searchText: 'tablet ipad technology', icon: Tablet1},
    {title: 'Media1', searchText: 'media technology computer', icon: Media1},
    {title: 'TV1', searchText: 'television tv media technology news communication', icon: TV1},
    {title: 'Printer1', searchText: 'media technology printer digital download', icon: Printer1},
    {title: 'ThreeDPrinter1', searchText: 'media technology 3D printer CAD', icon: ThreeDPrinter1},
    {title: 'ThreeDPrinter2', searchText: 'media technology 3D printer CAD', icon: ThreeDPrinter2},
    {title: 'Camera1', searchText: 'photo camera photography picture', icon: Camera1},
    {title: 'Camera2', searchText: 'photo camera photography picture', icon: Camera2},
    {title: 'Camera3', searchText: 'photo camera photography picture apeture', icon: Camera3},
    {title: 'Photo1', searchText: 'photo photography picture wedding engagement', icon: Photo1},
    {title: 'Photo2', searchText: 'photo photography picture landscape', icon: Photo2},
    {title: 'Photo3', searchText: 'photo photography picture portrait', icon: Photo3},
    {title: 'Photo4', searchText: 'photo photography picture plant', icon: Photo4},
    {title: 'Video1', searchText: 'video editing film production', icon: Video1},
    {title: 'Video2', searchText: 'video editing film production', icon: Video2},
    {title: 'Video3', searchText: 'video camera film production', icon: Video3},
    {title: 'Music1', searchText: 'music dj disc', icon: Music1},
    {title: 'Music2', searchText: 'music dj disc', icon: Music2},
    {title: 'Music3', searchText: 'music dj disc record player', icon: Music3},
    {title: 'Music4', searchText: 'music disc record player', icon: Music4},
    {title: 'Music5', searchText: 'music dj headphones', icon: Music5},
    {title: 'Music6', searchText: 'music note', icon: Music6},
    {title: 'Code1', searchText: 'coder programmer developer computer science technology stem', icon: Code1},
    {title: 'Science1', searchText: 'science technology stem atom', icon: Science1},
    {title: 'Science2', searchText: 'science technology stem flask', icon: Science2},
    {title: 'Science3', searchText: 'science technology stem flask', icon: Science3},
    {title: 'Magnet1', searchText: 'magnet science', icon: Magnet1},
    {title: 'Sun1', searchText: 'sun stars weather day light', icon: Sun1},
    {title: 'Sun2', searchText: 'sun stars weather day light', icon: Sun2},
    {title: 'Moon1', searchText: 'moon stars horoscope weather night', icon: Moon1},
    {title: 'Umbrella1', searchText: 'umbrella rain weather', icon: Umbrella1},
    {title: 'Umbrella2', searchText: 'umbrella rain weather', icon: Umbrella2},
    {title: 'Umbrella3', searchText: 'umbrella rain weather', icon: Umbrella3},
    {title: 'Calender1', searchText: 'calender date event month', icon: Calender1},
    {title: 'Ruler1', searchText: 'ruler math measurement', icon: Ruler1},
    {title: 'Calculator1', searchText: 'calculator math equations', icon: Calculator1},
    {title: 'Calculator2', searchText: 'calculator math equations', icon: Calculator2},
    {title: 'Award1', searchText: 'award trophy', icon: Award1},
    {title: 'Award2', searchText: 'award trophy', icon: Award2},
    {title: 'Award3', searchText: 'award trophy', icon: Award3},
    {title: 'Award4', searchText: 'award trophy', icon: Award4},
    {title: 'Award5', searchText: 'award trophy', icon: Award5},
    {title: 'Certificate1', searchText: 'award certificate diploma', icon: Certificate1},
    {title: 'Certificate2', searchText: 'award certificate diploma', icon: Certificate2},
    {title: 'Certificate3', searchText: 'award certificate diploma', icon: Certificate3},
    {title: 'Sticker1', searchText: 'sticker decal label stationary', icon: Sticker1},
    {title: 'Sticker2', searchText: 'sticker decal label stationary', icon: Sticker2},
    {title: 'Notebook1', searchText: 'notebook journal stationary', icon: Notebook1},
    {title: 'Notebook2', searchText: 'notebook journal stationary', icon: Notebook2},
    {title: 'Notebook3', searchText: 'notebook journal stationary', icon: Notebook3},
    {title: 'Book1', searchText: 'book journal reading library', icon: Book1},
    {title: 'Book2', searchText: 'book journal reading library', icon: Book2},
    {title: 'Book3', searchText: 'book journal reading library', icon: Book3},
    {title: 'Book4', searchText: 'book journal reading library', icon: Book4},
    {title: 'VideoGame1', searchText: 'video game controller switch playstation xbox', icon: VideoGame1},
    {title: 'VideoGame2', searchText: 'video game controller switch playstation xbox', icon: VideoGame2},
    {title: 'VideoGame3', searchText: 'video game controller switch playstation xbox', icon: VideoGame3},
    {title: 'VideoGame4', searchText: 'video game controller switch playstation xbox', icon: VideoGame4},
    {title: 'Chess1', searchText: 'chess, knight, horse, board game', icon: Chess1},
    {title: 'BoardGame1', searchText: 'meeple piece board game', icon: BoardGame1},
    {title: 'Puzzle1', searchText: 'puzzle piece', icon: Puzzle1},
    {title: 'Puzzle2', searchText: 'puzzle piece', icon: Puzzle2},
    {title: 'Puzzle3', searchText: 'puzzle piece', icon: Puzzle3},
    {title: 'Puzzle4', searchText: 'puzzle piece', icon: Puzzle4},
    {title: 'Lego1', searchText: 'lego block building piece toy', icon: Lego1},
    {title: 'Lego2', searchText: 'lego block building piece toy', icon: Lego2},
    {title: 'Plushie1', searchText: 'plushie stuffed animal teddy bear toy', icon: Plushie1},
    {title: 'Ball1', searchText: 'beach ball toy', icon: Ball1},
    {title: 'Tennis1', searchText: 'tennis ball sport', icon: Tennis1},
    {title: 'Cricket1', searchText: 'cricket ball sport', icon: Cricket1},
    {title: 'Golf1', searchText: 'golf ball sport', icon: Golf1},
    {title: 'Basketball1', searchText: 'basketball ball sport', icon: Basketball1},
    {title: 'Soccer1', searchText: 'soccer ball sport', icon: Soccer1},
    {title: 'Pool1', searchText: 'pool eight ball sport', icon: Pool1},
    {title: 'Bowling1', searchText: 'bowling ball sport', icon: Bowling1},
    {title: 'Baseball1', searchText: 'baseball ball sport', icon: Baseball1},
    {title: 'Volleyball1', searchText: 'volleyball ball sport', icon: Volleyball1},
    {title: 'Badminton1', searchText: 'badminton birdie shuttlecock sport', icon: Badminton1},
    {title: 'AmericanFootball1', searchText: 'american football sport', icon: AmericanFootball1},
    {title: 'Boxing1', searchText: 'boxing gloves sport', icon: Boxing1},
    {title: 'Hockey1', searchText: 'hockey puck sport', icon: Hockey1},
    {title: 'Fitness1', searchText: 'fitness health yoga', icon: Fitness1},
    {title: 'Fitness2', searchText: 'fitness health dumbell weight', icon: Fitness2},
    {title: 'Fish1', searchText: 'fish fishing seafood aquatic aquarium pet animal', icon: Fish1},
    {title: 'Whale1', searchText: 'whale fish aquatic animal', icon: Whale1},
    {title: 'Frog1', searchText: 'frog amphibian aquatic animal', icon: Frog1},
    {title: 'Frog2', searchText: 'frog amphibian aquatic animal', icon: Frog2},
    {title: 'Turtle1', searchText: 'sea turtle tortise reptile aquatic aquarium pet slow animal', icon: Turtle1},
    {title: 'Snake1', searchText: 'snake reptile animal pet', icon: Snake1},
    {title: 'Bird1', searchText: 'bird animal pet', icon: Bird1},
    {title: 'Bird2', searchText: 'bird animal pet', icon: Bird2},
    {title: 'Rat1', searchText: 'rat mouse mice animal pet', icon: Rat1},
    {title: 'Pig1', searchText: 'pig hog animal farm', icon: Pig1},
    {title: 'Rabbit1', searchText: 'rabbit bunny easter fast', icon: Rabbit1},
    {title: 'Elephant1', searchText: 'elephant', icon: Elephant1},
    {title: 'Snail1', searchText: 'snail slow animal', icon: Snail1},
    {title: 'Cat1', searchText: 'cat kitten kitty meow feline pet animal', icon: Cat1},
    {title: 'Cat2', searchText: 'cat kitten kitty meow feline pet animal', icon: Cat2},
    {title: 'Dog1', searchText: 'dog puppy bark woof animal pet', icon: Dog1},
    {title: 'Dog2', searchText: 'dog puppy bark woof animal pet', icon: Dog2},
    {title: 'Dog3', searchText: 'dog puppy bark woof animal pet', icon: Dog3},
    {title: 'Paw1', searchText: 'cat kitten kitty meow feline paw dog puppy bark woof animal pet', icon: Paw1},
    {title: 'Dinosaur1', searchText: 'dinosaur ancient fossil trex jurassic', icon: Dinosaur1},
    {title: 'Dinosaur2', searchText: 'dinosaur ancient fossil stegosaurus jurassic', icon: Dinosaur2},
    {title: 'Fossil1', searchText: 'dinosaur ancient fossil trex jurassic', icon: Fossil1},
    {title: 'Unicorn1', searchText: 'unicorn fantasy horse', icon: Unicorn1},
    {title: 'Dragon1', searchText: 'dragon d&d fantasy', icon: Dragon1},
    {title: 'Dragon2', searchText: 'dragon d&d serpent fantasy', icon: Dragon2},
    {title: 'Wood1', searchText: 'wood plank', icon: Wood1},
    {title: 'Plant1', searchText: 'plant pot sprout', icon: Plant1},
    {title: 'Flower1', searchText: 'flower floral petal', icon: Flower1},
    {title: 'Flower2', searchText: 'flower floral petal', icon: Flower2},
    {title: 'Clover1', searchText: 'four leaf clover lucky saint patricks holiday', icon: Clover1},
    {title: 'Easter1', searchText: 'easter egg holiday', icon: Easter1},
    {title: 'Easter2', searchText: 'easter egg holiday', icon: Easter2},
    {title: 'Easter3', searchText: 'easter egg holiday', icon: Easter3},
    {title: 'Easter4', searchText: 'easter egg holiday', icon: Easter4},
    {title: 'Easter5', searchText: 'easter egg holiday basket', icon: Easter5},
    {title: 'America1', searchText: 'america fourth of july 4th independence holiday patriot', icon: America1},
    {title: 'America2', searchText: 'america fourth of july 4th independence holiday patriot', icon: America2},
    {title: 'Pumpkin1', searchText: 'pumpkin fall halloween holiday', icon: Pumpkin1},
    {title: 'Pumpkin2', searchText: 'pumpkin fall halloween holiday', icon: Pumpkin2},
    {title: 'Skeleton1', searchText: 'skeleton bones fall halloween holiday', icon: Skeleton1},
    {title: 'Grave1', searchText: 'grave headstone fall halloween holiday', icon: Grave1},
    {title: 'Menorah1', searchText: 'hannukah channukah menorah jewish holiday', icon: Menorah1},
    {title: 'David1', searchText: 'hannukah channukah star david jewish holiday', icon: David1},
    {title: 'ChristmasTree1', searchText: 'christmas tree pine holiday', icon: ChristmasTree1},
    {title: 'ChristmasTree2', searchText: 'christmas tree pine holiday', icon: ChristmasTree2},
    {title: 'ChristmasTree3', searchText: 'christmas tree pine holiday', icon: ChristmasTree3},
    {title: 'ChristmasTree4', searchText: 'christmas tree pine holiday', icon: ChristmasTree4},
    {title: 'Ornament1', searchText: 'christmas tree ornament holiday', icon: Ornament1},
    {title: 'Ornament2', searchText: 'christmas tree ornament holiday', icon: Ornament2},
    {title: 'Ornament3', searchText: 'christmas tree ornament holiday', icon: Ornament3},
    {title: 'Santa1', searchText: 'christmas santa holiday', icon: Santa1},
    {title: 'Snowman1', searchText: 'christmas snowman holiday', icon: Snowman1},
    {title: 'Stocking1', searchText: 'christmas stocking holiday', icon: Stocking1},
    {title: 'Wreath1', searchText: 'christmas wreath decor holiday', icon: Wreath1},
    {title: 'CandyCane1', searchText: 'christmas candy cane sweet treat holiday', icon: CandyCane1},
    {title: 'Star1', searchText: 'christmas star holiday', icon: Star1},
    {title: 'Bow1', searchText: 'christmas bow present', icon: Bow1},
    {title: 'Heart1', searchText: 'heart love valentines health holiday', icon: Heart1},
    {title: 'Heart2', searchText: 'heart love valentines health holiday', icon: Heart2},
    {title: 'Party1', searchText: 'party birthday new years holiday', icon: Party1},
    {title: 'Present1', searchText: 'gift present birthday christmas holiday', icon: Present1},
    {title: 'Candle1', searchText: 'candle light wax', icon: Candle1},
    {title: 'Candle2', searchText: 'candle light wax', icon: Candle2},
    {title: 'Food1', searchText: 'eat food knife fork', icon: Food1},
    {title: 'Food2', searchText: 'eat food knife fork', icon: Food2},
    {title: 'Fries1', searchText: 'fast food fries potatoes American', icon: Fries1},
    {title: 'Hamburger1', searchText: 'fast food hamburger sandwhich American', icon: Hamburger1},
    {title: 'Pizza1', searchText: 'fast food pizza Italian', icon: Pizza1},
    {title: 'Taco1', searchText: 'fast food taco Mexican', icon: Taco1},
    {title: 'Sandwhich1', searchText: 'food sandwhich', icon: Sandwhich1},
    {title: 'HotDog1', searchText: 'food hot dog', icon: HotDog1},
    {title: 'Takeout1', searchText: 'food takeout Chinese', icon: Takeout1},
    {title: 'Soda1', searchText: 'drink soda pop', icon: Soda1},
    {title: 'Coffee1', searchText: 'drink coffee caffine', icon: Coffee1},
    {title: 'Tea1', searchText: 'drink tea caffine', icon: Tea1},
    {title: 'Beer1', searchText: 'drink beer alcohol', icon: Beer1},
    {title: 'Wine1', searchText: 'drink wine alcohol', icon: Wine1},
    {title: 'Bread1', searchText: 'bread loaf baking food', icon: Bread1},
    {title: 'Cake1', searchText: 'cake baking birthday celebration food', icon: Cake1},
    {title: 'Cake2', searchText: 'cake baking wedding celebration food', icon: Cake2},
    {title: 'Cupcake1', searchText: 'cupcake baking pastry food', icon: Cupcake1},
    {title: 'Cupcake2', searchText: 'cupcake baking pastry birthday food', icon: Cupcake2},
    {title: 'Donut1', searchText: 'donut baking pastry food', icon: Donut1},
    {title: 'Donut2', searchText: 'donut baking pastry food', icon: Donut2},
    {title: 'Cookie1', searchText: 'cookie baking pastry food', icon: Cookie1},
    {title: 'Biscuit1', searchText: 'biscuit cookie baking food', icon: Biscuit1},
    {title: 'Gingerbread1', searchText: 'gingerbread cookie baking christmas holiday food', icon: Gingerbread1},
    {title: 'Chocolate1', searchText: 'chocolate bar sweets cocoa food', icon: Chocolate1},
    {title: 'Candy1', searchText: 'candy treat sweets halloween food', icon: Candy1},
    {title: 'Candy2', searchText: 'candy treat sweets halloween food', icon: Candy2},
    {title: 'Popsicle1', searchText: 'popsicle sweet frozen treat food', icon: Popsicle1},
    {title: 'IceCream1', searchText: 'ice cream dairy sweet frozen treat food', icon: IceCream1},
    {title: 'IceCream2', searchText: 'ice cream dairy sweet frozen treat food', icon: IceCream2},
    {title: 'Apple1', searchText: 'apple fruit food', icon: Apple1},
    {title: 'Strawberry1', searchText: 'strawberry berry fruit food', icon: Strawberry1},
    {title: 'Banana1', searchText: 'banana fruit food', icon: Banana1},
    {title: 'Cherry1', searchText: 'cherry fruit food', icon: Cherry1},
    {title: 'Citrus1', searchText: 'citrus orange lemon lime grapefruit fruit food', icon: Citrus1},
    {title: 'Car1', searchText: 'car driving travel', icon: Car1},
    {title: 'Truck1', searchText: 'semi truck driving travel', icon: Truck1},
    {title: 'Boat1', searchText: 'sailboat boat sailing travel', icon: Boat1},
    {title: 'Boat2', searchText: 'sailboat boat sailing cruising travel', icon: Boat2},
    {title: 'Plane1', searchText: 'plane airplane airport travel', icon: Plane1},
    {title: 'Plane2', searchText: 'plane airplane airport travel', icon: Plane2},
    {title: 'Rocket1', searchText: 'rocket space launch', icon: Rocket1},
    {title: 'Rocket2', searchText: 'rocket space launch', icon: Rocket2},
    {title: 'Rocket3', searchText: 'rocket space launch', icon: Rocket3},
    {title: 'Anchor1', searchText: 'anchor ocean ship boat', icon: Anchor1},
    {title: 'Island1', searchText: 'island tropical beach palm tree', icon: Island1},
    {title: 'Shell1', searchText: 'sea shell tropical beach', icon: Shell1},
    {title: 'Shell2', searchText: 'sea shell conch tropical beach', icon: Shell2},
    {title: 'Mountain1', searchText: 'mountain land hiking', icon: Mountain1},
    {title: 'Home1', searchText: 'home house residence address', icon: Home1},
    {title: 'Translation1', searchText: 'translation language words speech', icon: Translation1},
    {title: 'Translation2', searchText: 'translation language words speech', icon: Translation2},
    {title: 'Translation3', searchText: 'translation language words speech', icon: Translation3},
    {title: 'Person1', searchText: 'person people client human baby boy', icon: Person1},
    {title: 'Person2', searchText: 'person people client human baby girl', icon: Person2},
    {title: 'Person3', searchText: 'person people client human boy child kid', icon: Person3},
    {title: 'Person4', searchText: 'person people client human girl child kid', icon: Person4},
    {title: 'Person5', searchText: 'person people client human boy man adult', icon: Person5},
    {title: 'Person6', searchText: 'person people client human girl woman adult', icon: Person6},
    {title: 'Person7', searchText: 'person people client human boy man adult', icon: Person7},
    {title: 'Person8', searchText: 'person people client human girl woman adult', icon: Person8},
    {title: 'Person9', searchText: 'person people client human boy man adult', icon: Person9},
    {title: 'Person10', searchText: 'person people client human girl woman adult', icon: Person10},
    {title: 'Person11', searchText: 'person people client human boy man adult', icon: Person11},
    {title: 'Person12', searchText: 'person people client human girl woman adult', icon: Person12},
    {title: 'Person13', searchText: 'person people client human boy man adult', icon: Person13},
    {title: 'Person14', searchText: 'person people client human girl woman adult', icon: Person14},
    {title: 'Person15', searchText: 'person people client human boy man adult grandpa', icon: Person15},
    {title: 'Person16', searchText: 'person people client human girl woman adult grandma', icon: Person16},
    {title: 'HappyFace1', searchText: 'happy face good feedback review', icon: HappyFace1},
    {title: 'NeutralFace1', searchText: 'neutral face feedback review', icon: NeutralFace1},
    {title: 'UnhappyFace1', searchText: 'sad face bad feedback review', icon: UnhappyFace1},
    {title: 'CheckMark1', searchText: 'check checkmark approval correct good yes', icon: CheckMark1},
    {title: 'XMark1', searchText: 'x disapproval wrong bad no', icon: XMark1},
    {title: 'Stop1', searchText: 'stop sign bad no', icon: Stop1},
    {title: 'Stop2', searchText: 'stop sign bad no', icon: Stop2},
    {title: 'Firewall1', searchText: 'firewall sign stop bad no', icon: Firewall1}
]