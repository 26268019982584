import React, {useEffect, useState} from 'react'
import '../../../scss/ProfileTab.scss'
import {useLocation, useNavigate} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {navPath} from "../../Helpers";

interface IProfileTab {
    setShowProfile: (show: boolean) => void;
}

const ProfileTab: React.FunctionComponent<IProfileTab> = (props) => {
    const location = useLocation();
    const [selectEditProfile, setSelectEditProfile] = useState(true);
    const [selectViewProfile, setSelectViewProfile] = useState(false);
    const [selectManageSubscription, setSelectManageSubscription] = useState(false);
    const [selectPriceBreakdown, setSelectPriceBreakdown] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setSelectEditProfile(location.pathname === navPath.editProfile);
        setSelectViewProfile(location.pathname === navPath.profile)
        setSelectManageSubscription(location.pathname === navPath.manageSubscription)
        setSelectPriceBreakdown(location.pathname === navPath.pricebreakdown);
    }, [location.pathname])

    const goBackHome = () => {
        props.setShowProfile(false)
        navigate(navPath.projects)
    }

    const chooseEditProfile = () => {
        setSelectEditProfile(true);
        setSelectViewProfile(false);
        setSelectManageSubscription(false);
        setSelectPriceBreakdown(false);
        navigate(navPath.editProfile)
    }

    const chooseViewProfile = () => {
        setSelectEditProfile(false);
        setSelectViewProfile(true);
        setSelectManageSubscription(false);
        setSelectPriceBreakdown(false);
        navigate(navPath.profile)
    }

    const chooseManageSubscription = () => {
        setSelectEditProfile(false);
        setSelectViewProfile(false);
        setSelectManageSubscription(true);
        setSelectPriceBreakdown(false);
        navigate(navPath.manageSubscription)
    }

    const choosePriceBreakdown = () => {
        setSelectEditProfile(false);
        setSelectViewProfile(false);
        setSelectManageSubscription(false);
        setSelectPriceBreakdown(true);
        navigate(navPath.pricebreakdown)
    }

    return (
        <div>
            <button className="blueBtn" style={{margin: '15px 0px 25px'}} onClick={() => goBackHome()} type="button">
                <AiOutlineArrowLeft/> Return to Main Page
            </button>
            <div className={selectEditProfile ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => chooseEditProfile()}>
                <label>Edit Account</label>
            </div>
            <div className={selectViewProfile ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => chooseViewProfile()}>
                <label>Preview Public Profile</label>
            </div>
            <div className={selectManageSubscription ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => chooseManageSubscription()}>
                <label>Manage Subscription</label>
            </div>
            <div className={selectPriceBreakdown ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => choosePriceBreakdown()}>
                <label>Price Breakdowns</label>
            </div>
        </div>
    );
}

export default ProfileTab