import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {initializeApp} from 'firebase/app'
// import { getStorage } from "firebase/storage";
import {config} from './config'

const Firebase = initializeApp(config.firebaseConfig)

export const Providers = {
    google: new GoogleAuthProvider()
}
// const storage = getStorage(Firebase)
export const auth = getAuth();
export default Firebase;