import {cancelTokenSource, requests} from './base/http-common'
import {ProjectData} from "../data/ProjectData";
import {Column} from "../data/Column";
import {ProjectScreenDto} from "../data/ProjectScreenDto";

class ProjectDataSource {

    BASEURL = "project"

    Post = {
        create: (accountId: string, title: string, icon: string, color: string, backgroundColor: string, backgroundImage: string): Promise<ProjectScreenDto> =>
            requests.post(`${this.BASEURL}`, new ProjectData({
                    accountId: accountId,
                    title: title,
                    iconImage: icon,
                    iconColor: color,
                    iconBackgroundColor: backgroundColor,
                    backgroundImage: backgroundImage
                }), cancelTokenSource.token
            ),
        createColumn: (accountId: string, projectId: string, title: string): Promise<Column> =>
            requests.post(`${this.BASEURL}/column`, new Column({
                    accountId: accountId,
                    projectId: projectId,
                    title: title
                }), cancelTokenSource.token
            )
    };

    Get = {
        // fromId: (id: string): Promise<ProjectData> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        // details: (id: string): Promise<ProjectScreenData> => requests.get(`${this.BASEURL}/details/${id}`, cancelTokenSource.token),
        // allDetailsFromAccount: (accountId: string): Promise<ProjectScreenDto[]> => requests.get(`${this.BASEURL}/details/all/${accountId}`, cancelTokenSource.token),
        allArchiveFromAccount: (accountId: string): Promise<ProjectData[]> => requests.get(`${this.BASEURL}/archive/all/${accountId}`, cancelTokenSource.token),
        // allFromAccount: (accountId: string): Promise<ProjectData[]> => requests.get(`${this.BASEURL}/all/${accountId}`, cancelTokenSource.token),
    };

    Update = {
        update: (id: string, acctId: string, title: string, icon: string, color: string, backgroundColor: string, backgroundImage: string): Promise<ProjectData> =>
            requests.put(`${this.BASEURL}/update`, new ProjectData({
                    id: id,
                    accountId: acctId,
                    title: title,
                    iconImage: icon,
                    iconColor: color,
                    iconBackgroundColor: backgroundColor,
                    backgroundImage: backgroundImage
                }), cancelTokenSource.token
            ),
        // addColumn: (id: string, columnId: string, columnTitle: string): Promise<ProjectData> =>
        //     requests.put(`${this.BASEURL}/addcolumn`, new ColumnData({
        //         id: columnId,
        //         projectId: id,
        //         title: columnTitle})
        //     ),
        columnOrder: (id: string, acctId: string, order: string[], nameOrder: string[]): Promise<ProjectData> =>
            requests.put(`${this.BASEURL}/order`, new ProjectData({
                    id: id,
                    accountId: acctId,
                    columnOrder: order,
                    columnTitles: nameOrder
                }), cancelTokenSource.token
            ),
        title: (id: string, acctId: string, title: string): Promise<Column> =>
            requests.put(`${this.BASEURL}/column/title`, new Column({
                    id: id,
                    accountId: acctId,
                    title: title
                }), cancelTokenSource.token
            ),
        archive: (id: string, acctId: string): Promise<ProjectData> =>
            requests.put(`${this.BASEURL}/archive`, new ProjectData({
                    id: id,
                    accountId: acctId,
                }), cancelTokenSource.token
            ),
        unarchive: (id: string, acctId: string): Promise<ProjectScreenDto> =>
            requests.put(`${this.BASEURL}/unarchive`, new ProjectData({
                    id: id,
                    accountId: acctId,
                }), cancelTokenSource.token
            )
    };

    Delete = {
        remove: (id: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}`, cancelTokenSource.token).then(result => {
            return result === 200;
        }),
        removeColumn: (id: string, columnId: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}/${columnId}`, cancelTokenSource.token).then(result => {
            return result === 200;
        })
    };
}

export default new ProjectDataSource();