import {Draggable, DraggableProvided, DraggableStateSnapshot} from "@hello-pangea/dnd";
import React, {useEffect, useState} from "react";
import {cardAgingWithCompletedDate, cardAgingWithDueDate, colorPicker, formatDate} from "../Helpers";
import {CardDto} from "../../data/CardDto";
import {IoMdClock} from "react-icons/io";
import {BsFillCalendar2CheckFill} from "react-icons/bs";
import './../../scss/ProjectCards.css'


//https://stackoverflow.com/questions/71249987/dynamically-change-the-background-of-a-cards

interface ICardInfoProps {
    card: CardDto,
    index: number,
    showEdit: () => void,
    clientName: string
}

//Need to place modal in each commission otherwise a modal popup will cause all modals to pop up at once.

export const CardInfo: React.FunctionComponent<ICardInfoProps> = (props) => {
    const [card, setCard] = useState(props.card);
    const [dueDateColor, setDueDateColor] = useState("black")
    useEffect(() => {
        setCard(props.card)
        //maintains completed aging of the card
        if (props.card.completedDate !== "") {
            if (new Date(props.card.completedDate).getTime() > new Date(props.card.dueDate).getTime()) {
                setDueDateColor("red")
            } else {
                const cardAging = cardAgingWithCompletedDate(props.card.startDate, props.card.dueDate, props.card.completedDate);
                if (cardAging >= 75) {
                    setDueDateColor("orange")
                } else if (cardAging >= 50) {
                    setDueDateColor("yellow")
                } else if (cardAging >= 25) {
                    setDueDateColor("teal")
                } else {
                    setDueDateColor("green")
                }
            }
        } else if (new Date(props.card.dueDate).getTime() < new Date().getTime()) {
            setDueDateColor("red")
        } else {
            const cardAging = cardAgingWithDueDate(props.card.startDate, props.card.dueDate);
            if (cardAging >= 75) {
                setDueDateColor("orange")
            } else if (cardAging >= 50) {
                setDueDateColor("yellow")
            } else if (cardAging >= 25) {
                setDueDateColor("teal")
            } else {
                setDueDateColor("green")
            }
        }
    }, [props.card])

    return (
        <div onClick={() => props.showEdit()}>
            <Draggable
                key={card.id}
                draggableId={card.id}
                index={props.index}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <>
                        <div
                            className='card-view'
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                        >
                            <div className="card-view-content-container" style={{
                                backgroundColor: `${card.cardColor}`
                            }}>
                                <p className="card-title">
                                    {card.title} ({card.quantity})
                                </p>
                                {card.clientId && <div className="card-label-wrapper">
                                    <p key={card.clientId} className="card-label-tag"
                                       style={{backgroundColor: colorPicker.labelBlue, color: colorPicker.white}}>
                                        {props.clientName}
                                    </p>
                                </div>}
                                <div className="card-label-wrapper"
                                     style={{display: card.tagDetails && card.tagDetails.length > 0 ? 'flex' : 'none'}}>
                                    {card.tagDetails && card.tagDetails.map((item, index) => (
                                        <p key={item?.id ?? index} className="card-label-tag"
                                           style={{backgroundColor: item?.color ?? "", color: colorPicker.white}}>
                                            {item?.name ?? ""}
                                        </p>))}
                                </div>

                                {card.dueDate && !card.completedDate && (
                                    <div className="card-due-date">
                                        <IoMdClock size={20}/>
                                        <p>{formatDate(card.dueDate)}</p>
                                    </div>
                                )}

                                {card.completedDate && (
                                    <div className="card-completed-date">
                                        <BsFillCalendar2CheckFill size={15}/>
                                        <p>{formatDate(card.completedDate)}</p>
                                    </div>
                                )}
                            </div>

                            <div style={{
                                height: "8px",
                                width: "100%",
                                backgroundColor: `${dueDateColor}`,
                                borderBottomLeftRadius: "5px",
                                borderBottomRightRadius: "5px"
                            }}>
                            </div>
                        </div>
                    </>
                )}
            </Draggable>

        </div>
    );
}