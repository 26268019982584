import {BasicOptions} from "./BasicOptions";

export class CheckOptions extends BasicOptions {
    checked: boolean;
    color: string;

    public constructor(id: string, name: string, color: string, checked: boolean) {
        super(id, name);
        this.color = color;
        this.checked = checked;
    }
}