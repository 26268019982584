import React, {useEffect, useState} from "react";
import {FiMoreHorizontal} from "react-icons/fi";
import {Menu, MenuItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {navPath} from "../../Helpers";
import Zorua from '../../../assets/zorua-profile-pic.jpeg';

interface IProfileLabel {
    username: string;
    email: string;
    image: string;
    setShowProfile: (show: boolean) => void;
    isDeactivated: boolean;
    logout: () => void;
}

const ProfileLabel: React.FunctionComponent<IProfileLabel> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [useDefaultImage, setUseDefaultImage] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setUseDefaultImage(props.image === null || props.image === "")
    }, [props.image])

    const handleImageError = () => {
        // Handle the image loading error here, e.g., set a state variable to indicate the error.
        setUseDefaultImage(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (open) {
            // If the menu is open, close it.
            handleClose();
        } else {
            // If the menu is closed, open it.
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    return (
        <div className={"nav-profile"} onClick={(event) => {
            handleClick(event)
        }}>
            {useDefaultImage ? (
                // Render the image with the onError event handler.
                <img
                    className={"profile-image"}
                    src={Zorua}
                    alt="Default profile image of our cat Zorua."
                    onError={handleImageError}
                />
            ) : (
                // Display user chosen image when there is no error.
                <img className={"profile-image"} src={props.image} alt="Profile image."/>
            )}
            <div className={"profile-text"}>
                <span className={"profile-username"}>{props.username}</span>
                <span className={"profile-role"}>{props.isDeactivated ? "DEACTIVATED" : "Account Owner / Admin"}</span>
                {/*This code change will denote a profile's rol instead of email*/}
                {/* <span className={"profile-email"}>{props.email}</span> */}
            </div>
            <span className={"profile-options"}><FiMoreHorizontal/></span>
            <Menu
                className={"profile-options-menu"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    navigate(navPath.editProfile)
                }}>Settings</MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    props.logout()
                }}>Log Out</MenuItem>
            </Menu>
        </div>
    );
}

export default ProfileLabel;