import React from 'react';
import {ReactComponent as YellowStars} from '../assets/yellow-stars.svg';
import CampGround from '../assets/camp-ground.png';
import CampGroundMobile from '../assets/camp-ground-mobile.png';
import {useNavigate} from "react-router-dom";
import {navPath} from "../components/Helpers";

interface INotFound {

}

const NotFound: React.FC<INotFound> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="App">
            <div className="not-found-container">
                <div className="yellow-stars-wrapper">
                    <YellowStars/>
                </div>
                <div className="not-found-content-wrapper">
                    <h1>404: The Lost Campfire</h1>
                    <p>{`Oh no! Looks like you've wandered off the beaten path and stumbled upon our 404 page.
                        But don't fret! While our web pages may be a little elusive, we've got a cozy campfire to
                        keep you company in this digital wilderness. Press the button below when you're ready to
                        leave.`}</p>
                    <button className='bigBlueBtn' onClick={() => navigate(navPath.home)} type="button">Home</button>
                </div>
                <div className="stage">
                    <div className="campfire">
                        <div className="sparks">
                            <div className="spark"></div>
                            <div className="spark"></div>
                            <div className="spark"></div>
                            <div className="spark"></div>
                            <div className="spark"></div>
                            <div className="spark"></div>
                            <div className="spark"></div>
                            <div className="spark"></div>
                        </div>
                        <div className="logs">
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                            <div className="log">
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                                <div className="streak"></div>
                            </div>
                        </div>
                        <div className="sticks">
                            <div className="stick"></div>
                            <div className="stick"></div>
                            <div className="stick"></div>
                            <div className="stick"></div>
                        </div>
                        <div className="fire">
                            <div className="fire__red">
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                            </div>
                            <div className="fire__orange">
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                            </div>
                            <div className="fire__yellow">
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                            </div>
                            <div className="fire__white">
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                                <div className="flame"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={CampGround} className='camp-ground-default'/>
                <img src={CampGroundMobile} className='camp-ground-mobile'/>
            </div>
        </div>
    );
};

export default NotFound;