import React, {useEffect, useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {Form} from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import {BsFillCheckCircleFill} from "react-icons/bs";
import ContactUsPlants from '../assets/contact-us-plants.png';
import {colorPicker} from "../components/Helpers";

interface IContactUs {
    submitContactUs: (name: string, email: string, message: string, subjectType: number) => void,
    contactUsConfirmation: boolean
}

const ContactUs: React.FC<IContactUs> = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subjectType, setSubjectType] = useState("0");
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const contactUsContainerRef = useRef<HTMLDivElement | null>(null);
    const [showMessageSentConfirmation, setMessageSentConfirmation] = useState(false);


    useEffect(() => {
        if (props.contactUsConfirmation) {
            setMessageSentConfirmation(true);
            setTimeout(function () {
                setMessageSentConfirmation(false);
            }, 5000);
        }
    }, [props.contactUsConfirmation])

    const toggleMessageSentConfirmation = () => setMessageSentConfirmation(!showMessageSentConfirmation);

    const validateFields = () => {
        if (name.length > 0 && email.length > 0 && message.length > 0 && subjectType !== "0" && new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(email)) {
            props.submitContactUs(name, email, message, Number(subjectType))
            setName("");
            setEmail("");
            setMessage("");
            setSubjectType("0")
        }
    }

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={contactUsContainerRef}
            />
            <div className="contact-us-container" ref={contactUsContainerRef}>
                <ul className="lightrope">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <Toast show={showMessageSentConfirmation} onClose={toggleMessageSentConfirmation}>
                    <Toast.Header closeButton aria-label="Hide" closeVariant="white">
                        <BsFillCheckCircleFill size={23} color={colorPicker.confirmationGreen}/>
                        <strong className="savedConfirmationText">Your message has been sent!</strong>
                    </Toast.Header>
                </Toast>
                <div className='contact-us-content-wrapper'>
                    <h1>Contact Us</h1>
                    <p>{`Have a question? Fill out the form below and we'll get back to you as soon as possible.`}</p>
                    {/* I took this form code from HelpDropDown.tsx. Look at that code for attaching logic. */}
                    <Form id="contactUsForm" className="contact-us-form" onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <div className='contactUsFormColumnWrapper'>
                            <div className="contact-us-column">
                                <label className='contact-us-label-subheader'
                                       htmlFor="contactName">Name:<span style={{color: '#FF3347'}}> *</span></label>
                                <input
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control"
                                    type="text"
                                    value={name}
                                />
                            </div>
                            <div className="contact-us-column">
                                <label className='contact-us-label-subheader'
                                       htmlFor="contactEmail">Email:<span style={{color: '#FF3347'}}> *</span></label>
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    type="text"
                                    value={email}
                                />
                            </div>
                            <div className="contact-us-column">
                                <label className='contact-us-label-subheader'
                                       htmlFor="contactSubject">
                                    Subject Type:
                                    <span style={{color: '#FF3347'}}> *</span></label>
                                <Form.Select className="contactTypeSelection" aria-label="Select subject type"
                                             value={subjectType}
                                             onChange={(e) => {
                                                 setSubjectType(e.target.value)
                                             }}>
                                    <option value="0">Choose A Subject</option>
                                    <option value="1">Feedback</option>
                                    <option value="2">Bug Report</option>
                                    <option value="3">Feature Suggestion</option>
                                    <option value="4">Business Inquiry</option>
                                    <option value="5">Other</option>
                                </Form.Select>
                            </div>
                            <div className="contact-us-column" style={{marginBottom: "0px"}}>
                                <label className='contact-us-label-subheader'
                                       htmlFor="contactMessage">
                                    Message:
                                    <span style={{color: '#FF3347'}}> *</span></label>
                                <textarea
                                    className="feedbackDescriptionInput form-control"
                                    id='contactMessage'
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value)
                                    }}
                                    maxLength={3000}
                                    rows={4}
                                    cols={50}
                                ></textarea>
                            </div>
                            <div className="contact-us-column">
                                <button form="contactUsForm"
                                        disabled={name === "" || email === "" || !new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(email) || message === "" || subjectType === "0"}
                                        type="button"
                                        className={name === "" || email === "" || !new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(email) || message === "" || subjectType === "0" ? "disabledBtn" : "blueBtn"}
                                        onClick={() => validateFields()}
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </Form>
                    <img className="contactUsPlants" src={ContactUsPlants}/>
                    <CommCommFooter/>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;