import {AggregateBase} from "./AggregateBase";

export class Tag extends AggregateBase {
    accountId: string = "";
    projectId: string = "";
    name: string = "";
    color: string = "";

    public constructor(init?: Partial<Tag>) {
        super();
        Object.assign(this, init);
    }
}