import React, { useState, useEffect, useRef } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import {MdNotificationsActive} from "react-icons/md";

//https://codepen.io/vkive/pen/VwMOxmX
interface NotificationPopoverProps {
  content: any;
}

const NotificationPopover: React.FC<NotificationPopoverProps> = (props) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setShowPopover(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handlePopoverToggle = () => {
    setShowPopover(!showPopover);
  };

  const handlePopoverContentClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const popover = (
    <Popover id="popover-basic" onClick={handlePopoverContentClick}>
      <Popover.Header as="h3">Notification Settings</Popover.Header>
      <Popover.Body>
        {props.content}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      show={showPopover}
      onToggle={handlePopoverToggle}
      overlay={popover}
    >
      <div className="purpleBtn notification-settings-btn" ref={popoverRef}>
        <MdNotificationsActive size={23} />
      </div>
    </OverlayTrigger>
  );
  };

export default NotificationPopover;