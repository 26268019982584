import React from 'react';
import '../../../scss/EditCard.scss'; // Create a CSS file for styling the loading overlay
import LoadingCommComm from '../../../assets/CommCommLogo-Black.svg'

interface ComponentLoadingProps {
    isLoadingComponent: boolean;
}

const ComponentLoading: React.FC<ComponentLoadingProps> = (props) => {
    return props.isLoadingComponent ? (
        <img className="comment-loading-animation" src={LoadingCommComm}/>
    ) : null;
};

export default ComponentLoading;
