import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CommComm from "../../../assets/CommCommLogo-Combo-White.png";
import {Client} from "../../../data/Client";
import {navPath} from "../../Helpers";
import UnprotectedProfileLabel from "../../Base/ProfileLabel/UnprotectedProfileLabel";
import '../../../scss/TopBarNavigation.scss'
import {AccountData} from "../../../data/AccountData";
import NotificationPopover from '../TopBar/NotificationPopover';

export interface ITopNavBar {
    selectedClient: Client,
    changeEmailNotification: (clientId: string, notify: boolean) => void,
    changePhoneNotification: (clientId: string, notify: boolean) => void,
    account: AccountData | undefined;
    setUnprotectedAccountId: (acct: string) => void
}

const TopNavBar: React.FunctionComponent<ITopNavBar> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [emailNotify, setEmailNotify] = useState(props.selectedClient.receiveEmailNotification);
    // const [phoneNotify, setPhoneNotify] = useState(props.selectedClient.receivePhoneNumberNotification)
    const [boardPath, setBoardPath] = useState("");
    const [showBoardMenuItem, setShowBoardMenuItem] = useState(true);
    const [showProfileMenuItem, setShowProfileMenuItem] = useState(false);
    const [showBreakdownMenuItem, setShowBreakdownMenuItem] = useState(false);

    const {accountId} = useParams();

    useEffect(() => {
        setBoardPath(location.pathname)
        // if (location.pathname.includes("breakdown")) {
        //     selectBreakdown();
        // } else if (location.pathname.includes("profile")) {
        //     selectProfile()
        // } else {
        //     selectBoard()
        // }
    }, [])

    useEffect(() => {
        if (accountId) {
            props.setUnprotectedAccountId(accountId)
        }
    }, [accountId])

    // useEffect(() => {
    //     if (accountId) {
    //         AccountDataSource.Get.fromId(accountId).then((response) => {
    //             setImage(response.image);
    //             setName(response.displayRealName ? response.firstName + " " + response.lastName : response.username);
    //             setEmail(response.email);
    //             setAcctType(response.acctType);
    //             props.setUnprotectedAccount(response);
    //         });
    //     }
    // }, [accountId])

    const changeEmailNotify = () => {
        props.changeEmailNotification(props.selectedClient.id, !emailNotify)
        setEmailNotify(!emailNotify)
    }

    // const changePhoneNotify = () => {
    //     props.changePhoneNotification(props.selectedClient.id, !phoneNotify)
    //     setPhoneNotify(!phoneNotify)
    // }

    const selectProfile = () => {
        setShowProfileMenuItem(true);
        setShowBoardMenuItem(false);
        setShowBreakdownMenuItem(false);
    }

    const selectBoard = () => {
        setShowProfileMenuItem(false);
        setShowBoardMenuItem(true);
        setShowBreakdownMenuItem(false);
    }

    const selectBreakdown = () => {
        setShowProfileMenuItem(false);
        setShowBoardMenuItem(false);
        setShowBreakdownMenuItem(true);
    }

    return (
        <div style={{display: "flex"}}>
            <div className={"navigation-topbar-wrapper"}>
                <div className="topbar-column">
                    <div className="navigation-topbar-logo">
                        <img className='topnav-CommCommLogo' src={CommComm} onClick={() => navigate(navPath.home)}/>
                    </div>
                </div>
                <div className="topbar-column">
                    <div className="topbar-row">
                        <div className="email-toggle">
                            <label className={"toggleLabels"}>Commission Email Updates: </label>
                            <label className="topnav-switch">
                                <input type="checkbox" defaultChecked={emailNotify} onChange={changeEmailNotify}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {/* <div className="text-toggle">
                            <label className={"toggleLabels"}>Phone Push Notifications: </label>
                            <label className="topnav-switch">
                                <input type="checkbox" defaultChecked={phoneNotify} onChange={changePhoneNotify}/>
                                <span className="slider round"></span>
                            </label>
                        </div> */}
                        <NotificationPopover
                            content={
                                <div className="email-toggle-inside-popover">
                                    <label className={"toggleLabels"}>Commission Email Updates: </label>
                                    <label className="topnav-switch">
                                        <input type="checkbox" defaultChecked={emailNotify}
                                               onChange={changeEmailNotify}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            }/>
                        <UnprotectedProfileLabel
                            name={(props.account?.displayRealName ? props.account?.firstName + " " + props.account?.lastName : props.account?.username) ?? ""}
                            email={props.account?.email ?? ""}
                            image={props.account?.image ?? ""}
                            acctType={props.account?.acctType ?? 0}
                            boardPath={boardPath}
                            showBoardMenuItem={showBoardMenuItem}
                            showProfileMenuItem={showProfileMenuItem}
                            showBreakdownMenuItem={showBreakdownMenuItem}
                            selectProfile={selectProfile}
                            selectBoard={selectBoard}
                            selectBreakdown={selectBreakdown}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopNavBar