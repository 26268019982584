import React, { useEffect } from 'react';

//https://codepen.io/vkive/pen/VwMOxmX
const Rain: React.FC = () => {
  useEffect(() => {
    const amount = 5;
    const body = document.querySelector('.innerWindowFrame');
    let rainDrop = 0;
    //if anything copies the raindrop variable then it will have the raindrop effect.
    while (rainDrop < amount) {
      const drop = document.createElement('rainDrop');
      const size = Math.random() * 10;
      const posX = Math.floor(Math.random() * window.innerWidth);
      const delay = Math.random() * -10;
      const duration = Math.random() * 5;

      drop.style.width = 0.2 + size + 'px';
      drop.style.left = posX + 'px';
      drop.style.animationDelay = delay + 's';
      drop.style.animationDuration = 1 + duration + 's';
      body?.appendChild(drop);
      rainDrop++;
    }
  }, []);

  return null; // or return <></> if you want an empty React fragment
};

export default Rain;