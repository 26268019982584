import React, {useEffect, useRef, useState} from "react";
import {
    AccountSubscriptionPlan,
    constantList, freeQuillContainer, freeQuillFormats,
    handleImageUpload, premiumQuillContainer, premiumQuillFormats,
} from "../../Helpers";
import Delta from "quill-delta";
import Quill from "quill";

interface INotes {
    title: string;
    text: string
    headerColor: string;
    updateNotes: (note: string) => void
    setNotesLength: (length: number) => void;
    notesLength: number;
    acctSubscription: number;
}

//https://docs.slatejs.org/walkthroughs/01-installing-slate
//https://stackoverflow.com/questions/63359138/react-closing-a-dropdown-when-click-outside
const RichTextClientNotes: React.FunctionComponent<INotes> = (props) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);
    const isMounted = useRef(false);
    const [characterCount, setCharacterCount] = useState(0);

    const initializeQuillEditor = () => {
        if (editorRef.current) {
            quillRef.current = new Quill(editorRef.current, {
                theme: 'snow',
                formats: props.acctSubscription > AccountSubscriptionPlan.Basic ? premiumQuillFormats : freeQuillFormats,
                placeholder: 'Click here to write client notes.',
                modules: {
                    toolbar: {
                        container: props.acctSubscription > AccountSubscriptionPlan.Basic ? premiumQuillContainer : freeQuillContainer,
                        handlers: {
                            image: () => handleImageUpload(quillRef?.current || new Quill(document.createElement('div')), "card_notes")
                        }
                    },
                    clipboard: {
                        matchers: [['img', (node: any, delta: Delta) => {
                            return new Delta();
                        }]]
                    }

                }
            });
            // Use clipboard API to set initial content if props.text is not empty
            quillRef.current.clipboard.dangerouslyPasteHTML(props.text ?? "");

            quillRef.current.on('text-change', () => {
                // if (isInitialSetting.current) return;
                props.updateNotes(quillRef.current?.root.innerHTML || '')
                setCharacterCount(quillRef.current?.root.innerHTML.length || 0)
                props.setNotesLength(quillRef.current?.root.innerHTML.length || 0);
            });
        }

        return () => {
            // Clean up on unmount
            if (quillRef.current) {
                quillRef.current.off('text-change');
                quillRef.current = null;
            }
        };
    }

    useEffect(() => {
        // Fix for renders two toolbar
        if (!isMounted.current) {
            initializeQuillEditor();
            isMounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (quillRef.current && props.text !== quillRef.current.root.innerHTML) {
            quillRef.current.clipboard.dangerouslyPasteHTML(props.text);
        }
    }, [props.text]);

    return (
        <div id="client-notes" className="sticky-note">
            <div className="sticky-note-header" style={{backgroundColor: props.headerColor}}>
                <div>{props.title}</div>
                <div className="close">
                    {/* <MdEdit style={{color: enableEdit ? "#3498db" : "black"}}/> */}
                </div>
            </div>
            <div ref={editorRef} style={{height: '300px'}}/>
            <div
                hidden={characterCount < constantList.richTextLengthShowCounter}>{characterCount}/{constantList.richTextMaxLength}</div>
        </div>
    );
}

export default RichTextClientNotes;