import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import EditPriceBreakdown from '../assets/UserGuideImages/Edit-Price-Breakdown.png';
import BreakdownProjectDropdown from '../assets/UserGuideImages/Breakdown-Project-Dropdown.png';
import Labor from '../assets/UserGuideImages/Breakdown-Labor.png';
import Materials from '../assets/UserGuideImages/Breakdown-Materials.png';
import Shipping from '../assets/UserGuideImages/Breakdown-Shipping.png';
import OtherCosts from '../assets/UserGuideImages/Breakdown-Other-Costs.png';
import BreakdownEstimate from '../assets/UserGuideImages/Breakdown-Total.png';
import BreakdownPreview from '../assets/UserGuideImages/Breakdown-Preview.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuidePriceBreakdowns {

}

const UserGuidePriceBreakdowns: React.FC<IUserGuidePriceBreakdowns> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuidePriceBreakdownsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsProjectSelectionRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsLaborRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsMaterialsRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsShippingRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsOtherCostsRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsTotalCostEstimateRef = useRef<HTMLDivElement | null>(null);
    const userGuidePriceBreakdownsPriceBreakdownPreviewRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    //Finds the path's hash and compares it to the section ids. There is a 1 sec delay so the page fully loads for scrolling.
    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "projectSelection":
                        userGuidePriceBreakdownsProjectSelectionRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "labor":
                        userGuidePriceBreakdownsLaborRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "materials":
                        userGuidePriceBreakdownsMaterialsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "shipping":
                        userGuidePriceBreakdownsShippingRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "otherCosts":
                        userGuidePriceBreakdownsOtherCostsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "totalCostEstimate":
                        userGuidePriceBreakdownsTotalCostEstimateRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "priceBreakdownPreview":
                        userGuidePriceBreakdownsPriceBreakdownPreviewRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuidePriceBreakdowns)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuidePriceBreakdownsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuidePriceBreakdownsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('projectSelection')}>1. Project Selection</li>
                                <li onClick={() => scrollToSection('labor')}>2. Labor</li>
                                <li onClick={() => scrollToSection('materials')}>3. Materials</li>
                                <li onClick={() => scrollToSection('shipping')}>4. Shipping</li>
                                <li onClick={() => scrollToSection('otherCosts')}>5. Other Costs</li>
                                <li onClick={() => scrollToSection('totalCostEstimate')}>6. Total Cost Estimate</li>
                                <li onClick={() => scrollToSection('priceBreakdownPreview')}>7. Price Breakdown
                                    Preview
                                </li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Price Breakdowns</h1>
                        <p className='biggerText'>{`Price breakdowns provide approximate cost assessments for the seller's
                            services/products, offering clients insights into the factors influencing commission
                            costs.`}</p>
                        <img src={EditPriceBreakdown} className='smallImg' style={{marginBottom: "35px"}}
                             alt="user guide price breakdowns"/>

                        <section id="projectSelection" ref={userGuidePriceBreakdownsProjectSelectionRef}>
                            <h2>1. Project Selection</h2>
                            <img src={BreakdownProjectDropdown} alt="user guide price breakdown project selection"/>
                            <p>To create a breakdown, start by clicking the dropdown under <strong>{`"Project
                                Title"`}</strong> and select the project for which you want to create a breakdown.
                                The dropdown options are derived from the names of the active project tabs you have
                                created.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> To edit an existing breakdown, select
                                    the intended project from this dropdown to make edits.</p>
                            </div>
                        </section>

                        <section id="labor" ref={userGuidePriceBreakdownsLaborRef}>
                            <h2>2. Labor</h2>
                            <img src={Labor} className='medImg' alt="user guide price breakdown labor"/>
                            <p>In each breakdown, you can specify and describe the type of labor required for the
                                project, along with a cost range relative to the final price.
                                The minimum price represents the lowest potential labor cost, and the maximum reflects
                                the highest potential labor cost.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> A maximum of five labor descriptions
                                    is allowed.</p>
                            </div>
                        </section>

                        <section id="materials" ref={userGuidePriceBreakdownsMaterialsRef}>
                            <h2>3. Materials</h2>
                            <img src={Materials} className='medImg' alt="user guide price breakdown materials"/>
                            <p>In each breakdown, you can specify and describe the type of materials required for the
                                project, along with a cost range relative to the final price.
                                The minimum price represents the lowest potential cost for materials, and the maximum
                                reflects the highest potential cost for materials.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> A maximum of five material
                                    descriptions is allowed.</p>
                            </div>
                        </section>

                        <section id="shipping" ref={userGuidePriceBreakdownsShippingRef}>
                            <h2>4. Shipping</h2>
                            <img src={Shipping} className='medImg' alt="user guide price breakdown shipping"/>
                            <p>In each breakdown, you can specify and describe the type of shipping materials required
                                for the project, along with a cost range relative to the final price.
                                The minimum price represents the lowest potential shipping cost, and the maximum
                                reflects the highest potential shipping cost.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> A maximum of five shipping
                                    descriptions is allowed.
                                    If shipping does not apply to your product/service, feel free to leave the list
                                    field empty and indicate a monetary range of <strong>$0.00</strong>.</p>
                            </div>
                        </section>

                        <section id="otherCosts" ref={userGuidePriceBreakdownsOtherCostsRef}>
                            <h2>5. Other Costs</h2>
                            <img src={OtherCosts} className='medImg' alt="user guide price breakdown other costs"/>
                            <p>In each breakdown, you can specify and describe any other costs required for the project
                                that do not fit the previous fields, along with a cost range relative to the final
                                price.
                                The minimum price represents the lowest potential cost for the other expenses, and the
                                maximum reflects the highest potential cost for the other expenses.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> A maximum of ten other cost
                                    descriptions is allowed.
                                    If other costs does not apply to your product/service, feel free to leave the list
                                    field empty and indicate a monetary range of <strong>$0.00</strong>.</p>
                            </div>
                        </section>

                        <section id="totalCostEstimate" ref={userGuidePriceBreakdownsTotalCostEstimateRef}>
                            <h2>6. Total Cost Estimate</h2>
                            <img src={BreakdownEstimate} className='medImg'
                                 alt="user guide price breakdowns total cost estimate"/>
                            <p>After completing all breakdown fields, a <strong>{`"Range Estimate of Total
                                Cost"`}</strong> is calculated. This range is determined by adding the
                                minimum and maximum prices from the labor, materials, shipping, and other cost fields,
                                offering clients an expected price range.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Remember to save your breakdowns by
                                    clicking the <strong>{`"Save Breakdown"`}</strong> button
                                    or <strong>{`"Update Breakdown"`}</strong>{` button if you're editing an exisitng
                                    breakdown.`}</p>
                            </div>
                        </section>

                        <section id="priceBreakdownPreview" ref={userGuidePriceBreakdownsPriceBreakdownPreviewRef}>
                            <h2>6. Price Breakdown Preview</h2>
                            <img src={BreakdownPreview} className='medImg'
                                 alt="user guide price breakdown price breakdown preview"/>
                            <p>All completed breakdowns can be previewed at the bottom of the page. This preview
                                reflects how it will appear to clients
                                in their <Link to={navPath.userGuideProgressBoards} className="hyperlink">Progress
                                    Board</Link>. Within the preview, you
                                can review the items and price ranges listed under each section. If needed, you can
                                delete the breakdown by clicking
                                the <strong>{`"Delete Breakdown"`}</strong> button, whether to rebuild it from scratch
                                or if
                                you no longer offer the product/service.</p>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuidePriceBreakdowns;