import {formatValue} from "react-currency-input-field";
import Quill from 'quill';
import {getAuth} from "firebase/auth";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import storage from "../config/FirebaseStorage";
import {config} from "../config/config";
import CommComm from "../assets/CommComm-Background.png";
import CommCommTb from "../assets/BackgroundGalleryThumbnails/CommComm-Background-tb.jpg";
import CherryBlossoms from "../assets/cherry-blossoms.jpg";
import CherryBlossomsTb from "../assets/BackgroundGalleryThumbnails/cherry-blossoms-tb.jpg";
import PurplePainting from "../assets/purple-painting.jpg";
import PurplePaintingTb from "../assets/BackgroundGalleryThumbnails/purple-painting-tb.jpg";
import Clouds from "../assets/purple-orange-sky.jpg";
import CloudsTb from "../assets/BackgroundGalleryThumbnails/purple-orange-sky-tb.jpg";
import BluePinkHallway from "../assets/blue-pink-hallway.jpg";
import BluePinkHallwayTb from "../assets/BackgroundGalleryThumbnails/blue-pink-hallway-tb.jpg";
import PinkMetalBoards from "../assets/pink-metal-boards.jpg";
import PinkMetalBoardsTb from "../assets/BackgroundGalleryThumbnails/pink-metal-boards-tb.jpg";
import NeonCityAerial from "../assets/neon-city-aerial-view.jpg";
import NeonCityAerialTb from "../assets/BackgroundGalleryThumbnails/neon-city-aerial-view-tb.jpg";
import NeonCityStreet from "../assets/neon-city-street-view.jpg";
import NeonCityStreetTb from "../assets/BackgroundGalleryThumbnails/neon-city-street-view-tb.jpg";
import NeonFence from "../assets/neon-fence.jpg";
import NeonFenceTb from "../assets/BackgroundGalleryThumbnails/neon-fence-tb.jpg";
import NeonLights from "../assets/neon-lights.jpg";
import NeonLightsTb from "../assets/BackgroundGalleryThumbnails/neon-lights-tb.jpg";
import NeonTunnel from "../assets/neon-tunnel.jpg";
import NeonTunnelTb from "../assets/BackgroundGalleryThumbnails/neon-tunnel-tb.jpg";
import NeonTower from "../assets/neon-tower.jpg";
import NeonTowerTb from "../assets/BackgroundGalleryThumbnails/neon-tower-tb.jpg";
import PinkCity from "../assets/pink-city.jpg";
import PinkCityTb from "../assets/BackgroundGalleryThumbnails/pink-city-tb.jpg";
import FoggyBridge from "../assets/foggy-bridge.jpg";
import FoggyBridgeTb from "../assets/BackgroundGalleryThumbnails/foggy-bridge-tb.jpg";
import NeonPalmTrees from "../assets/neon-palm-trees.jpg";
import NeonPalmTreesTb from "../assets/BackgroundGalleryThumbnails/neon-palm-trees-tb.jpg";
import BluePinkMarble from "../assets/blue-pink-color-marble.jpg";
import BluePinkMarbleTb from "../assets/BackgroundGalleryThumbnails/blue-pink-color-marble-tb.jpg";
import Delta from "quill-delta";

const abbreviatedMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const fullMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


export const formatDate = (value: string) => {

    if (!value) return "";
    const date = new Date(value);
    if (!date) return "";

    const day = date.getUTCDate();
    const month = abbreviatedMonths[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return month + " " + day + " " + year;
};

export const formatDateMonth = (value: string) => {

    if (!value) return "";
    const date = new Date(value);
    if (!date) return "";

    return fullMonths[date.getUTCMonth()];
};

export const formatDateQuarters = (value: string) => {

    if (!value) return "";
    const date = new Date(value);
    if (!date) return "";

    switch (date.getUTCMonth()) {
        case 0: {
            return "January - March";
        }
        case 1: {
            return "January - March";
        }
        case 2: {
            return "January - March";
        }
        case 3: {
            return "April - June";
        }
        case 4: {
            return "April - June";
        }
        case 5: {
            return "April - June";
        }
        case 6: {
            return "July - September";
        }
        case 7: {
            return "July - September";
        }
        case 8: {
            return "July - September";
        }
        case 9: {
            return "October - December";
        }
        case 10: {
            return "October - December";
        }
        case 11: {
            return "October - December";
        }
        default: {
            return ""
        }
    }
};

export const formatDateYear = (value: string) => {

    if (!value) return "";
    const date = new Date(value);
    if (!date) return "";

    return date.getUTCFullYear();
};

export const formatDateTime = (value: string) => {
    if (!value) return "";
    const date = new Date(value);
    if (!date) return "";

    return date.toLocaleString();
};

export const getCurrentFormattedDateTime = () => {
    const timestamp = Date.now();
    const date = new Date(timestamp);

    // Convert to the desired format
    return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    });
};

export const formatSubscriptionName = (plan: number) => {
    switch (plan) {
        case AccountSubscriptionPlan.Basic: {
            return "Basic";
        }
        case AccountSubscriptionPlan.Standard: {
            return "Standard";
        }
        case AccountSubscriptionPlan.Pro: {
            return "Pro";
        }
        case AccountSubscriptionPlan.Free: {
            return "Free";
        }
        default: {
            return "";
        }
    }
};

export const formatSubscriptionPrice = (priceId: string) => {
    switch (priceId) {
        case config.stripePriceIdConfig.ProYearly: {
            return config.stripePriceConfig.ProYearly;
        }
        case config.stripePriceIdConfig.ProMonthly: {
            return config.stripePriceConfig.ProMonthly;
        }
        case config.stripePriceIdConfig.StandardYearly: {
            return config.stripePriceConfig.StandardYearly;
        }
        case config.stripePriceIdConfig.StandardMonthly: {
            return config.stripePriceConfig.StandardMonthly;
        }
        case config.stripePriceIdConfig.BasicYearly: {
            return config.stripePriceConfig.BasicYearly;
        }
        case config.stripePriceIdConfig.BasicMonthly: {
            return config.stripePriceConfig.BasicMonthly;
        }
        case "": {
            return config.stripePriceConfig.FreeMonthly;
        }
        default: {
            return "";
        }
    }
};

export const cardAgingWithDueDate = (startDate: string, dueDate: string) => {
    return ((new Date().getTime() - new Date(startDate).getTime()) / (new Date(dueDate).getTime() - new Date(startDate).getTime())) * 100;
};

export const cardAgingWithCompletedDate = (startDate: string, dueDate: string, completeDate: string) => {
    return ((new Date(completeDate).getTime() - new Date(startDate).getTime()) / (new Date(dueDate).getTime() - new Date(startDate).getTime())) * 100;
};

export const formatTimeIntervalName = (timeInterval: number) => {
    return timeInterval === 1 ? "Monthly" : "Yearly";
};

export const navPath = {
    profile: "/profile",
    editProfile: "/edit-profile",
    manageSubscription: "/manage-subscription",
    pricebreakdown: "/edit-price-breakdown",
    unprotectedPriceBreakdown: "breakdown",
    projects: "/projects",
    clients: "/clients",
    login: "/login",
    home: "/home",
    pricingTier: "/pricing-tier",
    team: "/team",
    faq: "/faq",
    userGuide: "/user-guide",
    userGuideProjectBoards: "/user-guide/project-boards",
    userGuideClientBoards: "/user-guide/client-boards",
    userGuideProgressBoards: "/user-guide/progress-boards",
    userGuideCommissionCards: "/user-guide/commission-cards",
    userGuideArchiveBoards: "/user-guide/archive-boards",
    userGuideProfileSettings: "/user-guide/profile-settings",
    userGuidePriceBreakdowns: "/user-guide/price-breakdowns",
    userGuideManageSubscription: "/user-guide/manage-subscription",
    contactus: "/contact-us",
    privacypolicy: "/privacy-policy",
    termsandconditions: "/terms-and-conditions",
    commcommassets: "/comm-comm-assets-",
    commissionArchive: "/archive/commissions",
    projectsArchive: "/archive/projects",
    clientsArchive: "/archive/clients",
    unprotectedProfile: "profile",
    unsubscribe: "/unsubscribe",
    checkoutSuccess: "/checkout-success",
    checkoutError: "/checkout-error",
    notFound: "/404"
}

export const AccountSubscriptionPlan = {
    Free: 1,
    Basic: 3,
    Standard: 5,
    Pro: 7
}

export const colorPicker = {
    commCommPink: "#F171AB",
    commCommYellow: "#FFC721",
    commCommBlue: "#3498DB",
    labelBlue: "#15A6DF",
    commCommDarkPurple: "#361042",
    white: "#FFFFFF",
    black: "#000000",
    commissionCardBlue: "#ABCEEB",
    commissionCardYellow: "#FFF69B",
    commissionCardGreen: "#BCDFC9",
    commissionCardPink: "#F6C2D9",
    commissionCardGray: "#E4DAE2",
    confirmationGreen: "#00CC63",
    errorRed: "#FF3347",
    defaultBlue: "#16A7E0",
}

export const constantList = {
    richTextLengthShowCounter: 4500,
    richTextMaxLength: 5000,
    imageMaxSize: 5000000
}

export const commissionLink = (baseUrl: string, accountId: string, clientId: string) => {
    return `${baseUrl}/acct/${accountId}/client/${clientId}`
}

export const referralLink = (baseUrl: string, username: string) => {
    return `${baseUrl}/login?refCode=${username}`
}

export const validUrl = (url: string) => {
    const regexHttp = new RegExp('^(http(s):\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$')
    return regexHttp.test(url);
}

export const formatCurrencyNumberWithDecimal = (amount: number) => {
    let p = amount.toFixed(2).split(".");
    if (p.length !== 2) {
        p[0] = amount.toString();
        p[1] = "00";
    }

    return p[0].split("").reverse().reduce(function (acc, amount, i, orig) {
        return amount + (amount !== "-" && i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
};

export const formatCurrency = (value: number) => {
    return formatValue({
        value: value.toString(),
        decimalScale: 2,
        intlConfig: {locale: 'en-US', currency: 'USD'},
    })
}

export const handleImageUpload = async (editor: Quill, subFolder: string) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    const auth = getAuth();
    input.onchange = async () => {
        const file = input.files?.[0];
        //5MB limit
        if (file && file.size <= constantList.imageMaxSize) {
            try {
                const storageRef = ref(storage, `images/${auth.currentUser?.uid}/${subFolder}/${crypto.randomUUID()}`)
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        // const percent = Math.round(
                        //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        // );

                        // update progress
                        // props.setPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            const cursorPosition = editor.getSelection()?.index || 0;
                            editor.insertEmbed(cursorPosition, 'image', url);
                        }).catch((error) => {
                            console.error('Image upload failed:', error);
                        });
                    }
                );

                // let url = ImageUploadService({file, path});
                // // const response = await axios.post('/upload-image', formData);
                // // const imageUrl = response.data.imageUrl;
                // //
                // const editor: any = document.querySelector('.quill .ql-editor');
                // const cursorPosition = editor.getSelection().index;
                // editor.insertEmbed(cursorPosition, 'image', url);
            } catch (error) {
                console.error('Image upload failed:', error);
            }
        }
    };
};

export const quillModulesFree = {
    toolbar: [
        // [{font: []}],
        // [{header: [1, 2, 3, 4, 5, 6, false]}],
        ["bold", "italic", "underline", "strike"],
        [{color: []}, {background: []}],
        // [{script: "sub"}, {script: "super"}],
        // ["blockquote"],
        [{list: "ordered"}, {list: "bullet"}],
        // [{indent: "-1"}, {indent: "+1"}, {align: []}],
        // ["link", "image"],
        // ["clean"],
    ],
    clipboard: {
        matchVisual: true,
        matchers: [[Node.ELEMENT_NODE, (node: any, delta: Delta) => {
            if (node.tagName === 'IMG') {
                return new Delta();
            } else {
                return delta;
            }
        }]]
    }
};

export const quillModulesPremium = {
    toolbar: [
        // [{font: []}],
        // [{header: [1, 2, 3, 4, 5, 6, false]}],
        ["bold", "italic", "underline", "strike"],
        [{color: []}, {background: []}],
        // [{script: "sub"}, {script: "super"}],
        // ["blockquote"],
        [{list: "ordered"}, {list: "bullet"}],
        // [{indent: "-1"}, {indent: "+1"}, {align: []}],
        ["image"],
        // ["clean"],
    ],
    clipboard: {
        matchVisual: true,
        matchers: [[Node.ELEMENT_NODE, (node: any, delta: Delta) => {
            if (node.tagName === 'IMG') {
                return new Delta();
            } else {
                return delta;
            }
        }]]
    }
};
export const clientReplyQuillFormats = [
    'bold',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'indent',
    'list',
    'align',
    'direction',
    'formula'];

export const freeQuillFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'];

export const premiumQuillFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'formula'];

export const clientReplyQuillContainer = [
    ['bold', 'italic', 'underline', 'strike'],
    [{'align': []}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
];

export const freeQuillContainer = [
    ['bold', 'italic', 'underline', 'strike'],
    [{'align': []}],
    [{'color': []}, {'background': []}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
];

export const premiumQuillContainer = [
    ['bold', 'italic', 'underline', 'strike'],
    [{'align': []}],
    [{'color': []}, {'background': []}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['image'],
];

export const backgroundImages = [
    {
        id: 1,
        img: CommComm,
        thumb: CommCommTb
    },
    {
        id: 2,
        img: CherryBlossoms,
        thumb: CherryBlossomsTb
    },
    {
        id: 3,
        img: PurplePainting,
        thumb: PurplePaintingTb
    },
    {
        id: 4,
        img: Clouds,
        thumb: CloudsTb
    },
    {
        id: 5,
        img: BluePinkHallway,
        thumb: BluePinkHallwayTb
    },
    {
        id: 6,
        img: PinkMetalBoards,
        thumb: PinkMetalBoardsTb
    },
    {
        id: 7,
        img: NeonCityAerial,
        thumb: NeonCityAerialTb
    },
    {
        id: 8,
        img: NeonCityStreet,
        thumb: NeonCityStreetTb
    },
    {
        id: 9,
        img: NeonFence,
        thumb: NeonFenceTb
    },
    {
        id: 10,
        img: NeonLights,
        thumb: NeonLightsTb
    },
    {
        id: 11,
        img: NeonTunnel,
        thumb: NeonTunnelTb
    },
    {
        id: 12,
        img: NeonTower,
        thumb: NeonTowerTb
    },
    {
        id: 13,
        img: PinkCity,
        thumb: PinkCityTb
    },
    {
        id: 14,
        img: FoggyBridge,
        thumb: FoggyBridgeTb
    },
    {
        id: 15,
        img: NeonPalmTrees,
        thumb: NeonPalmTreesTb
    },
    {
        id: 16,
        img: BluePinkMarble,
        thumb: BluePinkMarbleTb
    }
];
//Doesn't work well when importing. Sometimes the regex will trigger and sometimes it wont
// export const emailRegex = new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm);