import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {BasicOptions} from "../../data/BasicOptions";

interface ISideRadioPopupOptions {
    original: BasicOptions
    title: string;
    warningText: string;
    list: BasicOptions[];
    closePopup: () => void;
    saveText: string;
    saveOption: (optionId: string) => void;
}

const SideRadioPopupOptions: React.FunctionComponent<ISideRadioPopupOptions> = (props) => {
    const [choice, setChoice] = useState<BasicOptions>(props.original);

    return (
        <div className="sideActionBtnPopup">
            <div className="sideBtnPopupHeader">
                <h4>{props.title}</h4>
                <div style={{padding: "0px 0px 5px 5px"}} onClick={props.closePopup}><CloseIcon
                    style={{cursor: "pointer"}}/></div>
            </div>
            {/* <input readOnly={true} disabled={true} type={props.title} className="form-control" id="optionInput"
                   value={choice.name} placeholder={`Select ${props.title}...`}/> */}
            <p className="featureNote">{props.warningText}</p>
            <div className="radioBtnWrapper">
                {props.list && props.list.map((item: BasicOptions, index) => {
                    return <label key={index}>
                        <input style={{cursor: "pointer"}} type="radio" name="optionName"
                               onChange={() => setChoice(item)}
                               checked={choice.id === item.id}/>
                        <span style={{marginLeft: "5px"}}>{item.name}</span>
                    </label>
                })}
            </div>
            <button className="blueBtn" type="button" style={{width: '100%', margin: '10px 0px'}} onClick={() => {
                props.saveOption(choice.id);
                props.closePopup();
            }}>
                {props.saveText}
            </button>
        </div>
    );
}

export default SideRadioPopupOptions