import {CardActivityBase} from "./CardActivityBase";

export class CardMovement extends CardActivityBase {
    previousColumnId: string = "";
    index: number = 0;
    previousColumnName: string = "";
    currentColumnName: string = "";

    public constructor(init?: Partial<CardMovement>) {
        super();
        Object.assign(this, init);
    }
}