import React from 'react';
import {navPath} from "../Helpers";
import {useNavigate} from "react-router-dom";

interface IOtherGuideMenu {
}

const OverallGuideMenu: React.FunctionComponent<IOtherGuideMenu> = () => {
    const navigate = useNavigate();

    return (
        <div className='userGuideTopicList'>
            <h2>Other Guides</h2>
            <ul>
                <li onClick={() => navigate(navPath.userGuideProjectBoards)}>Project Boards</li>
                <li onClick={() => navigate(navPath.userGuideClientBoards)}>Client Boards</li>
                <li onClick={() => navigate(navPath.userGuideProgressBoards)}>Progress Boards</li>
                <li onClick={() => navigate(navPath.userGuideCommissionCards)}>Commission Cards</li>
                <li onClick={() => navigate(navPath.userGuideArchiveBoards)}>Archive Boards</li>
                <li onClick={() => navigate(navPath.userGuideProfileSettings)}>Profile Settings</li>
                <li onClick={() => navigate(navPath.userGuidePriceBreakdowns)}>Price Breakdowns</li>
                <li onClick={() => navigate(navPath.userGuideManageSubscription)}>Manage Subscription
                </li>
            </ul>
        </div>
    );
};

export default OverallGuideMenu