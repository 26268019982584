import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import ProgressBoard from '../assets/UserGuideImages/Progress-Board.png';
import ProgressBar from '../assets/UserGuideImages/Progress-Bar.png';
import CommissionTips from '../assets/UserGuideImages/Commission-Tip-Btn.png';
import UpdateToggles from '../assets/UserGuideImages/Commission-Update-Toggles.png';
import SellerProfile from '../assets/UserGuideImages/Seller-Profile.png';
import ProgressPriceBreakdown from '../assets/UserGuideImages/Progress-Price-Breakdown.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideProgressBoards {

}

const UserGuideProgressBoards: React.FC<IUserGuideProgressBoards> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideProgressBoardsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuideProgressBoardsProgressBarRef = useRef<HTMLDivElement | null>(null);
    const userGuideProgressBoardsCommissionHistoryRef = useRef<HTMLDivElement | null>(null);
    const userGuideProgressBoardsCommissionTipsRef = useRef<HTMLDivElement | null>(null);
    const userGuideProgressBoardsUpdateTogglesrRef = useRef<HTMLDivElement | null>(null);
    const userGuideProgressBoardsSellerProfileRef = useRef<HTMLDivElement | null>(null);
    const userGuideProgressBoardsSellerBreakdownsRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "progressBar":
                        userGuideProgressBoardsProgressBarRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "commissionHistory":
                        userGuideProgressBoardsCommissionHistoryRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "commissionTips":
                        userGuideProgressBoardsCommissionTipsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "updateToggles":
                        userGuideProgressBoardsUpdateTogglesrRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "sellerProfile":
                        userGuideProgressBoardsSellerProfileRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "sellerBreakdowns":
                        userGuideProgressBoardsSellerBreakdownsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuideProgressBoards)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideProgressBoardsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideProgressBoardsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('progressBar')}>1. Progress Bars</li>
                                <li onClick={() => scrollToSection('commissionHistory')}>2. Commission History</li>
                                <li onClick={() => scrollToSection('commissionTips')}>3. Commission Tips</li>
                                <li onClick={() => scrollToSection('updateToggles')}>4. Commission Update Toggles</li>
                                <li onClick={() => scrollToSection('sellerProfile')}>5. Seller Profile</li>
                                <li onClick={() => scrollToSection('sellerBreakdowns')}>6. Seller Breakdowns</li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Progress Boards</h1>
                        <p className='biggerText'>{`Progress boards are meant to help the client track their commission's
                            progress and help the seller keep a paper trail.`}</p>
                        <img src={ProgressBoard} className='smallImg' style={{marginBottom: "35px"}}
                             alt={"user guide progress board"}/>

                        <section id="progressBar" ref={userGuideProgressBoardsProgressBarRef}>
                            <h2>1. Progress Bars</h2>
                            <img src={ProgressBar} alt={"user guide progress board progress bar"}/>
                            <p><strong>Progress Bars</strong>{` indicate what step a client's commission is on. The steps
                                inside progress bars change depending on the type of project the client has ordered.
                                The titles for the steps come from the column titles in their respective project boards.
                                Visit the `}<Link to={navPath.userGuideProjectBoards} className="hyperlink">Project
                                Board</Link> guide to learn more.</p>
                        </section>

                        <section id="commissionHistory" ref={userGuideProgressBoardsCommissionHistoryRef}>
                            <h2>2. Commission History</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/SxJdlo6FD64"
                                    title="video progress board commission history demo"
                                    allowFullScreen></iframe>
                            <p>{`Think of the <strong>commission history</strong> as a documented paper trail. The history
                                includes timestamped comments, images, and commission movement activity.
                                To review a commission's history, click on the `}<strong>{`"Show Details"`}</strong> button.
                            </p>
                            <p>{`The short video above demonstrates how a seller's commission comment will be translated
                                into a client's commission history.`}</p>
                        </section>

                        <section id="commissionTips" ref={userGuideProgressBoardsCommissionTipsRef}>
                            <h2>3. Commission Tips</h2>
                            <img src={CommissionTips} alt={"user guide progress board commission tips"}/>
                            <p>Every commission has a <strong>{"`$ Leave Optional Tip`"}</strong>{` button attached to them.
                                It is at the client's discretion on whether or not they tip a seller for their services.
                                Please do not directly solicit clients for tips. The tip button will be disabled by
                                default, but it will be activated once a commission is `}<strong>complete</strong>.
                                {/* You can fill out your venmo handle in the settings. The following is valid form of this handle: "@steve-foo" or "steve-foo" */}
                            </p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> The tip button will not link properly
                                    if the seller has not provided a <strong>Venmo</strong> handle in their <Link
                                        to={navPath.userGuideProfileSettings} className="hyperlink">profile
                                        settings</Link>.</p>
                            </div>
                        </section>

                        <section id="updateToggles" ref={userGuideProgressBoardsUpdateTogglesrRef}>
                            <h2>4. Commission Update Toggles</h2>
                            <img src={UpdateToggles} alt={"user guide progress board update toggles"}/>
                            <p>{`In the top navigation bar of a client's progress board, there should be a toggle button
                                like the image above for turning commission email updates on or off. Sellers have no
                                direct control over this setting, so clients will be responsible for them.
                                However, sellers do have the ability to double check these settings on their end in
                                their client's contact info. Visit the `}<Link to={navPath.userGuideClientBoards}
                                                                               className="hyperlink">Client
                                Guide</Link> to learn more.
                            </p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> There will eventually be a toggle for
                                    commission texts coming soon.</p>
                            </div>
                        </section>

                        <section id="sellerProfile" ref={userGuideProgressBoardsSellerProfileRef}>
                            <h2>5. Seller Profile</h2>
                            <img src={SellerProfile} className='medImg'
                                 alt={"user guide progress board seller profile"}/>
                            <p>Clients can view a <strong>{`Seller's Profile`}</strong> on their progress boards. If the
                                seller filled out all the fields, the profile should show a bio, business link, social
                                media links, email, phone number, and venmo handle.</p>
                            <p>{`In order to view a seller's profile, click on the seller's profile info in the top right
                                corner and click the `}<strong>{`"Seller Profile"`}</strong> option.</p>
                        </section>

                        <section id="sellerBreakdowns" ref={userGuideProgressBoardsSellerBreakdownsRef}>
                            <h2>6. Seller Breakdowns</h2>
                            <img src={ProgressPriceBreakdown} className='medImg'
                                 alt={"user guide progress board seller breakdown"}/>
                            <p><strong>Seller Breakdowns</strong> are intended as approximate monetary assessments of
                                the different projects/services the seller provides.
                                They are intended to give clients a better idea of what they are paying for. Breakdowns
                                will vary depending on the project the client has ordered.</p>
                            <p>{`In order to view a seller's breakdown(s), click on the seller's profile info in the top
                                right corner and click the `}<strong>{`"Price Breakdowns"`}</strong> option.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Seller breakdowns are not available in
                                    every subscription tier. They are only available in the Basic, Standard and Pro
                                    plans.</p>
                            </div>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> If a seller does not create a
                                    breakdown, a client will not see anything but a message that will encourage them to
                                    reach out for one.</p>
                            </div>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideProgressBoards;