import React, {useEffect, useState} from 'react';
import './App.css';
import './scss/style.css'
import {Route, Routes, useNavigate} from "react-router-dom";
import ProjectWorkflowBoard from "./pages/ProjectWorkflowBoard";

import LoginSignUp from "./pages/LoginSignUp";
import {UserAuth} from "./context/AuthContextProvider";
import ProjectDataSource from "./api/ProjectDataSource";
import ClientDataSource from "./api/ClientDataSource";
import {ProjectDetails} from "./data/ProjectDetails";
import AccountDataSource from "./api/AccountDataSource";
import {AccountData} from "./data/AccountData";
import {NavigationSideBarLayout} from "./components/Navigation/SideBar/NavigationSideBarLayout";
import {ClientDto} from "./data/ClientDto";
import ClientWorkflowBoard from "./pages/ClientWorkflowBoard";
import {Card} from "./data/Card";
import CardDataSource from "./api/CardDataSource";
import {CardDto} from "./data/CardDto";
import TagDataSource from "./api/TagDataSource";
import {Client} from "./data/Client";
import EditProfile from "./pages/EditProfile";
import ProfileView from "./pages/ProfileView";
import ManageSubscription from "./pages/ManageSubscription";
import PriceBreakdown from "./pages/PriceBreakdown";
import {Tag} from "./data/Tag";
import {ColumnDto} from "./data/ColumnDto";
import ColumnDataSource from "./api/ColumnDataSource";
import {CommissionProgressTrackingBoard} from "./pages/CommissionProgressTrackingBoard";
import {NavigationTopBarLayout} from "./components/Navigation/TopBar/NavigationTopBarLayout";
import {colorPicker, navPath} from "./components/Helpers";
import UnprotectedProfileView from "./pages/UnprotectedProfileView";
import Home from "./pages/Home";
import PricingTier from "./pages/PricingTier";
import CardActivityDataSource from "./api/CardActivityDataSource";
import {CardComment} from "./data/CardComment";
import {DropResult} from "@hello-pangea/dnd";
import ArchiveCommissionsBoard from "./pages/ArchiveCommissionsBoard";
import ArchiveProjectsBoard from "./pages/ArchiveProjectsBoard";
import ArchiveClientsBoard from "./pages/ArchiveClientsBoard";
import {ProjectData} from "./data/ProjectData";
import NotFound from './pages/NotFound';
import {CardProgressHistoryDetails} from "./data/CardProgressHistoryDetails";
import DefaultNoContent from "./pages/DefaultNoContent";
import {ArchiveCardDetails} from "./data/ArchiveCardDetails";
import {ModalErrorReloadMessage} from "./components/Base/Popup/ModalErrorReloadMessage";
import FeedbackDataSource from "./api/FeedbackDataSource";
import Team from './pages/Team';
import Faq from './pages/Faq';
import UserGuide from './pages/UserGuide';
import UserGuideProjectBoards from './pages/UserGuide-ProjectBoards';
import UserGuideClientBoards from './pages/UserGuide-ClientBoards';
import UserGuideProgressBoards from './pages/UserGuide-ProgressBoards';
import UserGuideCommissionCards from './pages/UserGuide-CommissionCards';
import UserGuideArchiveBoards from './pages/UserGuide-ArchiveBoards';
import UserGuideProfileSettings from './pages/UserGuide-ProfileSettings';
import UserGuidePriceBreakdowns from './pages/UserGuide-PriceBreakdowns';
import UserGuideManageSubscription from './pages/UserGuide-ManageSubscription';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import UnprotectedPriceBreakdown from './pages/UnprotectedPriceBreakdown';
import ProjectBreakdownDataSource from "./api/ProjectBreakdownDataSource";
import {ProjectBreakdown} from "./data/ProjectBreakdown";
import CommCommAssets from "./pages/CommCommAssets";
import {CardProgress} from "./data/CardProgress";
import {ProjectBreakdownDto} from "./data/ProjectBreakdownDto";
import Unsubscribe from "./pages/Unsubscribe";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import CheckoutError from "./pages/CheckoutError";
import {loadStripe} from '@stripe/stripe-js';
import StripeDataSource from "./api/StripeDataSource";
import {config} from "./config/config";
import TransactionDataSource from "./api/TransactionDataSource";
import {CommCommTransaction} from "./data/CommCommTransaction";
import WebPageDataSource from "./api/InitialWebPageDataSource";
import {InitialWebPageDto} from "./data/WebPageDto";
import ContactUsDataSource from "./api/ContactUsDataSource";


//https://stackoverflow.com/questions/63009673/react-hooks-with-firestore-data-1-1k-reads-in-30-seconds-from-2-collections-w
//https://beta.reactjs.org/learn/updating-arrays-in-state
//https://stackoverflow.com/questions/73925417/react-state-inside-react-context-is-not-updating
function App() {
    const {logOut, user} = UserAuth();
    const navigate = useNavigate();
    const [account, setAccount] = useState<AccountData>();
    const [unprotectedAccount, setUnprotectedAccount] = useState<AccountData>();
    const [clients, setClients] = useState<ClientDto[]>([]);
    const [projects, setProjects] = useState<ProjectDetails[]>([]);
    const [projectBreaksdowns, setProjectBreakdowns] = useState<ProjectBreakdown[]>([]);
    const [projectBreaksdownsDto, setProjectBreakdownsDto] = useState<ProjectBreakdownDto[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<string>("");
    const [selectedProjectId, setSelectedProjectId] = useState<string>("");
    const [selectedClient, setSelectedClient] = useState<ClientDto>();
    const [selectedProject, setSelectedProject] = useState<ProjectDetails>();
    const [showProfile, setShowProfile] = useState(false);
    const [showArchive, setShowArchive] = useState(false);
    const [loadArchiveFirstTime, setLoadArchiveFirstTime] = useState(true);
    const [archivedProjects, setArchivedProjects] = useState<ProjectData[]>([]);
    const [archivedClients, setArchivedClients] = useState<Client[]>([]);
    const [archivedCommissions, setArchivedCommissions] = useState<ArchiveCardDetails[]>([])
    const [tags, setTags] = useState<Tag[]>([]);
    const [clientViewingProgressBoard, setClientViewingProgressBoard] = useState<Client>()
    const [cardsUsed, setCardsUsed] = useState(0);
    const [cardMax, setCardMax] = useState(0);
    //Card this is currently being displayed
    const [displayCommission, setDisplayCommission] = useState<CardDto>({} as CardDto);
    const [displayArchiveCommission, setDisplayArchiveCommission] = useState<ArchiveCardDetails>({} as ArchiveCardDetails);
    //Done Column warning for
    const [showDoneColumnWarning, setShowDoneColumnWarning] = useState(false);
    const [doneCard, setDoneCard] = useState<CardDto>({} as CardDto);
    const [doneCardSrcColumn, setDoneCardSrcColumn] = useState<string>("");
    const [doneCardDestColumn, setDoneCardDestColumn] = useState<string>("");
    const [doneCardDestColumnIndex, setDoneCardDestColumnIndex] = useState<number>(0);
    //Done Column congratulations
    const [showDoneColumnCongrats, setShowDoneColumnCongrats] = useState(false);
    //In Queue Column warning for no client, start date and/or no due date
    const [showInQueueColumnWarning, setShowInQueueColumnWarning] = useState(false);
    // const [inQueueCard, setInQueueCard] = useState<CardDto>({} as CardDto);
    // const [inQueueCardSrcColumn, setInQueueCardSrcColumn] = useState<string>("");
    // const [inQueueCardDestColumn, setInQueueCardDestColumn] = useState<string>("");
    // const [inQueueCardDestColumnIndex, setInQueueCardDestColumnIndex] = useState<number>(0);
    // Show Project Archive Warning if they still have cards
    const [showArchiveProjectWarning, setShowArchiveProjectWarning] = useState(false);
    //Show Client Archive Warning if they still have cards
    const [showArchiveClientWarning, setShowArchiveClientWarning] = useState(false);
    const [cardComments, setCardComments] = useState<CardProgressHistoryDetails[]>([])
    const [isAccountLoading, setIsAccountLoading] = useState(false);
    const [isClientLoading, setIsClientLoading] = useState(false);
    const [isProjectLoading, setIsProjectLoading] = useState(false);
    const [isArchiveCommissionLoading, setIsArchiveCommissionLoading] = useState(false);
    const [isArchiveClientLoading, setIsArchiveClientLoading] = useState(false);
    const [isArchiveProjectLoading, setIsArchiveProjectLoading] = useState(false);
    const [isLoadingProgressTrackingBoard, setIsLoadingProgressTrackingBoard] = useState(false);
    const [isUnprotectedBreakdownLoading, setIsUnprotectedBreakdownLoading] = useState(false);
    const [isUnprotectedProfileLoading, setIsUnprotectedProfileLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("An Error Occured.");
    const [loggedIn, setLoggedIn] = useState(false);
    const [feedbackConfirmation, setFeedbackConfirmation] = useState(false);
    const [contactUsConfirmation, setContactUsConfirmation] = useState(false);
    const [isSideNavVisible, setIsSideNavVisible] = useState(true);
    const [isProjectSaving, setIsProjectSaving] = useState(false);
    const [isClientSaving, setIsClientSaving] = useState(false);
    const [isCardSaving, setIsCardSaving] = useState(false);
    const [isProfileSaving, setIsProfileSaving] = useState(false);
    const [isBreakdownSaving, setIsBreakdownSaving] = useState(false);
    const [isClientCommentSaving, setIsClientCommentSaving] = useState(false);
    //logic for loading animation of unarchiving
    const [unarchivingProjectId, setUnarchivingProjectId] = useState("");
    const [unarchivingClientId, setUnarchivingClientId] = useState("");
    const [archivingProjectId, setArchivingProjectId] = useState("");
    const [archivingClientId, setArchivingClientId] = useState("");

    //project breakdown delete confirmation
    const [projectBreakdownDeleteConfirmation, setProjectBreakdownDeleteConfirmation] = useState("");
    const [projectBreakdownUpdatedConfirmation, setProjectBreakdownUpdatedConfirmation] = useState("");
    const [unprotectedAccountId, setUnprotectedAccountId] = useState<string>("");
    const [unprotectedClientId, setUnprotectedClientId] = useState<string>("");
    const [progressCardsDetails, setProgressCardsDetails] = useState<CardProgress[]>([]);
    const [progressCards, setProgressCard] = useState<Card[]>([]);
    const [showArchiveCounter, setShowArchiveCounter] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [transactionHistory, setTransactionHistory] = useState<CommCommTransaction[]>([]);

    //Logic for setting login tab
    const [loginTab, setLoginTab] = useState<boolean>(true)

    //#region Unprotected pages

    useEffect(() => {
        if (unprotectedAccountId && unprotectedClientId) {
            setIsLoadingProgressTrackingBoard(true)
            setIsUnprotectedBreakdownLoading(true);
            ClientDataSource.Get.fromId(unprotectedClientId).then((response) => {
                setClientViewingProgressBoard(response);
            }).catch((error) => {
                navigate(navPath.notFound)
            });
            ClientDataSource.Get.allProjectCardsForClient(unprotectedAccountId, unprotectedClientId).then((response) => {
                if (progressCardsDetails.length === 0) {
                    //save cards so client can respond to them
                    setProgressCard(response.cards)
                    //sort cards by last updated
                    let newCardProgressArr = response.cardProgresses.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }))
                    // Use Promise.all to wait for all asynchronous operations to complete
                    Promise.all(
                        newCardProgressArr.map((card, index) =>
                            CardActivityDataSource.Get.fromCard(card.cardId)
                                .then((activityResponse) => {
                                    newCardProgressArr[index] = {
                                        ...newCardProgressArr[index],
                                        cardHistoryDetails:
                                            activityResponse.length > 0 ? activityResponse.reverse() : [], //reverse order of the list to  allow more recent to show
                                    };
                                })
                                .catch(() => {
                                })
                        )
                    ).then(() => {
                        // All asynchronous operations have completed
                        setIsLoadingProgressTrackingBoard(false);
                        setProgressCardsDetails(newCardProgressArr);
                    }).catch(() => {
                        setIsLoadingProgressTrackingBoard(false);
                        navigate(navPath.notFound);
                    });
                } else {
                    setIsLoadingProgressTrackingBoard(false);
                }
            }).catch((error) => {
                navigate(navPath.notFound)
            });

            ProjectBreakdownDataSource.Get.fromAccountWithClientId(unprotectedAccountId, unprotectedClientId).then((response) => {
                setProjectBreakdownsDto(response);
                setIsUnprotectedBreakdownLoading(false);
            }).catch((error) => {
                setIsUnprotectedBreakdownLoading(false);
                navigate(navPath.notFound)
            });
        }
    }, [unprotectedAccountId, unprotectedClientId])

    useEffect(() => {
        if (unprotectedAccountId) {
            setIsUnprotectedProfileLoading(true)

            AccountDataSource.Get.fromId(unprotectedAccountId).then((response) => {
                setUnprotectedAccount(response);
                setIsUnprotectedProfileLoading(false)
            }).catch((error) => {
                setIsUnprotectedProfileLoading(false)
                navigate(navPath.notFound)
            });
        }
    }, [unprotectedAccountId])

    //#endregion

    //when user logs in check for their email and if the loggedin is true
    useEffect(() => {
        if (user != null || loggedIn) {
            setIsAccountLoading(true);
            AccountDataSource.Get.fromEmail(user?.email ?? "").then((response: AccountData) => {
                setCardsUsed(response.cardsUsed);
                setCardMax(response.cardMax);
                setAccount(response);
            }).finally(() => {
                setIsAccountLoading(false);
            })
        }
    }, [user?.email, loggedIn])

    useEffect(() => {

        if (user != null && projects.length === 0) {
            setIsProjectLoading(true);
            setIsClientLoading(true);
            WebPageDataSource.Get.InitialWebPageDetails(account?.id ?? "").then((response: InitialWebPageDto) => {
                let projList = response.projectDetailsDtos.map((psd) => {
                    setTags(psd.tagData)
                    return new ProjectDetails(psd.projectData, psd.columnData, psd.cardData, psd.tagData);
                }).sort((a, b) => b.quantity - a.quantity)
                setProjects(projList);
                setSelectedProjectId(projList[0]?.id ?? "");

                let clientList = response.clientDetailsDtos.map((csd) => {
                    return new ClientDto(csd.clientData, csd.cardData, csd.tagData);
                }).sort((a, b) => b.quantity - a.quantity)
                setClients(clientList);
                setSelectedClientId(clientList[0]?.id ?? "");
            }).finally(() => {
                setIsProjectLoading(false);
                setIsClientLoading(false);
            })
        }

        if (user != null) {
            ProjectBreakdownDataSource.Get.fromAccount(account?.id ?? "").then((response: ProjectBreakdown[]) => {
                setProjectBreakdowns(response)
            })
        }

    }, [account?.id])

    useEffect(() => {
        if (selectedProjectId) {
            let projDetails = projects.filter(proj => proj.id === selectedProjectId)[0]
            setSelectedProject(projDetails);
        }
    }, [selectedProjectId])

    useEffect(() => {
        if (selectedClientId) {
            let clientDetails = clients.filter(client => client.id === selectedClientId)[0]
            setSelectedClient(clientDetails);
        }
    }, [selectedClientId])

    useEffect(() => {
        if (showArchive && loadArchiveFirstTime) {
            setIsArchiveProjectLoading(true)
            setIsArchiveClientLoading(true);

            //prevent from pulling again if its already existing
            if (showArchiveCounter === 0) {
                setShowArchiveCounter(showArchiveCounter + 1)
                setIsArchiveCommissionLoading(true);
                CardDataSource.Get.allArchivedFromAccount(account?.id ?? "").then((response) => {
                    let commissions = response.cardData.map((cd) => {
                        return new ArchiveCardDetails(cd, response.tagData, cd.projectTitle, cd.clientName);
                    })
                    setArchivedCommissions(commissions);
                }).finally(() => {
                    setIsArchiveCommissionLoading(false);
                })
            }

            ProjectDataSource.Get.allArchiveFromAccount(account?.id ?? "").then((response) => {
                setArchivedProjects(response);
            }).finally(() => {
                setIsArchiveProjectLoading(false);
            })

            ClientDataSource.Get.allArchivedFromAccount(account?.id ?? "").then((response) => {
                setArchivedClients(response);
            }).finally(() => {
                setIsArchiveClientLoading(false);
            })

            setLoadArchiveFirstTime(false);
        }
    }, [showArchive])

    const logUserOut = () => {
        setShowProfile(false);
        setShowArchive(false);
        setSelectedProjectId("");
        setSelectedClientId("");
        setLoadArchiveFirstTime(true)
        setSelectedClient(undefined);
        setSelectedProject(undefined);
        setAccount(undefined);
        setCardsUsed(0);
        setCardMax(0);
        setShowDoneColumnWarning(false);
        setShowDoneColumnCongrats(false);
        setDoneCardSrcColumn("");
        setDoneCardDestColumn("");
        setDoneCardDestColumnIndex(0);
        setShowInQueueColumnWarning(false);
        // setInQueueCardSrcColumn("");
        // setInQueueCardDestColumn("");
        // setInQueueCardDestColumnIndex(0);
        setShowArchiveProjectWarning(false)
        setShowArchiveClientWarning(false)
        setDoneCard({} as CardDto);
        // setInQueueCard({} as CardDto);
        setClients([]);
        setProjects([]);
        setArchivedClients([]);
        setArchivedProjects([]);
        setArchivedCommissions([]);
        setTags([]);
        setCardComments([]);
        setIsAccountLoading(false);
        setIsClientLoading(false);
        setIsProjectLoading(false);
        setIsArchiveClientLoading(false);
        setIsArchiveCommissionLoading(false);
        setIsArchiveProjectLoading(false);
        setShowArchiveCounter(0)
        logOut()
    }

    const reloadPage = () => {
        window.location.reload();
    }

    const retrieveClientsArchiveCards = (clientId: string) => {
        CardDataSource.Get.allArchivedFromClient(clientId).then((response) => {
            if (response) {
                let commissions = response.cardData.map((cd) => {
                    return new ArchiveCardDetails(cd, response.tagData, cd.projectTitle, cd.clientName);
                })
                setArchivedCommissions(commissions);
            }
        })
    }

    //commission is selected from a work board
    const selectCard = (card: CardDto) => {
        //If cardIndex is -1 then card is archived and should be retrieved from client List
        setCardComments([]); //Clear and Refresh Card comments if new card is chosen
        syncDisplayedCommission(card)
    }

    //Puts the card data in the edit card pop up
    const syncDisplayedCommission = (card: CardDto) => {
        //if archiveIndex is 0 or greater than the card exists in the archive list.
        let archiveIndex = archivedCommissions.findIndex(x => x.id === card.id);
        if (archiveIndex >= 0) {
            //put data into the archive card pop up
            setDisplayArchiveCommission(archivedCommissions[archiveIndex]);
        }
        const projIndex = getProjectIndex(card.projectId);
        //If projIndex is -1 then card is archived and should be retrieved from client List
        if (projIndex >= 0) {
            const columnIndex = getColumnIndex(projects[projIndex], card.columnId);
            const cardIndex = getCardIndexInProjects(projects[projIndex], card.columnId, card.id);
            setDisplayCommission(projects[projIndex].columnDetails[columnIndex].cardDtos[cardIndex]);
        } else {
            const clientIndex = getClientIndex(card.clientId);
            const cardIndex = getCardIndexInClients(clients[clientIndex], card.id);
            //Need to set display commission inorder to trigger the display archive commission
            setDisplayCommission(clients[clientIndex].cardDtos[cardIndex]);
        }
    }

    // useEffect(() => {
    //     setShowArchive(location.pathname === navPath.commissionArchive || location.pathname === navPath.clientsArchive || location.pathname === navPath.projectsArchive)
    // }, [location.pathname])

    const syncProjects = (project: ProjectDetails, index: number) => {
        const newSetOfProjects = copyAllProjects();
        newSetOfProjects[index] = project;
        setProjects(newSetOfProjects);
        if (project.id === selectedProjectId) {
            setSelectedProject(project)
        }
    }

    const syncClients = (client: ClientDto, index: number) => {
        const newSetOfClients = copyAllClients();
        newSetOfClients[index] = client;
        setClients(newSetOfClients);
        // console.log(newSetOfClients);
        if (client.id === selectedClientId) {
            setSelectedClient(client)
        }
    }

    function syncCard(newProject: ProjectDetails, columnIndex: number, cardIndex: number, card: Card, tagDetails: Tag[], projIndex: number) {
        newProject.columnDetails[columnIndex].cardDtos[cardIndex] = new CardDto(card, tagDetails);
        syncProjects(newProject, projIndex);
        //May not have a client attached to the card
        verifyAndSyncClientsCard(card, tagDetails);
        //sync selected card
        syncDisplayedCommission(new CardDto(card, tagDetails));
    }

    //updates a card
    const syncCardUpdates = (card: Card) => {
        let project = projects.find(x => x.id === card.projectId);
        let projIndex = projects.findIndex(x => x.id === card.projectId);
        const newProject = {...project} as ProjectDetails

        const columnIndex = project!.columnDetails.findIndex(x => x.id === card.columnId);
        const cardIndex = project!.columnDetails[columnIndex].cardDtos.findIndex(x => x.id === card.id);
        const tagDetails = project!.columnDetails[columnIndex].cardDtos[cardIndex].tagDetails;
        //add to order of columnDetails

        syncCard(newProject, columnIndex, cardIndex, card, tagDetails, projIndex);
    }

    function syncRemoveClientCard(card: Card) {
        let client = clients.find(x => x.id === card.clientId);
        let clientIndex = clients.findIndex(x => x.id === card.clientId);
        const newClient = {...client} as ClientDto
        const clientCardIndex = client!.cardDtos.findIndex(x => x.id === card.id);
        newClient.cardDtos.splice(clientCardIndex, 1);
        syncClients(newClient, clientIndex);
    }

//remove card from projects workflow and client board
    const syncCardRemoval = (card: Card) => {
        syncCardRemovalFromProjects(card)
        if (card.clientId != null && card.clientId !== '') {
            syncRemoveClientCard(card);
        }
    }

    const syncCardRemovalFromProjects = (card: Card) => {
        let project = projects.find(x => x.id === card.projectId);
        let projIndex = projects.findIndex(x => x.id === card.projectId);
        const newProject = {...project} as ProjectDetails

        const columnIndex = project!.columnDetails.findIndex(x => x.id === card.columnId);
        const cardIndex = project!.columnDetails[columnIndex].cardDtos.findIndex(x => x.id === card.id);
        const cardOrderIndex = project!.columnDetails[columnIndex].cardOrder.findIndex(x => x === card.id);
        //add to order of columnDetails
        syncProjects(newProject, projIndex);

        newProject.columnDetails[columnIndex].cardOrder.splice(cardOrderIndex, 1);
        newProject.columnDetails[columnIndex].cardDtos.splice(cardIndex, 1);
    }

    const syncCardAddingClientField = (card: Card) => {
        let client = clients.find(x => x.id === card.clientId);
        let clientIndex = clients.findIndex(x => x.id === card.clientId);

        const newClient = {...client} as ClientDto;
        const cardTags: Tag[] = [];
        card.tags.forEach((tag) => {
            const t = tags.find(t => t.id === tag);
            if (t) {
                cardTags.push(t);
            }
        })
        newClient.cardDtos.splice(0, 0, new CardDto(card, cardTags));

        syncClients(newClient, clientIndex);
        syncCardUpdates(card)
        incrementClientCards(card.clientId);
    }

    const syncModifyCardClientField = (card: Card, oldClientId: string) => {
        let client = clients.find(x => x.id === card.clientId);

        let oldClient = clients.find(x => x.id === oldClientId);
        let oldCard = oldClient!.cardDtos.find(x => x.id === card.id);
        if (oldCard) {
            const newClient = {...client} as ClientDto;
            newClient.cardDtos.splice(0, 0, new CardDto(card, oldCard.tagDetails));
            //add with tags to new client
            verifyAndSyncClientsCard(card, oldCard.tagDetails);
            incrementClientCards(card.clientId);
            //remove previous client
            syncRemoveClientCard({...card, clientId: oldClientId});
            decrementClientCards(oldClientId)
            //sync current project
            syncCardUpdates(card);
        }
    }

    const syncModifyCardProjectField = (card: Card, oldProjectId: string, oldColumnId: string) => {
        let project = projects.find(x => x.id === card.projectId);
        let projectIndex = projects.findIndex(x => x.id === card.projectId);
        let oldProjectIndex = projects.findIndex(x => x.id === oldProjectId);

        let oldColumnIndex = projects[oldProjectIndex].columnDetails.findIndex(x => x.id === oldColumnId);
        let oldCard = projects[oldProjectIndex].columnDetails[oldColumnIndex].cardDtos.find(x => x.id === card.id)

        //will move card to the InQueue column
        const newProject = {...project} as ProjectDetails
        newProject.columnDetails[0].cardDtos.splice(0, 0, new CardDto(card, oldCard!.tagDetails))
        newProject.columnDetails[0].cardOrder.splice(0, 0, card.id);
        syncProjects(newProject, projectIndex);
        verifyAndSyncClientsCard(card, oldCard!.tagDetails);
        incrementProjectCards(newProject.id);
        syncCardRemovalFromProjects({...card, projectId: oldProjectId, columnId: oldColumnId});
        decrementProjectCards(oldProjectId)
    }

    //Add and remove tags are done here
    const syncCardTags = (card: Card, tagId: string, action: string) => {
        let project = projects.find(x => x.id === card.projectId);
        let projIndex = projects.findIndex(x => x.id === card.projectId);
        const newProject = {...project} as ProjectDetails

        const columnIndex = project!.columnDetails.findIndex(x => x.id === card.columnId);
        const cardIndex = project!.columnDetails[columnIndex].cardDtos.findIndex(x => x.id === card.id);
        const tagDetails = project!.columnDetails[columnIndex].cardDtos[cardIndex].tagDetails;
        //add to order of columnDetails
        if (action === "delete") {
            const tagIndex = tagDetails.findIndex(x => x.id === tagId);
            tagDetails.splice(tagIndex, 1);
        } else {
            const tagIndex = tags.findIndex(x => x.id === tagId);
            tagDetails.push(tags[tagIndex]);
        }
        syncCard(newProject, columnIndex, cardIndex, card, tagDetails, projIndex);
    }

    function cardTagAction(action: string, card: CardDto, tag: Tag, tagIndex: number) {
        if (action === "delete") {
            const tagOrderIndex = card.tags.findIndex(x => x === tag.id);
            card.tagDetails.splice(tagIndex, 1)
            card.tags.splice(tagOrderIndex, 1)
        } else {
            card.tagDetails[tagIndex] = {
                ...card.tagDetails[tagIndex],
                color: tag.color,
                name: tag.name
            }
        }
    }

    const syncTags = (tag: Tag, action = "") => {
        let project = projects.find(x => x.id === tag.projectId);
        let projectIndex = projects.findIndex(x => x.id === tag.projectId);
        const newProject = {...project} as ProjectDetails;
        const tagClients: string[] = [];
        const tagCards: string[] = [];
        // let selectedCard: CardDto = {} as CardDto
        for (const column of newProject.columnDetails) {
            for (const card of column.cardDtos) {
                const tagIndex = card.tagDetails.findIndex(x => x.id === tag.id);
                if (tagIndex >= 0) {
                    tagClients.push(card.clientId)
                    tagCards.push(card.id)
                    cardTagAction(action, card, tag, tagIndex);
                }
                if (displayCommission.id === card.id) {
                    // selectedCard = card;
                }
            }
        }
        syncProjects(newProject, projectIndex);

        const newSetOfClients = copyAllClients();
        const filteredClients = newSetOfClients.filter(c => tagClients.includes(c.id))
        for (const client of filteredClients) {
            const clientIndex = clients.findIndex(x => x.id === client.id);
            const cards = client.cardDtos.filter(card => tagCards.includes(card.id))
            for (const card of cards) {
                const tagIndex = card.tagDetails.findIndex(x => x.id === tag.id);
                if (tagIndex >= 0) {
                    cardTagAction(action, card, tag, tagIndex);
                }
            }
            syncClients(client, clientIndex);
        }
        if (action === "delete") {
            removeTag(tag.id)
        } else {
            updateTag(tag);
        }
    }

    const updateTag = (tag: Tag) => {
        const tagIndex = getTagIndex(tag.id);
        const newSetOfTags = copyAllTags();
        newSetOfTags[tagIndex] = {
            ...newSetOfTags[tagIndex],
            color: tag.color,
            name: tag.name
        };
        setTags(newSetOfTags);
    }

    const removeTag = (tagId: string) => {
        const tagIndex = getTagIndex(tagId);
        const newSetOfTags = copyAllTags();
        newSetOfTags.splice(tagIndex, 1)
        setTags(newSetOfTags);
    }

    const copySingleProject = (projectId: string): ProjectDetails => {
        let project = projects.find(x => x.id === projectId);
        return {...project} as ProjectDetails;
    }

    const copyAllProjects = (): ProjectDetails[] => {
        return [...projects];
    }

    const getProjectIndex = (projectId: string): number => {
        return projects.findIndex(x => x.id === projectId);
    }

    const getColumnIndex = (project: ProjectDetails, columnId: string): number => {
        return project.columnDetails.findIndex(x => x.id === columnId);
    }

    const getCardIndexInProjects = (project: ProjectDetails, columnId: string, cardId: string): number => {
        let columnIndex = project.columnDetails.findIndex(x => x.id === columnId);
        return project.columnDetails[columnIndex].cardDtos.findIndex(x => x.id === cardId);
    }

    const copySingleClient = (clientId: string): ClientDto => {
        let client = clients.find(x => x.id === clientId);
        return {...client} as ClientDto;
    }

    const copyAllClients = (): ClientDto[] => {
        return [...clients];
    }

    const getClientIndex = (clientId: string): number => {
        return clients.findIndex(x => x.id === clientId);
    }

    //Cannot read properties of undefined (reading 'findIndex')
    const getCardIndexInClients = (client: ClientDto, cardId: string): number => {
        return client.cardDtos.findIndex(x => x.id === cardId);
    }

    const getTagIndex = (tagId: string): number => {
        return tags.findIndex(x => x.id === tagId);
    }

    const copyAllTags = (): Tag[] => {
        return [...tags];
    }

    const selectClient = (clientId: string) => {
        setSelectedClientId(clientId)
    }

    const selectProject = (projectId: string) => {
        setSelectedProjectId(projectId)
    }

    const incrementProjectCards = (projectId: string) => {
        const newSetOfProjects = copyAllProjects();
        const projectIndex = getProjectIndex(projectId);
        newSetOfProjects[projectIndex].quantity = projects[projectIndex].quantity + 1
        setProjects(newSetOfProjects)
    }

    const decrementProjectCards = (projectId: string) => {
        const newSetOfProjects = copyAllProjects();
        const projectIndex = getProjectIndex(projectId);
        newSetOfProjects[projectIndex].quantity = projects[projectIndex].quantity - 1
        setProjects(newSetOfProjects)
    }

    const incrementClientCards = (clientId: string) => {
        const newSetOfClients = copyAllClients();
        const clientIndex = getClientIndex(clientId);
        newSetOfClients[clientIndex].quantity = clients[clientIndex].quantity + 1
        setClients(newSetOfClients)
    }

    const decrementClientCards = (clientId: string) => {
        const newSetOfClients = copyAllClients();
        const clientIndex = getClientIndex(clientId);
        newSetOfClients[clientIndex].quantity = clients[clientIndex].quantity - 1
        setClients(newSetOfClients)
    }

    const reorderAddCard = (list: any[], endIndex: number, card: any): any[] => {
        let result = Array.from(list);
        result.splice(endIndex, 0, card)
        return result
    };

    const reorderRemoveCard = (list: any[], startIndex: number): any[] => {
        let result = Array.from(list);
        result.splice(startIndex, 1)
        return result
    };

    const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
        let result = Array.from(list);
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    };

    const submitDeactivationFeedback = (comment: string, reasonType: number) => {
        AccountDataSource.Update.deactivate(account?.id ?? "", comment, reasonType).then((response) => {
            if (response) {
                setAccount({...account!, isDeactivated: true});
            }
        }).catch(error => {
            setShowError(true);
            setErrorMessage("Feedback Error");
        });
    }

    const updateAccountReactivation = () => {
        AccountDataSource.Update.reactivate(account?.id ?? "").then((response) => {
            if (response) {
                setAccount({...account!, isDeactivated: false});
            }
        }).catch(error => {
            setShowError(true);
            setErrorMessage("Feedback Error");
        });
    }

    const randomCardColor = () => {
        const rand = Math.floor(Math.random() * 5) + 1;
        switch (rand) {
            case 1: {
                return colorPicker.commissionCardYellow;
            }
            case 2: {
                return colorPicker.commissionCardPink;
            }
            case 3: {
                return colorPicker.commissionCardGray;
            }
            case 4: {
                return colorPicker.commissionCardGreen;
            }
            case 5: {
                return colorPicker.commissionCardBlue;
            }
        }
    }

    //#region Account

    const updateAccount = (acct: AccountData) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            acct.id = account?.id ?? "";
            setIsProfileSaving(true);
            AccountDataSource.Update.basic(acct).then((response: AccountData) => {
                response.displayRealName = acct.displayRealName;
                setAccount(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Account update Error");
            }).finally(() => {
                setIsProfileSaving(false)
            })
        }
    }

    //#endregion


    //#region CommCommTransaction

    const getTransactionHistory = () => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            TransactionDataSource.Get.all(account?.id ?? "").then((result) => {
                setTransactionHistory(result);
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Transaction History Error");
            })
        }
    }

    //#endregion

    //#region Stripe checkout

    const stripeCheckout = (product: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            loadStripe(config.publishableKey)
                .then(stripe => {
                    if (!stripe) {
                        throw new Error('Stripe is not loaded');
                    }
                    return StripeDataSource.Post.checkout(account?.id ?? "", product).then(response => {
                        if (!response) {
                            throw new Error('Stripe Session could not be created');
                        }
                        setRedirectUrl(response)
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                    setErrorMessage("Stripe checkout Error");
                });
        }
    }

    const stripeBilling = () => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            loadStripe(config.publishableKey)
                .then(stripe => {
                    if (!stripe) {
                        throw new Error('Stripe is not loaded');
                    }
                    return StripeDataSource.Post.billing(account?.id ?? "").then(response => {
                        if (!response) {
                            throw new Error('Stripe Session could not be created');
                        }
                        setRedirectUrl(response);
                        //redirect to url
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                    setErrorMessage("Stripe billing Error");
                });
        }
    }

    //#endregion

    //#region Project Breakdown Actions

    const saveProjectBreakdown = (breakdown: ProjectBreakdown) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            setIsBreakdownSaving(true);
            ProjectBreakdownDataSource.Update.breakdown(breakdown).then((response: ProjectBreakdown) => {
                let index = projectBreaksdowns.findIndex(x => x.id === response.id);
                const newSetOfBreakdowns = [...projectBreaksdowns];
                if (index >= 0) {
                    newSetOfBreakdowns[index] = response;
                } else {
                    newSetOfBreakdowns.push(response)
                }
                setProjectBreakdownUpdatedConfirmation(response.updatedAt);
                setProjectBreakdowns(newSetOfBreakdowns);
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Project Breakdown Error");
            }).finally(() => {
                setIsBreakdownSaving(false)
            })
        }
    }

    const deleteProjectBreakdown = (breakdown: ProjectBreakdown) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            let index = projectBreaksdowns.findIndex(x => x.id === breakdown.id);
            if (index >= 0) {
                ProjectBreakdownDataSource.Delete.remove(breakdown.id, breakdown.accountId).then((response) => {
                    if (response) {
                        const newSetOfBreakdowns = [...projectBreaksdowns];
                        newSetOfBreakdowns.splice(index, 1);
                        setProjectBreakdowns(newSetOfBreakdowns);
                        setProjectBreakdownDeleteConfirmation(Date.now().toString())
                    }
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Project Breakdown Error");
                });
            }
        }
    }

    //#endregion

    //#region Project Actions

    const editProject = (id: string, title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (title && title.trim().length > 0 && icon && icon.trim().length > 0
                && iconColor && iconColor.trim().length > 0 && iconBackgroundColor
                && iconBackgroundColor.trim().length > 0 && background && background.trim().length > 0) {
                ProjectDataSource.Update.update(id, account?.id ?? "", title, icon, iconColor, iconBackgroundColor, background).then((result) => {
                    const newSetOfProjects = [...projects]
                    let index = projects.findIndex(proj => proj.id === result.id)
                    newSetOfProjects[index] = {
                        ...newSetOfProjects[index],
                        title: result.title,
                        iconImage: result.iconImage,
                        iconColor: result.iconColor,
                        iconBackgroundColor: result.iconBackgroundColor,
                        backgroundImage: result.backgroundImage
                    };
                    syncProjects(newSetOfProjects[index], index)
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Project Update Error");
                })
            }
        }
    }

    const createProject = (title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (title && title.trim().length > 0 && icon && icon.trim().length > 0
                && iconColor && iconColor.trim().length > 0 && iconBackgroundColor
                && iconBackgroundColor.trim().length > 0 && background && background.trim().length > 0) {
                setIsProjectSaving(true);
                ProjectDataSource.Post.create(account?.id ?? "", title, icon, iconColor, iconBackgroundColor, background).then((result) => {
                    let project = new ProjectDetails(result.projectData, result.columnData, result.cardData, result.tagData);
                    setProjects([
                        ...projects,
                        project
                    ])
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Create Project Error");
                }).finally(() => {
                    setIsProjectSaving(false)
                })
            }
        }
    }

    const archiveProject = (projectId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            const index = projects.findIndex(x => x.id === projectId);
            if (projects[index].quantity > 0) {
                setShowArchiveProjectWarning(true);
            } else {
                setIsProjectSaving(true);
                setArchivingProjectId(projectId);
                ProjectDataSource.Update.archive(projectId, account?.id ?? "").then((result) => {
                    setArchivedProjects([...archivedProjects, result]);
                    const copyOfProjects = [...projects];
                    const index = copyOfProjects.findIndex(x => x.id === result.id)
                    copyOfProjects.splice(index, 1);
                    setProjects(copyOfProjects);
                    //Changes view from being on previous archived project
                    if (copyOfProjects.length > 0) {
                        setSelectedProject(copyOfProjects[0]);
                        setSelectedProjectId(copyOfProjects[0].id);
                    } else {
                        reloadPage();
                    }
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Archive Project Error");
                }).finally(() => {
                    setIsProjectSaving(false);
                    setArchivingProjectId("");
                })
            }
        }
    }

    const unarchiveProject = (projectId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            setUnarchivingProjectId(projectId);
            ProjectDataSource.Update.unarchive(projectId, account?.id ?? "").then((result) => {
                let project = new ProjectDetails(result.projectData, result.columnData, result.cardData, result.tagData);
                const copyOfArchivedProjects = [...archivedProjects];
                const index = copyOfArchivedProjects.findIndex(x => x.id === project.id)
                copyOfArchivedProjects.splice(index, 1);
                setArchivedProjects(copyOfArchivedProjects)
                setProjects([...projects, project]);
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Unarchive Project Error");
            }).finally(() => {
                setUnarchivingProjectId("")
            })
        }
    }

    const cancelArchiveProject = () => {
        setShowArchiveProjectWarning(false);
    }

    //#endregion

    //#region Client Actions

    const editClient = (id: string, firstName: string, lastName: string, email: string, phoneNumber: string, icon: string, iconColor: string, iconBackgroundColor: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (firstName && firstName.trim().length > 1 && lastName && lastName.trim().length > 1 && ((email && email.trim().length > 0)
                || (phoneNumber && phoneNumber.trim().length > 9))) {
                ClientDataSource.Update.update(
                    id,
                    account?.id ?? "",
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    icon, iconColor === "" ? colorPicker.white : iconColor,
                    iconBackgroundColor === "" ? colorPicker.black : iconBackgroundColor).then((result) => {
                    let index = clients.findIndex(cli => cli.id === result.id)
                    const newSetOfClients = copyAllClients();
                    newSetOfClients[index] = {
                        ...newSetOfClients[index],
                        firstName: result.firstName,
                        lastName: result.lastName,
                        email: result.email,
                        phoneNumber: result.phoneNumber,
                        iconImage: result.iconImage,
                        iconColor: result.iconColor,
                        iconBackgroundColor: result.iconBackgroundColor
                    };
                    syncClients(newSetOfClients[index], index)
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Update Client Error");
                })
            }
        }
    }


    const createClient = (firstName: string, lastName: string, email: string, phoneNumber: string, icon: string, icondColor: string, iconBackgroundColor: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (firstName && firstName.trim().length > 1 && lastName && lastName.trim().length > 1 && ((email && email.trim().length > 0)
                || (phoneNumber && phoneNumber.trim().length > 9))) {
                setIsClientSaving(true);
                ClientDataSource.Post.create(account?.id ?? "", firstName, lastName, email, phoneNumber, icon, icondColor, iconBackgroundColor).then((result) => {
                    let client = new ClientDto(result, [], []);
                    setClients([
                        ...clients,
                        client
                    ])
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Create Client Error");
                }).finally(() => {
                    setIsClientSaving(false)
                })
            }
        }
    }

    const updateClientNotes = (clientId: string, notes: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            ClientDataSource.Update.notes(clientId, account?.id ?? "", notes).then((response: Client) => {
                const newSetOfClients = copyAllClients();

                const clientIndex = newSetOfClients.findIndex(x => x.id === response.id);
                newSetOfClients[clientIndex] = {
                    ...newSetOfClients[clientIndex],
                    notes: response.notes
                }
                syncClients(newSetOfClients[clientIndex], clientIndex)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Update Client Error");
            })
        }
    }

    const updateClientPhoneNumber = (clientId: string, phoneNumber: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            ClientDataSource.Update.phoneNumber(clientId, account?.id ?? "", phoneNumber).then((response: Client) => {
                const newSetOfClients = copyAllClients();

                const clientIndex = newSetOfClients.findIndex(x => x.id === response.id);
                newSetOfClients[clientIndex] = {
                    ...newSetOfClients[clientIndex],
                    phoneNumber: response.phoneNumber,
                    receivePhoneNumberNotification: response.receivePhoneNumberNotification
                }
                syncClients(newSetOfClients[clientIndex], clientIndex)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Update Client Error");
            })
        }
    }

    const updateClientEmail = (clientId: string, email: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            ClientDataSource.Update.email(clientId, account?.id ?? "", email).then((response: Client) => {
                const newSetOfClients = copyAllClients();

                const clientIndex = newSetOfClients.findIndex(x => x.id === response.id);
                newSetOfClients[clientIndex] = {
                    ...newSetOfClients[clientIndex],
                    email: response.email,
                    receiveEmailNotification: response.receiveEmailNotification
                }
                syncClients(newSetOfClients[clientIndex], clientIndex)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Update Client Error");
            })
        }
    }

    const changeEmailNotification = (clientId: string, notify: boolean) => {
        ClientDataSource.Update.emailNotificationSelf(clientId, notify).then((response) => {
            setClientViewingProgressBoard(response)
        }).catch(error => {
            setShowError(true);
            setErrorMessage("Update Client Error");
        })
    }

    const changePhoneNotification = (clientId: string, notify: boolean) => {
        ClientDataSource.Update.phoneNumberNotificationSelf(clientId, notify).then((response) => {
            setClientViewingProgressBoard(response)
        }).catch(error => {
            setShowError(true);
            setErrorMessage("Update Client Error");
        })
    }

    const archiveClient = (clientId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            const index = clients.findIndex(x => x.id === clientId);
            if (clients[index].cardDtos.length > 0) {
                setShowArchiveClientWarning(true);
            } else {
                setIsClientSaving(true);
                setArchivingClientId(clientId);
                ClientDataSource.Update.archive(clientId, account?.id ?? "").then((result) => {
                    const copyOfArchivedClients = [...archivedClients];
                    copyOfArchivedClients.push(result);
                    setArchivedClients(copyOfArchivedClients);

                    const copyOfClients = [...clients];
                    const index = copyOfClients.findIndex(x => x.id === result.id)
                    copyOfClients.splice(index, 1);
                    setClients(copyOfClients);
                    //Changes view from being on previous archived client
                    if (copyOfArchivedClients.length > 0) {
                        setSelectedClient(copyOfClients[0])
                        setSelectedClientId(copyOfClients[0].id)
                    } else {
                        reloadPage()
                    }
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Archive Client Error");
                }).finally(() => {
                    setArchivingClientId("");
                    setIsClientSaving(false);
                })
            }
        }
    }

    const unarchiveClient = (clientId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            setUnarchivingClientId(clientId);
            ClientDataSource.Update.unarchive(clientId, account?.id ?? "").then((result) => {
                const copyOfArchivedClients = [...archivedClients];
                const index = copyOfArchivedClients.findIndex(x => x.id === result.id)
                copyOfArchivedClients.splice(index, 1);
                setArchivedClients(copyOfArchivedClients)
                const copyOfClients = [...clients];
                copyOfClients.push(new ClientDto(result, [], []));
                setClients(copyOfClients);
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Unarchive Client Error");
            }).finally(() => {
                setUnarchivingClientId("")
            })
        }
    }

    const cancelArchiveClient = () => {
        setShowArchiveClientWarning(false);
    }

    //#endregion

    //#region Card Actions

    const addCard = (title: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (title.trim() !== "" && cardsUsed < cardMax) {
                const newCard: Card = new Card({
                    accountId: selectedProject?.accountId,
                    projectId: selectedProject?.id,
                    columnId: selectedProject?.columnDetails[0].id,
                    title: title,
                    cardColor: randomCardColor()
                });
                setIsCardSaving(true);
                CardDataSource.Post.create(newCard.accountId, newCard.projectId, newCard.columnId, title, newCard.cardColor).then(result => {
                    let project = projects.find(x => x.id === result.projectId)
                    let projIndex = projects.findIndex(x => x.id === result.projectId)
                    const newProject = {...project} as ProjectDetails

                    newProject.columnDetails[0].cardDtos.push(new CardDto(result, []));
                    syncProjects(newProject, projIndex);
                    incrementProjectCards(newProject.id);
                    setCardsUsed(cardsUsed + 1)
                }).catch(error => {
                    setShowError(true);
                    if (cardsUsed === cardMax) {
                        setErrorMessage("Max Commission Creation Reached");
                    } else {
                        setErrorMessage("Creating Commission Error");
                    }
                }).finally(() => {
                    setIsCardSaving(false)
                })
            }
        }
    }

    const editCardProject = (card: Card, oldProjectId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (!card.isArchived) {
                CardDataSource.Update.project(card.id, account?.id ?? "", card.projectId, card.columnId, account?.id ?? "").then(result => {
                    //if projectID is different remove the card
                    syncModifyCardProjectField(result, oldProjectId, card.columnId);
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Commission Update Error");
                })
            }
        }
    }

    const editCardClient = (card: Card, oldClientId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (!card.isArchived) {
                CardDataSource.Update.client(card.id, account?.id ?? "", card.clientId, account?.id ?? "").then(result => {
                    //if clientID is different remove the card
                    if (oldClientId !== null && "" !== oldClientId) {
                        syncModifyCardClientField(result, oldClientId);
                    } else {
                        syncCardAddingClientField(result)
                    }
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Commission Update Error");
                })
            }
        }
    }

    const deleteCard = (card: Card) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (!card.isArchived) {
                CardDataSource.Delete.remove(card.id).then(result => {
                    if (result) {
                        syncCardRemoval(card)
                        decrementProjectCards(card.projectId)
                        setCardsUsed(cardsUsed > 0 ? cardsUsed - 1 : 0)
                        if (account) {
                            setAccount({
                                ...account,
                                cardDeletesLeft: (account?.cardDeletesLeft ?? 0) - 1,
                                cardsUsed: account.cardsUsed > 0 ? account.cardsUsed - 1 : 0
                            })
                        }
                    }
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Deleting Commission Error");
                })
            }
        }
    }

    const updateCardTitle = (cardId: string, title: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.title(cardId, account?.id ?? "", title, account!.id).then((response: Card) => {
                syncCardUpdates(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const updateCardQuantity = (cardId: string, count: number) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.quantity(cardId, account?.id ?? "", count, account!.id).then((response: Card) => {
                syncCardUpdates(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const updateCardDueDate = (cardId: string, date: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.dueDate(cardId, account?.id ?? "", date, account!.id).then((response: Card) => {
                syncCardUpdates(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const updateCardStartDate = (cardId: string, date: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.startDate(cardId, account?.id ?? "", date, account!.id).then((response: Card) => {
                syncCardUpdates(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const updateCardColor = (cardId: string, color: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.cardColor(cardId, account?.id ?? "", color, account!.id).then((response: Card) => {
                syncCardUpdates(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const updateCardCommissionNotes = (cardId: string, notes: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.notes(cardId, account?.id ?? "", notes, account!.id).then((response: Card) => {
                syncCardUpdates(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const addTagsOnCard = (cardId: string, tagId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.addTag(cardId, account?.id ?? "", account?.id ?? "", tagId).then((response) => {
                //figure out edit or subtract for tags on a card
                syncCardTags(response, tagId, "add")
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    const removeTagsOnCard = (cardId: string, tagId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            CardDataSource.Update.removeTag(cardId, account?.id ?? "", account?.id ?? "", tagId).then((response) => {
                //figure out edit or subtract for tags on a card
                syncCardTags(response, tagId, "delete")
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Update Error");
            })
        }
    }

    function verifyAndSyncClientsCard(result: Card, tagDetails: Tag[]) {
        let client = clients.find(x => x.id === result.clientId);
        if (client) {
            let clientIndex = clients.findIndex(x => x.id === result.clientId);
            const newClient = {...client, updatedAt: Date.now().toString()} as ClientDto
            const clientCardIndex = getCardIndexInClients(client, result.id);
            newClient.cardDtos[clientCardIndex] = new CardDto(result, tagDetails);
            syncClients(newClient, clientIndex);
        }
    }

    const moveCard = (card: CardDto, sourceColId: string, destinationColId: string, endIndex = 0) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            let projIndex = getProjectIndex(card.projectId);
            const newProject = copySingleProject(card.projectId);
            const newClient = copySingleClient(card.clientId);
            const start = newProject.columnDetails.find(x => x.id === sourceColId) as ColumnDto;
            if (newProject.columnDetails[newProject.columnDetails.length - 1].id === sourceColId) {
                //trying to move from final column
                return;
            }

            //moving to done column
            if (newProject.columnDetails[newProject.columnDetails.length - 2].id === sourceColId && newProject.columnDetails[newProject.columnDetails.length - 1].id === destinationColId) {
                setShowDoneColumnCongrats(true);
            }
            const end = newProject.columnDetails.find(x => x.id === destinationColId) as ColumnDto;
            const startColIndex = newProject.columnDetails.findIndex(x => x.id === sourceColId);
            const endColIndex = newProject.columnDetails.findIndex(x => x.id === destinationColId);
            const startCardOrderIndex = newProject.columnDetails[startColIndex].cardOrder.findIndex(x => x === card.id);
            const startCardIndex = newProject.columnDetails[startColIndex].cardDtos.findIndex(x => x.id === card.id);
            const oldCard = newProject.columnDetails[startColIndex].cardDtos[startCardIndex];
            card.columnId = destinationColId;

            //update card columnId
            newProject.columnDetails[startColIndex].cardOrder = reorderRemoveCard(start.cardOrder, startCardOrderIndex) as string[];
            newProject.columnDetails[startColIndex].cardDtos = reorderRemoveCard(start.cardDtos, startCardIndex) as CardDto[];
            newProject.columnDetails[endColIndex].cardOrder = reorderAddCard(end.cardOrder, endIndex, card.id) as string[];
            newProject.columnDetails[endColIndex].cardDtos = reorderAddCard(end.cardDtos, endIndex, new CardDto(card, oldCard.tagDetails)) as CardDto[];

            syncProjects(newProject, projIndex);
            //update clients columnId
            verifyAndSyncClientsCard(card, oldCard.tagDetails);
            CardDataSource.Update.movement(card.id, oldCard.title, account?.id ?? "", account?.username ?? "", card.projectId,
                newProject.title, card.clientId, `${newClient.firstName} ${newClient.lastName}`, end.id, end.title, start.id, start.title, endIndex).then((result) => {
                //update previous and current column card order and card details
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Movement Error");
            })
        }
    }

    const onDrag = (result: DropResult) => {
        if (!account!.isDeactivated && !account!.isSuspended) {

            const {destination, source, type} = result;
            let project = projects.find(x => x.id === selectedProjectId)
            let projIndex = projects.findIndex(x => x.id === selectedProjectId)
            const newProject = {...project} as ProjectDetails

            // dropped outside the list
            if (!destination) {
                return;
            }

            // dropped in the same spot
            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            if (type === 'column') {
                //prevent first and last column from being moved
                if ((source.index > 0 && source.index < project!.columnDetails.length - 1
                    && destination.index > 0 && destination.index < project!.columnDetails.length - 1)) {

                    const columnOrder = reorder(
                        newProject.columnOrder,
                        source.index,
                        destination.index
                    ) as string[];

                    const columnTitles = reorder(
                        newProject.columnTitles,
                        source.index,
                        destination.index
                    ) as string[];

                    const columnDetails = reorder(
                        newProject.columnDetails,
                        source.index,
                        destination.index
                    ) as ColumnDto[];

                    newProject.columnOrder = columnOrder;
                    newProject.columnTitles = columnTitles;
                    newProject.columnDetails = columnDetails;

                    syncProjects(newProject, projIndex)

                    ProjectDataSource.Update.columnOrder(newProject.id, account?.id ?? "", newProject.columnOrder, newProject.columnTitles).then((result) => {
                    }).catch(error => {
                        setShowError(true);
                        setErrorMessage("Column Update Error");
                    })
                    return;
                } else {
                    return;
                }
            }

            if (selectedProject) {
                const start = selectedProject.columnDetails.find(x => x.id === source.droppableId) as ColumnDto;
                const startColIndex = selectedProject.columnDetails.findIndex(x => x.id === source.droppableId);

                //moving from same column
                if (source.droppableId === destination.droppableId && start != null) {

                    const order = reorder(
                        start.cardOrder,
                        source.index,
                        destination.index
                    ) as string[];

                    const cardDetails = start.cardDtos;
                    newProject.columnDetails[startColIndex!].cardOrder = order;
                    newProject.columnDetails[startColIndex!].cardDtos = reorder(
                        cardDetails,
                        source.index,
                        destination.index) as CardDto[];

                    syncProjects(newProject, projIndex);
                    ColumnDataSource.Update.cardOrder(source.droppableId, account?.id ?? "", order).then((result) => {
                    }).catch(error => {
                        setShowError(true);
                        setErrorMessage("Commission Movement Error");
                    })
                    return;
                }
                // if moving out of In Queue column, a pop up warning should come up that will allow warn them if a clientId, due date or start date doesn't exist.
                if (start && (start.cardDtos[source.index].clientId === "" || start.cardDtos[source.index].startDate === "" ||
                    start.cardDtos[source.index].dueDate === "") && source.droppableId === selectedProject.columnOrder[0]) {
                    //copy done card logic here
                    // setInQueueCard(start.cardDtos[source.index]);
                    // setInQueueCardSrcColumn(source.droppableId);
                    // setInQueueCardDestColumn(destination.droppableId);
                    // setInQueueCardDestColumnIndex(destination.index)
                    setShowInQueueColumnWarning(true);

                    // if moving to done column, a pop up warning should come up that will allow them to cancel or continue.
                } else if (destination.droppableId === selectedProject.columnOrder[selectedProject?.columnOrder.length - 1]) {
                    setDoneCard(start.cardDtos[source.index]);
                    setDoneCardSrcColumn(source.droppableId);
                    setDoneCardDestColumn(destination.droppableId);
                    setDoneCardDestColumnIndex(destination.index)
                    setShowDoneColumnWarning(true);
                } else {
                    // moving from one list to another
                    moveCard(start.cardDtos[source.index], source.droppableId, destination.droppableId, destination.index)
                }
            }
            return;
        }
    }

    const archiveCommission = (card: CardDto) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            syncCardRemoval(card);
            CardDataSource.Update.archive(card).then((result) => {
                decrementProjectCards(result.projectId);
                decrementClientCards(result.clientId);
                let project = projects.find(x => x.id === result.projectId);
                let client = clients.find(x => x.id === result.clientId);
                const copyOfArchivedCommissions = [...archivedCommissions];
                copyOfArchivedCommissions.push(new ArchiveCardDetails(result, card.tagDetails, project?.title ?? "Unknown", `${client?.firstName ?? "Unknown"}  ${client?.lastName ?? "Unknown"}`));
                setArchivedCommissions(copyOfArchivedCommissions)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Archive Commission Error");
            })
        }
    }

    //completed date assigned here.
    const moveCardToDoneColumnViaButton = (card: CardDto) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (displayCommission.id === card.id) {
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();
                let lastIndex = (selectedProject?.columnOrder.length ?? 0) - 1;
                card.completedDate = `${yyyy}/${mm}/${dd}`;
                moveCard(card, card.columnId, selectedProject?.columnOrder[lastIndex] ?? "", 0);
                cancelDoneCardMovement()
            }
        }
    }

    const moveCardToDoneColumn = () => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            let newDoneCard = {...doneCard, completedDate: `${yyyy}/${mm}/${dd}`};
            setDoneCard(newDoneCard);
            moveCard(newDoneCard, doneCardSrcColumn, doneCardDestColumn, doneCardDestColumnIndex);
            cancelDoneCardMovement()
        }
    }

    const cancelDoneCardMovement = () => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            setDoneCard({} as CardDto);
            setDoneCardSrcColumn("");
            setDoneCardDestColumn("");
            setDoneCardDestColumnIndex(0)
            setShowDoneColumnWarning(false);
        }
    }

    const cancelInQueueCardMovement = () => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            // setInQueueCard({} as CardDto);
            // setInQueueCardSrcColumn("");
            // setInQueueCardDestColumn("");
            // setInQueueCardDestColumnIndex(0)
            setShowInQueueColumnWarning(false);
        }
    }

    //#endregion

    //#region Column Action

    const editColumn = (columnId: string, title: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (title.trim() !== "") {
                let project = projects.find(x => x.id === selectedProjectId)
                let projIndex = projects.findIndex(x => x.id === selectedProjectId)
                const newProject = {...project} as ProjectDetails
                const colIndex = newProject.columnDetails.findIndex(x => x.id === columnId);
                newProject.columnDetails[colIndex]!.title = title
                newProject.columnTitles[colIndex] = title
                syncProjects(newProject, projIndex)
                ProjectDataSource.Update.title(columnId, account?.id ?? "", title).then((result) => {

                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Column Update Error");
                })
            }
        }
    }

    const addColumn = (text: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            if (text.trim() !== "") {
                let project = projects.find(x => x.id === selectedProjectId)
                let projIndex = projects.findIndex(x => x.id === selectedProjectId)
                const newProject = {...project} as ProjectDetails

                if (newProject.columnOrder.length < 10) {
                    ProjectDataSource.Post.createColumn(project!.accountId, project!.id, text).then((result) => {
                        newProject.columnTitles.splice(newProject.columnTitles.length - 1, 0, result.title)
                        newProject.columnOrder.splice(newProject.columnOrder.length - 1, 0, result.id)
                        newProject.columnDetails.splice(newProject.columnDetails.length - 1, 0, new ColumnDto(result, []))
                        syncProjects(newProject, projIndex)
                    }).catch(error => {
                        setShowError(true);
                        setErrorMessage("Create Column Error");
                    })
                }
            }
        }
    }

    const removeColumn = (columnId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            let project = projects.find(x => x.id === selectedProjectId)
            let projIndex = projects.findIndex(x => x.id === selectedProjectId)
            const newProject = {...project} as ProjectDetails

            const columnIndex = newProject.columnDetails.findIndex(x => x.id === columnId)
            //Verify first and last column not removed
            //Verify at least 3 columns are left
            //Verify no cards are in the column before removal
            if (newProject.columnOrder.length > 3 && columnIndex !== 0 && columnIndex !== newProject.columnOrder.length - 1
                && newProject.columnDetails[columnIndex!].cardOrder.length === 0) {
                newProject.columnDetails.splice(columnIndex, 1);
                newProject.columnOrder.splice(columnIndex, 1);
                newProject.columnTitles.splice(columnIndex, 1);
                syncProjects(newProject, projIndex)
                ProjectDataSource.Delete.removeColumn(newProject.id, columnId).then((result) => {
                }).catch(error => {
                    setShowError(true);
                    setErrorMessage("Column Removal Error");
                })
            }
        }
    }


    //#endregion

    //#region Tag Action

    const createTag = (cardData: Card, tag: string, color: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            TagDataSource.Post.create(account?.id ?? "", cardData.projectId, tag, color)
                .then(result => {
                    const newSetOfTags = copyAllTags();
                    newSetOfTags.push(result);
                    setTags(newSetOfTags);
                }).catch(error => {
                setShowError(true);
                setErrorMessage("Create Tag Error");
            })
        }
    }

    const deleteTag = (tagId: string, projectId: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            TagDataSource.Delete.remove(tagId).then((response) => {
                syncTags(new Tag({id: tagId, projectId: projectId}), "delete")
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Delete Tag Error");
            })
        }
    }

    //edit tags
    const editTag = (cardData: CardDto, id: string, name: string, color: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            TagDataSource.Update.basic(id, account?.id ?? "", name, color).then((response: Tag) => {
                syncTags(response)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Update Tag Error");
            })
        }
    }

    //#endregion

    //#region Card Activity Actions

    const updateCardComments = (card: Card, comment: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            const proj = copySingleProject(card.projectId)
            const client = copySingleClient(card.clientId)
            let data = new CardComment({
                comment: comment,
                cardId: card.id,
                cardTitle: card.title,
                accountId: card.accountId,
                accountUsername: account?.username ?? "",
                projectId: card.projectId,
                projectTitle: proj.title,
                clientId: card.clientId,
                clientName: `${client.firstName} ${client.lastName}`,
                editorId: account?.id ?? "",
                currentColumnId: card.columnId,
                activityType: 10//comments
            })
            CardActivityDataSource.Post.createComment(data).then((response) => {
                const newCard = {
                    ...card,
                    updatedAt: response.updatedAt
                }
                const cardComment = new CardProgressHistoryDetails({
                    profileUrl: account?.image ?? "",
                    editor: `${account?.firstName ?? ""} ${account?.lastName ?? ""}`,
                    detailText: response.comment,
                    createdAt: response.updatedAt
                })
                setCardComments([cardComment, ...cardComments])
                syncCardUpdates(newCard)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Comment Error");
            })
        }
    }

    const updateCardClientComments = (card: Card, comment: string) => {
        if (!account!.isDeactivated && !account!.isSuspended) {
            const proj = copySingleProject(card.projectId)
            const client = copySingleClient(card.clientId)
            let data = new CardComment({
                comment: comment,
                cardId: card.id,
                cardTitle: card.title,
                accountId: card.accountId,
                accountUsername: account?.username ?? "",
                projectId: card.projectId,
                projectTitle: proj.title,
                clientId: card.clientId,
                clientName: `${client.firstName} ${client.lastName}`,
                editorId: account?.id ?? "",
                currentColumnId: card.columnId,
                activityType: 12//client comments
            })
            setIsClientCommentSaving(true);
            CardActivityDataSource.Post.createClientComment(data).then((response) => {
                const newCard = {
                    ...card,
                    updatedAt: response.updatedAt
                }
                const cardComment = new CardProgressHistoryDetails({
                    profileUrl: "",
                    editor: `${client?.firstName ?? ""} ${client?.lastName ?? ""}`,
                    detailText: response.comment,
                    createdAt: response.updatedAt
                })
                setCardComments([cardComment, ...cardComments])
                syncCardUpdates(newCard)
            }).catch(error => {
                setShowError(true);
                setErrorMessage("Commission Comment Error");
            }).finally(() => {
                setIsClientCommentSaving(false);
            })
        }
    }

    const directToArchive = (show: boolean) => {
        setShowArchive(show);
        navigate(navPath.commissionArchive);
    }

    const getCardComments = (cardId: string) => {
        CardActivityDataSource.Get.fromCard(cardId).then((response) => {
            setCardComments(response.reverse())
        }).catch(error => {
            setShowError(true);
            setErrorMessage("Commission Comment Error");
        });
    }

    //#endregion

    //#region Feedback actions

    const submitFeedback = (message: string, feedbackType: number) => {
        FeedbackDataSource.Post.create(account?.id ?? "", message, feedbackType).then((response) => {
            setFeedbackConfirmation(response !== null);
        }).catch(error => {
            setFeedbackConfirmation(false);
            setShowError(true);
            setErrorMessage("Feedback Error");
        }).finally(() => {
            setFeedbackConfirmation(false)
        });
    }

    //#endregion

    //#region Contactus actions

    const submitContactUs = (name: string, email: string, message: string, subjectType: number) => {
        ContactUsDataSource.Post.create(name, email, message, subjectType).then((response) => {
            setContactUsConfirmation(response !== null);
        }).catch(error => {
            setContactUsConfirmation(false);
            setShowError(true);
            setErrorMessage("Contact Us Error");
        }).finally(() => {
            setContactUsConfirmation(false)
        });
    }

    //#endregion

    //#region Unsubscribe actions

    // const UpdateUnsubscribeNotification = (email: string, receiveNewsLetter: boolean) => {
    //     UnsubscribeDataSource.Update.notification(email, receiveNewsLetter).then((response) => {
    //     }).catch(error => {
    //         setShowError(true);
    //         setErrorMessage("Unsubscribe Error");
    //     });
    // }
    //
    // const GetUnsubscribe = (email: string) => {
    //     UnsubscribeDataSource.Get.email(email).then((response) => {
    //     }).catch(error => {
    //         setShowError(true);
    //         setErrorMessage("Unsubscribe Error");
    //     });
    // }

    //#endregion

    return (
        <>
            <Routes>
                <Route path='/acct/:accountId'
                       element={clientViewingProgressBoard &&
                           <NavigationTopBarLayout setUnprotectedAccountId={setUnprotectedAccountId}
                                                   selectedClient={clientViewingProgressBoard}
                                                   account={unprotectedAccount}
                                                   changeEmailNotification={changeEmailNotification}
                                                   changePhoneNotification={changePhoneNotification}/>}>
                    <Route path='client/:clientId'
                           element={<CommissionProgressTrackingBoard progressCards={progressCards}
                                                                     isClientCommentSaving={isClientCommentSaving}
                                                                     client={copySingleClient(unprotectedClientId)}
                                                                     progressCardsDetails={progressCardsDetails}
                                                                     setUnprotectedClient={setUnprotectedClientId}
                                                                     isLoadingProgressTrackingBoard={isLoadingProgressTrackingBoard}
                                                                     updateCardClientComments={updateCardClientComments}
                                                                     setUnprotectedAccount={setUnprotectedAccountId}/>}/>
                    <Route path='profile'
                           element={<UnprotectedProfileView account={unprotectedAccount}
                                                            isUnprotectedProfileLoading={isUnprotectedProfileLoading}
                                                            setUnprotectedAccountId={setUnprotectedAccountId}/>}/>
                    <Route path='client/:clientId/breakdown'
                           element={<UnprotectedPriceBreakdown projectBreakdownsDto={projectBreaksdownsDto}
                                                               setUnprotectedClientId={setUnprotectedClientId}
                                                               setUnprotectedAccountId={setUnprotectedAccountId}
                                                               isUnprotectedBreakdownLoading={isUnprotectedBreakdownLoading}/>}/>
                </Route>
                <Route element={account && <NavigationSideBarLayout
                    account={account}
                    projects={projects}
                    clients={clients}
                    createClient={createClient}
                    createProject={createProject}
                    editProject={editProject}
                    editClient={editClient}
                    selectedProject={selectedProjectId}
                    selectedClient={selectedClientId}
                    showProfile={showProfile}
                    setShowProfile={setShowProfile}
                    setShowArchive={directToArchive}
                    showArchive={showArchive}
                    selectProject={selectProject}
                    selectClient={selectClient}
                    archiveClient={archiveClient}
                    archiveProject={archiveProject}
                    logout={logUserOut}
                    isSideNavVisible={isSideNavVisible}
                    setIsSideNavVisible={setIsSideNavVisible}
                    loadSavedClient={isClientSaving}
                    loadSavedProjects={isProjectSaving}
                    archivingProjectId={archivingProjectId}
                    archivingClientId={archivingClientId}
                />}>
                    <Route path={navPath.projects} element={
                        selectedProject && account ? <ProjectWorkflowBoard
                            account={account}
                            selectedProject={selectedProject}
                            incrementProjectCards={incrementProjectCards}
                            decrementProjectCards={decrementProjectCards}
                            isSideNavVisible={isSideNavVisible}
                            tags={tags}
                            addCard={addCard}
                            editColumn={editColumn}
                            addColumn={addColumn}
                            removeColumn={removeColumn}
                            addTag={createTag}
                            updateTag={editTag}
                            addTagOnCard={addTagsOnCard}
                            removeTagOnCard={removeTagsOnCard}
                            deleteTag={deleteTag}
                            updateCardTitle={updateCardTitle}
                            updateCardQuantity={updateCardQuantity}
                            updateCardDueDate={updateCardDueDate}
                            updateCardStartDate={updateCardStartDate}
                            updateCardColor={updateCardColor}
                            updateCardNotes={updateCardCommissionNotes}
                            deleteCard={deleteCard}
                            projects={projects}
                            clients={clients}
                            cardsUsed={cardsUsed}
                            cardMax={cardMax}
                            onDrag={onDrag}
                            updateCardComments={updateCardComments}
                            saveClient={editCardClient}
                            saveProject={editCardProject}
                            moveCardViaButton={moveCard}
                            archiveCommission={archiveCommission}
                            showDoneColumnWarning={showDoneColumnWarning}
                            setShowDoneColumnWarning={setShowDoneColumnWarning}
                            showDoneColumnCongrats={showDoneColumnCongrats}
                            setShowDoneColumnCongrats={setShowDoneColumnCongrats}
                            confirmDoneCardMovement={moveCardToDoneColumn}
                            cancelDoneCardMovement={cancelDoneCardMovement}
                            showInQueueColumnWarning={showInQueueColumnWarning}
                            setShowInQueueColumnWarning={setShowInQueueColumnWarning}
                            cancelInQueueCardMovement={cancelInQueueCardMovement}
                            showArchiveProjectWarning={showArchiveProjectWarning}
                            setShowArchiveProjectWarning={setShowArchiveProjectWarning}
                            cancelArchiveProjectWarning={cancelArchiveProject}
                            getCardComments={getCardComments}
                            cardComments={cardComments}
                            displayCommission={displayCommission}
                            selectCard={selectCard}
                            isProjectLoading={isProjectLoading}
                            submitFeedback={submitFeedback}
                            feedbackConfirmation={feedbackConfirmation}
                            moveCardToDoneColumnViaButton={moveCardToDoneColumnViaButton}
                            setFeedbackConfirmation={setFeedbackConfirmation}
                            loadSavedCard={isCardSaving}
                        /> : <DefaultNoContent loading={isProjectLoading}/>}/>
                    <Route path={navPath.clients} element={
                        selectedClient && account ? <ClientWorkflowBoard
                            account={account}
                            clients={clients}
                            cardsUsed={cardsUsed}
                            cardMax={cardMax}
                            selectedClient={selectedClient}
                            projects={projects}
                            tags={tags}
                            isSideNavVisible={isSideNavVisible}
                            addTag={createTag}
                            updateTag={editTag}
                            addTagOnCard={addTagsOnCard}
                            removeTagOnCard={removeTagsOnCard}
                            deleteTag={deleteTag}
                            updateCardTitle={updateCardTitle}
                            updateCardQuantity={updateCardQuantity}
                            updateCardDueDate={updateCardDueDate}
                            updateCardStartDate={updateCardStartDate}
                            updateCardColor={updateCardColor}
                            deleteCard={deleteCard}
                            updateCardNotes={updateCardCommissionNotes}
                            updateClientNotes={updateClientNotes}
                            updateClientEmail={updateClientEmail}
                            updateClientPhoneNumber={updateClientPhoneNumber}
                            updateCardComments={updateCardComments}
                            saveClient={editCardClient}
                            saveProject={editCardProject}
                            moveCardViaButton={moveCard}
                            archiveCommission={archiveCommission}
                            showArchiveClientWarning={showArchiveClientWarning}
                            setShowArchiveClientWarning={setShowArchiveClientWarning}
                            cancelArchiveClientWarning={cancelArchiveClient}
                            getCardComments={getCardComments}
                            cardComments={cardComments}
                            displayCommission={displayCommission}
                            selectCard={selectCard}
                            isClientLoading={isClientLoading}
                            submitFeedback={submitFeedback}
                            feedbackConfirmation={feedbackConfirmation}
                            showDoneColumnCongrats={showDoneColumnCongrats}
                            setShowDoneColumnCongrats={setShowDoneColumnCongrats}
                            moveCardToDoneColumnViaButton={moveCardToDoneColumnViaButton}
                            getClientsArchiveCards={retrieveClientsArchiveCards}
                            archivedCommission={displayArchiveCommission}
                            setFeedbackConfirmation={setFeedbackConfirmation}
                        /> : <DefaultNoContent loading={isClientLoading}/>}/>
                    <Route path={navPath.editProfile} element={<EditProfile
                        firstName={account?.firstName ?? ""}
                        lastName={account?.lastName ?? ""}
                        biography={account?.biography ?? ""}
                        image={account?.image ?? ""}
                        email={account?.email ?? ""}
                        phoneNumber={account?.phoneNumber ?? ""}
                        shopLink={account?.shopLink ?? ""}
                        socialMedia1={account?.socialMedia1 ?? ""}
                        socialMedia2={account?.socialMedia2 ?? ""}
                        socialMedia3={account?.socialMedia3 ?? ""}
                        venmo={account?.venmoHandle ?? ""}
                        username={account?.username ?? ""}
                        saveAccount={updateAccount}
                        accountIsInactive={account?.isDeactivated ?? false}
                        accountUpdateConfirmation={account?.updatedAt ?? ""}
                        isAccountLoading={isAccountLoading}
                        displayRealName={account?.displayRealName ?? false}
                        isSideNavVisible={isSideNavVisible}
                        submitDeactivationFeedback={submitDeactivationFeedback}
                        submitReactivation={updateAccountReactivation}
                        isProfileSaving={isProfileSaving}
                    />}/>
                    <Route path={navPath.profile} element={<ProfileView
                        firstName={account?.firstName ?? ""}
                        lastName={account?.lastName ?? ""}
                        biography={account?.biography ?? ""}
                        image={account?.image ?? ""}
                        email={account?.email ?? ""}
                        phoneNumber={account?.phoneNumber ?? ""}
                        shopLink={account?.shopLink ?? ""}
                        socialMedia1={account?.socialMedia1 ?? ""}
                        socialMedia2={account?.socialMedia2 ?? ""}
                        socialMedia3={account?.socialMedia3 ?? ""}
                        venmo={account?.venmoHandle ?? ""}
                        username={account?.username ?? ""}
                        isAccountLoading={isAccountLoading}
                        displayRealName={account?.displayRealName ?? false}
                        isSideNavVisible={isSideNavVisible}
                    />}/>
                    <Route path={navPath.manageSubscription} element={account ? <ManageSubscription
                        redirectUrl={redirectUrl}
                        account={account}
                        isSideNavVisible={isSideNavVisible}
                        checkoutSubscription={stripeCheckout}
                        billingSubscription={stripeBilling}
                        transactionHistory={transactionHistory}
                        getTransactionHistory={getTransactionHistory}
                    /> : <DefaultNoContent loading={isArchiveCommissionLoading}/>}/>
                    <Route path={navPath.pricebreakdown} element={<PriceBreakdown
                        projectBreakdowns={projectBreaksdowns}
                        saveBreakdown={saveProjectBreakdown}
                        deleteBreakdown={deleteProjectBreakdown}
                        accountSubscription={account?.acctSubscriptionPlan ?? 0}
                        projectBreakdownDeleteConfirmation={projectBreakdownDeleteConfirmation}
                        projectBreakdownUpdatedConfirmation={projectBreakdownUpdatedConfirmation}
                        projects={projects}
                        isSideNavVisible={isSideNavVisible}
                        isBreakdownSaving={isBreakdownSaving}
                    />}/>
                    <Route path={navPath.commissionArchive} element={account ? <ArchiveCommissionsBoard
                        archiveCards={archivedCommissions}
                        account={account}
                        projects={projects.map((proj) => {
                            return proj.project
                        }).concat(archivedProjects)}
                        clients={clients.map((clnt) => {
                            return clnt.client
                        }).concat(archivedClients)}
                        isSideNavVisible={isSideNavVisible}
                        getCardComments={getCardComments}
                        cardComments={cardComments}
                        isArchiveCommissionLoading={isArchiveCommissionLoading}
                        setShowArchive={setShowArchive}
                        submitFeedback={submitFeedback}
                        feedbackConfirmation={feedbackConfirmation}
                        setFeedbackConfirmation={setFeedbackConfirmation}
                    /> : <DefaultNoContent loading={isArchiveCommissionLoading}/>}/>
                    <Route path={navPath.projectsArchive} element={account ? <ArchiveProjectsBoard
                        unarchive={unarchiveProject}
                        projects={projects}
                        archivedProjects={archivedProjects}
                        isArchiveProjectLoading={isArchiveProjectLoading}
                        isSideNavVisible={isSideNavVisible}
                        setShowArchive={setShowArchive}
                        submitFeedback={submitFeedback}
                        feedbackConfirmation={feedbackConfirmation}
                        setFeedbackConfirmation={setFeedbackConfirmation}
                        unarchivingProjectId={unarchivingProjectId}
                        account={account}/> : <DefaultNoContent loading={isArchiveProjectLoading}/>}/>
                    <Route path={navPath.clientsArchive} element={account ? <ArchiveClientsBoard
                        unarchive={unarchiveClient}
                        clients={archivedClients}
                        isArchiveClientLoading={isArchiveClientLoading}
                        isSideNavVisible={isSideNavVisible}
                        setShowArchive={setShowArchive}
                        submitFeedback={submitFeedback}
                        feedbackConfirmation={feedbackConfirmation}
                        unarchivingClientId={unarchivingClientId}
                        setFeedbackConfirmation={setFeedbackConfirmation}
                        account={account}/> : <DefaultNoContent loading={isClientLoading}/>}/>
                </Route>
                <Route path={navPath.login} element={<LoginSignUp
                    setLoggedIn={setLoggedIn}
                    loginTab={loginTab}
                />}/>
                <Route path={navPath.home} element={<Home setLoginTab={setLoginTab}/>}/>
                <Route path="/" element={<Home setLoginTab={setLoginTab}/>}/>
                <Route path={navPath.pricingTier} element={<PricingTier/>}/>
                <Route path={navPath.team} element={<Team/>}/>
                <Route path={navPath.userGuide} element={<UserGuide/>}/>
                <Route path={navPath.userGuideProjectBoards} element={<UserGuideProjectBoards/>}/>
                <Route path={navPath.userGuideClientBoards} element={<UserGuideClientBoards/>}/>
                <Route path={navPath.userGuideProgressBoards} element={<UserGuideProgressBoards/>}/>
                <Route path={navPath.userGuideCommissionCards} element={<UserGuideCommissionCards/>}/>
                <Route path={navPath.userGuideArchiveBoards} element={<UserGuideArchiveBoards/>}/>
                <Route path={navPath.userGuideProfileSettings} element={<UserGuideProfileSettings/>}/>
                <Route path={navPath.userGuidePriceBreakdowns} element={<UserGuidePriceBreakdowns/>}/>
                <Route path={navPath.userGuideManageSubscription} element={<UserGuideManageSubscription/>}/>
                <Route path={navPath.faq} element={<Faq/>}/>
                <Route path={navPath.contactus} element={<ContactUs
                    submitContactUs={submitContactUs}
                    contactUsConfirmation={contactUsConfirmation}
                />}/>
                <Route path={navPath.privacypolicy} element={<PrivacyPolicy/>}/>
                <Route path={navPath.termsandconditions} element={<TermsAndConditions/>}/>
                <Route path={navPath.commcommassets} element={<CommCommAssets/>}/>
                <Route path={navPath.unsubscribe} element={<Unsubscribe/>}/>
                <Route path={navPath.checkoutSuccess} element={<CheckoutSuccess/>}/>
                <Route path={navPath.checkoutError} element={<CheckoutError/>}/>
                {/*<Route path='/archive/clients' element={ <ArchiveClients/>}/>*/}
                {/*<Route path='/customers' element={<Protected> <Customers/> </Protected>}/>*/}
                {/*<Route path='/customer/:id' element={<Protected> <Customer/> </Protected>}/>*/}
                {/*<Route path='/404' element={<Protected> <NotFound/> </Protected>}/>*/}
                <Route path='*' element={<NotFound/>}/>
            </Routes>
            <ModalErrorReloadMessage
                isOpen={showError}
                toggle={setShowError}
                modalTitle={errorMessage}
                // children={}
                reload={reloadPage}>
                <p>An error has occurred. If this problem persists try refreshing the page.</p>
            </ModalErrorReloadMessage>
            {/*</NavBar>*/}
        </>
    );
}

export default App;
