import {AggregateBase} from "./AggregateBase";

export class DeactivationFeedback extends AggregateBase {
    accountId: string = "";
    comment: string = "";
    reasonType: number = 0;

    public constructor(init?: Partial<DeactivationFeedback>) {
        super();
        Object.assign(this, init);
    }
}