import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody} from "react-bootstrap";
import {Tag} from "../../../data/Tag";
import CloseIcon from '@mui/icons-material/Close';
import RichTextCommissionNotes from "../RichTextCommissionNotes";
import {AccountData} from "../../../data/AccountData";
import {CardProgressHistoryDetails} from "../../../data/CardProgressHistoryDetails";
import {colorPicker, commissionLink, formatDateTime} from "../../Helpers";
import parse from 'html-react-parser';
import {ArchiveCardDetails} from "../../../data/ArchiveCardDetails";
import Zorua from '../../../assets/zorua-profile-pic.jpeg';
import CommCommLogo from '../../../assets/CommCommLogo-Black.png';

import ComponentLoading from "./ComponentLoading";

interface IViewArchivedCardProps {
    account: AccountData
    card: ArchiveCardDetails;
    isOpen: boolean;
    toggleShowCardModal: () => void;
    getCardComments: (cardId: string) => void;
    cardComments: CardProgressHistoryDetails[];
}

const ViewArchivedCard: React.FunctionComponent<IViewArchivedCardProps> = (props) => {
    const [title, setTitle] = useState(props.card.title);
    const [quantity, setQuantity] = useState<number>(0);
    const [notes, setNotes] = useState(props.card.notes);
    const [notesLength, setNotesLength] = useState(0)
    const [dueDate, setDueDate] = useState(props.card.dueDate);
    const [startDate, setStartDate] = useState(props.card.startDate);
    const [clientTag, setClientTag] = useState<Tag>(new Tag());
    const [projectTag, setProjectTag] = useState<Tag>(new Tag());
    const [backgroundColor, setBackgroundColor] = useState("");
    const [isShareCommissionShown, setIsShareCommissionShown] = useState(false);
    const [cardHistoryRecords, setCardHistoryRecords] = useState<CardProgressHistoryDetails[]>([]);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [shareCommissionLink, setShareCommissionLink] = useState("");
    const [useDefaultImage, setUseDefaultImage] = useState(true);
    const [loadingComments, setLoadingComments] = useState<boolean>(false);

    useEffect(() => {
        if (props.isOpen) {
            setTitle(props.card.title);
            setQuantity(props.card.quantity);
            setNotes(props.card.notes);
            setDueDate(props.card.dueDate);
            setStartDate(props.card.startDate);
            setClientTag(new Tag({
                id: props.card.clientId,
                name: props.card.clientName,
                color: colorPicker.labelBlue
            }))
            setProjectTag(new Tag({
                id: props.card.projectId,
                name: props.card.projectTitle,
                color: colorPicker.labelBlue
            }))
            setBackgroundColor(props.card?.cardColor ?? "")
            setShareCommissionLink(commissionLink(window.location.origin, props.card.accountId, props.card.clientId))
            setCardHistoryRecords([]);
            setShowDetails(false);
            setLoadingComments(false);
        }
    }, [props.isOpen])

    useEffect(() => {
        if (showDetails && props.card.id !== undefined) {
            props.getCardComments(props.card.id)
            setLoadingComments(true);
        }
    }, [showDetails])

    useEffect(() => {
        if (props.cardComments) {
            setCardHistoryRecords(props.cardComments);
            setLoadingComments(false);
        }
    }, [props.cardComments])

    useEffect(() => {
        setUseDefaultImage(props.account.image === null || props.account.image === "")
    }, [props.account.image])

    if (!props.isOpen) {
        return null;
    }

    const show = () => {
        setShowDetails(!showDetails)
    }

    const handleShareCommissionClick = () => {
        // 👇️ toggle shown state
        setIsShareCommissionShown(current => !current);
    };

    const closePopup = () => {
        setIsShareCommissionShown(false);
        props.toggleShowCardModal();
    }

    const handleImageError = () => {
        // Handle the image loading error here, e.g., set a state variable to indicate the error.
        setUseDefaultImage(false);
    };

    return (
        <div key={props.card.id}>
            {props.isOpen && <Modal size="lg"
                                    show={props.isOpen}
                                    onHide={closePopup}>
                <ModalBody className="edit-card-popup-background">
                    <form id='editCommission' className="editCardForm">
                        <div className="editCardStandardRow" style={{marginBottom: '0px'}}>
                            <div className="commissionNameColumn">
                                <label className="editFormLabel"
                                       htmlFor='commission-name'>
                                    Commission Name
                                </label>

                                <input className="commissionNameInput"
                                       id='commission-name'
                                       type='text'
                                       value={title}
                                       disabled={true}
                                />
                            </div>

                            <div className="commissionQuantityColumn">
                                <label className="editFormLabel"
                                       htmlFor='commission-quantity'>
                                    Quantity
                                </label>

                                <input className="quantityInput"
                                       id='commission-quantity'
                                       type='number'
                                       value={quantity}
                                       disabled={true}
                                />
                            </div>

                            <div className="commissionCloseBtn">
                                <div style={{padding: "0px 0px 5px 5px"}} onClick={closePopup}><CloseIcon/></div>
                            </div>
                        </div>

                        <div className="editCardStandardRow" style={{columnGap: '25px'}}>
                            <div className="editCardMainBodyColumn">
                                <div className="editCardStandardRow">
                                    <div className="tagColumn">
                                        <label className="editFormLabel"
                                               htmlFor='commission-client'>
                                            Client
                                        </label>

                                        <p className="card-label-tag" style={{
                                            backgroundColor: clientTag.color,
                                            color: colorPicker.white,
                                            marginBottom: '5px'
                                        }}>
                                            {clientTag.name}
                                        </p>
                                    </div>

                                    <div className="tagColumn">
                                        <label className="editFormLabel"
                                               htmlFor='commission-project'>
                                            Project
                                        </label>

                                        <p className="card-label-tag" style={{
                                            backgroundColor: projectTag.color,
                                            color: colorPicker.white,
                                            marginBottom: '5px'
                                        }}>
                                            {projectTag.name}
                                        </p>
                                    </div>

                                    <div className="tagColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Labels'>
                                            Labels
                                        </label>
                                        <div className="moreLabelsRow">
                                            {props.card.tagDetails && props.card.tagDetails.map((item, index) => (
                                                <p key={item.id} className="card-label-tag" style={{
                                                    backgroundColor: item.color,
                                                    color: colorPicker.white,
                                                    marginBottom: '5px'
                                                }}>
                                                    {item.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="editCardStandardRow">
                                    <div className="editCardStandardColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Start Date'>
                                            Start Date
                                        </label>

                                        <input className="dateInput"
                                               id='startDate'
                                               type='date'
                                               defaultValue={startDate}
                                               min='2023-01-01'
                                               disabled={true}
                                        />
                                    </div>
                                    <div className="editCardStandardColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Due Date'>
                                            Due Date
                                        </label>

                                        <input className="dateInput"
                                               id='dueDate'
                                               type='date'
                                               defaultValue={dueDate}
                                               min={new Date().toISOString().slice(0, 10)}
                                               disabled={true}
                                        />
                                    </div>

                                    <div className="editCardStandardColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Card Color'>
                                            Commission Color
                                        </label>

                                        <div className="flex-bg-color-row">
                                            <div>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#FFF69B',
                                                    border: backgroundColor === '#FFF69B' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#F6C2D9',
                                                    border: backgroundColor === '#F6C2D9' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#E4DAE2',
                                                    border: backgroundColor === '#E4DAE2' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#BCDFC9',
                                                    border: backgroundColor === '#BCDFC9' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#ABCEEB',
                                                    border: backgroundColor === '#ABCEEB' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="notesColumn">
                                    <label className="editFormLabel"
                                           htmlFor='Description'>
                                        Commission Notes
                                    </label>
                                    <p className="featureNote">Notes are not visible to the client.</p>
                                    <div className="noteInput">
                                        <RichTextCommissionNotes notes={notes} setNotes={setNotes}
                                                                 notesLength={notesLength}
                                                                 acctSubscription={props.account.acctSubscriptionPlan}
                                                                 active={false}
                                                                 setNotesLength={setNotesLength}/>
                                    </div>

                                    <button className="disabledBtn" type="button"
                                            style={{width: '120px', margin: '10px 0px'}}
                                            disabled={true}>
                                        Save Notes
                                    </button>
                                </div>

                                <div className="commentInfoRow">
                                    <div className="editCardStandardColumn"
                                         style={{flex: '3 1 1', marginRight: '13px'}}>
                                        <label className="editFormLabel"
                                               htmlFor='Comments'>
                                            Commission Comments
                                        </label>
                                        <p className="featureNote">Comments and picture attachments are viewable by the
                                            client.</p>
                                    </div>

                                    <button className="blueBtn" style={{flex: '1'}} type="button"
                                            onClick={() => show()}>
                                        {showDetails ? "Hide Details" : "Show Details"}
                                    </button>
                                    {/* Make the show details change to hide details when everything is revealed. */}
                                </div>

                                <div className="editCardStandardRow">
                                    {useDefaultImage ? (
                                        // Render the image with the onError event handler.
                                        <img
                                            className="comment-profile-image"
                                            src={Zorua}
                                            alt="Default profile image of our cat Zorua."
                                            onError={handleImageError}
                                        />
                                    ) : (
                                        // Display user chosen image when there is no error.
                                        <img
                                            className="comment-profile-image"
                                            src={props.account.image}
                                            alt="Account profile image."
                                        />
                                    )}
                                    <textarea className="newArtistCommentInput"
                                              id='newComment'
                                              maxLength={500}
                                              rows={1}
                                              cols={50}
                                              disabled={true}
                                    />
                                </div>
                                <button className="disabledBtn" style={{width: '150px'}} type="button"
                                        disabled={true}>
                                    Save Comment
                                </button>
                                <ComponentLoading isLoadingComponent={loadingComments}/>
                                {cardHistoryRecords && <div style={{
                                    display: showDetails ? "block" : "none",
                                    maxHeight: "300px",
                                    overflow: "auto"
                                }}>
                                    {cardHistoryRecords.map((details, index) => {
                                        return (
                                            <div key={index} className="history-details-container">
                                                <img
                                                    className="history-profile-image"
                                                    src={details.profileUrl === null || details.profileUrl === "" ? CommCommLogo : details.profileUrl}
                                                    alt="Account profile image."
                                                />
                                                <div className="history-details">
                                                    <div>
                                                        <span
                                                            className='history-author'>{details.editor}</span> {parse(details.detailText)}
                                                    </div>
                                                    <div className='history-date'>
                                                        {formatDateTime(details.createdAt)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>}
                            </div>

                            {/* 👇️ show elements on click */}
                            {isShareCommissionShown && (
                                <div className="sideActionBtnPopup">
                                    <div className="sideBtnPopupHeaderNoSearch">
                                        <h4>Share Link</h4>
                                        <div style={{padding: "0px 0px 5px 5px"}} onClick={handleShareCommissionClick}>
                                            <CloseIcon/></div>
                                    </div>
                                    <label className="shareLinkLabel" htmlFor='Comments'>
                                        Link to this commission:
                                    </label>
                                    {/*change input action*/}
                                    <input type="client" className="form-control" style={{marginBottom: '10px'}}
                                           id="exampleInputPassword1"
                                           value={shareCommissionLink} onChange={(e) => {
                                        setTitle(e.target.value)
                                    }} placeholder="CommComm Link..."/>
                                </div>
                            )}

                            <div className="sideActionColumn">
                                <div className="sideActionColumnInnerRow">
                                    <div className="editCardStandardColumn">
                                        <label className="editSideActionLabel"
                                               htmlFor='Description'>
                                            Actions
                                        </label>
                                        <Button className="black-outline-btn" style={{width: '180px'}} type="button"
                                                onClick={handleShareCommissionClick}>
                                            Share Commission
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>}
        </div>
    );
}

export default ViewArchivedCard
