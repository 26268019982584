import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {TbChevronLeft} from "react-icons/tb";
import {AiFillEdit} from "react-icons/ai";

interface ISideManageLabelPopupOptions {
    tagId: string;
    editText: string;
    editColor: string;
    previousPopUp: () => void;
    closePopup: () => void;
    saveLabel: (label: string, color: string) => void;
    deleteLabel: (tagId: string) => void;
    updateLabel: (tagId: string, name: string, color: string) => void;
}

const SideManageLabelPopupOptions: React.FunctionComponent<ISideManageLabelPopupOptions> = (props) => {
    const [newLabel, setNewLabel] = useState(props.editText);
    // const [labelColor, setLabelColor] = useState(props.editColor ?? "#DB2828");
    const [labelBackground, setLabelBackground] = useState("");
    const [validLabelName, setValidLabelName] = useState(true);

    useEffect(() => {
        setLabelBackground(props.editColor ?? "#DB2828");
        setValidLabelName(true);
    }, [])

    const save = () => {
        let label = true;
        if (!(validLabelName && newLabel.length > 0)) {
            label = false;
            setValidLabelName(false);
        }
        if (label) {
            props.saveLabel(newLabel, labelBackground);
            props.previousPopUp();
        }
    }

    const update = () => {
        let label = true;
        if (!(validLabelName && newLabel.length > 0)) {
            label = false;
            setValidLabelName(false);
        }
        if (label) {
            props.updateLabel(props.tagId, newLabel, labelBackground);
            props.previousPopUp();
        }
    }

    const remove = () => {
        props.deleteLabel(props.tagId);
        props.previousPopUp();
    }

    const handleLabel = (input: string) => {
        setNewLabel(input);
        if (new RegExp(/^.{1,51}$/).test(input)) {
            setValidLabelName(true);
        } else {
            setValidLabelName(false);
        }
    };


    return (
        <div className="sideActionBtnPopup">
            <div className="sideBtnPopupHeader">
                <div style={{paddingBottom: "5px", cursor: "pointer"}} onClick={props.previousPopUp}><TbChevronLeft
                    size={28}/></div>
                <h4>{props.editText === "" ? "Create Label" : "Edit Label"}</h4>
                <div className="createLabelCloseBtn" style={{padding: "0px 0px 5px 5px"}} onClick={props.closePopup}>
                    <CloseIcon style={{cursor: "pointer"}}/></div>
            </div>
            <div className="labelPreviewWrapper">
                <div className="labelPreviewColor" style={{backgroundColor: labelBackground}}>
                    {/* When editing make background previously assigned color.
                    When creating new label, make the first color the default selected choice so background isn't white when loaded. */}
                    <label>{newLabel}</label>
                    <AiFillEdit className="exampleEditIcon"/>
                </div>
            </div>
            <div className="labelTitleInputColumn">
                <label style={{color: validLabelName ? "black" : "red"}}>Title</label>
                <input className="form-control" id="createLabel" value={newLabel}
                       onChange={(e) => handleLabel(e.target.value)} placeholder="New Label Name..."/>
            </div>
            <div className="labelColorInputColumn">
                <label>Select Label Color</label>
                <div className="label-bg-color-row">
                    <div onClick={() => setLabelBackground('#DB2828')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#DB2828',
                            border: labelBackground === '#DB2828' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#CC0099')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#CC0099',
                            border: labelBackground === '#CC0099' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#9900CC')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#9900CC',
                            border: labelBackground === '#9900CC' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#3333CC')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#3333CC',
                            border: labelBackground === '#3333CC' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#0066CC')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#0066CC',
                            border: labelBackground === '#0066CC' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                </div>
                <div className="label-bg-color-row">
                    <div onClick={() => setLabelBackground('#17AADD')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#17AADD',
                            border: labelBackground === '#17AADD' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#00CCCC')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#00CCCC',
                            border: labelBackground === '#00CCCC' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#00CC99')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#00CC99',
                            border: labelBackground === '#00CC99' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#00CC00')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#00CC00',
                            border: labelBackground === '#00CC00' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#99CC00')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#99CC00',
                            border: labelBackground === '#99CC00' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                </div>
                <div className="label-bg-color-row">
                    <div onClick={() => setLabelBackground('#F4B62C')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#F4B62C',
                            border: labelBackground === '#F4B62C' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#EE8C13')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#EE8C13',
                            border: labelBackground === '#EE8C13' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#ED4F4F')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#ED4F4F',
                            border: labelBackground === '#ED4F4F' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#773f2b')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#773f2b',
                            border: labelBackground === '#773f2b' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                    <div onClick={() => setLabelBackground('#6D6D6D')}>
                        <div className="label-color-choice" style={{
                            backgroundColor: '#6D6D6D',
                            border: labelBackground === '#6D6D6D' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                        }}/>
                    </div>
                </div>
            </div>
            {/* <input type="color" id="backgroundColorPicker" className="pickerBox" value={labelColor} aria-describedby="backgroundColorPickerHelp" onChange={(e) => {setLabelColor(e.target.value)}}/> */}
            <button className="blueBtn" type="button" style={{width: '100%', margin: '10px 0px'}} onClick={() => {
                props.editText === "" ? save() : update()
            }}>
                {props.editText === "" ? "Create Label" : "Save Label"}
            </button>
            {props.editText !== "" &&
                <button className="redBtn" type="button" style={{width: '100%', margin: '10px 0px'}} onClick={() => {
                    remove()
                }}>
                    Delete Label
                </button>}
        </div>
    );
}

export default SideManageLabelPopupOptions
