import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from "react-icons/io";

//https://codepen.io/vkive/pen/VwMOxmX
interface IconWithTooltipProps {
  text: String;
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };

  const tooltip = (
    <Tooltip>
      {props.text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      show={showTooltip}
      onToggle={handleTooltipToggle}
      overlay={tooltip}
    >
      <div>
        <IoMdInformationCircleOutline
          className="feature-info-icon"
          size={23}
        />
      </div>
    </OverlayTrigger>
  );
  };

export default IconWithTooltip;