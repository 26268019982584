import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import CommComm from "../../assets/CommCommLogo-Combo-White.png";
import {HiOutlineMenu, HiX} from "react-icons/hi";
import {colorPicker, navPath} from "../Helpers";

interface ICommCommHeaderProps {
    showHamburgerMenu: boolean;
    setShowHamburgerMenu: (show: boolean) => void;
    htmlRef: React.RefObject<HTMLDivElement>;
}

const CommCommHeader: React.FC<ICommCommHeaderProps> = (props) => {
    const navigate = useNavigate();
    const [topBarBackgroundChange, setTopBarBackgroundChange] = useState(false);

    useEffect(() => {

    }, []);

    const changeTopbarColor = () => {
        if (props.htmlRef.current) {
            setTopBarBackgroundChange(props.htmlRef.current.scrollTop >= 5);
        }
    };

    useEffect(() => {
        if (props.htmlRef.current) {
            // adding the event when scroll change Logo
            props.htmlRef.current.addEventListener('scroll', changeTopbarColor);
            // window.addEventListener("scroll", changeTopbarColor, true);
            return () => {
                if (props.htmlRef.current) {
                    props.htmlRef.current.removeEventListener('scroll', changeTopbarColor);
                }
            };
        }
    }, [props.htmlRef])

    return (
        <>
            <header style={{backgroundColor: `${topBarBackgroundChange ? colorPicker.commCommDarkPurple : ""}`}}>
                <div className="front-site-navbar">
                    <Link to={navPath.home}>
                        <img className='navCommCommLogo' src={CommComm} onClick={() => navigate(navPath.home)}/>
                    </Link>
                    <ul className='front-site-navbar-links'>
                        <li onClick={() => navigate(navPath.home)}><a href="#">Home</a></li>
                        <li onClick={() => navigate(navPath.team)}><a href="#">Team</a></li>
                        <li onClick={() => navigate(navPath.pricingTier)}><a href="#">Pricing</a></li>
                        <li onClick={() => navigate(navPath.userGuide)}><a href="#">User Guide</a></li>
                        <li onClick={() => navigate(navPath.faq)}><a href="#">FAQ</a></li>
                        <button className='blueBtn front-login-btn' onClick={() => navigate(navPath.login)}
                                type="button">Login
                        </button>
                    </ul>
                    <div className='hamburger-menu-btn'
                         onClick={() => props.setShowHamburgerMenu(!props.showHamburgerMenu)}>
                        {props.showHamburgerMenu ? <HiX size={30}/> : <HiOutlineMenu size={30}/>}
                    </div>
                </div>

                <div className='hamburger-dropdown' style={{height: `${props.showHamburgerMenu ? "300px" : "0"}`}}>
                    <li onClick={() => navigate(navPath.home)}><a href="#">Home</a></li>
                    <li onClick={() => navigate(navPath.team)}><a href="#">Team</a></li>
                    <li onClick={() => navigate(navPath.pricingTier)}><a href="#">Pricing</a></li>
                    <li onClick={() => navigate(navPath.userGuide)}><a href="#">Guide</a></li>
                    <li onClick={() => navigate(navPath.faq)}><a href="#">FAQ</a></li>
                    <button className='blueBtn front-login-btn' onClick={() => navigate(navPath.login)}
                            type="button">Login
                    </button>
                </div>
            </header>
        </>
    );
};

export default CommCommHeader;