import React, {useState} from "react";
import {EditProjectPopupModal} from "../../ProjectOverviewBoard/Popup/EditProjectPopupModal";
import {ProjectData} from "../../../data/ProjectData";
import ListItem from "../../Base/HorizontalList/ListItem/ListItem";
import {colorPicker} from "../../Helpers";
import {SVGIconList} from "../../Base/SVGPicker/SVGIconList";


export interface IListProjectItem {
    id: string;
    project: ProjectData;
    selectedProject: string;
    editProject: (id: string, title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    archiveProject: (projectId: string) => void;
}

//https://stackoverflow.com/questions/28365233/inline-css-styles-in-react-how-to-implement-ahover
const ListProjectItem: React.FunctionComponent<IListProjectItem> = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true)
    };

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <ListItem
                id={props.id}
                selectedItem={props.selectedProject}
                title={props.project.title}
                // image="https://bobbyhadz.com/images/blog/react-prevent-multiple-button-clicks/thumbnail.webp"
                toggleShow={handleShow}
                quantity={props.project.quantity}
                icon={SVGIconList.filter(x => x.title === props.project.iconImage)[0] ?? SVGIconList[0]}
                iconColor={props.project.iconColor ?? colorPicker.defaultBlue}
                iconBackgroundColor={props.project.iconBackgroundColor ?? colorPicker.commCommPink}
            />
            <EditProjectPopupModal show={show} handleClose={handleClose} image={props.project.backgroundImage}
                                   backgroundColor={props.project.iconBackgroundColor ?? colorPicker.commCommPink}
                                   color={props.project.iconColor ?? colorPicker.defaultBlue}
                                   title={props.project.title} editProject={props.editProject} id={props.project.id}
                                   icon={SVGIconList.filter(x => x.title === props.project.iconImage)[0] ?? SVGIconList[0]}
                                   archiveProject={props.archiveProject}
            />
        </>
    );
}

export default ListProjectItem;