import React, {useEffect} from 'react';
import CommCommFooter from "../components/Home/CommCommFooter";
import {useParams} from "react-router-dom";
import ProjectBreakdownPreview from "../components/Base/ProjectBreakdownPreview";
import {ProjectBreakdownDto} from "../data/ProjectBreakdownDto";
import LoadingOverlay from "../components/Home/LoadingOverlay";

interface IUnProtectedPriceBreakdown {
    projectBreakdownsDto: ProjectBreakdownDto[];
    setUnprotectedAccountId: (accountId: string) => void;
    setUnprotectedClientId: (clientId: string) => void;
    isUnprotectedBreakdownLoading: boolean;

}

const UnprotectedPriceBreakdown: React.FC<IUnProtectedPriceBreakdown> = (props) => {
    const {accountId} = useParams();
    const {clientId} = useParams();

    useEffect(() => {
        if (accountId && clientId) {
            props.setUnprotectedAccountId(accountId);
            props.setUnprotectedClientId(clientId);
        }
    }, [accountId, clientId])

    return (
        <div className="App">
            <div className="unprotected-profile-view-wrapper">
                <div
                    className={`unprotected-breakdown-container ${props.projectBreakdownsDto.length === 0 ? 'no-breakdown-height' : ''}`}>
                    <div className="price-breakdown-header">
                        <h1>Project Price Breakdowns</h1>
                        <p>Breakdowns are intended as approximate monetary assessments of the different
                            projects/services the seller provides.
                            They are intended to give you a better idea of what you are paying for.</p>
                    </div>
                    {props.projectBreakdownsDto && props.projectBreakdownsDto.length > 0 ? props.projectBreakdownsDto.map((breakdown, index) => {
                            const isLastBreakdown = index === props.projectBreakdownsDto.length - 1;
                            return <div
                                className={`public-table-breakdown-section ${props.projectBreakdownsDto.length === 1 ? 'single-breakdown' : ''} ${isLastBreakdown ? 'single-breakdown' : ''}`}
                                key={breakdown.id}>
                                <ProjectBreakdownPreview
                                    key={breakdown.id}
                                    projectBreakdown={breakdown}
                                    title={breakdown.title}
                                />
                            </div>
                        }) :
                        <div className='noAvailableBreakdown'>
                            <h2>No Price Breakdown Available</h2>
                            <p>The seller currently has no breakdown for the item(s) you ordered. Please contact them if
                                you would like to request one.</p>
                        </div>}
                </div>
                <LoadingOverlay isLoading={props.isUnprotectedBreakdownLoading}/>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UnprotectedPriceBreakdown;