import {cancelTokenSource, requests} from './base/http-common'
import {Card} from "../data/Card";
import {CardMovement} from "../data/CardMovement";
import {ArchiveCommissionsDto} from "../data/ArchiveCommissionsDto";

class CardDataSource {

    BASEURL = "card"

    Post = {
        create: (accountId: string, projectId: string, columnId: string, title: string, cardColor: string): Promise<Card> =>
            requests.post(`${this.BASEURL}`, new Card({
                    accountId: accountId,
                    projectId: projectId,
                    columnId: columnId,
                    title: title,
                    cardColor: cardColor
                }), cancelTokenSource.token
            )
    };
    Get = {
        // fromId: (id: string): Promise<CardData> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        // allFromProject: (projectId: string): Promise<CardData[]> => requests.get(`${this.BASEURL}/project/${projectId}`, cancelTokenSource.token),
        // allFromClient: (clientId: string): Promise<CardData[]> => requests.get(`${this.BASEURL}/client/${clientId}`, cancelTokenSource.token),
        // allFromColumn: (columnId: string): Promise<CardData[]> => requests.get(`${this.BASEURL}/column/${columnId}`),
        allArchivedFromClient: (clientId: string): Promise<ArchiveCommissionsDto> => requests.get(`${this.BASEURL}/archive/client/${clientId}`, cancelTokenSource.token),
        allArchivedFromAccount: (acctId: string): Promise<ArchiveCommissionsDto> => requests.get(`${this.BASEURL}/archive/all/${acctId}`, cancelTokenSource.token),
    };
    //TODO add archive and unarhcive update to card
    Update = {
        all: (card: Card): Promise<Card> =>
            requests.put(`${this.BASEURL}/all`, card, cancelTokenSource.token
            ),
        title: (id: string, acctId: string, title: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/title`, new Card({
                    id: id,
                    title: title,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        notes: (id: string, acctId: string, notes: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/notes`, new Card({
                    id: id,
                    notes: notes,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        cardColor: (id: string, acctId: string, cardColor: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/cardcolor`, new Card({
                    id: id,
                    cardColor: cardColor,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        quantity: (id: string, acctId: string, quantity: number, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/quantity`, new Card({
                    id: id,
                    quantity: quantity,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        dueDate: (id: string, acctId: string, dueDate: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/duedate`, new Card({
                    id: id,
                    dueDate: dueDate,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        startDate: (id: string, acctId: string, startDate: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/startdate`, new Card({
                    id: id,
                    startDate: startDate,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        // completedDate: (card: CardData): Promise<CardData> =>
        //     requests.put(`${this.BASEURL}/completedate`, new CardData({
        //             id: card.id,
        //             dueDate: card.dueDate,
        //             startDate: card.startDate,
        //             accountId: card.accountId,
        //             editorId: card.accountId
        //         }), cancelTokenSource.token
        //     ),
        project: (id: string, acctId: string, projectId: string, columnId: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/project`, new Card({
                    id: id,
                    accountId: acctId,
                    projectId: projectId,
                    columnId: columnId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        // breakdown: (id: string, breakdown: string): Promise<CardData> =>
        //     requests.put(`${this.BASEURL}/breakdown`, new CardData({
        //             id: id,
        //             projectBreakdownId: breakdown
        //         }), cancelTokenSource.token
        //     ),
        client: (id: string, acctId: string, clientId: string, editor: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/client`, new Card({
                    id: id,
                    accountId: acctId,
                    clientId: clientId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        addTag: (id: string, acctId: string, editor: string, tagId: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/tag/${tagId}/add`, new Card({
                    id: id,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        removeTag: (id: string, acctId: string, editor: string, tagId: string): Promise<Card> =>
            requests.put(`${this.BASEURL}/tag/${tagId}/remove`, new Card({
                    id: id,
                    accountId: acctId,
                    editorId: editor
                }), cancelTokenSource.token
            ),
        // allTags: (cardId: string, tags: string[]): Promise<CardData> =>
        //     requests.put(`${this.BASEURL}/tag/${cardId}/edit`, tags, cancelTokenSource.token
        //     ),
        movement: (
            id: string,
            cardTitle: string,
            acctId: string,
            accountUsername: string,
            projectId: string,
            projectName: string,
            clientId: string,
            clientName: string,
            currentColumn: string,
            currentColName: string,
            previousColumn: string,
            previousColName: string,
            currentIndex: number): Promise<Card> =>
            requests.put(`${this.BASEURL}/movement`, new CardMovement({
                    id: id,
                    cardTitle: cardTitle,
                    accountId: acctId,
                    accountUsername: accountUsername,
                    editorId: acctId,
                    projectId: projectId,
                    projectTitle: projectName,
                    clientId: clientId,
                    clientName: clientName,
                    currentColumnId: currentColumn,
                    currentColumnName: currentColName,
                    previousColumnId: previousColumn,
                    previousColumnName: previousColName,
                    index: currentIndex
                }), cancelTokenSource.token
            ),
        archive: (card: Card): Promise<Card> =>
            requests.put(`${this.BASEURL}/archive`, new Card({
                    id: card.id,
                    accountId: card.accountId,
                    editorId: card.accountId,
                    projectId: card.projectId,
                    columnId: card.columnId,
                    dueDate: card.dueDate,
                    startDate: card.startDate,
                }), cancelTokenSource.token
            )
    };

    Delete = {
        remove: (id: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}`, cancelTokenSource.token).then(result => {
            return result === 200;
        })
    };
}

export default new CardDataSource();