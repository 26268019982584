import React, {PropsWithChildren} from "react";
import {Modal} from "react-bootstrap";
import {IoWarning} from "react-icons/io5";


interface IModalWarningMessageProps extends PropsWithChildren {
    isOpen: boolean;
    toggle: (show: boolean) => void;
    modalTitle: string;
    cancel: () => void;
    cancelText: string;
}

export const ModalWarningMessage: React.FunctionComponent<IModalWarningMessageProps> = ({children, ...props}) => (
    <div>
        <Modal
            show={props.isOpen}
            onHide={() => props.toggle(!props.isOpen)}
        >
            <div className={"modalWarningMessageBackground"}>
                <Modal.Header closeButton aria-label="Hide" closeVariant="white" style={{paddingBottom: "0px"}}>
                </Modal.Header>
                <Modal.Body style={{paddingTop: "0px"}}>
                    <div className="modalWarningMessageContentWrapper">
                        <div className="modalMessageRow">
                            <IoWarning style={{color: "#FFC722", width: "130px"}} size={130}/>
                            <div className='modalWarningMessageColumn'>
                                <div className="modalWarningMessageHeader">{props.modalTitle}</div>
                                {children}
                            </div>
                        </div>
                        <div className="modalMessageRow">
                            <button className='blueBtn' onClick={props.cancel}
                                    type="button">{props.cancelText}</button>
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    </div>
);