import React from 'react';
import {formatCurrency} from "../Helpers";
import Table from "react-bootstrap/Table";
import {ProjectBreakdown} from "../../data/ProjectBreakdown";

interface IProjectBreakdownPreview {
    projectBreakdown: ProjectBreakdown;
    title: string;
}

const ProjectBreakdownPreview: React.FunctionComponent<IProjectBreakdownPreview> = (props) => {

    // useEffect(() => {
    //     console.log(props)
    // }, [])

    return (
        <>
            {props.projectBreakdown && <Table striped bordered hover>
                <thead>
                <tr>
                    <th>
                        <p className='breakdown-project-name'>{props.title}</p>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr className="whiteTableRow">
                    <td>
                        <div className='breakdown-table-row'>
                            <div className='breakdown-table-column'>
                                <p>Labor</p>
                                <ul>
                                    {props.projectBreakdown.labor && props.projectBreakdown.labor.map((item, index) => {
                                        return <li key={index}>{item}</li>
                                    })}
                                </ul>
                            </div>
                            <p>{formatCurrency(props.projectBreakdown.laborMin)} - {formatCurrency(props.projectBreakdown.laborMax)}</p>
                        </div>
                    </td>
                </tr>
                <tr className="grayTableRow">
                    <td>
                        <div className='breakdown-table-row'>
                            <div className='breakdown-table-column'>
                                <p>Materials</p>
                                <ul>
                                    {props.projectBreakdown.materials && props.projectBreakdown.materials.map((item, index) => {
                                        return <li key={index}>{item}</li>
                                    })}
                                </ul>
                            </div>
                            <p>{formatCurrency(props.projectBreakdown.materialsMin)} - {formatCurrency(props.projectBreakdown.materialsMax)}</p>
                        </div>
                    </td>
                </tr>
                <tr className="whiteTableRow">
                    <td>
                        <div className='breakdown-table-row'>
                            <div className='breakdown-table-column'>
                                <p>Shipping</p>
                                <ul>
                                    {props.projectBreakdown.shipping && props.projectBreakdown.shipping.map((item, index) => {
                                        return <li key={index}>{item}</li>
                                    })}
                                </ul>
                            </div>
                            <p>{formatCurrency(props.projectBreakdown.shippingMin)} - {formatCurrency(props.projectBreakdown.shippingMax)}</p>
                        </div>
                    </td>
                </tr>
                <tr className="grayTableRow">
                    <td>
                        <div className='breakdown-table-row'>
                            <div className='breakdown-table-column'>
                                <p>Other Costs</p>
                                <ul>
                                    {props.projectBreakdown.otherCosts && props.projectBreakdown.otherCosts.map((item, index) => {
                                        return <li key={index}>{item}</li>
                                    })}
                                </ul>
                            </div>
                            <p>{formatCurrency(props.projectBreakdown.otherCostsMin)} - {formatCurrency(props.projectBreakdown.otherCostsMax)}</p>
                        </div>
                    </td>
                </tr>
                <tr className="whiteTableRow">
                    <td>
                        <div className='breakdown-table-row'>
                            <p>Range Estimate of Total Cost</p>
                            <p>{formatCurrency(props.projectBreakdown.otherCostsMin + props.projectBreakdown.shippingMin + props.projectBreakdown.materialsMin + props.projectBreakdown.laborMin)} - {formatCurrency(props.projectBreakdown.otherCostsMax + props.projectBreakdown.shippingMax + props.projectBreakdown.materialsMax + props.projectBreakdown.laborMax)}</p>
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>}
        </>
    );
};

export default ProjectBreakdownPreview