import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const RedirectFromWWW: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        const pathname = window.location.pathname;
        const search = window.location.search;

        if (hostname.startsWith('www.')) {
            const nonWWWHostname = hostname.replace('www.', '');
            const nonWWWUrl = `${protocol}//${nonWWWHostname}${pathname}${search}`;
            window.location.replace(nonWWWUrl);
        }
    }, [navigate]);

    return null;
};

export default RedirectFromWWW;