import {cancelTokenSource, requests} from './base/http-common'
import {Feedback} from "../data/Feedback";

class FeedbackDataSource {

    BASEURL = "feedback"

    Post = {
        create: (accountId: string, message: string, feedbackType: number): Promise<Feedback> => requests.post(`${this.BASEURL}`,
            new Feedback({
                accountId: accountId,
                message: message,
                feedbackType: feedbackType
            }), cancelTokenSource.token
        )
    };

    Get = {
        allFromAccount: (accountId: string): Promise<Feedback[]> => requests.get(`${this.BASEURL}/acct/${accountId}`, cancelTokenSource.token),
    };
}

export default new FeedbackDataSource();