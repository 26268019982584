import storage from '../config/FirebaseStorage'
import {ref, getDownloadURL, uploadBytesResumable} from "firebase/storage"
//https://www.makeuseof.com/upload-files-to-firebase-using-reactjs/
//https://github.com/machadop1407/firebase-file-upload/blob/main/src/App.js

export interface IImageUploadProps {
    file: any,
    path: string,
    setProfileUrl: (url: string) => void,
    // setPercent: (percent: number) => void,
    updateAcct: (url: string) => void
}


export const ImageUploadService = (props: IImageUploadProps): void => {
    if (!props.file) {
        alert("Please choose a file first!")
    }

    // const storageRef = ref(storage, `/images/${file.name}`)
    const storageRef = ref(storage, `images/${props.path}`)
    const uploadTask = uploadBytesResumable(storageRef, props.file);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            // const percent = Math.round(
            //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
            // update progress
            // props.setPercent(percent);
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                props.updateAcct(url)
                props.setProfileUrl(url)
            });
        }
    );
}