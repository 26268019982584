import React, {useEffect, useState} from "react";
import {FiMoreHorizontal} from "react-icons/fi";
import {Menu, MenuItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {navPath} from "../../Helpers";
import Zorua from '../../../assets/zorua-profile-pic.jpeg';

interface IUnprotectedProfileLabel {
    name: string;
    email: string;
    image: string;
    acctType: number;
    boardPath: string;
    showBoardMenuItem: boolean;
    showProfileMenuItem: boolean;
    showBreakdownMenuItem: boolean;
    selectProfile: () => void;
    selectBreakdown: () => void;
    selectBoard: () => void;
}

const UnprotectedProfileLabel: React.FunctionComponent<IUnprotectedProfileLabel> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [useDefaultImage, setUseDefaultImage] = useState(true);

    useEffect(() => {
        setUseDefaultImage(props.image === null || props.image === "")
    }, [props.image])

    const handleImageError = () => {
        // Handle the image loading error here, e.g., set a state variable to indicate the error.
        setUseDefaultImage(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (open) {
            // If the menu is open, close it.
            handleClose();
        } else {
            // If the menu is closed, open it.
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const navigate = useNavigate();

    const open = Boolean(anchorEl);

    return (
        <div className={"nav-profile-topbar"} onClick={(event) => {
            handleClick(event)
        }}>
            {useDefaultImage ? (
                // Render the image with the onError event handler.
                <img
                    className={"profile-image-topbar"}
                    src={Zorua}
                    alt="Default profile image of our cat Zorua."
                    onError={handleImageError}
                />
            ) : (
                // Display user chosen image when there is no error.
                <img className={"profile-image-topbar"} src={props.image} alt="Profile image."/>
            )}
            <div className={"profile-text-topbar"}>
                <span title={props.name} className={"profile-username"}>{props.name}</span>
                <span title={props.email} className={"profile-role"}>{props.email} </span>
                {/*This code change will denote a profile's rol instead of email*/}
                {/* <span className={"profile-email"}>{props.email}</span> */}
            </div>
            <span className={"profile-options"}><FiMoreHorizontal/></span>
            <Menu
                className={"profile-options-menu-top-bar"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>

                <MenuItem hidden={props.showBoardMenuItem} onClick={() => {
                    handleClose()
                    props.selectBoard()
                    navigate(props.boardPath)
                }}>Progress Board</MenuItem>
                <MenuItem hidden={props.showProfileMenuItem} onClick={() => {
                    handleClose()
                    props.selectProfile()
                    navigate(navPath.unprotectedProfile)
                }}>Seller Profile</MenuItem>
                <MenuItem hidden={props.showBreakdownMenuItem} onClick={() => {
                    handleClose()
                    props.selectBreakdown()
                    navigate(props.boardPath + "/" + navPath.unprotectedPriceBreakdown)
                }}>Price Breakdowns</MenuItem>
            </Menu>
        </div>
    );
}

export default UnprotectedProfileLabel;