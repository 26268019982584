import React, {useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import Accordion from 'react-bootstrap/Accordion';

interface IFaq {

}

const Faq: React.FC<IFaq> = (props) => {
    // const [selectedYearlyTab, setSelectedYearlyTab] = useState(true);
    // const [selectedMonthlyTab, setSelectedMonthlyTab] = useState(false);
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const faqContainerRef = useRef<HTMLDivElement | null>(null);
    // const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     // Simulate loading process
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 2000); // Change the timeout as per your actual loading process
    // }, []);

    // useEffect(() => {
    //     setIsLoading(false);
    // }, []);

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={faqContainerRef}
            />
            <div className="faq-container" ref={faqContainerRef}>
                <div className='faq-content-wrapper'>
                    <h1>Frequently Asked Questions</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Is the free plan really free?</Accordion.Header>
                            <Accordion.Body>
                                {`Yes! The best part is that the free plan isn't a trial that will go away in X amount of days. 
                            We've included a decent amount of features for free, so that you can see if the app will work for your business.`}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What payment methods do you accept?</Accordion.Header>
                            <Accordion.Body>
                                We process all transactions throught Stripe, so we accept numerous payment methods like
                                Visa, Mastercard, Discover, and American Express.
                                Feel free to visit <a className="hyperlink"
                                                      href="https://stripe.com/payments/payment-methods">{`Stripe's Payment Method`}</a> page
                                to learn more.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What is your refund policy?</Accordion.Header>
                            <Accordion.Body>
                                Unfortunately, there is no refund policy for subscription plans.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What if I need to cancel my plan?</Accordion.Header>
                            <Accordion.Body>
                                In your account, go to your <b>profile settings</b> &#8594; <b>manage subscription</b>,
                                and you should see the option to cancel your plan.
                                If you cancel a monthly plan, you will have the allotted cards you paid for until the
                                end of your month cycle.
                                If you cancel a yearly plan, you will have the allotted cards you paid for until the end
                                of your year cycle.
                                Any plan cancellation will revert an account to the free tier by default.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How do I upgrade my free plan to a paid one?</Accordion.Header>
                            <Accordion.Body>
                                In your account, go to your <b>profile settings</b> &#8594; <b>manage subscription</b>,
                                and you should see the option to upgrade your plan.
                                Otherwise please visit our <a className="hyperlink"
                                                              href="https://commcomm.app/pricing-tier">pricing
                                page</a> to choose a plan.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>What happens if I hit my commission limit?</Accordion.Header>
                            <Accordion.Body>
                                When you hit the cap for commissions allotted to you for the month, you will have to
                                wait until the cap resets the following month to create more commissions.
                                If you find yourself using more than five cards a month, please consider upgrading to
                                one of our paid plans.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Do you have a referral program?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we do! When you refer a friend to CommComm right now, you&apos;ll both receive an
                                additional 5 free commission cards for your current cycle period! In order to find
                                your
                                referral code, go to your <b>profile settings</b> &#8594; <b>edit account</b>.
                                You should see your personal referral link as you scroll down. As of now, the referral
                                promotion will be applied to your first 12 referrals each year.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Do you have a mobile app?</Accordion.Header>
                            <Accordion.Body>
                                We do not have one right now. However, it may be a future consideration if a lot of
                                interested is displayed for it.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <section>
                    <div className="wave wave1"></div>
                    <div className="wave wave2"></div>
                    <div className="wave wave3"></div>
                    <div className="wave wave4"></div>
                </section>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default Faq;