import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import ClientBoard from '../assets/UserGuideImages/Client-Board.png';
import ClientTabs from '../assets/UserGuideImages/Client-Tabs.png';
import ActiveCommissionTracker from '../assets/UserGuideImages/Active-Commissions-Tracker.png';
import CommissionList from '../assets/UserGuideImages/Commission-List.png';
import ClientNotes from '../assets/UserGuideImages/Client-Notes.png';
import ContactInfo from '../assets/UserGuideImages/Contact-Info.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideClientBoards {

}

const UserGuideClientBoards: React.FC<IUserGuideClientBoards> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideClientBoardsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuideClientBoardsClientTabsRef = useRef<HTMLDivElement | null>(null);
    const userGuideClientBoardsAddingClientRef = useRef<HTMLDivElement | null>(null);
    const userGuideClientBoardsActiveCommissionsTrackerRef = useRef<HTMLDivElement | null>(null);
    const userGuideClientBoardsCommissionListRef = useRef<HTMLDivElement | null>(null);
    const userGuideClientBoardsClientNotesRef = useRef<HTMLDivElement | null>(null);
    const userGuideClientBoardsContactInfoRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "clientTabs":
                        userGuideClientBoardsClientTabsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "addingClient":
                        userGuideClientBoardsAddingClientRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "activeCommissionsTracker":
                        userGuideClientBoardsActiveCommissionsTrackerRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "commissionList":
                        userGuideClientBoardsCommissionListRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "clientNotes":
                        userGuideClientBoardsClientNotesRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "contactInfo":
                        userGuideClientBoardsContactInfoRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuideClientBoards)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideClientBoardsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideClientBoardsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('clientTabs')}>1. Client Tabs</li>
                                <li onClick={() => scrollToSection('addingClient')}>2. Adding a New Client</li>
                                <li onClick={() => scrollToSection('activeCommissionsTracker')}>3. Active Commissions
                                    Tracker
                                </li>
                                <li onClick={() => scrollToSection('commissionList')}>4. Commissions List</li>
                                <li onClick={() => scrollToSection('clientNotes')}>5. Client Notes</li>
                                <li onClick={() => scrollToSection('contactInfo')}>6. Contact Info</li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Client Boards</h1>
                        <p className='biggerText'>Client boards are meant to help the seller organize their clients and
                            keep detailed notes about them.</p>
                        <img src={ClientBoard} className='smallImg' style={{marginBottom: "35px"}}
                             alt="user guide client boards"/>

                        <section id="clientTabs" ref={userGuideClientBoardsClientTabsRef}>
                            <h2>1. Client Tabs</h2>
                            <img src={ClientTabs} alt="user guide client boards client tabs"/>
                            <p>Think of <strong>Client Tabs</strong>{` like folders. Each tab contains detailed
                                information about a client, including all their commissions, contact information, and
                                any notes you've recorded of them.
                                The numbers in the pink boxes, keep track of how many active commissions are inside of
                                each client tab. To edit tabs, hover over them and click the pencil icon. A pop-up
                                should appear
                                that is named after the client. Editable features include:`}</p>
                            <ul>
                                <li>{`The client's first and last name.`}</li>
                                <li>{`The client's email and phone number.`}</li>
                                <li>The client icon</li>
                                <li>The client icon colors</li>
                            </ul>

                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> There is an option to archive the
                                    client when you edit them. Archiving enables you to declutter your workspace by
                                    removing unused clients.
                                    Archived clients go to the <Link className="hyperlink"
                                                                     to={navPath.userGuideArchiveBoards}>Archive
                                        Board</Link>.</p>
                            </div>
                        </section>

                        <section id="addingClient" ref={userGuideClientBoardsAddingClientRef}>
                            <h2>2. Adding a New Client</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/ixnDRHuz_H0"
                                    title="user guide client board adding client demo"
                                    allowFullScreen></iframe>
                            <p>The short video above demonstrates the steps below on how to create a new client.</p>
                            <ol>
                                <li>
                                    Press the <strong>{`"+ Add Cient"`}</strong> button under your client tabs. A pop-up
                                    should appear called <strong>{`"Create Client"`}</strong>.
                                </li>
                                <li>
                                    {`Inside the pop-up, you will first have to fill out the client's full name.`}
                                </li>
                                <li>
                                    Then, fill out their email or phone number. Their contact info will be used to send
                                    them automatic updates when progress is made on their commission(s).
                                </li>
                                <li>Next, choose an icon to represent your new client and give it some custom colors.
                                </li>
                                <li>Lastly, click <strong>{`"Create New Project Board"`}</strong>. An empty new client
                                    board
                                    should appear.
                                </li>
                            </ol>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Email is available in every
                                    subscription tier. However, texting is only available in our Standard and Pro tier.
                                </p>
                            </div>
                        </section>

                        <section id="activeCommissionsTracker" ref={userGuideClientBoardsActiveCommissionsTrackerRef}>
                            <h2>3. Active Commissions Tracker</h2>
                            <img src={ActiveCommissionTracker}
                                 alt="user guide client boards active commission tracker"/>
                            <p>The tracker that says <strong>{`"Total Active Commissions: 3"`}</strong> keeps track of
                                how
                                many <strong>active</strong> commission cards the client has.</p>
                        </section>

                        <section id="commissionList" ref={userGuideClientBoardsCommissionListRef}>
                            <h2>4. Commission List</h2>
                            <img src={CommissionList} alt="user guide client boards commission list"/>
                            <p>The <strong>Commission List</strong> column is where you can see all the commissions the
                                client has ever ordered from you.
                                The list will be on <strong>{`"Active Commissions"`}</strong> by default, however, you
                                can
                                toggle to <strong>{`"Archived Commissions"`}</strong> by clicking on the three dots in
                                the
                                upper right corner.</p>
                        </section>

                        <section id="clientNotes" ref={userGuideClientBoardsClientNotesRef}>
                            <h2>5. Client Notes</h2>
                            <img src={ClientNotes} alt="user guide client boards client notes"/>
                            <p><strong>Client Notes</strong>{` is where you can jot down anything of importance about the
                                client. Did they have a favorite color?
                                Were they a great customer? Write down your thoughts here! Don't forget to click
                                the `}<strong>{`"Save Client Notes"`}</strong>{` button when you're done. These personal notes
                                will never be shown to a client.`}</p>
                        </section>

                        <section id="contactInfo" ref={userGuideClientBoardsContactInfoRef}>
                            <h2>6. Contact Info</h2>
                            <img src={ContactInfo} alt="user guide client boards contact info"/>
                            <p>The <strong>Contact Info</strong>{` column is where your client's contact information is
                                stored.
                                This information can be edited by clicking on the lock icon on the upper right corner.
                                In this column, you can also see the client's notification settings.
                                If a client is having trouble getting notifications, you can check here first and let
                                them know if their settings are on or off. If you update a client's email or phone
                                number then their respective
                                toggles will automatically be turned on.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Currently we only have email
                                    notification settings available. We will be adding texting notification settings as
                                    soon as possible.</p>
                            </div>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideClientBoards;