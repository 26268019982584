import React, {useState} from "react";
import {IconButton, InputBase, Paper} from "@mui/material";
import {Clear} from "@mui/icons-material";

interface AddItemProps {
    addItem: (text: string) => void
    setShow: (show: boolean) => void
    type: string
}

const AddItem = ({setShow, addItem, type}: AddItemProps) => {
    const [title, setTitle] = useState('')

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(e.target.value)
    }

    const handleBtnConfirm = () => {
        addItem(title)
        setTitle('')
        setShow(false)
    }

    return (
        <div>
            <div className="addCardInputBox">
                <Paper style={{padding: "5px 10px"}}>
                    <InputBase
                        onChange={handleOnChange}
                        autoFocus={true}
                        multiline
                        // onBlur={() => setShow(false)}
                        fullWidth
                        value={title}
                        placeholder={type === 'card' ? 'Enter a title for this commission...' : 'Enter column name'}
                    />
                </Paper>
            </div>
            <div className="addCardBtnContainer">
                <button className="blueBtn" style={{margin: '5px 5px 0px 0px'}} type="button"
                        onClick={handleBtnConfirm}>
                    {type === 'card' ? 'Add Commission' : 'Add Column'}
                </button>
                <IconButton onClick={() => setShow(false)}>
                    <Clear></Clear>
                </IconButton>
            </div>
        </div>
    );
}

export default AddItem