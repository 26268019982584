import {cancelTokenSource, requests} from './base/http-common'
import {ProjectBreakdown} from "../data/ProjectBreakdown";
import {ProjectBreakdownDto} from "../data/ProjectBreakdownDto";

class ProjectBreakdownDataSource {

    BASEURL = "projectbreakdown"

    Post = {
        create: (breakdown: ProjectBreakdown): Promise<ProjectBreakdown> => requests.post(`${this.BASEURL}`, breakdown, cancelTokenSource.token)
    };

    Get = {
        fromId: (id: string): Promise<ProjectBreakdown> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        fromProjectId: (projectId: string): Promise<ProjectBreakdown> => requests.get(`${this.BASEURL}/project/${projectId}`, cancelTokenSource.token),
        fromAccount: (accountId: string): Promise<ProjectBreakdown[]> => requests.get(`${this.BASEURL}/account/${accountId}`, cancelTokenSource.token),
        fromAccountWithClientId: (accountId: string, clientId: string): Promise<ProjectBreakdownDto[]> => requests.get(`${this.BASEURL}/account/${accountId}/client/${clientId}`, cancelTokenSource.token)

    };

    Update = {
        breakdown: (projectBreakdown: ProjectBreakdown): Promise<ProjectBreakdown> => requests.put(`${this.BASEURL}/breakdown`,
            projectBreakdown, cancelTokenSource.token
        ),
        project: (id: string, projectId: string): Promise<ProjectBreakdown> => requests.put(`${this.BASEURL}/project`,
            new ProjectBreakdown(
                {
                    id: id,
                    projectId: projectId
                }), cancelTokenSource.token
        ),
    };

    Delete = {
        remove: (breakdownId: string, acctId: string): Promise<boolean> => requests.delete(`${this.BASEURL}/acct/${acctId}/breakdown/${breakdownId}`, cancelTokenSource.token).then(result => {
            return result === 200;
        })
    };
}

export default new ProjectBreakdownDataSource();