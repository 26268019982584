import {AggregateBase} from "./AggregateBase";

export class Card extends AggregateBase {
    clientId: string = "";
    projectId: string = "";
    projectBreakdownId: string = "";
    editorId: string = "";
    columnId: string = "";
    accountId: string = "";
    dueDate: string = "";
    startDate: string = "";
    completedDate: string = "";
    title: string = "";
    quantity: number = 0;
    notes: string = "";
    cardColor: string = "";
    tags: string[] = []
    isArchived: boolean = false;
    archiveDate: string | null = null;

    public constructor(init?: Partial<Card>) {
        super()
        Object.assign(this, init);
    }
}