import {cancelTokenSource, requests} from './base/http-common'
import {Tag} from "../data/Tag";

class TagDataSource {

    BASEURL = "tag"

    Post = {
        create: (accountId: string, projectId: string, name: string, color: string): Promise<Tag> => requests.post(`${this.BASEURL}`,
            new Tag({
                accountId: accountId,
                projectId: projectId,
                name: name,
                color: color
            }), cancelTokenSource.token
        )
    };

    Get = {
        // fromId: (id: string): Promise<Tag> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        allFromAccount: (accountId: string): Promise<Tag[]> => requests.get(`${this.BASEURL}/acct/${accountId}`, cancelTokenSource.token),
        // allFromProject: (projectId: string): Promise<Tag[]> => requests.get(`${this.BASEURL}/proj/${projectId}`, cancelTokenSource.token)

    };

    Update = {
        basic: (id: string, acct: string, name: string, color: string): Promise<Tag> => requests.put(`${this.BASEURL}/basic`,
            new Tag(
                {
                    id: id,
                    accountId: acct,
                    name: name,
                    color: color
                }), cancelTokenSource.token
        ),
        // name: (id: string, acct: string, name: string): Promise<Tag> => requests.put(`${this.BASEURL}/name`,
        //     new Tag(
        //         {
        //             id: id,
        //             accountId: acct,
        //             name: name
        //         }), cancelTokenSource.token
        // ),
        // color: (id: string, acct: string, color: string): Promise<Tag> => requests.put(`${this.BASEURL}/color`,
        //     new Tag(
        //         {
        //             id: id,
        //             accountId: acct,
        //             color: color
        //         }), cancelTokenSource.token
        // ),
    };

    Delete = {
        remove: (id: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}`, cancelTokenSource.token).then(result => {
            return result === 200;
        })
    };
}

export default new TagDataSource();