import {Tag} from "./Tag";
import {Card} from "./Card";

export class CardDto extends Card {
    tagDetails: Tag[] = []

    public constructor(card: Card, tags: Tag[]) {
        super(card)
        if (tags.length !== 0) {
            let ts = card.tags.map((tagId) => {
                return tags.find(t => t.id === tagId)
            })
            if (ts !== undefined) {
                this.tagDetails = ts as Tag[];
            }
        }
    }
}