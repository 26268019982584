import React, {useEffect, useState} from 'react';
import {ProjectData} from "../../data/ProjectData";
import {Client} from "../../data/Client";
import {SVGIconType} from "./SVGPicker/SVGIconType";
import {AccountData} from "../../data/AccountData";
import {AccountSubscriptionPlan} from "../Helpers";
import ComponentLoading from "./Popup/ComponentLoading";

interface IArchiveProjectCard {
    item: ProjectData | Client;
    titleName: string;
    selectedItemId: string;
    selectItem: (itemDetails: any) => void;
    unarchive: (projectId: string) => void;
    iconColor: string;
    icon: SVGIconType;
    iconBackgroundColor: string;
    account: AccountData;
    projects: ProjectData[];
    unarchivingProjectId: string;
}

const ArchiveProjectCard: React.FunctionComponent<IArchiveProjectCard> = (props) => {
    const [item, setItem] = useState<ProjectData | Client>(props.item)
    const [titleName, setTitleName] = useState("");
    const [loadingAnimation, setLoadingAnimation] = useState(false);
    // const [unarchiving, setUnarchiving] = useState(false);
    // const [selectedItem, setSelectedItem] = useState<string>(props.selectedItemId)

    useEffect(() => {
        setItem(props.item);
        setTitleName(props.titleName)
    }, [props.selectedItemId, props.item])

    useEffect(() => {
        setLoadingAnimation(props.item.id === props.unarchivingProjectId)
    }, [props.unarchivingProjectId])

    return (
        <div key={item.id} style={{padding: "0px 10px"}}
             onClick={() => props.selectItem(item)}
        >
            <div
                className={"archived-card-view"}
                style={{backgroundColor: '#1B0821', padding: "15px"}}>
                <div className="archived-card-view-row">
                    <div className={"nav-list-item-icon"}
                         style={{backgroundColor: `${props.iconBackgroundColor}`}}>
                        {/*<IconContext.Provider*/}
                        {/*    value={{color: item.iconColor, size: '1.4em'}}>*/}
                        {/*    {React.createElement(props.icon.icon)}*/}
                        {/*</IconContext.Provider>*/}
                        <props.icon.icon width='20px' height='20px' fill={props.iconColor}/>
                    </div>
                    <span className={"nav-list-item-font"}>{titleName}</span>
                </div>
                <ComponentLoading isLoadingComponent={loadingAnimation}/>
                <button className='blueBtn' style={{width: "55%"}} type="button"
                        hidden={(props.account.acctSubscriptionPlan <= AccountSubscriptionPlan.Free && props.projects.length > 4) || loadingAnimation}
                        onClick={() => {
                            props.unarchive(item.id);
                            // setUnarchiving(true);
                        }
                        }>Unarchive
                </button>
            </div>
        </div>
    );
};

export default ArchiveProjectCard