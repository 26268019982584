import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import Quantity from '../assets/UserGuideImages/Quantity.png';
import CommissionEditCard from '../assets/UserGuideImages/Commission-Edit-Card.png';
import AssignClient from '../assets/UserGuideImages/Assign-Client.png';
import AssignProject from '../assets/UserGuideImages/Assign-Project.png';
import CommissionColor from '../assets/UserGuideImages/Commission-Color.png';
import CommissionNotes from '../assets/UserGuideImages/Commission-Notes.png';
import ShareCommission from '../assets/UserGuideImages/Share-Link.png';
import DeleteCommission from '../assets/UserGuideImages/Delete-Commission.png';
import ArchiveCommission from '../assets/UserGuideImages/Archive-Btn.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideCommissionCards {

}

const UserGuideCommissionCards: React.FC<IUserGuideCommissionCards> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideCommissionCardsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsCommissionQuantityRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsAssignClientRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsAssignProjectRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsManageLabelsRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsCardAgingRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsCommissionColorRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsCommissionNotesRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsCommissionCommentsRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsShareCommissionRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsCardMovementRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsDeleteCommissionRef = useRef<HTMLDivElement | null>(null);
    const userGuideCommissionCardsArchiveCommissionRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "commissionQuantity":
                        userGuideCommissionCardsCommissionQuantityRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "assignClient":
                        userGuideCommissionCardsAssignClientRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "assignProject":
                        userGuideCommissionCardsAssignProjectRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "manageLabels":
                        userGuideCommissionCardsManageLabelsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "cardAging":
                        userGuideCommissionCardsCardAgingRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "commissionColor":
                        userGuideCommissionCardsCommissionColorRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;

                    case "commissionNotes":
                        userGuideCommissionCardsCommissionNotesRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "commissionComments":
                        userGuideCommissionCardsCommissionCommentsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "shareCommission":
                        userGuideCommissionCardsShareCommissionRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "cardMovement":
                        userGuideCommissionCardsCardMovementRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "deleteCommission":
                        userGuideCommissionCardsDeleteCommissionRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "archiveCommission":
                        userGuideCommissionCardsArchiveCommissionRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuideCommissionCards)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideCommissionCardsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideCommissionCardsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('commissionQuantity')}>1. Commission Quantity</li>
                                <li onClick={() => scrollToSection('assignClient')}>2. Assign a Client</li>
                                <li onClick={() => scrollToSection('assignProject')}>3. Assign a Project</li>
                                <li onClick={() => scrollToSection('manageLabels')}>4. Manage Labels</li>
                                <li onClick={() => scrollToSection('cardAging')}>5. Card Aging</li>
                                <li onClick={() => scrollToSection('commissionColor')}>6. Commission Color</li>
                                <li onClick={() => scrollToSection('commissionNotes')}>7. Commission Notes</li>
                                <li onClick={() => scrollToSection('commissionComments')}>8. Commission Comments</li>
                                <li onClick={() => scrollToSection('shareCommission')}>9. Share a Commission</li>
                                <li onClick={() => scrollToSection('cardMovement')}>10. Card Movement</li>
                                <li onClick={() => scrollToSection('deleteCommission')}>11. Delete a Commission</li>
                                <li onClick={() => scrollToSection('archiveCommission')}>12. Archive a Commission</li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Commission Cards</h1>
                        <p className='biggerText'>{`Commission cards are designed to store a commission's information and
                            act as a tool to track and communicate the progress of the commission throughout the
                            seller's workflow.`}</p>
                        <img src={CommissionEditCard} className='medImg' style={{marginBottom: "35px"}}
                             alt="user guide commission edit card"/>

                        <section id="commissionQuantity" ref={userGuideCommissionCardsCommissionQuantityRef}>
                            <h2>1. Commission Quantity</h2>
                            <img src={Quantity} className='shrinkImg'
                                 alt="user guide commission cards commission quantity"/>
                            <p>Clicking on a commission card allows you to edit various details, including assigning
                                a <strong>quantity</strong> amount. This feature helps in tracking the number of items
                                within an order. Essentially, a single commission card can
                                be used to track one or multiple commissions from a client. For example, a photographer
                                delivering 30 photos to a client can use one commission card with a quantity of 30 to
                                represent the total
                                amount needed for the order, instead of creating 30 separate commission cards.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> The quantity number will be visible in
                                    the commission card preview.</p>
                            </div>
                        </section>

                        <section id="assignClient" ref={userGuideCommissionCardsAssignClientRef}>
                            <h2>2. Assign a Client</h2>
                            <img src={AssignClient} alt="user guide commission cards assign client"/>
                            <p>When a new commission card is created, a client tag will need to be assigned to it. A
                                client tag can be assigned by clicking the <strong>{`"Add Client"`}</strong> button
                                located under the list called <strong>{`"Edit Card"`}</strong>{`. When the button is clicked,
                                a pop-up like the image above will appear on screen. The commission's client can always
                                be changed later in the event that the seller has chosen the wrong client.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong>{` Adding or changing the commission's
                                    client will close the card edit pop-up entirely to update the commission card.`}</p>
                            </div>
                        </section>

                        <section id="assignProject" ref={userGuideCommissionCardsAssignProjectRef}>
                            <h2>3. Assign a Project</h2>
                            <img src={AssignProject} alt="user guide commission cards assign project"/>
                            <p>When a new commission card is created, its project tag will automatically be assigned to
                                the board it was created in. The project tag can be changed by clicking the <strong>{`"Change
                                    Project"`}</strong> button
                                located under the list called <strong>{`"Edit Card"`}</strong>{`. When the button is clicked,
                                a pop-up like the image above will appear on screen. The commission's project tag can
                                always
                                be changed later in the event that the seller has chosen the wrong project.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong>{` Changing the commission's project will
                                    close the card edit pop-up entirely to update the commission card.`}</p>
                            </div>
                        </section>

                        <section id="manageLabels" ref={userGuideCommissionCardsManageLabelsRef}>
                            <h2>4. Manage Labels</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/iy2OuzkKx5U"
                                    title="user guide commission card manage label demo"
                                    allowFullScreen></iframe>
                            <p>{`Organize your commission cards by adding labels to them. For instance, commissions inside
                                a shirt project tab may need labels that indicate what shirt size they're meant to be.
                                Labels can be created
                                by clicking the `}<strong>{`"Manage Labels"`}</strong> button located under the list
                                called <strong>{`"Edit Card"`}</strong>. The key features for managing labels are:</p>
                            <ul>
                                <li>{`You can search through existing labels for the one you're looking for.`}</li>
                                <li>You can edit or delete existing labels by clicking on their pencil icons. When
                                    editing labels, you can change their titles or background color.
                                </li>
                                <li>You can create new labels by clicking
                                    the <strong>{`"Create Label"`}</strong> button.
                                    You can give the label a title and background color.
                                </li>
                                <li>You can click and unclick the checkboxes next to labels to add or remove them to a
                                    commission card.
                                </li>
                            </ul>
                            <p>The short video above demonstrates these features on how labels work and what you can do
                                with them.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Labels created within a specific
                                    project are exclusive to that project and cannot be used elsewhere. This is meant to
                                    help with label organization.</p>
                            </div>
                        </section>

                        <section id="cardAging" ref={userGuideCommissionCardsCardAgingRef}>
                            <h2>5. Card Aging</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/hQM0Y2bWY-k"
                                    title="user guide commission cards aging demo"
                                    allowFullScreen></iframe>
                            <p>{`Every commission card has a colored bottom border. This border color changes over time
                                depending on how close the commission's due date is relative to the current date.
                                In other words, the commission card is aging in real time and giving a visual indicator
                                of its priority level. The border can turn into these four colors:`}</p>
                            <ul>
                                <li>Green - This means you have a lot of time before the due date.</li>
                                <li>Teal</li>
                                <li>Yellow</li>
                                <li>Red - This means the due date is a few days away!</li>
                            </ul>
                            <p>The short video above demonstrates <strong>card aging</strong> by changing the due date
                                for a commission card and showing the subsequent change in its border.</p>
                        </section>

                        <section id="commissionColor" ref={userGuideCommissionCardsCommissionColorRef}>
                            <h2>6. Commission Color</h2>
                            <img src={CommissionColor} alt="user guide commission cards commission color"/>
                            <p><strong>{`"Commission Color"`}</strong> gives the option to set the background color for
                                the
                                commission card. This feature can be used to color-code commission cards or to just add
                                some fun to your board!</p>
                        </section>

                        <section id="commissionNotes" ref={userGuideCommissionCardsCommissionNotesRef}>
                            <h2>7. Commission Notes</h2>
                            <img src={CommissionNotes} className='smallImg'
                                 alt="user guide commission cards commission notes"/>
                            <p><strong>Commission Notes</strong> is a dedicated space for sellers to jot down details
                                about their commissions. Any notes written here are private and only visible to the
                                seller.</p>
                        </section>

                        <section id="commissionComments" ref={userGuideCommissionCardsCommissionCommentsRef}>
                            <h2>8. Commission Comments</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/SxJdlo6FD64"
                                    title="user guide commission cards comments demo"
                                    allowFullScreen></iframe>
                            <p>Think of <strong>commission comments</strong>{` as a way to simultaneously document the
                                seller's work and communicate progress directly to the client. For instance, sellers can
                                use commission comments to share
                                progress images or inform clients about commission start dates.`}</p>
                            <p>{`Messages and picture attachments sent through comments are documented in the commission's
                                history and publically viewable by the client in
                                their `}<Link className='hyperlink' to={navPath.userGuideProgressBoards}>Progress
                                Board</Link>{`. The history includes timestamped comments, images, and commission
                                movement activity. To review a commission's history,
                                click on the `}<strong>{`"Show Details"`}</strong> button.</p>
                            <p>{`The short video above demonstrates how a seller's commission comments will be translated
                                into a client's commission history.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> The ability to comment images is not
                                    available in every subscription tier. They are only available in the Standard and
                                    Pro plans.</p>
                            </div>
                        </section>

                        <section id="shareCommission" ref={userGuideCommissionCardsShareCommissionRef}>
                            <h2>9. Share a Commission</h2>
                            <img src={ShareCommission} alt="user guide commission cards share commission"/>
                            <p>Every commission card has a <strong>{`"Share Commission"`}</strong> button located under
                                the
                                list called <strong>{`"Actions"`}</strong>. When the button is clicked, a pop-up like
                                the
                                image above will appear on screen.
                                The provided link is unique to the commission, and it will lead the client to their
                                personal <Link className='hyperlink' to={navPath.userGuideProgressBoards}>Progress
                                    Board</Link>. There are two ways you can copy the link:</p>
                            <ul>
                                <li>You can highlight the link and copy it using keyboard commands or the right click
                                    menu.
                                </li>
                                <li>You can click the <strong>{`"Copy Link"`}</strong> button.</li>
                            </ul>
                        </section>

                        <section id="cardMovement" ref={userGuideCommissionCardsCardMovementRef}>
                            <h2>10. Card Movement</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/PU_lhDXB3OQ"
                                    title="user guide commission card movement demo"
                                    allowFullScreen></iframe>
                            <p>Commission cards can be moved in two different ways:</p>
                            <ul>
                                <li>You can click on a commission card and press the <strong>{`"Move to Next
                                    Column"`}</strong> button located under the list
                                    called <strong>{`"Actions"`}</strong>.
                                </li>
                                <li>You can drag commission cards backwards or forwards into any other column in your
                                    workflow. It does not have to be an adjacent column.
                                </li>
                            </ul>
                            <p>The short video above demonstrates how to move a card using these two ways.</p>
                        </section>

                        <section id="deleteCommission" ref={userGuideCommissionCardsDeleteCommissionRef}>
                            <h2>11. Delete a Commission</h2>
                            <img src={DeleteCommission} alt="user guide commission cards delete commission"/>
                            <p>Every commission card has a <strong>{`"Delete"`}</strong> button located under the list
                                called <strong>{`"Actions"`}</strong>{`.
                                When the button is clicked, the warning in the image above will appear on screen. Like
                                the warning describes, deleting a commission card will permanently remove it from your
                                project board. Once the commission is deleted, there is no option for recovery.
                                Additionally, the assigned number of deletions depends on the seller's subscription
                                plan.
                                A tracker for how many deletes you have left will be in the warning.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong>{` Deleted commissions do not count
                                    against your plan's allotted amount of commissions.`}</p>
                            </div>
                        </section>

                        <section id="archiveCommission" ref={userGuideCommissionCardsArchiveCommissionRef}>
                            <h2>12. Archive a Commission</h2>
                            <img src={ArchiveCommission} alt="user guide commission cards archive commission"/>
                            <p>Every commission card has an <strong>{`"Archive"`}</strong> button located under the list
                                called <strong>{`"Actions"`}</strong>.
                                The button is disabled by default until the commission card is placed into
                                the <strong>Done</strong> column.
                                When the Archive button is clicked, the commission card will automatically be sent to
                                the <Link className='hyperlink' to={navPath.userGuideArchiveBoards}>Archive Board</Link>.
                            </p>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideCommissionCards;