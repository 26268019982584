import {AggregateBase} from "./AggregateBase";

export class ProjectData extends AggregateBase {
    accountId: string = "";
    title: string = "";
    iconColor: string = "";
    iconBackgroundColor: string = "";
    iconImage: string = "";
    backgroundImage: string = "";
    columnOrder: string[] = [];
    columnTitles: string[] = [];
    quantity: number = 0;
    isArchived: boolean = false;
    archiveDate: string | null = null;

    public constructor(init?: Partial<ProjectData>) {
        super()
        Object.assign(this, init);
    }
}