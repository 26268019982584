import React, {useState} from "react";
import {
    Draggable,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot
} from "@hello-pangea/dnd";
import {CardInfo} from "./CardInfo";
import {MoreHoriz} from "@mui/icons-material";
import {Menu, MenuItem} from "@mui/material";
import AddCommission from "./AddCommission";
import {CardDto} from "../../data/CardDto";
import {ColumnDto} from "../../data/ColumnDto";
import AddColumn from "./AddColumn";
import {ProjectDetails} from "../../data/ProjectDetails";
import {ClientDto} from "../../data/ClientDto";
import {AccountData} from "../../data/AccountData";
import ComponentLoading from "../Base/Popup/ComponentLoading";

interface ColumnInfoProps {
    account: AccountData;
    projects: ProjectDetails[];
    clients: ClientDto[];
    column: ColumnDto;
    cards: CardDto[];
    removeColumn: (columnId: string) => void;
    index: number;
    columnCount: number;
    editColumn: (id: string, title: string) => void;
    addCard: (text: string) => void;
    addColumn: (text: string) => void;
    toggleShowCardModal: () => void;
    selectCard: (card: CardDto) => void;
    addCommissionIsHidden: boolean;
    loadSavedCard: boolean;
}

const getCommissionContainerStyle = (isDraggingOver: boolean): {} => ({
    transition: "background 0.2s ease",
    background: isDraggingOver ? '#FFF4D2' : '#FFF4D2',
    flexGrow: 1,
    minHeight: 100,
    borderRadius: "0px 0px 9px 9px"
});

export const ColumnInfo: React.FunctionComponent<ColumnInfoProps> = (props) => {
    // const [showCardModal, setShowCardModalCardModal] = useState(false);
    // const toggleShowCardModal = () => setShowCardModalCardModal(!showCardModal);
    const [showColumnEdit, setShowColumnEdit] = useState(false);
    const toggleShowColumnEdit = () => setShowColumnEdit(!showColumnEdit);


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [newTitle, setNewTitle] = useState(props.column.title);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    const selectCard = (card: CardDto) => {
        // setSelectedCard(props.cards[index]);
        // setSelectedIndex(index);
        props.selectCard(card)
        props.toggleShowCardModal();
    };

    const getClientName = (clientId: string) => {
        const index = props.clients.findIndex(x => x.id === clientId);
        if (index >= 0) {
            return props.clients[index].firstName + " " + props.clients[index].lastName
        } else {
            return "";
        }
    }

    // Handles Enter key press for column titles
    const handleColumnTitleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // Prevent the default behavior of the Enter key (e.g., avoiding new lines in the input)
            event.preventDefault();

            // Submit the edited column title
            props.editColumn(props.column.id, newTitle);

            // Toggle off the column title edit mode
            setShowColumnEdit(false);
        }
    };

    return (
        <>
            <Draggable draggableId={props.column.id} index={props.index}>
                {(dragProvided, snapshot) => (
                    <div
                        className='column'
                        // style={getColumnStyle(dragProvided.draggableProps.style, snapshot.isDragging)}
                        // style={{
                        //     margin: "8px",
                        //     width: "220px",
                        //     border: "1px solid lightgrey",
                        //     borderRadius: "2px",
                        //     display:"flex",
                        //     background:'orange',
                        //     // flexDirection:"column"
                        //     ...dragProvided.draggableProps.style
                        //}}
                        {...dragProvided.draggableProps}
                        ref={dragProvided.innerRef}
                    >
                        {/*<div className='column-edit'>*/}
                        {/*    {index===0 || index===columnCount-1 ? null : <RemoveColumn removeColumn={removeColumn} columnId={column.id}/>}*/}
                        {/*</div>*/}
                        <div className='column-title'
                             {...dragProvided.dragHandleProps}>
                            {showColumnEdit ? (
                                    <div>
                                        <input
                                            autoFocus
                                            onChange={(e) => {
                                                setNewTitle(e.target.value)
                                            }}
                                            value={newTitle}
                                            onBlur={() => {
                                                props.editColumn(props.column.id, newTitle)
                                                setShowColumnEdit(false)
                                            }}
                                            onKeyDown={handleColumnTitleEnterKey}
                                        />
                                    </div>) :
                                (<div onClick={toggleShowColumnEdit} className="columnTitleText" style={{flexGrow: 1}}>
                                    {props.column.title}
                                </div>)
                            }
                            {props.index === 0 || props.index === props.columnCount - 1 ? null :
                                <div style={{padding: "0px 20px"}} onClick={(event) => {
                                    handleClick(event)
                                }}><MoreHoriz/></div>}
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {
                                    props.removeColumn(props.column.id)
                                    handleClose()
                                }}>Remove Column</MenuItem>
                                {/*<MenuItem onClick={() => {*/}
                                {/*    handleClose()*/}
                                {/*}}>Lock Column</MenuItem>*/}
                            </Menu>
                        </div>
                        <div>

                        </div>
                        <div className="column-card-wrapper">
                            <Droppable
                                key={props.column.id}
                                droppableId={props.column.id}
                                type='card'>
                                {(dropProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                    <div style={getCommissionContainerStyle(snapshot.isDraggingOver)}
                                         ref={dropProvided.innerRef}
                                         {...dropProvided.droppableProps}
                                    >
                                        {props.cards && props.cards
                                            .map((card: CardDto, index) => (
                                                <div key={card.id} onClick={() => selectCard(card)}>
                                                    <CardInfo card={card} index={index}
                                                              clientName={getClientName(card.clientId)}
                                                              showEdit={() => {
                                                                  // setSelectedCard({...card})
                                                                  // toggleShowCardModal()
                                                              }}/>
                                                </div>
                                            ))}

                                        {dropProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>

                        {props.index === props.columnCount - 1 ?
                            <AddColumn isHidden={props.columnCount >= 10} addItem={props.addColumn}
                                       type='column'/> : null}
                        {props.index === 0 ?
                            <>
                                <ComponentLoading isLoadingComponent={props.loadSavedCard}/>
                                {!props.loadSavedCard &&
                                    <AddCommission isHidden={props.addCommissionIsHidden} addItem={props.addCard}
                                                   type='card'/>}
                            </> : null}
                    </div>
                )}
            </Draggable>
        </>
    )
};