import {AggregateBase} from "./AggregateBase";

export class ProjectBreakdown extends AggregateBase {
    accountId: string = "";
    projectId: string = "";
    currency: string = "";
    name: string = "";
    labor: string[] = [];
    laborMin: number = 0.0;
    laborMax: number = 0.0;
    materials: string[] = [];
    materialsMin: number = 0.0;
    materialsMax: number = 0.0;
    shipping: string[] = [];
    shippingMin: number = 0.0;
    shippingMax: number = 0.0;
    otherCosts: string[] = [];
    otherCostsMin: number = 0.0;
    otherCostsMax: number = 0.0;

    public constructor(init?: Partial<ProjectBreakdown>) {
        super()
        Object.assign(this, init);
    }
}