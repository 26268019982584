import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import ProfileSettings from '../assets/UserGuideImages/Profile-Settings.png';
import ProfileUploadImg from '../assets/UserGuideImages/Profile-Upload-Img.png';
import ProfileNameBio from '../assets/UserGuideImages/Profile-Name-Bio.png';
import BusinessSocialLinks from '../assets/UserGuideImages/Profile-Links.png';
import ContactAndVenmo from '../assets/UserGuideImages/Profile-Contact-Info.png';
import Deactivation from '../assets/UserGuideImages/Profile-Deactivation.png';
import ProfilePreview from '../assets/UserGuideImages/Profile-Preview.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideProfileSettings {

}

const UserGuideProfileSettings: React.FC<IUserGuideProfileSettings> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideProfileSettingsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuideProfileSettingsUploadingPictureRef = useRef<HTMLDivElement | null>(null);
    const userGuideProfileSettingsNameAndBioRef = useRef<HTMLDivElement | null>(null);
    const userGuideProfileSettingsBusinessSocialLinksRef = useRef<HTMLDivElement | null>(null);
    const userGuideProfileSettingsContactAndVenmoRef = useRef<HTMLDivElement | null>(null);
    const userGuideProfileSettingsDeactivationRef = useRef<HTMLDivElement | null>(null);
    const userGuideProfileSettingsProfilePreviewRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "uploadingPicture":
                        userGuideProfileSettingsUploadingPictureRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "nameAndBio":
                        userGuideProfileSettingsNameAndBioRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "businessSocialLinks":
                        userGuideProfileSettingsBusinessSocialLinksRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "contactAndVenmo":
                        userGuideProfileSettingsContactAndVenmoRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "deactivation":
                        userGuideProfileSettingsDeactivationRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "profilePreview":
                        userGuideProfileSettingsProfilePreviewRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuideProfileSettings)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideProfileSettingsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideProfileSettingsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('uploadingPicture')}>1. Uploading a Picture</li>
                                <li onClick={() => scrollToSection('nameAndBio')}>2. Name & Bio</li>
                                <li onClick={() => scrollToSection('businessSocialLinks')}>3. Business & Social Links
                                </li>
                                <li onClick={() => scrollToSection('contactAndVenmo')}>4. Contact Info & Venmo</li>
                                <li onClick={() => scrollToSection('deactivation')}>5. Deactivation</li>
                                <li onClick={() => scrollToSection('profilePreview')}>6. Profile Preview</li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Profile Settings</h1>
                        <p className='biggerText'>Profile Settings is where sellers can edit their profile information
                            and preview how it appears to clients.</p>
                        <img src={ProfileSettings} className='smallImg' style={{marginBottom: "35px"}}
                             alt="user guide profile settings"/>

                        <section id="uploadingPicture" ref={userGuideProfileSettingsUploadingPictureRef}>
                            <h2>1. Uploading a Picture</h2>
                            <img src={ProfileUploadImg} className='shrinkImg'
                                 alt="user guide profile settings profile contact and venmo"/>
                            <p>{`Sellers can upload their profile picture by clicking on the blue camera icon shown in the
                                picture above. Your computer's file manager will open for you to choose a picture.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Fun Note:</strong>{` The default profile picture
                                    features the founders' cat, Zorua! You can read about them on the `}<Link
                                    to={navPath.team} className="hyperlink">Team Page</Link>.</p>
                            </div>
                        </section>

                        <section id="nameAndBio" ref={userGuideProfileSettingsNameAndBioRef}>
                            <h2>2. Name & Bio</h2>
                            <img src={ProfileNameBio} className='smallImg'
                                 alt="user guide profile settings profile name and biography"/>
                            <p>{`Sellers can include their name and a bio about themselves in their profile. Use the bio
                                to express yourself and tell clients what you're about!`}</p>
                        </section>

                        <section id="businessSocialLinks" ref={userGuideProfileSettingsBusinessSocialLinksRef}>
                            <h2>3. Business & Social Links</h2>
                            <img src={BusinessSocialLinks} className='smallImg'
                                 alt="user guide profile settings profile social media links"/>
                            <p>Sellers can include their business and social media links on their profile. Only three
                                social links are allowed, however,
                                feel free to use social media reference landing pages like <a href="https://linktr.ee/"
                                                                                              className='hyperlink'>Linktree</a> if
                                you need to showcase more than three links.</p>
                        </section>

                        <section id="contactAndVenmo" ref={userGuideProfileSettingsContactAndVenmoRef}>
                            <h2>4. Contact Info & Venmo</h2>
                            <img src={ContactAndVenmo} className='smallImg'
                                 alt="user guide profile settings profile contact and venmo"/>
                            <p>Sellers can see their CommComm sign-up email and username (non-editable). They can enter
                                a phone number and Venmo Handle
                                for client contact and tipping via the tip Button on the <Link
                                    to={navPath.userGuideProgressBoards} className='hyperlink'>Progress
                                    Board</Link> after commission completion.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Sellers can toggle <strong>{`"Display
                                    real name to client(s):"`}</strong> to choose between showing their username or
                                    first
                                    and last name in their public profile.</p>
                            </div>
                        </section>

                        <section id="deactivation" ref={userGuideProfileSettingsDeactivationRef}>
                            <h2>5. Deactivation</h2>
                            <img src={Deactivation} className='smallImg'
                                 alt="user guide profile settings profile deactivation"/>
                            <p>Sellers can opt to take a break from CommComm and deactivate their account temporarily.
                                Account deactivation
                                is reversible, and sellers can reactivate their account by logging in whenever they
                                choose to return.</p>
                        </section>

                        <section id="profilePreview" ref={userGuideProfileSettingsProfilePreviewRef}>
                            <h2>6. Profile Preview</h2>
                            <img src={ProfilePreview} className='smallImg'
                                 alt="user guide profile settings profile preview"/>
                            <p>{`Sellers can preview their profile's appearance to clients by clicking the `}<strong>{`"Preview Public Profile"`}</strong>button
                                in the <strong>Settings</strong> navigation.</p>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideProfileSettings;