import {cancelTokenSource, requests} from './base/http-common'
import {CardComment} from "../data/CardComment";
import {CardProgressHistoryDetails} from "../data/CardProgressHistoryDetails";

class CardActivityDataSource {

    BASEURL = "cardactivity"

    Post = {
        createComment: (comment: CardComment): Promise<CardComment> => requests.post(`${this.BASEURL}/comment`, comment, cancelTokenSource.token),
        createClientComment: (comment: CardComment): Promise<CardComment> => requests.post(`${this.BASEURL}/clientcomment`, comment, cancelTokenSource.token)
    };

    Get = {
        // fromAcct: (accountId: string): Promise<CardActivityRecordDto> => requests.get(`${this.BASEURL}/acct/${accountId}`, cancelTokenSource.token),
        fromCard: (cardId: string): Promise<CardProgressHistoryDetails[]> => requests.get(`${this.BASEURL}/card/${cardId}`, cancelTokenSource.token)
    };

    Delete = {
        // remove: (id: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}`, cancelTokenSource.token).then(result => {
        //     return result === 200;
        // })
    };
}

export default new CardActivityDataSource();