import {cancelTokenSource, requests} from './base/http-common'
import {CommCommTransaction} from "../data/CommCommTransaction";

class CommCommTransactionDataSource {

    BASEURL = "transaction"

    Post = {};

    Get = {
        all: (accountId: string): Promise<CommCommTransaction[]> => requests.get(`${this.BASEURL}/all/${accountId}`
            , cancelTokenSource.token
        )
    };

    Update = {};

    Delete = {};
}

export default new CommCommTransactionDataSource();