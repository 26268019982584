import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {auth} from '../config/Firebase'
import {User, signOut} from "firebase/auth";
import {navPath} from "../components/Helpers";
import UseToken from "./UseToken";

//https://www.reddit.com/r/webdev/comments/us599i/what_is_the_difference_between_firebase/
export interface AuthContextModel {
    user: User | null;
    token: string;
    // signInWithGoogle : () => Promise<void>;
    logOut: () => void;
    isAuthenticated: boolean;

}

export interface IProtectedProps extends PropsWithChildren {
}

const AuthContext = React.createContext<AuthContextModel>({} as AuthContextModel);

export const AuthContextProvider: React.FunctionComponent<IProtectedProps> = (props) => {
    const {children} = props;
    const location = useLocation();
    //have to set /login as default otherwise login page redirects on failures.
    // const [pathname, setPathname] = useState("/login");
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('')
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [error, setError] = useState<string>('')
    const regexClientProgress = new RegExp('^\\/acct\\/[A-Za-z0-9]+\\/client\\/[A-Za-z0-9]+$')
    const regexUnProtectedProfile = new RegExp('^\\/acct\\/[A-Za-z0-9]+\\/profile+$')
    const regexUnProtectedBreakdown = new RegExp('^\\/acct\\/[A-Za-z0-9]+\\/client\\/[A-Za-z0-9]+\\/breakdown+$')
    const regexUserGuide = new RegExp('^\\/user-guide\\/[A-Za-z0-9_-]+$')

    // useEffect(() => {
    //     setPathname(location.pathname);
    // }, [location.pathname])

    useEffect(() => {
        const AuthCheck = auth.onIdTokenChanged((user) => {
            setIsAuthenticated(user != null)
            if (user) {
                setUser(user)
                setLoading(false);
                UseToken().setRefreshToken(user.refreshToken);
                user.getIdToken().then((newToken) => {
                    setToken(newToken)
                    UseToken().setToken(newToken)
                })
            } else {
                setUser(null)
                if (regexClientProgress.test(location.pathname) ||
                    regexUnProtectedProfile.test(location.pathname) ||
                    regexUnProtectedBreakdown.test(location.pathname) ||
                    regexUserGuide.test(location.pathname) ||
                    location.pathname === navPath.home ||
                    location.pathname === navPath.pricingTier ||
                    location.pathname === navPath.team ||
                    location.pathname === navPath.faq ||
                    location.pathname === navPath.userGuide ||
                    location.pathname === navPath.termsandconditions ||
                    location.pathname === navPath.contactus ||
                    location.pathname === navPath.privacypolicy) {
                    console.log("welcome")
                } else if (location.pathname === navPath.login) {
                    console.log("login")
                    sessionStorage.clear()
                } else {
                    navigate(navPath.home, {replace: true})
                }
            }
        });
        return () => AuthCheck();
    }, [auth])

    // const signInWithGoogle = async () => {
    //     if(error !== '') { setError('')}
    //
    //     setAuthenticating(true);
    //
    //     signInWithPopup(auth, Providers.google)
    //         .then((response) => {
    //             console.log(response.user.uid);
    //             navigate('/');
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setAuthenticating(false);
    //             setError('Unable to sign in');
    //             navigate('/login')
    //         })
    // }

    const logOut = () => {
        signOut(auth).then(r => {
            navigate(navPath.login, {replace: true})
            sessionStorage.clear()
        })
    }

    const values = {
        user,
        token,
        // signInWithGoogle,
        logOut,
        isAuthenticated
    }

    if (loading) return <h1>loading...</h1>

    return (<AuthContext.Provider value={values}>
        {children}
    </AuthContext.Provider>);
};

export const UserAuth = () => {
    return useContext(AuthContext)
}