export class AccountDto {
    email: string = "";
    username: string = "";
    firstName: string = "";
    lastName: string = "";
    referralCode: string = "";

    public constructor(email: string, username: string, firstName: string, lastName: string, referralCode: string) {
        this.email = email;
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.referralCode = referralCode;

    }
}