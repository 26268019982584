import React, {useState, useRef, useEffect} from "react";
import Quill from 'quill';
import {
    AccountSubscriptionPlan,
    constantList, freeQuillContainer, freeQuillFormats,
    handleImageUpload, premiumQuillContainer, premiumQuillFormats
} from "../Helpers";
import DOMPurify from 'dompurify';
import Delta from "quill-delta";
//https://www.slatejs.org/examples/richtext
//https://patricknasralla.com/pages/slate-thesaurus-tutorial/
//https://portal.gitnation.org/contents/draftjs-editorjs-slatejs-choosing-the-best-text-editor-for-your-react-project
//https://www.plusdev.net/2023/08/05/rich-text-editor-in-react
//https://www.phind.com/search?cache=crha5iqcg1j45ca94ccako7n
interface IRichTextEditorCommentsProps {
    comments: string;
    setComments: (text: string) => void;
    setCommentsLength: (length: number) => void;
    onFocus: boolean;
    acctSubscription: number;
}

const RichTextEditorComments: React.FunctionComponent<IRichTextEditorCommentsProps> = (props) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);
    const isMounted = useRef(false);
    const [characterCount, setCharacterCount] = useState(0);

    const initializeQuillEditor = () => {
        if (editorRef.current) {
            quillRef.current = new Quill(editorRef.current, {
                theme: 'snow',
                formats: props.acctSubscription > AccountSubscriptionPlan.Basic ? premiumQuillFormats : freeQuillFormats,
                modules: {
                    toolbar: {
                        container: props.acctSubscription > AccountSubscriptionPlan.Basic ? premiumQuillContainer : freeQuillContainer,
                        handlers: {
                            image: () => handleImageUpload(quillRef?.current || new Quill(document.createElement('div')), "card_notes")
                        }
                    },
                    clipboard: {
                        matchers: [['img', (node: any, delta: Delta) => {
                            return new Delta();
                        }]]
                    }

                }
            });
            quillRef.current.focus()
            quillRef.current.on('text-change', () => {
                // if (isInitialSetting.current) return;
                props.setComments(DOMPurify.sanitize(quillRef.current?.root.innerHTML || '', {USE_PROFILES: {html: true}}))
                setCharacterCount(quillRef.current?.root.innerHTML.length || 0)
                props.setCommentsLength(quillRef.current?.root.innerHTML.length || 0);
            });
        }

        return () => {
            // Clean up on unmount
            if (quillRef.current) {
                quillRef.current.off('text-change');
                quillRef.current?.setContents([]);
                quillRef.current = null;
            }
        };
    }

    useEffect(() => {
        if (props.onFocus) {
            if (!isMounted.current) {
                initializeQuillEditor();
                isMounted.current = true;
            } else {
                quillRef.current?.setContents([]);
            }
        }
    }, [props.onFocus])

    return (
        <>
            <div ref={editorRef}/>
            <div
                hidden={characterCount < constantList.richTextLengthShowCounter}>{characterCount}/{constantList.richTextMaxLength}</div>
        </>
    );
};

export default RichTextEditorComments