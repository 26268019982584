import React, {useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import CommCommTeam from '../assets/CommComm-Team.png';
import SteveFoo from '../assets/SteveFoo.jpg';
import LexisChoi from '../assets/LexisChoi.jpg';
import Eevee from '../assets/Eevee.jpg';
import Zorua from '../assets/Zorua.jpg';
import Tini from '../assets/Dratini.jpg';
import Eufy from '../assets/Eufy.jpg';
import Rain from '../components/Home/Rain';

interface ITeam {

}

const Team: React.FC<ITeam> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const teamContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={teamContainerRef}
            />
            <div className="team-container" ref={teamContainerRef}>
                <div className='windowFrame'>
                    <div className='innerWindowFrame'>
                        <div className='rain-container'>
                            <Rain/>
                        </div>
                        <div className='human-team-content-wrapper'>
                            <div className='commCommCrewHeading'>
                                <h1>CommComm Crew</h1>
                                <p className='subTitleText'>{`"Coming together is a beginning, staying together is progress, and working together is success." - Henry Ford`}</p>
                            </div>
                            <div className='humanTeamWrapper'>
                                <div className='teamInfoWrapper'>
                                    <div className='teamInfoHeader'>
                                        <img src={SteveFoo} alt="commcomm team member foo"/>
                                        <div className="teamTitle">
                                            <h2>Steve Foo</h2>
                                            <h3>CEO & Co-Founder</h3>
                                        </div>
                                    </div>
                                    <p>{`Steve has always been a natural problem solver, tackling challenges both in his professional coding work and in his everyday life. 
                                        When he pitched the idea of CommComm to Lexis, she said, "Son of a gun, I'm in!" (although she may not remember this part). 
                                        Now, Steve takes great pride in presenting the culmination of his and Lexis' efforts. He knows what they made isn't perfect, 
                                        but he hopes it's good enough for you to adopt it as part of your lifestyle.`}</p>
                                </div>
                                <div className='teamInfoWrapper'>
                                    <div className='teamInfoHeader'>
                                        <img src={LexisChoi} alt="commcomm team member lexis"/>
                                        <div className="teamTitle">
                                            <h2>Lexis Choi</h2>
                                            <h3>CMO & Co-Founder</h3>
                                        </div>
                                    </div>
                                    <p>{`When Lexis heard of Steve's idea of CommComm, she saw the potential it had to help Etsy sellers like herself. 
                                        She knows what they made isn't perfect because more than half the team are cats and a roomba. 
                                        However, she hopes others will give CommComm a chance and see the potential that she saw in it. 
                                        Also, she hopes that everyone enjoys the lofi/vaporwave inspired designs she made for the site.`}</p>
                                </div>
                            </div>
                        </div>
                        <div className='pet-team-content-wrapper'>
                            <div className='commCommPetCrewHeading'>
                                <h1>CommComm Pet Crew</h1>
                                <p className='subTitleText'>The humans say they did everything, but us pets are the real
                                    hard workers.</p>
                            </div>
                            <div className='humanTeamWrapper'>
                                <div className='teamInfoWrapper'>
                                    <div className='teamInfoHeader'>
                                        <img src={Eevee} alt="commcomm team member eevee"/>
                                        <div className="teamTitle">
                                            <h2>Eevee</h2>
                                            <h3>Chief Nap Officer</h3>
                                        </div>
                                    </div>
                                    <p>{`Eevee is Lexis' and Steve's cat. She makes sure everything is going well and that everyone takes their naps seriously. 
                                        If she's not napping, she's trying to eat cheese or her sister's food.`}</p>
                                </div>
                                <div className='teamInfoWrapper'>
                                    <div className='teamInfoHeader'>
                                        <img src={Zorua} alt="commcomm team member zorua"/>
                                        <div className="teamTitle">
                                            <h2>Zorua</h2>
                                            <h3>Chief Bug Officer</h3>
                                        </div>
                                    </div>
                                    <p>{`Zorua is Lexis' and Steve's cat. She has the job of keeping the office bug free and happy. 
                                        If she's not catching bugs, she's catching naps with Eevee.`}</p>
                                </div>
                                <div className='teamInfoWrapper'>
                                    <div className='teamInfoHeader'>
                                        <img src={Tini} alt="commcomm team member dratini"/>
                                        <div className="teamTitle">
                                            <h2>Dratini</h2>
                                            <h3>Chief Zoomie Officer</h3>
                                        </div>
                                    </div>
                                    <p>{`Dratini is Lexis' and Steve's cat. She keeps everyone fit by encouraging zoomies around the office. 
                                        If she's not exercising, she'll be taking a nap on the couch with Lexis.`}</p>
                                </div>
                                <div className='teamInfoWrapper'>
                                    <div className='teamInfoHeader'>
                                        <img src={Eufy} alt="commcomm team member eufy"/>
                                        <div className="teamTitle">
                                            <h2>Eufy</h2>
                                            <h3>Chief Cleaning Officer</h3>
                                        </div>
                                    </div>
                                    <p>{`The office wouldn't be as clean without Steve's roomba, Eufy. According to Steve, he's the cleanest pet he ever had. 
                                        When he's not cleaning, he's recharging for the next day.`}</p>
                                </div>
                            </div>
                        </div>
                        <img className="commCommTeam" src={CommCommTeam} alt="commcomm team"/>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default Team;