import React, {useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import UnderConstruction from '../assets/UserGuideImages/Under-Construction.png';
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideManageSubscription {

}

const UserGuideManageSubscription: React.FC<IUserGuideManageSubscription> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideManageSubscriptionContainerRef = useRef<HTMLDivElement | null>(null);

    // const scrollToSection = (id: string) => {
    //     const sectionName = document.getElementById(id);
    //
    //     if (sectionName) {
    //         sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
    //     }
    // };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideManageSubscriptionContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideManageSubscriptionContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        {/* <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('projectTabs')}>1. Project Tabs</li>
                                <li onClick={() => scrollToSection('addingProject')}>2. Adding a New Project</li>
                                <li onClick={() => scrollToSection('commissionUsageTracker')}>3. Commission Usage Trackers</li>
                                <li onClick={() => scrollToSection('queueColumn')}>4. In Queue Column</li>
                                <li onClick={() => scrollToSection('columnFeatures')}>5. Column Features</li>
                                <li onClick={() => scrollToSection('doneColumn')}>6. Done Column</li>
                            </ul>
                        </div> */}
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Manage Subsciption</h1>
                        <p className='biggerText'>CommComm offers four subscription tiers with flexible payment options,
                            which includes monthly and yearly installments.</p>
                        <img src={UnderConstruction} alt={"user guide under construction"}/>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideManageSubscription;