import React, {useState, useRef, useEffect} from "react";
import Quill from 'quill';
import {
    clientReplyQuillContainer, clientReplyQuillFormats,
    constantList
} from "../Helpers";
import DOMPurify from 'dompurify';
import Delta from "quill-delta";

interface IRichTextClientCommentsProps {
    onOpen: boolean;
    replyText: string;
    setReply: (text: string) => void;
    setReplyLength: (length: number) => void;
}

const RichTextClientComments: React.FunctionComponent<IRichTextClientCommentsProps> = (props) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);
    const isMounted = useRef(false);
    const [characterCount, setCharacterCount] = useState(0);

    const initializeQuillEditor = () => {
        if (editorRef.current) {
            quillRef.current = new Quill(editorRef.current, {
                theme: 'snow',
                formats: clientReplyQuillFormats,
                modules: {
                    toolbar: {
                        container: clientReplyQuillContainer,
                    },
                    clipboard: {
                        matchers: [['img', (node: any, delta: Delta) => {
                            return new Delta();
                        }]]
                    }

                }
            });
            quillRef.current.focus()
            quillRef.current.on('text-change', () => {
                // if (isInitialSetting.current) return;
                props.setReply(DOMPurify.sanitize(quillRef.current?.root.innerHTML || '', {USE_PROFILES: {html: true}}));
                setCharacterCount(quillRef.current?.root.innerHTML.length || 0);
                props.setReplyLength(quillRef.current?.root.innerHTML.length || 0);
            });
        }

        return () => {
            // Clean up on unmount
            if (quillRef.current) {
                quillRef.current.off('text-change');
                quillRef.current = null;
            }
        };
    }

    useEffect(() => {
        if (props.onOpen) {
            if (!isMounted.current) {
                initializeQuillEditor();
                isMounted.current = true;
            } else {
                quillRef.current?.setContents([]);
            }
        }
    }, [props.onOpen])

    return (
        <>
            <div ref={editorRef}/>
            <div
                hidden={characterCount < constantList.richTextLengthShowCounter}>{characterCount}/{constantList.richTextMaxLength}</div>
        </>
    );
};

export default RichTextClientComments