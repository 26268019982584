import {AggregateBase} from "./AggregateBase";

export class Column extends AggregateBase {
    accountId: string = "";
    projectId: string = "";
    title: string = "";
    cardOrder: string[] = []
    isLocked: boolean = false;

    public constructor(init?: Partial<Column>) {
        super()
        Object.assign(this, init);
    }
}