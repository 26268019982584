import React from 'react';
import Toast from "react-bootstrap/Toast";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {colorPicker} from "../Helpers";

interface ISaveConfirmationToast {
    updateConfirmation: boolean;
    toggleUpdateConfirmation: () => void;
    confirmationText: string;
}

const SaveConfirmationToast: React.FunctionComponent<ISaveConfirmationToast> = (props) => {
    return (
        <>
            <Toast show={props.updateConfirmation} onClose={props.toggleUpdateConfirmation}>
                <Toast.Header closeButton aria-label="Hide" closeVariant="white">
                    <BsFillCheckCircleFill size={23} color={colorPicker.confirmationGreen}/>
                    <strong className="savedConfirmationText">{props.confirmationText}</strong>
                </Toast.Header>
            </Toast>
        </>
    );
};

export default SaveConfirmationToast