import {cancelTokenSource, requests} from './base/http-common'
import {StripeLookup} from "../data/StripeLookup";

class StripeDataSource {

    BASEURL = "stripe"

    Post = {
        checkout: (accountId: string, productName: string): Promise<string> => requests.post(`${this.BASEURL}/create-checkout-session`,
            new StripeLookup(accountId, productName), cancelTokenSource.token
        ),
        billing: (accountId: string): Promise<string> => requests.post(`${this.BASEURL}/billing-portal-session`,
            accountId, cancelTokenSource.token
        )
    };

    Get = {};

    Update = {};

    Delete = {};
}

export default new StripeDataSource();