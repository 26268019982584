import {cancelTokenSource, requests} from './base/http-common'
import {ContactUs} from "../data/ContactUs";

class ContactUsDataSource {

    BASEURL = "contactus"

    Post = {
        create: (name: string, email: string, message: string, subjectType: number): Promise<ContactUs> => requests.post(`${this.BASEURL}`,
            new ContactUs({
                name: name,
                email: email,
                message: message,
                subjectType: subjectType
            }), cancelTokenSource.token
        )
    };

    Get = {
        allFromEmail: (email: string): Promise<ContactUs[]> => requests.get(`${this.BASEURL}/email/${email}`, cancelTokenSource.token),
    };
}

export default new ContactUsDataSource();