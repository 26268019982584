import {AggregateBase} from "./AggregateBase";

export class CardActivityBase extends AggregateBase {
    cardId: string = "";
    cardTitle: string = "";
    accountId: string = "";
    accountUsername: string = "";
    projectId: string = "";
    projectTitle: string = "";
    clientId: string = "";
    clientName: string = "";
    editorId: string = "";
    activityType: number = 0;
    currentColumnId: string = "";
    // comment: string = "";
    // image: string[] = [];

    public constructor(init?: Partial<CardActivityBase>) {
        super();
        Object.assign(this, init);
    }
}