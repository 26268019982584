import React, {useState} from 'react'
import {Modal, Form} from "react-bootstrap";
import "../../../scss/CreateClientPopupModal.css"
import {colorPicker} from '../../Helpers';
import {SVGIconPicker} from "../../Base/SVGPicker/SVGIconPicker";
import {SVGIconType} from "../../Base/SVGPicker/SVGIconType";
import {SVGIconList} from "../../Base/SVGPicker/SVGIconList";

interface ICreateClientPopupModal {
    createClient: (firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void,
}

export const CreateClientPopupModal: React.FunctionComponent<ICreateClientPopupModal> = (props) => {
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    // const [icon, setIcon] = useState<IconType>(Icons[0]);
    const [icon, setIcon] = useState<SVGIconType>(SVGIconList[0]);
    const [color, setColor] = useState(colorPicker.defaultBlue);
    const [backgroundColor, setBackgroundColor] = useState(colorPicker.commCommYellow);

    const handleClose = () => {
        setShow(false)
        setValidEmail(true);
        setValidFirstName(true);
        setValidLastName(true);
        setValidPhoneNumber(true);
    };

    const handleShow = () => {
        setShow(true)
        //logic to check if you are editing project or creating a new one.
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
    };

    const handleFirstName = (input: string) => {
        if (new RegExp(/^[a-zA-Z .-]{1,51}$/).test(input)) {
            setValidFirstName(true);
        } else {
            setValidFirstName(false);
        }
        setFirstName(input);
    };

    const handleLastName = (input: string) => {
        if (new RegExp(/^[a-zA-Z .-]{1,51}$/).test(input)) {
            setValidLastName(true);
        } else {
            setValidLastName(false);
        }
        setLastName(input);
    };

    const handlePhoneNumber = (input: string) => {
        if (new RegExp(/^[0-9]{10,50}$/).test(input) || input.length === 0) {
            setValidPhoneNumber(true);
        } else {
            setValidPhoneNumber(false);
        }
        setPhoneNumber(input);
    };

    const handleEmail = (input: string) => {
        if (new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(input) || input.length === 0) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
        setEmail(input);
    };

    const handleSubmit = () => {
        let first = true;
        let last = true;
        let mail = true;
        let num = true;
        if (!(validFirstName && firstName.length > 0)) {
            first = false;
            setValidFirstName(false);
        }
        if (!(validLastName && lastName.length > 0)) {
            last = false;
            setValidLastName(false);
        }
        if (!(validEmail && email.length > 3)) {
            mail = false;
            setValidEmail(false);
        }
        if (!(validPhoneNumber && phoneNumber.length > 9)) {
            num = false;
            setValidPhoneNumber(false);
        }
        if (first && last && (mail || num)) {
            props.createClient(firstName, lastName, email, phoneNumber, icon.title, color, backgroundColor)
            handleClose();
        }
    };

    // const onIconChange = (icon: IconType) => {
    //     setIcon(icon);
    // }

    const onIconChange = (icon: SVGIconType) => {
        setIcon(icon);
    }
    return (
        <>
            <button className={"add-project"} type="button" onClick={handleShow}>
                + Add Client
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
            >
                <div className={"create-client-popup-background"}>
                    <Modal.Header className="text-center" closeButton aria-label="Hide">
                        <Modal.Title className="create-client-header w-100">Create Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="createClientForm" className="form-design" onSubmit={(event) => {
                            event.preventDefault()
                            handleSubmit()
                        }}>
                            <div className='createClientColumnWrapper'>
                                <div className="innerCreateClientColumn">
                                    <label className='create-client-modal-label-header'>Client Name</label>
                                    <p className="featureNote">Name is required.</p>

                                    <label className='create-client-modal-label-subheader-one' htmlFor="clientFirstName"
                                           style={{color: validFirstName ? "black" : "red"}}>First Name<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="text" className="form-control" pattern="[a-zA-Z .-]{1,50}"
                                           id="clientFirstName"
                                           onChange={(e) => {
                                               handleFirstName(e.target.value)
                                           }} placeholder="Tony"/>

                                    <label className='edit-client-modal-label-subheader-two' htmlFor="clientLastName"
                                           style={{color: validLastName ? "black" : "red"}}>Last Name<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="text" className="form-control" pattern="[a-zA-Z .-]{1,50}"
                                           id="clientLastName" onChange={(e) => {
                                        handleLastName(e.target.value)
                                    }} placeholder="Stark"/>
                                </div>
                                <div className="innerCreateClientColumn">
                                    <label className='create-client-modal-label-header'>Contact Info</label>
                                    <p className="featureNote">You must list either a phone number or email to create a
                                        client.</p>

                                    <label className='create-client-modal-label-subheader-one' htmlFor="clientEmail"
                                           style={{color: validEmail ? "black" : "red"}}>Email<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="email" className="form-control" id="clientEmail" onChange={(e) => {
                                        handleEmail(e.target.value)
                                    }} placeholder="TonyStark@StarkIndustries.com"/>

                                    <label className='edit-client-modal-label-subheader-two' htmlFor="clientPhoneNumber"
                                           style={{color: validPhoneNumber ? "black" : "red"}}>Phone Number<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="number" pattern="\d*" className="form-control" id="clientPhoneNumber"
                                           onChange={(e) => {
                                               handlePhoneNumber(e.target.value)
                                           }} placeholder="1234567891"/>
                                </div>
                                <label className='create-client-modal-label-header'>Client Icon</label>
                                <p className="featureNote">Set an icon to differentiate your client.</p>
                                <div className="clientIconRow">
                                    <div className="clientIconColumn">
                                        <label className='add-project-modal-label'>Icon Image</label>
                                        {/*<IconPicker value={icon} onChange={onIconChange} color={color}*/}
                                        {/*            backgroundColor={backgroundColor}/>*/}
                                        <SVGIconPicker value={icon} onChange={onIconChange} color={color}
                                                       backgroundColor={backgroundColor}/>
                                    </div>
                                    <div className="clientIconColumn">
                                        <label className='add-project-modal-label'>Icon BG</label>
                                        <input type="color" id="backgroundColorPicker" className="pickerBox"
                                               value={backgroundColor} aria-describedby="backgroundColorPickerHelp"
                                               onChange={(e) => {
                                                   setBackgroundColor(e.target.value)
                                               }}/>
                                    </div>
                                    <div className="clientIconColumn">
                                        <label className='add-project-modal-label'>Icon Color</label>
                                        <input type="color" id="colorPicker" className="pickerBox" value={color}
                                               aria-describedby="colorPickerHelp" onChange={(e) => {
                                            setColor(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                                <div className="innerCreateClientColumn" style={{marginBottom: "0px"}}>
                                    <button form="createClientForm" type="submit" className="blueBtn">
                                        Create New Client
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}