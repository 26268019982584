import React, {useEffect, useState} from 'react';
import {AccountData} from "../data/AccountData";
import ArchiveBoardClientsTopBar from "../components/ArchivesBoard/ArchiveBoardClientsTopBar";
import {Client} from "../data/Client";
import ArchiveClientCard from "../components/Base/ArchiveClientCard";
import LoadingOverlay from "../components/Home/LoadingOverlay";
import {SVGIconList} from "../components/Base/SVGPicker/SVGIconList";
import {colorPicker} from "../components/Helpers";

interface IArchiveClientsBoardProps {
    account: AccountData;
    clients: Client[];
    unarchive: (clientId: string) => void;
    isArchiveClientLoading: boolean;
    setShowArchive: (show: boolean) => void;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
    isSideNavVisible: boolean;
    unarchivingClientId: string;
}

const clientAE: string = "Clients A-E";
const clientFJ: string = "Clients F-J";
const clientKO: string = "Clients K-O";
const clientPT: string = "Clients P-T";
const clientUZ: string = "Clients U-Z";
const otherClients: string = "Other Clients";

const refreshCards = () => {
    return new Map<string, Client[]>([
        [clientAE, []],
        [clientFJ, []],
        [clientKO, []],
        [clientPT, []],
        [clientUZ, []],
        [otherClients, []]
    ])
}

//hold the selected card info in a variables, so you can pass clientId to functions.
const ArchiveClientsBoard: React.FunctionComponent<IArchiveClientsBoardProps> = (props) => {
    const [archiveClients, setArchiveClients] = useState<Map<string, Client[]>>(refreshCards());
    const [client, setClient] = useState<Client>(props.clients[0] ?? new Client())
    const [year, setYear] = useState<number>(2023)

    useEffect(() => {
        props.setShowArchive(true)
    }, [])

    useEffect(() => {
        const newArchiveClients = refreshCards();
        for (let i = 0; i < props.clients.length; i++) {
            let proj = props.clients[i];
            let letter = proj.lastName.charAt(0).toUpperCase();
            if (letter >= 'A' && letter <= 'E') {
                if (!newArchiveClients.get(clientAE)!.includes(proj)) {
                    newArchiveClients.get(clientAE)!.push(proj);
                }
            } else if (letter >= 'F' && letter <= 'J') {
                if (!newArchiveClients.get(clientFJ)!.includes(proj)) {
                    newArchiveClients.get(clientFJ)!.push(proj);
                }
            } else if (letter >= 'K' && letter <= 'O') {
                if (!newArchiveClients.get(clientKO)!.includes(proj)) {
                    newArchiveClients.get(clientKO)!.push(proj);
                }
            } else if (letter >= 'P' && letter <= 'T') {
                if (!newArchiveClients.get(clientPT)!.includes(proj)) {
                    newArchiveClients.get(clientPT)!.push(proj);
                }
            } else if (letter >= 'U' && letter <= 'Z') {
                if (!newArchiveClients.get(clientUZ)!.includes(proj)) {
                    newArchiveClients.get(clientUZ)!.push(proj);
                }
            } else {
                if (!newArchiveClients.get(otherClients)!.includes(proj)) {
                    newArchiveClients.get(otherClients)!.push(proj);
                }
            }
        }
        setArchiveClients(newArchiveClients);

    }, [props.clients.length, year])

    const setClientDetails = (client: Client) => {
        setClient(client)
    }

    return (
        <>
            <div className="App">
                <div className='archiveBoardImgBg'>
                    <div className="archiveOverview" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <ArchiveBoardClientsTopBar
                            feedbackConfirmation={props.feedbackConfirmation}
                            setFeedbackConfirmation={props.setFeedbackConfirmation}
                            submitFeedback={props.submitFeedback}
                            setYear={setYear}/>
                        <div className="archive-content-wrapper">
                            {Array.from(archiveClients.keys()).map((key: string, indexKey: number) => (
                                <div key={key + indexKey} className="clientCommissionColumn">
                                    <div className="clientCommissionColumn-title">
                                        {key}
                                    </div>
                                    <div>
                                        {archiveClients.has(key) && archiveClients.get(key)!.length > 0 &&
                                            <div className="card-wrapper">
                                                {archiveClients.get(key)!.length > 0 && archiveClients.get(key)!.sort(
                                                    (c1, c2) => {
                                                        const result = c1.lastName.localeCompare(c2.lastName);
                                                        return result !== 0 ? result : c1.firstName.localeCompare(c2.firstName);
                                                    }).map((clientData: Client, index) => (
                                                    <div key={index}>
                                                        <ArchiveClientCard
                                                            item={clientData}
                                                            selectedItemId={client.id}
                                                            iconColor={clientData?.iconColor ?? colorPicker.defaultBlue}
                                                            iconBackgroundColor={clientData?.iconBackgroundColor ?? colorPicker.commCommPink}
                                                            icon={SVGIconList.filter(x => x.title === clientData.iconImage)[0] ?? SVGIconList[0]}
                                                            unarchive={props.unarchive}
                                                            titleName={`${clientData.firstName} ${clientData.lastName}`}
                                                            unarchivingClientId={props.unarchivingClientId}
                                                            selectItem={setClientDetails}/>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <LoadingOverlay isLoading={props.isArchiveClientLoading}/>
        </>
    );
}

export default ArchiveClientsBoard;
