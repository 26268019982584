import React, {useEffect, useState} from 'react'
import ListProjectItem from "./ListProjectItem";
import '../../../scss/Navigation.scss'
import TabItem from "./TabItem";
import ListClientItem from "./ListClientItem";
import {useLocation, useNavigate} from "react-router-dom";
import {CreateProjectPopupModal} from "../../ProjectOverviewBoard/Popup/CreateProjectPopupModal";
import {CreateClientPopupModal} from "../../ClientsOverviewBoard/Popup/CreateClientPopupModal";
import {ProjectDetails} from "../../../data/ProjectDetails";
import {ClientDto} from "../../../data/ClientDto";
import {navPath} from "../../Helpers";
import {AccountData} from "../../../data/AccountData";
import ComponentLoading from "../../Base/Popup/ComponentLoading";

interface INavTab {
    account: AccountData;
    projects: ProjectDetails[];
    clients: ClientDto[];
    createClient: (firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    createProject: (title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    editProject: (id: string, title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    editClient: (id: string, firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    setSelectedProject: (projectId: string) => void;
    setSelectedClient: (clientId: string) => void;
    selectedProject: string;
    selectedClient: string;
    archiveClient: (clientId: string) => void;
    archiveProject: (projectId: string) => void;
    loadSavedClients: boolean;
    loadSavedProjects: boolean;
    archivingProjectId: string;
    archivingClientId: string;
}

const NavTab: React.FunctionComponent<INavTab> = (props) => {
    const location = useLocation();
    const [selectedProjectTab, setSelectedProjectTab] = useState(location.pathname === navPath.projects);
    const [selectedClientTab, setSelectedClientTab] = useState(location.pathname === navPath.clients);
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedProjectTab(location.pathname === navPath.projects);
        setSelectedClientTab(location.pathname === navPath.clients);
    }, [location.pathname])

    const changeToClient = () => {
        setSelectedProjectTab(false)
        setSelectedClientTab(true)
        // navigate('/clients/' + props.selectedClient)
        navigate(navPath.clients)
    }

    const changeToProject = () => {
        setSelectedProjectTab(true)
        setSelectedClientTab(false)
        // navigate('/projects/' + props.selectedProject)
        navigate(navPath.projects)
    }


    return (

        <div className='nav-menu-items-wrapper'>
            <div>
                <TabItem onSelectClients={changeToClient} onSelectProjects={changeToProject}
                         isProjectTabSelected={selectedProjectTab} isClientTabSelected={selectedClientTab}/>
            </div>
            <div className={"nav-menu-items"}>
                {selectedProjectTab && <div>
                    <ComponentLoading isLoadingComponent={props.loadSavedProjects}/>
                    <ul>
                        {props.projects && props.projects
                            .sort((a, b) => b.quantity - a.quantity)
                            .map((item, index) => {
                                return (
                                    <li key={index} hidden={props.archivingProjectId === item.id} onClick={() => {
                                        props.setSelectedProject(item.id)
                                    }}>
                                        {/* <Link className="nav-menu-items-link" to={navPath.projects}> */}
                                        {/*<Link to={'/projects/' + item.id}>*/}
                                        <ListProjectItem id={item.id} project={item}
                                                         selectedProject={props.selectedProject}
                                                         editProject={props.editProject}
                                                         archiveProject={props.archiveProject}
                                        />
                                        {/* </Link> */}
                                    </li>
                                );
                            })}
                    </ul>

                </div>
                }
                {selectedClientTab && <div>
                    <ComponentLoading isLoadingComponent={props.loadSavedClients}/>
                    <ul style={{display: "inline", textAlign: "left"}}>
                        {props.clients && props.clients
                            .sort((a, b) => b.quantity - a.quantity)
                            .map((item, index) => {
                                return (
                                    <li key={index} hidden={props.archivingClientId === item.id} onClick={() => {
                                        props.setSelectedClient(item.id)
                                    }}>
                                        {/* <Link className="nav-menu-items-link" to={navPath.clients}> */}
                                        {/*<Link to={'/clients/' + item.id}>*/}
                                        <ListClientItem id={item.id} client={item} selectedClient={props.selectedClient}
                                                        editClient={props.editClient}
                                                        archiveClient={props.archiveClient}
                                        />
                                        {/* </Link> */}
                                    </li>
                                );
                            })}
                    </ul>
                </div>}
            </div>
            {selectedProjectTab &&
                <CreateProjectPopupModal createProject={props.createProject} projects={props.projects}
                                         acctSubscription={props.account.acctSubscriptionPlan}/>}
            {selectedClientTab && <CreateClientPopupModal createClient={props.createClient}/>}
        </div>
    );
}

export default NavTab