import React, {useEffect, useState} from "react";
import HelpDropDown from "../Base/HelpDropDown";
import {DateTime} from "../../pages/ProjectWorkflowBoard";
import {AccountData} from "../../data/AccountData";

interface IProjectOverviewTopBar {
    account: AccountData;
    cardsUsed: number;
    cardsTotal: number;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
}

const ProjectOverviewTopBar: React.FunctionComponent<IProjectOverviewTopBar> = (props) => {
    const [resetDays, setResetDays] = useState(0)

    useEffect(() => {
        const time = new Date(props.account.planEndDate).getTime() - new Date(Date.now()).getTime();
        setResetDays(Math.floor(time / (24 * 60 * 60 * 1000)))
    }, [props.account.planEndDate])

    return (
        <div className="projectOverviewTopBar">
            <div className='projectMarkerWrapper'>
                <div className='dateTimeMarker'>
                    <DateTime></DateTime>
                </div>
                <div className='totalCardsMarkerProjectTab'>
                    <p>Total Commissions Created: {props.cardsUsed}/{props.cardsTotal}</p>
                </div>
                <div className='cardReminderMarker'>
                    {props.account.isDeactivated ? <p>Account DEACTIVATED</p> :
                        <p>{resetDays} day(s) until commission total reset!</p>}
                </div>
            </div>
            <div className='projectTopBarBtnWrapper'>
                <HelpDropDown
                    setFeedbackConfirmation={props.setFeedbackConfirmation}
                    feedbackConfirmation={props.feedbackConfirmation}
                    submitFeedback={props.submitFeedback}/>
            </div>
        </div>
    );
}

export default ProjectOverviewTopBar;