import React, {useEffect, useState} from 'react';
import {colorPicker, formatDate} from "../Helpers";
import {ArchiveCardDetails} from "../../data/ArchiveCardDetails";
import {IoMdClock} from "react-icons/io";
import {BsFillCalendar2CheckFill} from "react-icons/bs";

interface IStationaryArchiveCard {
    card: ArchiveCardDetails;
    selectedCard: ArchiveCardDetails;
    selectCard: (cardDetails: ArchiveCardDetails) => void;
}

const StationaryArchiveCard: React.FunctionComponent<IStationaryArchiveCard> = (props) => {
    const [card, setCard] = useState<ArchiveCardDetails>(props.card)

    useEffect(() => {
        setCard(props.card);
    }, [props.card])

    return (
        <>
            <div key={card.id} style={{padding: "0px 10px"}}
                 onClick={() => props.selectCard(card)}
            >
                <div
                    className={"card-view"}
                    style={{backgroundColor: `${card.cardColor}`}}>
                    <div className="card-view-content-container">
                        <p className="card-title">
                            {card.title} ({card.quantity})
                        </p>
                        <div className="card-label-wrapper">
                            <p key={card.projectId} className="card-label-tag"
                               style={{backgroundColor: colorPicker.labelBlue, color: colorPicker.white}}>
                                {card.projectTitle}
                            </p>
                            <p key={card.clientId} className="card-label-tag"
                               style={{backgroundColor: colorPicker.labelBlue, color: colorPicker.white}}>
                                {card.clientName}
                            </p>
                            {card.tagDetails && card.tagDetails.map((item, index) => (
                                <p key={item.id} className="card-label-tag"
                                   style={{backgroundColor: item.color, color: colorPicker.white}}>
                                    {item.name}
                                </p>
                            ))}
                        </div>
                        {card.dueDate && !card.completedDate && (
                            <div className="card-due-date">
                                <IoMdClock size={20}/>
                                <p>{formatDate(card.dueDate)}</p>
                            </div>
                        )}

                        {card.completedDate && (
                            <div className="card-completed-date">
                                <BsFillCalendar2CheckFill size={15}/>
                                <p>{formatDate(card.completedDate)}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StationaryArchiveCard