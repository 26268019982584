import React, {useEffect, useState} from 'react'
import '../../../scss/ProfileTab.scss'
import {useLocation, useNavigate} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {navPath} from "../../Helpers";

interface IArchiveTab {
    setShowArchive: (show: boolean) => void;
}

const ArchiveTab: React.FunctionComponent<IArchiveTab> = (props) => {
    const location = useLocation();
    const [selectCommissions, setSelectCommissions] = useState(true);
    const [selectProjects, setSelectProjects] = useState(false);
    const [selectClients, setSelectClients] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setSelectCommissions(location.pathname === navPath.commissionArchive);
        setSelectProjects(location.pathname === navPath.projectsArchive)
        setSelectClients(location.pathname === navPath.clientsArchive);
    }, [location.pathname])

    const goBackHome = () => {
        props.setShowArchive(false)
        navigate(navPath.projects)
    }

    const chooseCommissions = () => {
        setSelectCommissions(true);
        setSelectProjects(false)
        setSelectClients(false);
        navigate(navPath.commissionArchive)
    }

    const chooseProjects = () => {
        setSelectCommissions(false);
        setSelectProjects(true)
        setSelectClients(false);
        navigate(navPath.projectsArchive)
    }

    const chooseClients = () => {
        setSelectCommissions(false);
        setSelectProjects(false)
        setSelectClients(true);
        navigate(navPath.clientsArchive)
    }

    return (
        <div>
            <button className="blueBtn" style={{margin: '15px 0px 25px'}} onClick={() => goBackHome()} type="button">
                <AiOutlineArrowLeft/> Return to Main Page
            </button>
            <div className={selectCommissions ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => chooseCommissions()}>
                <label>Commissions</label>
            </div>
            <div className={selectProjects ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => chooseProjects()}>
                <label>Projects</label>
            </div>
            <div className={selectClients ? "profileTabSelectedColor" : "profileTabDefaultColor"}
                 onClick={() => chooseClients()}>
                <label>Clients</label>
            </div>
        </div>
    );
}

export default ArchiveTab