import React, {useState, useEffect, useRef} from 'react';
import {FaChevronLeft, FaChevronRight, FaRegWindowClose} from "react-icons/fa";
import {SVGIconList} from "./SVGIconList"
import "../../../scss/IconPicker.css"
import {SVGIconType} from "./SVGIconType";

//Icons by Font Awesome - https://fontawesome.com/

interface ISVGIconPicker {
    value: SVGIconType,
    onChange: (icon: SVGIconType) => void,
    color?: string,
    backgroundColor?: string
}

const SVGIconPicker: React.FunctionComponent<ISVGIconPicker> = (props) => {
    const [SearchText, setSearchText] = useState("");
    const [CurrentPage, setCurrentPage] = useState(1);
    const [IsOpen, setIsOpen] = useState(false);
    //Handle outside pickbox clicks
    const pickboxRef = useRef<HTMLDivElement | null>(null);
    //filter icons by search text
    const perPage = 40;
    const filteredIcons = SVGIconList.filter(icon => icon.searchText.toLowerCase().includes(SearchText.toLowerCase()));
    const noOfPages = Math.ceil(filteredIcons.length / perPage);
    //previous Page
    const previousPage = () => {
        if (CurrentPage > 1) {
            setCurrentPage(CurrentPage - 1);
        }
    }
    //next Page
    const nextPage = () => {
        if (CurrentPage < noOfPages) {
            setCurrentPage(CurrentPage + 1);
        }
    }
    //select icon
    const selectIcon = (icon: SVGIconType) => {
        props.onChange(icon);
        setIsOpen(false);
    }
    //on search
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (pickboxRef.current && !pickboxRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handlePickboxContentClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <div className='icon-picker' ref={pickboxRef}>
            <div style={{position: "relative"}}>
                <a href='#'>
                    <div className={props.value.title}
                         onClick={(e) => {
                             e.preventDefault();
                             setIsOpen(true);
                         }}
                         style={{
                             display: "flex",
                             flexWrap: "wrap",
                             alignContent: "center",
                             justifyContent: "center",
                             background: props.backgroundColor,
                             padding: "5px",
                             width: "50px",
                             height: "50px",
                             borderRadius: "5px",
                             boxShadow: "2px 2px gray"
                         }}>
                        <props.value.icon fill={props.color} width={'33px'} height={'33px'}/>
                        {/*<IconContext.Provider value={{color: props.color, size: '40px'}}>*/}
                        {/*    {React.createElement(props.value.icon)}*/}
                        {/*</IconContext.Provider>*/}
                    </div>
                </a>
                {IsOpen &&
                    <div className='pick-box-container'>
                        <div className='pick-box' onClick={handlePickboxContentClick}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <div className="nosubmit">
                                    <input className="nosubmit" type="search" value={SearchText} onChange={onSearch}
                                           placeholder="Search..."/>
                                </div>
                                <div>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        previousPage()
                                    }} style={{marginLeft: "8px"}}>
                                        <i className='fa fa-chevron-left'><FaChevronLeft/></i>
                                    </a>
                                    {" " + CurrentPage} of {noOfPages + " "}
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        nextPage()
                                    }} style={{marginLeft: "8px"}}>
                                        <i className='fa fa-chevron-right'><FaChevronRight/></i>
                                    </a>
                                    <a href='#' onClick={(e) => {
                                        e.preventDefault();
                                        setIsOpen(false);
                                    }} style={{marginLeft: "8px"}}>
                                        <i className='fa-solid fa-close'><FaRegWindowClose/></i>
                                    </a>
                                </div>
                            </div>
                            <div className='icon-list'>
                                {
                                    filteredIcons.slice((CurrentPage - 1) * perPage, CurrentPage * perPage).map((icon, index) => {
                                        return (
                                            <div key={index} className="icon-item"
                                                 onClick={() => selectIcon(icon)}>
                                                <div className='icon'>
                                                    <i className={icon.title}
                                                       style={{
                                                           background: props.backgroundColor,
                                                           padding: "6px",
                                                           borderRadius: "4px",
                                                       }}>
                                                        {/*<IconContext.Provider value={{color: props.color}}>*/}
                                                        {/*    {React.createElement(icon.icon)}*/}
                                                        {/*</IconContext.Provider>*/}
                                                        <icon.icon fill={props.color} width={'24px'} height={'24px'}
                                                                   viewBox={"0 0 24 24"}/>
                                                    </i>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>}
            </div>

        </div>

    );
};

export {SVGIconPicker};