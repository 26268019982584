import React, {useEffect, useState} from "react";
import {BsLockFill, BsFillUnlockFill} from "react-icons/bs";
import {colorPicker} from "../../Helpers";


interface INotes {
    phoneNumber: string;
    email: string;
    phoneNumberNotification: boolean;
    emailNotification: boolean;
    updatePhone: (phoneNumber: string) => void;
    updateEmail: (email: string) => void;
}

const ContactInfo: React.FunctionComponent<INotes> = (props) => {
    const [enableEdit, setEnableEdit] = useState(false);
    const [phoneText, setPhoneText] = useState(props.phoneNumber);
    const [emailText, setEmailText] = useState(props.email);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [validEmail, setValidEmail] = useState(true);

    useEffect(() => {
        setPhoneNumber(props.phoneNumber);
        setEnableEdit(false);
    }, [props.phoneNumber])

    useEffect(() => {
        setEmail(props.email);
        setEnableEdit(false);
    }, [props.email])

    const onEdit = () => {
        setEnableEdit(!enableEdit)
    }

    const setEmail = (text: string) => {
        setEmailText(text);
        if (new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(text)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const setPhoneNumber = (text: string) => {
        setPhoneText(text);
        if (new RegExp(/^[0-9]{9,51}$/).test(text)) {
            setValidPhoneNumber(true);
        } else {
            setValidPhoneNumber(false);
        }
    }

    const handlePhoneNumberChange = (text: string) => {
        if (new RegExp(/^[0-9]{9,51}$/).test(text)) {
            if (text !== props.phoneNumber) {
                setPhoneText(text);
                props.updatePhone(text);
            }
        } else {
            setValidPhoneNumber(false);
        }
    }

    const handleEmailChange = (text: string) => {
        if (new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(text)) {
            if (text !== props.email) {
                setEmailText(text);
                props.updateEmail(text);
            }
        } else {
            setValidEmail(false);
        }
    }

    return (
        <div id="client-contact-info" className="sticky-note">
            <div className="sticky-note-header" style={{backgroundColor: colorPicker.commCommYellow}}>
                <div>Contact Info</div>
                <div className="close" onClick={onEdit}>
                    {enableEdit ? <BsFillUnlockFill style={{color: colorPicker.commCommBlue}}/> :
                        <BsLockFill style={{color: "black"}}/>}
                </div>
            </div>
            <div className="contactInfoWrapper">
                <b style={{color: validPhoneNumber ? "black" : "red"}}>Phone:</b>
                <br/>
                <input type="number" className="form-control" disabled={!enableEdit} value={phoneText}
                       onBlur={(e) => handlePhoneNumberChange(e.target.value)}
                       onChange={(e) => setPhoneNumber(e.target.value)}></input>
                <b style={{color: validEmail ? "black" : "red"}}>Email:</b>
                <br/>
                <input type="email" className="form-control" disabled={!enableEdit} value={emailText}
                       onBlur={(e) => handleEmailChange(e.target.value)}
                       onChange={(e) => setEmail(e.target.value)}></input>
                {/* <span>
                    <b>Commission Text Updates: </b><span>{props.phoneNumberNotification ? " On" : " Off"}</span>
                </span>
                <div style={{marginBottom: '8px'}}></div> */}
                <span>
                    <b>Commission Email Updates: </b><span>{props.emailNotification ? " On" : " Off"}</span>
                </span>
                <div style={{marginBottom: '5px'}}></div>
            </div>
        </div>
    );
}

export default ContactInfo;