import {Outlet} from 'react-router-dom';
import React from "react";
import {ProjectDetails} from "../../../data/ProjectDetails";
import NavBar from "./NavBar";
import {ClientDto} from "../../../data/ClientDto";
import {AccountData} from "../../../data/AccountData";

export interface INavigationSideBarLayout {
    account: AccountData;
    projects: ProjectDetails[];
    clients: ClientDto[];
    createClient: (firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    createProject: (title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    editProject: (id: string, title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    editClient: (id: string, firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    selectedProject: string;
    selectedClient: string;
    showProfile: boolean;
    showArchive: boolean;
    selectClient: (clientId: string) => void;
    selectProject: (projectId: string) => void;
    setShowProfile: (show: boolean) => void;
    setShowArchive: (show: boolean) => void;
    archiveProject: (projectId: string) => void;
    archiveClient: (clientId: string) => void;
    logout: () => void;
    isSideNavVisible: boolean;
    setIsSideNavVisible: (isVisible: boolean) => void;
    loadSavedClient: boolean;
    loadSavedProjects: boolean;
    archivingProjectId: string;
    archivingClientId: string;
}

export const NavigationSideBarLayout = (props: INavigationSideBarLayout) => (

    <>
        <NavBar
            account={props.account}
            projects={props.projects}
            clients={props.clients}
            createClient={props.createClient}
            createProject={props.createProject}
            editProject={props.editProject}
            editClient={props.editClient}
            selectedProject={props.selectedProject}
            selectedClient={props.selectedClient}
            selectProject={props.selectProject}
            selectClient={props.selectClient}
            showProfile={props.showProfile}
            showArchive={props.showArchive}
            setShowProfile={props.setShowProfile}
            setShowArchive={props.setShowArchive}
            archiveClient={props.archiveClient}
            archiveProject={props.archiveProject}
            logout={props.logout}
            isSideNavVisible={props.isSideNavVisible}
            setIsSideNavVisible={props.setIsSideNavVisible}
            loadSavedClients={props.loadSavedClient}
            loadSavedProjects={props.loadSavedProjects}
            archivingProjectId={props.archivingProjectId}
            archivingClientId={props.archivingClientId}
        />
        <Outlet/>
    </>
);