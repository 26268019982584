import React, {useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {Form, Modal} from "react-bootstrap";
import {AccountData} from "../data/AccountData";
import {ModalRedirectToStripeMessage} from "../components/Base/Popup/ModalRedirectToStripeMessage";
import {config} from "../config/config";
import {
    formatCurrency,
    formatDate,
    formatSubscriptionName,
    formatSubscriptionPrice,
    formatTimeIntervalName
} from "../components/Helpers";
import {CommCommTransaction} from "../data/CommCommTransaction";

interface IManageSubscription {
    account: AccountData;
    isSideNavVisible: boolean;
    // setShowManageSub: (show: boolean) => void;
    checkoutSubscription: (priceId: string) => void;
    billingSubscription: () => void;
    redirectUrl: string;
    transactionHistory: CommCommTransaction[]
    getTransactionHistory: () => void;
}

const ManageSubscription: React.FC<IManageSubscription> = (props) => {
    const [showCancelRenewlModal, setCancelRenewlModal] = useState(false);
    const [showRedirectModal, setRedirectModal] = useState(false);

    useEffect(() => {
        props.getTransactionHistory();
    }, [])

    // useEffect(() => {
    //     if (props.redirectUrl !== "" && showRedirectModal) {
    //         handleRedirectModalShow(true);
    //     }
    // }, [props.redirectUrl])

    const handleRedirectModalShow = (show: boolean) => {
        setRedirectModal(show);
    };

    const handleRedirectModalClose = () => {
        setRedirectModal(false);
    };

    const handleCancelRenewalSurveyClose = () => {
        setCancelRenewlModal(false);
    };

    const checkout = (priceId: string) => {
        if (props.account.planId.trim() === "") {
            props.checkoutSubscription(priceId);
            handleRedirectModalShow(true);
        } else {
            props.billingSubscription();
            handleRedirectModalShow(true);
        }
    }

    return (
        <>
            <div className="App">
                <div className='editProfileImgBg'>
                    <div className="edit-profile-wrapper" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <div className="manage-subscription-container">
                            <div className="manage-sub-section">
                                <h1>Your Subscription</h1>
                                <div className="manage-subscription-column">
                                    <div className="manage-subscription-row">
                                        <div className="manage-sub-text-row">
                                            <label>Subscription Plan:</label>
                                            <p>{formatSubscriptionName(props.account.acctSubscriptionPlan)}</p>
                                        </div>
                                        <div className="manage-sub-text-row">
                                            <label>Billing Cycle:</label>
                                            <p>{formatTimeIntervalName(props.account.acctTimeInterval)}</p>
                                        </div>
                                    </div>
                                    <div className="manage-subscription-row">
                                        <div className="manage-sub-text-row">
                                            <label>Billing Amount:</label>
                                            <p>{formatSubscriptionPrice(props.account.planId)}</p>
                                        </div>
                                        <div className="manage-sub-text-row">
                                            <label>Expiration:</label>
                                            <p>{props.account.planId === "" ? "Never" : formatDate(props.account.planEndDate)}</p>
                                        </div>
                                    </div>
                                    <p className='footnote'>*Taxes will be applied depending on your location.</p>
                                </div>
                            </div>
                            <div className="manage-sub-section">
                                <h1>Payment Details</h1>
                                <div className="manage-subscription-column">
                                    {/*<div className="manage-subscription-row">*/}
                                    {/*    <label>Payment Method:</label>*/}
                                    {/*    <img style={{width: "35px", height: "13px"}} src={Visa}/>*/}
                                    {/*    <p>ending in 2479 <a href="#" className='hyperlink'>Change</a></p>*/}
                                    {/*</div>*/}
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Payment History</Accordion.Header>
                                            <Accordion.Body>
                                                {props.transactionHistory.length > 0 ? props.transactionHistory
                                                        .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                                                        .map((transaction, index) => {
                                                            return (<div className="payment-row"
                                                                         key={transaction.id}
                                                                         style={{backgroundColor: index % 2 === 1 ? "#EEEEEE" : ""}}
                                                                         onClick={() => window.open(transaction.invoiceUrl, "_blank")}>
                                                                <p>Plan: <strong>{transaction?.description ?? ""}</strong>
                                                                </p>
                                                                <p>Payment
                                                                    Date: <strong>{formatDate(transaction.createdAt)}</strong>
                                                                </p>
                                                                <p>Total: <strong>{formatCurrency(transaction.total / 100)}</strong>
                                                                </p>
                                                            </div>)
                                                        }) :
                                                    <div className="payment-row">
                                                        <p><strong>No Records</strong></p>
                                                    </div>
                                                }

                                                {/*<div className="payment-row" style={{backgroundColor: "#EEEEEE"}}>*/}
                                                {/*    <p>Plan: <strong>Basic</strong></p>*/}
                                                {/*    <p>Payment Date: <strong>Mar 20, 2022</strong></p>*/}
                                                {/*    <p>Total: <strong>$60.00</strong></p>*/}
                                                {/*</div>*/}
                                                {/*<div className="payment-row">*/}
                                                {/*    <p>Plan: <strong>Basic</strong></p>*/}
                                                {/*    <p>Payment Date: <strong>Mar 20, 2021</strong></p>*/}
                                                {/*    <p>Total: <strong>$60.00</strong></p>*/}
                                                {/*</div>*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="manage-sub-section">
                                <h1>{props.account.planId === "" ? "Select Plan" : "Update Plan"}</h1>
                                <div className="manage-subscription-column">
                                    <div className="box-row">
                                        {/*<div className="plan-box">*/}
                                        {/*    <h2>FREE</h2>*/}
                                        {/*    <p className='boxBodyText'>Best for casual businesses</p>*/}
                                        {/*    <h3>$0</h3>*/}
                                        {/*    <p className='billingText'>FOREVER</p>*/}
                                        {/*    <p className='boxBodyText'>*Downgrading will remove all paid features from*/}
                                        {/*        current plan.</p>*/}
                                        {/*    <button className="blueBtn" type="button"*/}
                                        {/*            style={{width: '100%', margin: '10px 0px 0px'}}*/}
                                        {/*        // onClick={() => save()}*/}
                                        {/*    >Get Started*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}

                                        {/*Change the border style to when a plan is active*/}
                                        <div className="plan-box"
                                             style={{border: props.account.planId === config.stripePriceIdConfig.BasicMonthly ? "2px solid #F171AB" : ""}}>
                                            <h2>BASIC Monthly</h2>
                                            <p className='boxBodyText'>Best for small businesses that receives a
                                                consistent amount of monthly commissions/orders.</p>
                                            <h3>{config.stripePriceConfig.BasicMonthly}</h3>
                                            <p className='billingText'>Billed monthly</p>
                                            <p className='billingText'>30 commissions per month</p>
                                            <button
                                                disabled={props.account.planId === config.stripePriceIdConfig.BasicMonthly}
                                                className={props.account.planId === config.stripePriceIdConfig.BasicMonthly ? "disabledBtn" : "blueBtn"}
                                                type="button"
                                                style={{width: '100%', margin: '10px 0px 0px'}}
                                                onClick={() => checkout(config.stripePriceIdConfig.BasicMonthly)}
                                            >{props.account.planId === "" ? "Select Plan" : "Update Plan"}
                                            </button>
                                        </div>
                                        <div className="plan-box"
                                             style={{border: props.account.planId === config.stripePriceIdConfig.BasicYearly ? "2px solid #F171AB" : ""}}>
                                            <h2>BASIC Yearly</h2>
                                            <p className='boxBodyText'>Best for small businesses that receives a
                                                fluctuating amount of monthly commissions/orders.</p>
                                            <h3>{config.stripePriceConfig.BasicYearly}</h3>
                                            <p className='billingText'>Billed annually</p>
                                            <p className='billingText'>400 commissions per year</p>
                                            <button
                                                disabled={props.account.planId === config.stripePriceIdConfig.BasicYearly}
                                                className={props.account.planId === config.stripePriceIdConfig.BasicYearly ? "disabledBtn" : "blueBtn"}
                                                type="button"
                                                style={{width: '100%', margin: '10px 0px 0px'}}
                                                onClick={() => checkout(config.stripePriceIdConfig.BasicYearly)}
                                            >{props.account.planId === "" ? "Select Plan" : "Update Plan"}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="box-row">
                                        <div className="plan-box"
                                             style={{border: props.account.planId === config.stripePriceIdConfig.StandardMonthly ? "2px solid #F171AB" : ""}}>
                                            <h2>STANDARD Monthly</h2>
                                            <p className='boxBodyText'>Best for mid-sized businesses that receives a
                                                consistent amount of monthly commissions/orders.</p>
                                            <h3>{config.stripePriceConfig.StandardMonthly}</h3>
                                            <p className='billingText'>Billed monthly</p>
                                            <p className='billingText'>60 commissions per month</p>
                                            <button
                                                disabled={props.account.planId === config.stripePriceIdConfig.StandardMonthly}
                                                className={props.account.planId === config.stripePriceIdConfig.StandardMonthly ? "disabledBtn" : "blueBtn"}
                                                type="button"
                                                style={{width: '100%', margin: '10px 0px 0px'}}
                                                onClick={() => checkout(config.stripePriceIdConfig.StandardMonthly)}
                                            >{props.account.planId === "" ? "Select Plan" : "Update Plan"}
                                            </button>
                                        </div>
                                        <div className="plan-box"
                                             style={{border: props.account.planId === config.stripePriceIdConfig.StandardYearly ? "2px solid #F171AB" : ""}}>
                                            <h2>STANDARD Yearly</h2>
                                            <p className='boxBodyText'>Best for mid-sized businesses that receives a
                                                fluctuating amount of monthly commissions/orders.</p>
                                            <h3>{config.stripePriceConfig.StandardYearly}</h3>
                                            <p className='billingText'>Billed annually</p>
                                            <p className='billingText'>800 commissions per year</p>
                                            <button
                                                disabled={props.account.planId === config.stripePriceIdConfig.StandardYearly}
                                                className={props.account.planId === config.stripePriceIdConfig.StandardYearly ? "disabledBtn" : "blueBtn"}
                                                type="button"
                                                style={{width: '100%', margin: '10px 0px 0px'}}
                                                onClick={() => checkout(config.stripePriceIdConfig.StandardYearly)}
                                            >{props.account.planId === "" ? "Select Plan" : "Update Plan"}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="box-row">
                                        <div className="plan-box"
                                             style={{border: props.account.planId === config.stripePriceIdConfig.ProMonthly ? "2px solid #F171AB" : ""}}>
                                            <h2>PRO Monthly</h2>
                                            <p className='boxBodyText'>Best for big businesses that receives a
                                                consistent amount of monthly commissions/orders.</p>
                                            <h3>{config.stripePriceConfig.ProMonthly}</h3>
                                            <p className='billingText'>Billed monthly</p>
                                            <p className='billingText'>90 commissions per month</p>
                                            <button
                                                disabled={props.account.planId === config.stripePriceIdConfig.ProMonthly}
                                                className={props.account.planId === config.stripePriceIdConfig.ProMonthly ? "disabledBtn" : "blueBtn"}
                                                type="button"
                                                style={{width: '100%', margin: '10px 0px 0px'}}
                                                onClick={() => checkout(config.stripePriceIdConfig.ProMonthly)}
                                            >{props.account.planId === "" ? "Select Plan" : "Update Plan"}
                                            </button>
                                        </div>
                                        <div className="plan-box"
                                             style={{border: props.account.planId === config.stripePriceIdConfig.ProYearly ? "2px solid #F171AB" : ""}}>
                                            <h2>PRO Yearly</h2>
                                            <p className='boxBodyText'>Best for big businesses that receives a
                                                fluctuating amount of monthly commissions/orders.</p>
                                            <h3>{config.stripePriceConfig.ProYearly}</h3>
                                            <p className='billingText'>Billed annually</p>
                                            <p className='billingText'>1200 commissions per year</p>
                                            <button
                                                disabled={props.account.planId === config.stripePriceIdConfig.ProYearly}
                                                className={props.account.planId === config.stripePriceIdConfig.ProYearly ? "disabledBtn" : "blueBtn"}
                                                type="button"
                                                style={{width: '100%', margin: '10px 0px 0px'}}
                                                onClick={() => checkout(config.stripePriceIdConfig.ProYearly)}
                                            >{props.account.planId === "" ? "Select Plan" : "Update Plan"}
                                            </button>
                                        </div>
                                    </div>
                                    <p className='footnote'>For more detailed information, please visit our <a
                                        href="https://commcomm.app/pricing-tier" className='hyperlink'>Pricing
                                        Page</a>.</p>
                                </div>
                            </div>
                            <div className="edit-profile-deactivation" style={{border: "none"}}>
                                <label>Cancel Plan Renewal</label>
                                <p>Please be aware that a cancellation will allow you to retain the features of your
                                    subscription until the expiration date.
                                    After reaching the expiration date we will revert your paid tiered account back to a
                                    free tiered
                                    account. Paid features will no longer be available to you.</p>
                                <button className='redBtn' type="button"
                                        style={{width: 'fit-content', marginTop: '10px'}}
                                        onClick={() => {
                                            props.billingSubscription();
                                            handleRedirectModalShow(true);
                                        }}>Cancel Renewal
                                </button>
                            </div>
                        </div>
                        <Modal
                            show={showCancelRenewlModal}
                            onHide={handleCancelRenewalSurveyClose}
                            keyboard={false}
                        >
                            <div className={"create-client-popup-background"}>
                                <Modal.Header className="text-center" closeButton>
                                    <Modal.Title className="create-client-header w-100">Help Us Improve!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="cancelFeedbackForm" className="form-design">
                                        <div className='feedbackFormColumnWrapper'>
                                            <p className='formDescriptionText'>{`Please inform us of why you're canceling
                                                the renewal of your plan by filling out this short,
                                                anonymous form. As a small team, we appreciate your feedback and aim to
                                                ensure that everyone's voice is acknowledged.`}</p>
                                            <div className="innerFeedbackFormColumn">
                                                <label className='create-client-modal-label-subheader-one'
                                                       htmlFor="clientFirstName">
                                                    Reason for plan cancellation:
                                                    <span style={{color: '#FF3347'}}>*</span></label>
                                                <Form.Select className="generalFormTypeSelection" style={{width: "40%"}}
                                                             aria-label="Select feedback type"
                                                             onChange={(e) => {
                                                                 // setFeedbackType(e.target.value)
                                                             }}>
                                                    <option value="">Choose a reason</option>
                                                    <option value="1">I think a different plan would work better for
                                                        me.
                                                    </option>
                                                    <option value="2">I found an alternative website/solution.</option>
                                                    <option value="3">{`I'm taking a break from commissions.`}</option>
                                                    <option value="4">{`I'm shutting my business down.`}</option>
                                                    <option value="5">{`I'm rebranding and I want a fresh start with a new
                                                        account.`}
                                                    </option>
                                                    <option value="6">{`I'm not finding the website as useful as I thought
                                                        I would.`}
                                                    </option>
                                                    <option value="7">Other</option>
                                                </Form.Select>
                                            </div>
                                            <div className="innerFeedbackFormColumn" style={{marginBottom: "0px"}}>
                                                <label className='create-client-modal-label-subheader-one'
                                                       htmlFor="clientFirstName">
                                                    Additional Comments:
                                                    <span style={{color: '#FF3347'}}>*</span></label>
                                                <textarea
                                                    className="feedbackDescriptionInput form-control"
                                                    id='cancelFeedbackDescription'
                                                    // value={feedback}
                                                    onChange={(e) => {
                                                        // setFeedback(e.target.value)
                                                    }}
                                                    maxLength={1000}
                                                    rows={4}
                                                    cols={50}
                                                ></textarea>
                                            </div>
                                            <div className="innerFeedbackFormColumn" style={{marginBottom: "0px"}}>
                                                <button form="feedbackForm"
                                                        className='redBtn'
                                                        onClick={() => props.billingSubscription()}>
                                                    Submit & Cancel Renewal
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                            </div>
                        </Modal>
                        <ModalRedirectToStripeMessage account={props.account} isOpen={showRedirectModal}
                                                      toggleShow={handleRedirectModalShow}
                                                      cancel={handleRedirectModalClose}
                                                      redirectUrl={props.redirectUrl}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageSubscription;