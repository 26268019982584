import React from 'react';

interface IProgressBarProps {
    currentColumnIndex: number;
    columnNames: string[];
    segmentValue: number;
}

//https://react-bootstrap.github.io/components/progress/#example
//https://codesandbox.io/s/progresbar01-m28f0?file=/src/App.tsx:79-94
export const CardProgressBar: React.FunctionComponent<IProgressBarProps> = (props) => {

    // const progressBarStyle = (position: number) => {
    //     switch (position) {
    //         case 0:
    //             return "project-progress-bar-first";
    //         case props.columnNames.length - 1:
    //             return "project-progress-bar-last";
    //         default:
    //             return "project-progress-bar"
    //     }
    // }

    return (
        <div className="progress-segment">
            {props.columnNames && props.columnNames.map((name, index) => {
                return (index <= props.currentColumnIndex ?
                        <div key={`${name}_${index}`} className="item filled-segment"><span>{name}</span></div> :
                        <div key={`${name}_${index}`} className="item"><span>{name}</span></div>
                )
            })}
        </div>
    );
};