import {ProjectData} from "./ProjectData";
import {ColumnDto} from "./ColumnDto";
import {CardDto} from "./CardDto";
import {Tag} from "./Tag";
import {Card} from "./Card";
import {Column} from "./Column";

export class ProjectDetails extends ProjectData {
    project: ProjectData = new ProjectData();
    columnDetails: ColumnDto[] = [];

    public constructor(project: ProjectData, columns: Column[], cards: Card[], tags: Tag[]) {
        super(project);
        let orderedColumn = project.columnOrder.map((colId) => {
            return columns.find(x => x.id === colId)
        })
        this.columnDetails = orderedColumn.map(x => new ColumnDto(x as Column, []))
        this.columnDetails.forEach((col) => {
            let cs = col.cardOrder.map((cardId) => {
                let card = cards.find(c => c.id === cardId);
                if (card != null) {
                    let ts = card.tags.map((tagId) => {
                        return tags.find(t => t.id === tagId)
                    })
                    let cardDetail = new CardDto(card, [])
                    if (ts !== undefined) {
                        cardDetail.tagDetails = ts as Tag[];
                    }
                    return cardDetail
                } else {
                    return null
                }
            })
            col.cardDtos = cs as CardDto[];
        })
    }
}