import React, {useState} from "react";
import {Collapse, Paper} from "@mui/material";
import AddItem from "./AddItem";

interface AddCommissionProps {
    addItem: (text: string) => void;
    type: string;
    isHidden: boolean;
}

const AddCommission: React.FunctionComponent<AddCommissionProps> = (props) => {
    const [show, setShow] = useState(false);

    return (
        <div hidden={props.isHidden}>
            <Collapse in={show}>
                <AddItem setShow={setShow} addItem={props.addItem} type={props.type}></AddItem>
            </Collapse>
            <Collapse in={!show}>
                <Paper style={{backgroundColor: "#FFF4D2"}}
                       elevation={0}
                       onClick={() => setShow(!show)}>
                    <a className='addCommissionBtn'>+ Add Commission</a>
                </Paper>
            </Collapse>
        </div>
    );
}

export default AddCommission