import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import CommissionArchives from '../assets/UserGuideImages/Commissions-Archive.png';
import ProjectArchives from '../assets/UserGuideImages/Projects-Archive.png';
import ClientArchives from '../assets/UserGuideImages/Clients-Archive.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideArchiveBoards {

}

const UserGuideArchiveBoards: React.FC<IUserGuideArchiveBoards> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideArchiveBoardsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuideArchiveBoardsCommissionArchivesRef = useRef<HTMLDivElement | null>(null);
    const userGuideArchiveBoardsProjectArchivesRef = useRef<HTMLDivElement | null>(null);
    const userGuideArchiveBoardsClientArchivesRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "commissionArchives":
                        userGuideArchiveBoardsCommissionArchivesRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "projectArchives":
                        userGuideArchiveBoardsProjectArchivesRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "clientArchives":
                        userGuideArchiveBoardsClientArchivesRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuideArchiveBoards)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideArchiveBoardsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideArchiveBoardsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('commissionArchives')}>1. Commission Archives</li>
                                <li onClick={() => scrollToSection('projectArchives')}>2. Project Archives</li>
                                <li onClick={() => scrollToSection('clientArchives')}>3. Client Archives</li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Archive Boards</h1>
                        <p className='biggerText'>Archive boards serve as a designated space to store completed
                            commissions, as well as unused projects and clients.</p>

                        <section id="commissionArchives" ref={userGuideArchiveBoardsCommissionArchivesRef}>
                            <h2>1. Commission Archives</h2>
                            <img src={CommissionArchives} className='smallImg'
                                 alt="user guide archive boards commission archives"/>
                            <p>The <strong>Commission Archives</strong>{` is where archived commissions go after being
                                completed. All commission cards are disabled, allowing only viewing and reading access.
                                The commission cards are arranged into columns based on month ranges. A commission
                                card's placement is determined by their start date. The board's commission cards can be
                                filtered based on year as well.`}</p>
                        </section>

                        <section id="projectArchives" ref={userGuideArchiveBoardsProjectArchivesRef}>
                            <h2>2. Project Archives</h2>
                            <img src={ProjectArchives} className='smallImg'
                                 alt="user guide archive boards project archive"/>
                            <p>The <strong>Project Archives</strong> is where archived projects go. All projects can
                                always be sent back to
                                the <Link className='hyperlink' to={navPath.userGuideProjectBoards}>Project
                                    Board</Link> by clicking their <strong>{`"Unarchive"`}</strong>{` button.
                                Projects are arranged into columns based on alphabetical ranges. A project's placement
                                is determined by the first letter in their title.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Any project with special characters in
                                    their title will be sent to the <strong>{`"Other Projects"`}</strong> column.</p>
                            </div>
                        </section>

                        <section id="clientArchives" ref={userGuideArchiveBoardsClientArchivesRef}>
                            <h2>3. Client Archives</h2>
                            <img src={ClientArchives} className='smallImg'
                                 alt="user guide archive boards client archives"/>
                            <p>The <strong>Client Archives</strong> is where archived clients go. All clients can always
                                be sent back to
                                the <Link className='hyperlink' to={navPath.userGuideClientBoards}>Client
                                    Board</Link> by clicking their <strong>{`"Unarchive"`}</strong>{` button.
                                Clients are arranged into columns based on alphabetical ranges. A client's placement is
                                determined by their last name.`}</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> Any client with special characters in
                                    their name will be sent to the <strong>{`"Other Clients"`}</strong> column.</p>
                            </div>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideArchiveBoards;