import React from "react";
import HelpDropDown from "../Base/HelpDropDown";
import {DateTime} from "../../pages/ProjectWorkflowBoard";

interface IClientOverviewTopBar {
    firstName: string;
    lastName: string;
    activeCommissions: number;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
    isDeactivated: boolean;
}

const ClientOverviewTopBar: React.FunctionComponent<IClientOverviewTopBar> = (props) => {
    return (
        <div className="clientOverviewTopBar">
            <div className="clientMarkerWrapper">
                <div className='dateTimeMarker'>
                    <DateTime></DateTime>
                </div>
                <div className='currentClientMarker'>
                    <p>
                        {props.firstName} {props.lastName}
                    </p>
                </div>
                <div className='totalCardsMarkerClientTab'>
                    <p>Total Active Commissions: {props.activeCommissions}</p>
                </div>
                {props.isDeactivated && <div className='totalCardsMarkerClientTab'>
                    <p>Account DEACTIVATED</p>
                </div>}
            </div>

            <div className='projectTopBarBtnWrapper'>
                <HelpDropDown
                    setFeedbackConfirmation={props.setFeedbackConfirmation}
                    feedbackConfirmation={props.feedbackConfirmation}
                    submitFeedback={props.submitFeedback}/>
            </div>
        </div>
    );
}

export default ClientOverviewTopBar;