import React from 'react';
import LoadingOverlay from "../components/Home/LoadingOverlay";

interface IDefaultNoContent {
    loading: boolean;
}

const DefaultNoContent: React.FC<IDefaultNoContent> = (props) => {

    return (
        <>
            <div className="default-no-content-background">

            </div>
            <LoadingOverlay isLoading={props.loading}/>
        </>
    );
};

export default DefaultNoContent;