import {AggregateBase} from "./AggregateBase";

export class Client extends AggregateBase {
    accountId: string = "";
    firstName: string = "";
    lastName: string = "";
    phoneNumber: string = "";
    email: string = "";
    iconColor: string = "";
    iconBackgroundColor: string = "";
    iconImage: string = "";
    notes: string = "";
    quantity: number = 0;
    updatedByClient: string = "";
    receiveEmailNotification: boolean = false
    receivePhoneNumberNotification: boolean = false
    isArchived: boolean = false;
    archiveDate: string | null = null;

    public constructor(init?: Partial<Client>) {
        super();
        Object.assign(this, init);
    }
}