import React from "react";
import {AiOutlineProject} from "react-icons/ai";
import {RiFolderUserLine} from 'react-icons/ri'

interface ITabItem {
    onSelectProjects: () => void,
    onSelectClients: () => void,
    isProjectTabSelected: boolean,
    isClientTabSelected: boolean
}

//https://stackoverflow.com/questions/28365233/inline-css-styles-in-react-how-to-implement-ahover
const TabItem: React.FunctionComponent<ITabItem> = (props: ITabItem) => {

    return (
        <div className="navBarToggleSlider">
            <input type="radio" id="projects" name="tabs" checked={props.isProjectTabSelected} readOnly/>
            <label className="navTab" htmlFor="projects"
                   onClick={() => props.onSelectProjects()}><AiOutlineProject/> Projects</label>
            <input type="radio" id="clients" name="tabs" checked={props.isClientTabSelected} readOnly/>
            <label className="navTab" htmlFor="clients"
                   onClick={() => props.onSelectClients()}><RiFolderUserLine/> Clients</label>
            <span className="navGlider"></span>
        </div>

        // <div className={"project-tab"}>
        //     <div onClick={() => props.onSelectProjects()} style={{backgroundColor: props.isProjectTabSelected ? "#15A6DF" : "", borderColor: props.isProjectTabSelected ? "#15A6DF": "#511863"}}>
        //         <span className={"project-tab-border nav-tab-text"} ><GiNotebook/> Projects</span>
        //     </div>
        //     <div onClick={() => props.onSelectClients()} style={{backgroundColor: props.isClientTabSelected ? "#15A6DF" : "", borderColor: props.isClientTabSelected ? "#15A6DF": "#511863"}}>
        //         <span className={"project-tab-border nav-tab-text"} ><RiFolderUserLine/> Clients</span>
        //     </div>
        // </div>
    );
}

export default TabItem;