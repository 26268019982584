import React, {useState} from "react";
import {Client} from "../../../data/Client";
import ListItem from "../../Base/HorizontalList/ListItem/ListItem";
import {EditClientPopupModal} from "../../ClientsOverviewBoard/Popup/EditClientPopupModal";
import {colorPicker} from "../../Helpers";
import {SVGIconList} from "../../Base/SVGPicker/SVGIconList";


export interface IListClientItem {
    id: string;
    client: Client;
    selectedClient: string;
    editClient: (id: string, firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    archiveClient: (clientId: string) => void;
}

//https://stackoverflow.com/questions/28365233/inline-css-styles-in-react-how-to-implement-ahover
const ListClientItem: React.FunctionComponent<IListClientItem> = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true)
    };

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <ListItem
                id={props.id}
                selectedItem={props.selectedClient}
                title={props.client.lastName + ", " + props.client.firstName}
                // image="https://bobbyhadz.com/images/blog/react-prevent-multiple-button-clicks/thumbnail.webp"
                toggleShow={handleShow}
                quantity={props.client.quantity}
                icon={SVGIconList.filter(x => x.title === props.client.iconImage)[0] ?? SVGIconList[0]}
                iconColor={props.client.iconColor ?? colorPicker.defaultBlue}
                iconBackgroundColor={props.client.iconBackgroundColor ?? colorPicker.commCommPink}
            />
            <EditClientPopupModal show={show} handleClose={handleClose} id={props.id} firstName={props.client.firstName}
                                  lastName={props.client.lastName} email={props.client.email}
                                  phoneNumber={props.client.phoneNumber} editClient={props.editClient}
                                  icon={SVGIconList.filter(x => x.title === props.client.iconImage)[0] ?? SVGIconList[0]}
                                  iconColor={props.client.iconColor ?? colorPicker.defaultBlue}
                                  iconBackgroundColor={props.client.iconBackgroundColor ?? colorPicker.commCommPink}
                                  archiveClient={props.archiveClient}
            />
        </>
    );
}

export default ListClientItem;