import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {colorPicker} from "../../Helpers";

interface IFeedback {
    show: boolean;
    onHide: () => void;
    submitFeedback: (text: string, type: string) => void
}

export const Feedback: React.FC<IFeedback> = (props) => {
    const [feedbackText, setFeedbackText] = useState("");
    const [feedbackSelection, setFeedbackSelection] = useState("0");

    const validateFields = () => {
        if (feedbackText.length > 0 && feedbackSelection !== "0") {
            props.submitFeedback(feedbackText, feedbackSelection)
            setFeedbackText("");
            setFeedbackSelection("");
        }
    }


    return (<div>
        <Modal
            show={props.show}
            onHide={props.onHide}
            keyboard={false}
        >
            <div className={"create-client-popup-background"}>
                <Modal.Header className="text-center" closeButton>
                    <Modal.Title className="create-client-header w-100">Help Us Improve!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="feedbackForm" className="form-design">
                        <div className="feedbackFormColumnWrapper">
                            <p className="formDescriptionText">Did you find a bug or have a suggestion? Let us know
                                by filling out this short, anonymous form.</p>
                            <div className="innerFeedbackFormColumn">
                                <label className="create-client-modal-label-subheader-one"
                                       htmlFor="clientFirstName">
                                    Type of feedback:
                                    <span style={{color: colorPicker.errorRed}}>*</span></label>
                                <Form.Select className="generalFormTypeSelection" aria-label="Select feedback type"
                                             onChange={(e) => setFeedbackSelection(e.target.value)}>
                                    <option value="0">Choose a feedback type</option>
                                    <option value="1">Website Bug Report</option>
                                    <option value="2">Feature Suggestion</option>
                                    <option value="3">Other</option>
                                </Form.Select>
                            </div>
                            <div className="innerFeedbackFormColumn" style={{marginBottom: "0px"}}>
                                <label className="create-client-modal-label-subheader-one"
                                       htmlFor="clientFirstName">
                                    Description:
                                    <span style={{color: colorPicker.errorRed}}>*</span></label>
                                <textarea
                                    className="feedbackDescriptionInput form-control"
                                    id="feedbackDescription"
                                    value={feedbackText}
                                    onChange={(e) => setFeedbackText(e.target.value)}
                                    maxLength={1000}
                                    rows={4}
                                    cols={50}
                                ></textarea>
                            </div>
                            <div className="innerFeedbackFormColumn" style={{marginBottom: "0px"}}>
                                <button form="feedbackForm"
                                        disabled={feedbackSelection === "" || feedbackText === "0"}
                                        type="button"
                                        className={feedbackSelection === "" || feedbackText === "0" ? "disabledBtn" : "blueBtn"}
                                        onClick={validateFields}>
                                    Submit Feedback
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>
    </div>)
};