import React, {useEffect, useState} from 'react'
import {Form, Modal} from "react-bootstrap";
import "../../../scss/EditProjectPopupModal.css";
import BackgroundPreview from '../../../assets/background-preview-overlay.png';
import {SVGIconType} from "../../Base/SVGPicker/SVGIconType";
import {SVGIconPicker} from "../../Base/SVGPicker/SVGIconPicker";
import {backgroundImages} from "../../Helpers";

interface IEditProjectPopupModal {
    editProject: (id: string, title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    handleClose: () => void;
    show: boolean;
    image: string;
    icon: SVGIconType;
    color: string;
    title: string;
    backgroundColor: string;
    id: string;
    archiveProject: (projectId: string) => void;
}

export const EditProjectPopupModal: React.FunctionComponent<IEditProjectPopupModal> = (props) => {
    const [image, setImage] = useState(props.image);
    const [icon, setIcon] = useState<SVGIconType>(props.icon);
    const [color, setColor] = useState(props.color);
    const [projectTitle, setProjectTitle] = useState("");
    const [validProjectTitle, setValidProjectTitle] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);

    useEffect(() => {
        if (props.show) {
            setImage(props.image);
            setIcon(props.icon);
            setColor(props.color);
            setProjectTitle(props.title);
            setBackgroundColor(props.backgroundColor)
        }
    }, [props.show])

    const onIconChange = (icon: SVGIconType) => {
        setIcon(icon);
    }

    const handleArchive = () => {
        props.archiveProject(props.id)
        props.handleClose();
    };

    const handleClose = () => {
        props.handleClose();
        setValidProjectTitle(true);
    };

    const handleProjectTitle = (input: string) => {
        if (new RegExp(/^[a-zA-Z .-]{1,51}$/).test(input)) {
            setValidProjectTitle(true);
        } else {
            setValidProjectTitle(false);
        }
        setProjectTitle(input);
    };

    const handleSubmit = () => {
        let title = true;
        if (!(validProjectTitle && projectTitle.length > 0)) {
            title = false;
            setValidProjectTitle(false);
        }
        if (title) {
            props.editProject(props.id, projectTitle, icon.title, color, backgroundColor, image);
            props.handleClose();
        }
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                keyboard={false}
            >
                <div className={"edit-project-popup-background"}>
                    <Modal.Header className="text-center" closeButton aria-label="Hide">
                        <Modal.Title className="edit-project-header w-100">{projectTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="editProjectForm" className="edit-project-form">
                            <div className='projectBackgroundColumn'>
                                <label className='edit-project-modal-label'>Project Background</label>
                                {image && <div className="preview-image-wrapper">
                                    <img src={BackgroundPreview} className="background-preview-overlay"/>
                                    <img src={image} className="preview-image"/>
                                </div>}

                                <div className="background-image-gallery">
                                    {backgroundImages &&
                                        backgroundImages.map((img, index) => {
                                            return (
                                                <div key={img.id} className="bg-image"
                                                     onClick={() => setImage(img.img)}>
                                                    <img src={img.thumb} alt="upload"/>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="projectIconWrapperColumn">
                                <label className='edit-project-modal-label'>Project Icon</label>
                                <p className="featureNote" style={{marginBottom: "0px"}}>Set an icon to differentiate
                                    your project.</p>
                                <div className="projectIconRow">
                                    <div className="projectIconColumn">
                                        <label className='edit-project-modal-label'>Icon Image</label>
                                        <SVGIconPicker value={icon} onChange={onIconChange} color={color}
                                                       backgroundColor={backgroundColor}/>
                                    </div>
                                    <div className="projectIconColumn">
                                        <label className='edit-project-modal-label'>Icon BG</label>
                                        <input type="color" id="backgroundColorPicker" className="pickerBox"
                                               value={backgroundColor} aria-describedby="backgroundColorPickerHelp"
                                               onChange={(e) => {
                                                   setBackgroundColor(e.target.value)
                                               }}/>
                                    </div>
                                    <div className="projectIconColumn">
                                        <label className='edit-project-modal-label'>Icon Color</label>
                                        <input type="color" id="colorPicker" className="pickerBox" value={color}
                                               aria-describedby="colorPickerHelp" onChange={(e) => {
                                            setColor(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                            </div>

                            <div className="projectNameColumn">
                                <label style={{color: validProjectTitle ? "black" : "red"}}
                                       className='edit-project-modal-label' htmlFor="projectEditTitle">Project Name<span
                                    style={{color: '#FF3347'}}>*</span></label>
                                <p className="featureNote">Name is required.</p>
                                <input type="title" className="form-control" id="projectEditTitle"
                                       value={projectTitle}
                                       onChange={(e) => {
                                           handleProjectTitle(e.target.value)
                                       }} placeholder="Super Kool Project Title"/>
                                <div className="projectBtnRow">
                                    <button form="editProjectForm" type="button" className="blueBtn"
                                            style={{width: '100%', margin: '15px 5px 0px 0px'}} onClick={(e) => {
                                        // e.preventDefault()
                                        handleSubmit()
                                    }}>Save Project
                                    </button>

                                    <button form="editProjectForm" type="button" className="secondaryBtn"
                                            style={{width: '100%', margin: '15px 4px 0px 0px'}} onClick={(e) => {
                                        handleArchive()
                                    }}>Archive Project
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}