import {CardDto} from "./CardDto";
import {Tag} from "./Tag";
import {Card} from "./Card";
import {Client} from "./Client";

export class ClientDto extends Client {
    client: Client = new Client();
    cardDtos: CardDto[] = [];

    public constructor(client: Client, cards: Card[], tags: Tag[]) {
        super(client);
        let cs = cards.map((card) => {
            let ts = card.tags.map((tagId) => {
                return tags.find(t => t.id === tagId)
            })
            let cardDetail = new CardDto(card, [])
            if (ts !== undefined) {
                cardDetail.tagDetails = ts as Tag[];
            }
            return cardDetail

        })
        this.cardDtos = cs as CardDto[];
    }
}