import React, {PropsWithChildren} from "react";
import {Modal} from "react-bootstrap";
import {IoWarning} from "react-icons/io5";


interface IModalConfirmMessageProps extends PropsWithChildren {
    isOpen: boolean;
    toggleShow: (show: boolean) => void;
    modalTitle: string;
    submitText: string;
    action: () => void;
    cancel: () => void;
    cancelText: string;
}

export const ModalConfirmMessage: React.FunctionComponent<IModalConfirmMessageProps> = ({children, ...props}) => {

    return (
        <div>
            <Modal
                show={props.isOpen}
                onHide={() => props.toggleShow(!props.isOpen)}
            >
                <div className={"modalWarningMessageBackground"}>
                    <Modal.Header closeButton aria-label="Hide" closeVariant="white">
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalWarningMessageContentWrapper">
                            <div className="modalMessageRow">
                                <IoWarning style={{color: "#FFC722", width: "280px"}} size={130}/>
                                <div className='modalWarningMessageColumn'>
                                    <div className="modalWarningMessageHeader">{props.modalTitle}</div>
                                    {children}
                                </div>
                            </div>
                            <div className="modalMessageRow">
                                <button className='secondaryBtn' type="button"
                                        onClick={props.cancel}>{props.cancelText}</button>
                                <button className='blueBtn' type="button" onClick={props.action}
                                        form='stuff'>{props.submitText}</button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}