import React, {useEffect, useState} from 'react';
import Toast from 'react-bootstrap/Toast';
import {BsFillCheckCircleFill} from "react-icons/bs";
import {ClientDto} from "../data/ClientDto";
import RichTextClientNotes from "../components/ClientsOverviewBoard/Notes/RichTextClientNotes";
import {CardDto} from "../data/CardDto";
import {colorPicker, constantList} from "../components/Helpers";
import EditCard from "../components/Base/Popup/EditCard";
import {Card} from "../data/Card";
import {ProjectDetails} from "../data/ProjectDetails";
import ContactInfo from "../components/ClientsOverviewBoard/Notes/ContactInfo";
import ClientOverviewTopBar from "../components/ClientsOverviewBoard/ClientOverviewTopBar";
import {Tag} from "../data/Tag";
import {AccountData} from "../data/AccountData";
import StationaryClientCard from "../components/Base/StationaryCard";
import {ModalWarningMessage} from "../components/Base/Popup/ModalWarningMessage";
import {CardProgressHistoryDetails} from "../data/CardProgressHistoryDetails";
import LoadingOverlay from "../components/Home/LoadingOverlay";
import {ModalCongratsMessage} from "../components/Base/Popup/ModalCongratsMessage";
import ViewArchivedCard from "../components/Base/Popup/ViewArchivedCard";
import {ArchiveCardDetails} from "../data/ArchiveCardDetails";
import {MoreHoriz} from "@mui/icons-material";
import {Menu, MenuItem} from "@mui/material";

interface IClientWorkflowBoard {
    account: AccountData;
    cardsUsed: number;
    cardMax: number;
    selectedClient: ClientDto;
    clients: ClientDto[];
    projects: ProjectDetails[];
    deleteCard: (cardData: Card) => void;
    addTag: (cardData: Card, tag: string, color: string) => void;
    tags: Tag[];
    updateTag: (cardData: CardDto, tagId: string, name: string, color: string) => void;
    addTagOnCard: (cardId: string, tagId: string) => void;
    removeTagOnCard: (cardId: string, tagId: string) => void;
    deleteTag: (tagId: string, projectId: string) => void;
    updateCardNotes: (cardId: string, note: string) => void;
    updateCardTitle: (cardId: string, title: string) => void;
    updateCardQuantity: (cardId: string, count: number) => void;
    updateCardDueDate: (cardId: string, date: string) => void;
    updateCardStartDate: (cardId: string, date: string) => void;
    updateCardColor: (cardId: string, color: string) => void;
    updateClientNotes: (clientId: string, note: string) => void;
    updateClientPhoneNumber: (clientId: string, phoneNumber: string) => void;
    updateClientEmail: (clientId: string, phoneNumber: string) => void;
    saveClient: (cardData: Card, oldClientId: string) => void;
    saveProject: (cardData: Card, oldProjectId: string) => void;
    moveCardViaButton: (card: CardDto, sourceId: string, destinationId: string) => void;
    updateCardComments: (card: Card, comment: string) => void;
    archiveCommission: (card: CardDto) => void;
    showArchiveClientWarning: boolean;
    setShowArchiveClientWarning: (show: boolean) => void;
    cancelArchiveClientWarning: () => void;
    getCardComments: (cardId: string) => void;
    cardComments: CardProgressHistoryDetails[];
    isClientLoading: boolean;
    displayCommission: CardDto;
    selectCard: (card: CardDto) => void;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
    showDoneColumnCongrats: boolean;
    setShowDoneColumnCongrats: (show: boolean) => void;
    moveCardToDoneColumnViaButton: (card: CardDto) => void;
    getClientsArchiveCards: (clientId: string) => void;
    archivedCommission: ArchiveCardDetails;
    isSideNavVisible: boolean;
}

//hold the selected card info in a variables, so you can pass projectId to functions.
const ClientWorkflowBoard: React.FunctionComponent<IClientWorkflowBoard> = (props) => {
    // const {id} = useParams()
    const [client, setClient] = useState<ClientDto>(props.selectedClient);
    const [showCardModal, setShowCardModalCardModal] = useState(false);
    // const [projects, setProjects] = useState<ProjectDetails[]>([]);
    const [notesLength, setNotesLength] = useState(props.selectedClient?.notes?.length ?? 0)
    const [clientNotes, setClientNotes] = useState(props.selectedClient.notes);
    const [showClientNotesSavedConfirmation, setClientNotesSavedConfirmation] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [filterForArchive, setFilterForArchive] = useState(false);

    useEffect(() => {
        setClient(props.selectedClient)
        setClientNotes(props.selectedClient.notes)
        setNotesLength(props.selectedClient?.notes?.length ?? 0)
        props.getClientsArchiveCards(props.selectedClient.id);
        setFilterForArchive(false);
        // if (props.selectedClient.cardDetails.length > 0) {
        //     setCard(props.selectedClient.cardDetails[0])
        // } else {
        //     setCard(new CardDetails(new CardData(), []))
        // }
    }, [props.selectedClient.updatedAt])

    // useEffect(() => {
    //     setProjects(props.projects)
    // }, [props.projects])

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const toggleClientNotesSavedConfirmation = () => setClientNotesSavedConfirmation(!showClientNotesSavedConfirmation);

    const toggleShowCardModal = () => {
        setShowCardModalCardModal(!showCardModal)
    };

    const selectCard = (card: CardDto) => {
        props.selectCard(card);
        toggleShowCardModal();
    };

    const updateClientPhoneNumber = (phoneNumber: string) => {
        props.updateClientPhoneNumber(client.id, phoneNumber)
    }

    const updateClientEmail = (email: string) => {
        props.updateClientEmail(client.id, email)
    }

    const saveClientNotes = () => {
        if (clientNotes !== props.selectedClient.notes && notesLength <= constantList.richTextMaxLength) {
            props.updateClientNotes(client.id, clientNotes);
            setClientNotesSavedConfirmation(true);
            setTimeout(function () {
                setClientNotesSavedConfirmation(false);
            }, 4000);
        }
    }

    // const updateClientNotes = (note: string) => {
    //     if (note !== client?.notes) {
    //         props.updateClientNotes(client.id, note);
    //         setClientNotesSavedConfirmation(true);
    //         setTimeout(function () {
    //             setClientNotesSavedConfirmation(false);
    //         }, 4000);
    //     }

    return (
        <>
            <div className="App">
                <div className='clientBoardImgBg'>
                    <div className="clientOverview" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <Toast show={showClientNotesSavedConfirmation} onClose={toggleClientNotesSavedConfirmation}>
                            <Toast.Header closeButton aria-label="Hide" closeVariant="white">
                                <BsFillCheckCircleFill size={23} color={colorPicker.confirmationGreen}/>
                                <strong className="savedConfirmationText">Your client notes have been saved!</strong>
                            </Toast.Header>
                        </Toast>
                        <ClientOverviewTopBar
                            firstName={props.selectedClient.firstName}
                            lastName={props.selectedClient.lastName}
                            activeCommissions={props.selectedClient.quantity}
                            submitFeedback={props.submitFeedback}
                            feedbackConfirmation={props.feedbackConfirmation}
                            setFeedbackConfirmation={props.setFeedbackConfirmation}
                            isDeactivated={props.account.isDeactivated}
                        />
                        <ModalCongratsMessage
                            isOpen={props.showDoneColumnCongrats}
                            modalTitle={"Congratulations!"}
                            toggle={props.setShowDoneColumnCongrats}>
                            <p>{`We're proud of you for completing this commission.`}</p>
                        </ModalCongratsMessage>
                        <div className="client-overview-content-wrapper">
                            <div className="clientCommissionColumn">
                                <div className="clientCommissionColumn-title">
                                    {filterForArchive ? "Archive Commissions" : "Active Commissions"}
                                    <div style={{paddingRight: "5px"}} onClick={(event) => {
                                        handleClick(event)
                                    }}><MoreHoriz/>
                                    </div>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setFilterForArchive(!filterForArchive)
                                            handleClose()
                                        }}>{!filterForArchive ? "Archive Commissions" : "Active Commissions"}</MenuItem>
                                    </Menu>
                                </div>
                                <div className="card-wrapper">
                                    {props.selectedClient.cardDtos && props.selectedClient.cardDtos
                                        //filter by active and archive commissions
                                        .filter((x) => x.isArchived === filterForArchive)
                                        //sort by non archive first then most recently updated
                                        .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
                                        .map((cardDetail: CardDto, index) => (
                                            <div key={cardDetail.id}>
                                                <StationaryClientCard card={cardDetail}
                                                                      projectTitle={props.projects[props.projects.findIndex((p) => p.id === cardDetail.projectId)]?.title ?? "Archived"}
                                                                      selectedCard={props.displayCommission?.id ?? ""}
                                                                      selectCard={selectCard}/>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            {props.displayCommission && !props.displayCommission.isArchived ? <EditCard
                                account={props.account}
                                columnId={props.displayCommission.columnId}
                                isOpen={showCardModal}
                                toggleShowCardModal={toggleShowCardModal}
                                card={props.displayCommission}
                                deleteCard={props.deleteCard}
                                tags={props.tags}
                                addTag={props.addTag}
                                updateTag={props.updateTag}
                                addTagOnCard={props.addTagOnCard}
                                removeTagOnCard={props.removeTagOnCard}
                                deleteTag={props.deleteTag}
                                updateCardTitle={props.updateCardTitle}
                                updateCardQuantity={props.updateCardQuantity}
                                updateCardDueDate={props.updateCardDueDate}
                                updateCardStartDate={props.updateCardStartDate}
                                updateCardColor={props.updateCardColor}
                                updateCardNotes={props.updateCardNotes}
                                projects={props.projects}
                                clients={props.clients}
                                saveProject={props.saveProject}
                                saveClient={props.saveClient}
                                updateCardComments={props.updateCardComments}
                                moveCardViaButton={props.moveCardViaButton}
                                archiveCommission={props.archiveCommission}
                                moveCardToDoneColumnViaButton={props.moveCardToDoneColumnViaButton}
                                getCardComments={props.getCardComments}
                                cardComments={props.cardComments}
                            /> : <ViewArchivedCard
                                account={props.account}
                                isOpen={showCardModal}
                                toggleShowCardModal={toggleShowCardModal}
                                card={props.archivedCommission}
                                getCardComments={props.getCardComments}
                                cardComments={props.cardComments}
                            />}
                            <div className="clientOverviewFlexColumn">
                                <div style={{minWidth: "447px", maxWidth: "447px"}}>
                                    <RichTextClientNotes
                                        title={"Client Notes"}
                                        text={clientNotes}
                                        updateNotes={setClientNotes}
                                        setNotesLength={setNotesLength}
                                        notesLength={notesLength}
                                        acctSubscription={props.account.acctSubscriptionPlan}
                                        headerColor={colorPicker.commCommPink}/>
                                </div>
                                <button className="blueBtn" type="button" style={{width: '96%'}}
                                    // disabled={client.cardDetails.length === 0}
                                        onClick={saveClientNotes}>Save Client Notes
                                </button>
                            </div>

                            <ContactInfo
                                phoneNumber={props.selectedClient.phoneNumber} email={props.selectedClient.email}
                                emailNotification={props.selectedClient.receiveEmailNotification}
                                phoneNumberNotification={props.selectedClient.receivePhoneNumberNotification}
                                updatePhone={updateClientPhoneNumber}
                                updateEmail={updateClientEmail}
                            />
                            <ModalWarningMessage
                                isOpen={props.showArchiveClientWarning}
                                cancel={props.cancelArchiveClientWarning}
                                cancelText={"OK"}
                                modalTitle={"Archive Client Error"}
                                toggle={props.setShowArchiveClientWarning}>
                                <p>Your client still has active commissions attached to it. You can only archive clients
                                    with no active commissions.</p>
                            </ModalWarningMessage>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingOverlay isLoading={props.isClientLoading}/>
        </>
    );
}

export default ClientWorkflowBoard;
