import {cancelTokenSource, requests} from './base/http-common'
import {AccountData} from "../data/AccountData";
import {DeactivationFeedback} from "../data/DeactivationFeedback";
import {AccountDto} from "../data/AccountDto";
//https://javascript.plainenglish.io/frontend-api-calls-with-typescript-and-axios-68792d1e63c2
//https://www.youtube.com/watch?v=Oq_5t64_Yhs&ab_channel=gamingstar
class AccountDataSource {

    BASEURL = "account"

    Post = {
        create: (email: string, username: string, firstName: string, lastName: string, referralCode: string): Promise<AccountData> =>
            requests.post(`${this.BASEURL}`, new AccountDto(
                    email,
                    username,
                    firstName,
                    lastName,
                    referralCode
                ), cancelTokenSource.token
            )
    };

    Get = {
        fromId: (id: string): Promise<AccountData> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        fromEmail: (email: string): Promise<AccountData> => requests.get(`${this.BASEURL}/email/${email}`, cancelTokenSource.token),
        fromUsername: (username: string): Promise<AccountData> => requests.get(`${this.BASEURL}/uname/${username}`, cancelTokenSource.token),
        verifyEmail: (email: string): Promise<boolean> => requests.get(`${this.BASEURL}/vmail/${email}`, cancelTokenSource.token),
        verifyUsername: (username: string): Promise<boolean> => requests.get(`${this.BASEURL}/vname/${username}`, cancelTokenSource.token),
    };

    Update = {
        basic: (acct: AccountData): Promise<AccountData> =>
            requests.put(`${this.BASEURL}/basic`, new AccountData({
                    id: acct.id,
                    firstName: acct.firstName,
                    lastName: acct.lastName,
                    biography: acct.biography,
                    shopLink: acct.shopLink,
                    image: acct.image,
                    phoneNumber: acct.phoneNumber,
                    socialMedia1: acct.socialMedia1,
                    socialMedia2: acct.socialMedia2,
                    socialMedia3: acct.socialMedia3,
                    venmoHandle: acct.venmoHandle,
                    username: acct.username,
                    displayRealName: acct.displayRealName
                }), cancelTokenSource.token
            ),
        deactivate: (id: string, comment: string, reason: number): Promise<boolean> =>
            requests.put(`${this.BASEURL}/deactivate`, new DeactivationFeedback({
                    accountId: id,
                    comment: comment,
                    reasonType: reason,
                }), cancelTokenSource.token
            ),
        reactivate: (id: string): Promise<boolean> =>
            requests.put(`${this.BASEURL}/reactivate`, new AccountData({
                    id: id,
                }), cancelTokenSource.token
            ),
    };

    Delete = {};
}

export default new AccountDataSource();