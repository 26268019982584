const UseToken = () => {
    // const getToken = () => {
    //     const tokenString = localStorage.getItem('credentials') ?? ""
    //     return tokenString
    // }

    // const [token, setToken] = useState(getToken())
    const saveToken = (userToken: string) => {
        sessionStorage.setItem('credentials', userToken)
        // setToken(userToken)
    }

    const saveRefreshToken = (refreshToken: string) => {
        sessionStorage.setItem('refresh', refreshToken)
        // setToken(refreshToken)
    }
    return {
        setToken: saveToken,
        setRefreshToken: saveRefreshToken,
        // token
    }
}

export default UseToken