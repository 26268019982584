import {AggregateBase} from "./AggregateBase";

export class ContactUs extends AggregateBase {
    name: string = "";
    email: string = "";
    message: string = "";
    subjectType: number = 0;

    public constructor(init?: Partial<ContactUs>) {
        super();
        Object.assign(this, init);
    }
}