import React from 'react';
import DoneBalloons from '../assets/done-balloons.jpg';


interface ICommCommAssets {

}

const CommCommAssets: React.FC<ICommCommAssets> = (props) => {

    return (
        <div className="App">
            <img src={DoneBalloons} alt="Done Balloons"/>
        </div>
    );
};

export default CommCommAssets;