import React, {useState} from "react";
import {AiFillEdit} from 'react-icons/ai'
import {SVGIconType} from "../../SVGPicker/SVGIconType";


interface IListItem {
    id: string;
    selectedItem: string;
    title: string;
    quantity: number;
    toggleShow: () => void;
    icon: SVGIconType;
    iconColor: string;
    iconBackgroundColor: string;
}

const ListItem: React.FunctionComponent<IListItem> = (props) => {
    const [hover, setHover] = useState(false);

    return (
        <>
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={props.id === props.selectedItem ? "nav-list-item-selected" : "nav-list-item"}>
                <div className={"nav-list-item-icon"} style={{backgroundColor: `${props.iconBackgroundColor}`}}>
                    {/*<IconContext.Provider value={{color: props.iconColor, size: '20px'}}>*/}
                    {/*    {React.createElement(props.icon.icon)}*/}
                    {/*</IconContext.Provider>*/}
                    <props.icon.icon width='20px' height='20px' fill={props.iconColor}/>
                </div>
                <span className={"nav-list-item-font"} title={props.title}>{props.title}</span>
                <span className={"nav-edit"} style={{visibility: hover ? "visible" : "hidden"}}
                      onClick={() => props.toggleShow()}><AiFillEdit className={"nav-edit"}/></span>
                <span className={"nav-list-item-quantity"}>{props.quantity}</span>
            </div>
        </>
    );
}

export default ListItem;