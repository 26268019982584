import React, {useState} from 'react'
import {Form, Modal} from "react-bootstrap";
import "../../../scss/ProjectPopupModal.css";
import BackgroudPreview from '../../../assets/background-preview-overlay.png';
import {AccountSubscriptionPlan, backgroundImages, colorPicker} from '../../Helpers';
import {SVGIconPicker} from '../../Base/SVGPicker/SVGIconPicker';
import {SVGIconType} from '../../Base/SVGPicker/SVGIconType';
import {SVGIconList} from '../../Base/SVGPicker/SVGIconList';
import {ProjectDetails} from "../../../data/ProjectDetails";

interface IProjectPopupModal {
    createProject: (title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void,
    projects: ProjectDetails[];
    acctSubscription: number
}

export const CreateProjectPopupModal: React.FunctionComponent<IProjectPopupModal> = (props) => {
    const [show, setShow] = useState(false);
    const [icon, setIcon] = useState<SVGIconType>(SVGIconList[0]);
    const [color, setColor] = useState(colorPicker.defaultBlue);
    const [projectTitle, setProjectTitle] = useState("");
    const [validProjectTitle, setValidProjectTitle] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState(colorPicker.commCommYellow);


    const onIconChange = (icon: SVGIconType) => {
        setIcon(icon);
    }

    const [image, setImage] = useState(backgroundImages[0]);

    const handleClose = () => {
        setShow(false);
        setValidProjectTitle(true);
    };

    const handleShow = () => {
        setShow(true)
        //logic to check if you are editing project or creating a new one.
        setColor(colorPicker.defaultBlue)
        setBackgroundColor(colorPicker.commCommYellow)
        setIcon(SVGIconList[0])
        setImage(backgroundImages[0])
    };

    const handleProjectTitle = (input: string) => {
        if (new RegExp(/^[a-zA-Z .-]{1,51}$/).test(input)) {
            setValidProjectTitle(true);
        } else {
            setValidProjectTitle(false);
        }
        setProjectTitle(input);
    };

    const handleSubmit = () => {
        let title = true;
        if (!(validProjectTitle && projectTitle.length > 0)) {
            title = false;
            setValidProjectTitle(false);
        }
        if (title) {
            props.createProject(projectTitle, icon.title, color, backgroundColor, image.img)
            setShow(false)
        }
    };

    return (
        <>
            <button className={"add-project"} type="button" onClick={handleShow}
                    hidden={props.acctSubscription <= AccountSubscriptionPlan.Free && props.projects.length >= 5}>
                + Add Project
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
            >
                <div className={"add-project-popup-background"}>
                    <Modal.Header className="text-center" closeButton aria-label="Hide">
                        <Modal.Title className="new-project-header w-100">Create New Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="createProjectForm" className="addProjectForm" onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}>
                            <div className='projectBackgroundColumn'>
                                <label className='add-project-modal-label'>Project Background</label>
                                {image && <div className="preview-image-wrapper">
                                    <img src={BackgroudPreview} className="background-preview-overlay"/>
                                    <img src={image.thumb} className="preview-image"/>
                                </div>}
                                <div className="background-image-gallery">
                                    {backgroundImages &&
                                        backgroundImages.map((img, index) => {
                                            return (
                                                <div key={img.id} className="bg-image" onClick={() => setImage(img)}>
                                                    <img src={img.thumb} alt="upload"/>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="projectIconWrapperColumn">
                                <label className='add-project-modal-label'>Project Icon</label>
                                <p className="featureNote" style={{marginBottom: "0px"}}>Set an icon to differentiate
                                    your project.</p>
                                <div className="projectIconRow">
                                    <div className="projectIconColumn">
                                        <label className='add-project-modal-label'>Icon Image</label>
                                        <SVGIconPicker value={icon} onChange={onIconChange} color={color}
                                                       backgroundColor={backgroundColor}/>
                                    </div>
                                    <div className="projectIconColumn">
                                        <label className='add-project-modal-label'>Icon BG</label>
                                        <input type="color" id="backgroundColorPicker" className="pickerBox"
                                               value={backgroundColor} aria-describedby="backgroundColorPickerHelp"
                                               onChange={(e) => {
                                                   setBackgroundColor(e.target.value)
                                               }}/>
                                    </div>
                                    <div className="projectIconColumn">
                                        <label className='add-project-modal-label'>Icon Color</label>
                                        <input type="color" id="colorPicker" className="pickerBox" value={color}
                                               aria-describedby="colorPickerHelp" onChange={(e) => {
                                            setColor(e.target.value)
                                        }}/>
                                    </div>
                                </div>
                            </div>

                            <div className="projectNameColumn">
                                <label style={{color: validProjectTitle ? "black" : "red"}}
                                       className='add-project-modal-label' htmlFor="projectTitle">Project
                                    Name<span style={{color: '#FF3347'}}>*</span></label>
                                <p className="featureNote">Name is required.</p>
                                <input type="title" className="form-control" id="projectTitle"
                                       onChange={(e) => {
                                           handleProjectTitle(e.target.value)
                                       }} placeholder="Write super kool title here..."/>

                                <button form="createProjectForm" type="submit" className="blueBtn"
                                        style={{width: '100%', margin: '15px 5px 0px 0px'}}>Create New Project Board
                                </button>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}