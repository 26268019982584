import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import CommComm from '../../../assets/CommCommLogo-Combo-White.png';
import {ReactComponent as UpgradeStar} from '../../../assets/upgrade-plan-star.svg'
import '../../../scss/Navigation.scss';
import NavTab from "./NavTab";
import ProfileLabel from "../../Base/ProfileLabel/ProfileLabel";
import {ProjectDetails} from "../../../data/ProjectDetails";
import {ClientDto} from "../../../data/ClientDto";
import {AccountData} from "../../../data/AccountData";
import {BsFillArchiveFill} from "react-icons/bs";
import ProfileTab from "./ProfileTab";
import {navPath} from "../../Helpers";
import ArchiveTab from "./ArchiveTab";
import {HiOutlineMenu, HiX} from "react-icons/hi";

export interface INavBar {
    account: AccountData;
    projects: ProjectDetails[];
    clients: ClientDto[];
    createClient: (firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    createProject: (title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void;
    editProject: (id: string, title: string, icon: string, iconColor: string, iconBackgroundColor: string, background: string) => void
    editClient: (id: string, firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    selectedProject: string;
    selectedClient: string;
    showProfile: boolean;
    showArchive: boolean;
    selectClient: (clientId: string) => void;
    selectProject: (projectId: string) => void;
    setShowProfile: (show: boolean) => void;
    setShowArchive: (show: boolean) => void;
    archiveProject: (projectId: string) => void;
    archiveClient: (clientId: string) => void;
    logout: () => void;
    isSideNavVisible: boolean;
    setIsSideNavVisible: (isVisible: boolean) => void;
    loadSavedClients: boolean;
    loadSavedProjects: boolean;
    archivingProjectId: string;
    archivingClientId: string;
}

const NavBar: React.FunctionComponent<INavBar> = (props) => {
    const location = useLocation();
    const [showArchive, setShowArchive] = useState(props.showProfile);
    const [showProfile, setShowProfile] = useState(props.showArchive);
    //look into side nav and setting it
    const [isSideNavVisible] = useState(true);
    const [isSideHamburgerVisible, setIsSideHamburgerVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setShowProfile(location.pathname === navPath.profile || location.pathname === navPath.editProfile || location.pathname === navPath.pricebreakdown || location.pathname === navPath.manageSubscription);
        setShowArchive(location.pathname === navPath.commissionArchive || location.pathname === navPath.clientsArchive || location.pathname === navPath.projectsArchive);
    }, [location.pathname])

    return (
        <div style={{display: "flex", overflow: 'hidden'}}>
            {isSideNavVisible && (
                <div className={`navigation ${isSideHamburgerVisible ? 'slide-out' : ''}`}>
                    <div className={"active"}>
                        <div className="navbar">
                            <img className='navCommCommLogo' src={CommComm} onClick={() => navigate(navPath.projects)}/>
                            <div className="navCloseBtn" onClick={() => {
                                props.setIsSideNavVisible(false)
                                setIsSideHamburgerVisible(true)
                            }}>
                                <HiX size={23}/>
                            </div>
                        </div>
                        {showArchive && !showProfile && <ArchiveTab setShowArchive={props.setShowArchive}/>}
                        {showProfile && !showArchive && <ProfileTab setShowProfile={props.setShowProfile}/>}
                        {!showProfile && !showArchive && (props.projects || props.clients) && <NavTab
                            account={props.account}
                            selectedProject={props.selectedProject}
                            selectedClient={props.selectedClient}
                            createClient={props.createClient}
                            createProject={props.createProject}
                            editProject={props.editProject}
                            editClient={props.editClient}
                            setSelectedProject={props.selectProject}
                            setSelectedClient={props.selectClient}
                            clients={props.clients}
                            projects={props.projects}
                            archiveClient={props.archiveClient}
                            archiveProject={props.archiveProject}
                            loadSavedClients={props.loadSavedClients}
                            loadSavedProjects={props.loadSavedProjects}
                            archivingProjectId={props.archivingProjectId}
                            archivingClientId={props.archivingClientId}
                        />}
                    </div>
                    <div className={"nav-profile-wrapper"}>
                        <button className="purpleBtn starUpgradeBtn" type="button"
                                onClick={() => navigate(navPath.manageSubscription)}>
                            <UpgradeStar/> Upgrade Plan
                        </button>
                        <button className="purpleBtn archiveBtn" type="button"
                                onClick={() => props.setShowArchive(true)}>
                            <BsFillArchiveFill/> View Archives
                        </button>
                        <ProfileLabel
                            username={props.account.displayRealName ? props.account.firstName + " " + props.account.lastName : props.account.username}
                            email={props.account.email}
                            image={props.account.image}
                            setShowProfile={props.setShowProfile}
                            isDeactivated={props.account.isDeactivated}
                            logout={props.logout}
                        />
                    </div>
                </div>
            )}

            {isSideHamburgerVisible && (
                <div className={`side-hamburger-menu ${isSideNavVisible ? 'slide-in' : 'slide-out'}`}>
                    <div className="navCloseBtn" onClick={() => {
                        props.setIsSideNavVisible(true)
                        setIsSideHamburgerVisible(false)
                    }}>
                        <HiOutlineMenu size={23}/>
                    </div>
                    <div className='bottom-side-hamburger-menu'>
                        <a href="#" onClick={() => navigate(navPath.manageSubscription)}>
                            <UpgradeStar className="hamStar"/>
                        </a>
                        <a href="#" onClick={() => navigate(navPath.projectsArchive)}>
                            <BsFillArchiveFill size={22}/>
                        </a>
                        <a href="#" onClick={() => navigate(navPath.editProfile)}>
                            <img className={"ham-profile-image"} src={props.account.image} alt="Profile image."/>
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NavBar