import React, {PropsWithChildren} from "react";
import {Modal} from "react-bootstrap";
import {ReactComponent as DoneCelebration} from '../../../assets/done-celebration.svg'

interface IModalCongratsMessageProps extends PropsWithChildren {
    isOpen: boolean;
    toggle: (show: boolean) => void;
    children: any;
    modalTitle: string;
}

export const ModalCongratsMessage: React.FunctionComponent<IModalCongratsMessageProps> = ({children, ...props}) => (
    <div>
        <Modal
            show={props.isOpen}
            onHide={() => props.toggle(!props.isOpen)}
        >
            <div className={"modalCongratsMessageBackground"}>
                <Modal.Header closeButton aria-label="Hide" closeVariant="white" style={{paddingBottom: "0px"}}>
                </Modal.Header>
                <Modal.Body style={{paddingTop: "0px"}}>
                    <div className="modalCongratsMessageContentWrapper">
                        <div className='modalCongratsMessageColumn'>
                            <DoneCelebration/>
                            <div className="modalCongratsMessageHeader">{props.modalTitle}</div>
                            {children}
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    </div>
);