import {AggregateBase} from "./AggregateBase";

export class AccountData extends AggregateBase {
    email: string = "";
    phoneNumber: string = "";
    username: string = "";
    firstName: string = "";
    lastName: string = "";
    biography: string = "";
    image: string = "";
    shopLink: string = "";
    venmoHandle: string = "";
    socialMedia1: string = "";
    socialMedia2: string = "";
    socialMedia3: string = "";
    rootAccount: string = "";
    acctTimeInterval: number = 1;
    acctType: number = 1;
    acctSubscriptionPlan: number = 1;
    cardsUsed: number = 0;
    cardMax: number = 5;
    cardDeletesLeft: number = 5;
    planStartDate: string = "";
    planEndDate: string = "";
    isSuspended: boolean = false;
    isDeactivated: boolean = false;
    displayRealName: boolean = false;
    planId: string = "";

    public constructor(init?: Partial<AccountData>) {
        super();
        Object.assign(this, init);
    }
}