import React, {useEffect, useRef, useState} from 'react';
import {HiStar} from "react-icons/hi";
import IconWithTooltip from '../components/Home/IconWithTooltip';
import {ReactComponent as LeftBranch} from '../assets/left-branch.svg';
import {ReactComponent as RightBranch} from '../assets/right-branch.svg';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {config} from "../config/config";
import {useNavigate} from "react-router-dom";
import {navPath} from "../components/Helpers";

interface IPricingTier {

}

const PricingTier: React.FC<IPricingTier> = (props) => {
    const [selectedYearlyTab, setSelectedYearlyTab] = useState(false);
    const [selectedMonthlyTab, setSelectedMonthlyTab] = useState(true);
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const pricingTierContainerRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('credentials');
        setIsLoggedIn(token != null);
    }, [])

    const changeToYearly = () => {
        setSelectedMonthlyTab(false)
        setSelectedYearlyTab(true)
    }

    const changeToMonthly = () => {
        setSelectedMonthlyTab(true)
        setSelectedYearlyTab(false)
    }

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={pricingTierContainerRef}
            />
            <div className="pricing-tier-container" ref={pricingTierContainerRef}>
                <div id="leaves">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
                <div className='tree-branch-wrapper'>
                    <LeftBranch className='left-branch'/>
                    <RightBranch className='right-branch'/>
                </div>
                <div className='pricing-content-wrapper'>
                    <h1>Pick the Perfect Plan for Your Communication Needs!</h1>
                    <div className="yearMonthSlider">
                        <input type="radio" id="yearly" name="tabs" checked={selectedYearlyTab}
                               onChange={() => changeToYearly()}/>
                        <label className="tab" htmlFor="yearly">Yearly</label>
                        <input type="radio" id="monthly" name="tabs" checked={selectedMonthlyTab}
                               onChange={() => changeToMonthly()}/>
                        <label className="tab" htmlFor="monthly">Monthly</label>
                        <span className="glider"></span>
                    </div>
                    <div className="pricing-plans-wrapper">
                        <div className='pricing-plan'>
                            <h2>Free</h2>
                            <p className="plan-audience">Best for casual, hobby sellers</p>
                            <h3>$0</h3>
                            <p className="payment-type">FOREVER</p>
                            <button className='blueBtn' type="button" onClick={() => {
                                isLoggedIn ? navigate(navPath.projects) : navigate(navPath.login)
                            }}>Get Started
                            </button>
                            <div className='plan-details-wrapper'>
                                <h4>Free plan includes:</h4>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Up to 5 commission cards per month</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Unused cards will not carry over. The amount of cards will reset at the beginning of the month."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Up to 5 active project boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project boards can help you categorize the types of commissions you provide. Ex. Shirts, Stickers, etc."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited client boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Customers can be sorted into client boards where you can keep track of their individual requests and information."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Card age tracker</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Commission cards will visually age in color to indicate how close its deadline is."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Shareable client progress boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Each commission will have a shareable link that will take buyers to a personal progress board. Clients can view the progress of their commission(s)."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Client notification system</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"When you move commission cards across their project boards, clients will be sent text and/or email updates."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Access to tips for completed projects</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Completed projects will have optional tip buttons activated on the client progress board."}/>
                                </div>
                            </div>
                        </div>
                        <div className='pricing-plan'>
                            <h2>Basic</h2>
                            <p className="plan-audience">Best for small businesses</p>
                            <h3 className={selectedYearlyTab ? 'yearly-price' : 'monthly-price'}>{config.stripePriceConfig.BasicYearly}</h3>
                            <h3 className={selectedMonthlyTab ? 'yearly-price' : 'monthly-price'}>{config.stripePriceConfig.BasicMonthly}</h3>
                            <p className="payment-type">{selectedMonthlyTab ? "PER MONTH" : "PER YEAR"}</p>
                            <button className='blueBtn' type="button" onClick={() => {
                                isLoggedIn ? navigate(navPath.manageSubscription) : navigate(navPath.login)
                            }}>{isLoggedIn ? "Upgrade Now" : "Get Started"}
                            </button>
                            <div className='plan-details-wrapper'>
                                <h4>Basic plan includes:</h4>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        {selectedMonthlyTab ? <p>Up to 30 commission cards per month</p> :
                                            <p>Up to 400 commission cards per year</p>}
                                    </div>
                                    <IconWithTooltip
                                        text={selectedMonthlyTab ? "Unused cards will not carry over. The amount of cards will reset after a month." : "Unused cards will not carry over. The amount of cards will reset after a year."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited project boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project boards can help you categorize the types of commissions you provide. Ex. Shirts, Stickers, etc."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited client boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Customers can be sorted into client boards where you can keep track of their individual requests and information."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Card age tracker</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Commission cards will visually age in color to indicate how close its deadline is."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Shareable client progress boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Each commission will have a shareable link that will take buyers to a personal progress board. Clients can view the progress of their commission(s)."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Client notification system</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"When you move commission cards across their project boards, clients will be sent text and/or email updates."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Access to tips for completed projects</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Completed projects will have optional tip buttons activated on the client progress board."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited project price breakdowns</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project price breakdowns give the opportunity to show clients a general overview of why your projects are priced the way they are."}/>
                                </div>
                                {/*<div className='feature-list'>*/}
                                {/*    <div className='feature-name-divide'>*/}
                                {/*        <HiStar className="feature-star-icon" size={23}/>*/}
                                {/*        <p>Commission length calculation</p>*/}
                                {/*    </div>*/}
                                {/*    <IconWithTooltip*/}
                                {/*        text={"Commissions will be analyzed and an average time estimation til completion will be calculated and presented on its card."}/>*/}
                                {/*</div>*/}
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Delete up to 5 commissions a year</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"If an issue with a commission occurs, you may want to delete it. Deletions will not count toward your allotted card usage."}/>
                                </div>
                                {/*<div className='feature-list'>*/}
                                {/*    <div className='feature-name-divide'>*/}
                                {/*        <HiStar className="feature-star-icon" size={23}/>*/}
                                {/*        <p>Commission analytics and breakdowns</p>*/}
                                {/*    </div>*/}
                                {/*    <IconWithTooltip*/}
                                {/*        text={"Commission analytics will be available. Details will include frequent customers, how many types of commissions ordered, and more."}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className='pricing-plan standard-plan-highlight'>
                            <h2>Standard</h2>
                            <p className="plan-audience">Best for mid-sized businesses</p>
                            <h3 className={selectedYearlyTab ? 'yearly-price' : 'monthly-price'}>{config.stripePriceConfig.StandardYearly}</h3>
                            <h3 className={selectedMonthlyTab ? 'yearly-price' : 'monthly-price'}>{config.stripePriceConfig.StandardMonthly}</h3>
                            <p className="payment-type">{selectedMonthlyTab ? "PER MONTH" : "PER YEAR"}</p>
                            <button className='blueBtn' type="button" onClick={() => {
                                isLoggedIn ? navigate(navPath.manageSubscription) : navigate(navPath.login)
                            }}>{isLoggedIn ? "Upgrade Now" : "Get Started"}
                            </button>
                            <div className='plan-details-wrapper'>
                                <h4>Standard plan includes:</h4>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        {selectedMonthlyTab ? <p>Up to 60 commission cards per month</p> :
                                            <p>Up to 800 commission cards per year</p>}
                                    </div>
                                    <IconWithTooltip
                                        text={selectedMonthlyTab ? "Unused cards will not carry over. The amount of cards will reset after a month." : "Unused cards will not carry over. The amount of cards will reset after a year."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited project boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project boards can help you categorize the types of commissions you provide. Ex. Shirts, Stickers, etc."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited client boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Customers can be sorted into client boards where you can keep track of their individual requests and information."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Card age tracker</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Commission cards will visually age in color to indicate how close its deadline is."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Shareable client progress boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Each commission will have a shareable link that will take buyers to a personal progress board. Clients can view the progress of their commission(s)."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Client notification system</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"When you move commission cards across their project boards, clients will be sent text and/or email updates."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Access to tips for completed projects</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Completed projects will have optional tip buttons activated on the client progress board."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited project price breakdowns</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project price breakdowns give the opportunity to show clients a general overview of why your projects are priced the way they are."}/>
                                </div>
                                {/*<div className='feature-list'>*/}
                                {/*    <div className='feature-name-divide'>*/}
                                {/*        <HiStar className="feature-star-icon" size={23}/>*/}
                                {/*        <p>Commission length calculation</p>*/}
                                {/*    </div>*/}
                                {/*    <IconWithTooltip*/}
                                {/*        text={"Commissions will be analyzed and an average time estimation til completion will be calculated and presented on its card."}/>*/}
                                {/*</div>*/}
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Delete up to 10 commissions a year</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"If an issue with a commission occurs, you may want to delete it. Deletions will not count toward your allotted card usage."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Allow for commission updates to include images</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"You can now attach images to commission cards, and they will be sent as updates to clients."}/>
                                </div>
                                {/*<div className='feature-list'>*/}
                                {/*    <div className='feature-name-divide'>*/}
                                {/*        <HiStar className="feature-star-icon" size={23}/>*/}
                                {/*        <p>Commission analytics and breakdowns</p>*/}
                                {/*    </div>*/}
                                {/*    <IconWithTooltip*/}
                                {/*        text={"Commission analytics will be available. Details will include frequent customers, how many types of commissions ordered, and more."}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className='pricing-plan'>
                            <h2>Pro</h2>
                            <p className="plan-audience">Best for big businesses</p>
                            <h3 className={selectedYearlyTab ? 'yearly-price' : 'monthly-price'}>{config.stripePriceConfig.ProYearly}</h3>
                            <h3 className={selectedMonthlyTab ? 'yearly-price' : 'monthly-price'}>{config.stripePriceConfig.ProMonthly}</h3>
                            <p className="payment-type">{selectedMonthlyTab ? "PER MONTH" : "PER YEAR"}</p>
                            <button className='blueBtn' type="button" onClick={() => {
                                isLoggedIn ? navigate(navPath.manageSubscription) : navigate(navPath.login)
                            }}>{isLoggedIn ? "Upgrade Now" : "Get Started"}
                            </button>
                            <div className='plan-details-wrapper'>
                                <h4>Pro plan includes:</h4>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        {selectedMonthlyTab ? <p>Up to 90 commission cards per month</p> :
                                            <p>Up to 1200 commission cards per year</p>}
                                    </div>
                                    <IconWithTooltip
                                        text={selectedMonthlyTab ? "Unused cards will not carry over. The amount of cards will reset after a month." : "Unused cards will not carry over. The amount of cards will reset after a year."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited project boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project boards can help you categorize the types of commissions you provide. Ex. Shirts, Stickers, etc."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited client boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Customers can be sorted into client boards where you can keep track of their individual requests and information."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Card age tracker</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Commission cards will visually age in color to indicate how close its deadline is."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Shareable client progress boards</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Each commission will have a shareable link that will take buyers to a personal progress board. Clients can view the progress of their commission(s)."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Client notification system</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"When you move commission cards across their project boards, clients will be sent text and/or email updates."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Access to tips for completed projects</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Completed projects will have optional tip buttons activated on the client progress board."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Unlimited project price breakdowns</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"Project price breakdowns give the opportunity to show clients a general overview of why your projects are priced the way they are."}/>
                                </div>
                                {/*<div className='feature-list'>*/}
                                {/*    <div className='feature-name-divide'>*/}
                                {/*        <HiStar className="feature-star-icon" size={23}/>*/}
                                {/*        <p>Commission length calculation</p>*/}
                                {/*    </div>*/}
                                {/*    <IconWithTooltip*/}
                                {/*        text={"Commissions will be analyzed and an average time estimation til completion will be calculated and presented on its card."}/>*/}
                                {/*</div>*/}
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Delete up to 15 commissions a year</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"If an issue with a commission occurs, you may want to delete it. Deletions will not count toward your allotted card usage."}/>
                                </div>
                                <div className='feature-list'>
                                    <div className='feature-name-divide'>
                                        <HiStar className="feature-star-icon" size={23}/>
                                        <p>Allow for commission updates to include images</p>
                                    </div>
                                    <IconWithTooltip
                                        text={"You can now attach images to commission cards, and they will be sent as updates to clients."}/>
                                </div>
                                {/*<div className='feature-list'>*/}
                                {/*    <div className='feature-name-divide'>*/}
                                {/*        <HiStar className="feature-star-icon" size={23}/>*/}
                                {/*        <p>Commission analytics and breakdowns</p>*/}
                                {/*    </div>*/}
                                {/*    <IconWithTooltip*/}
                                {/*        text={"Commission analytics will be available. Details will include frequent customers, how many types of commissions ordered, and more."}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
            {/* LoadingOverlay component used here */}
            {/* <LoadingOverlay isLoading={isLoading}/> */}
        </div>
    );
};

export default PricingTier;