import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import ProjectBoard from '../assets/UserGuideImages/Project-Board.png';
import ProjectTabs from '../assets/UserGuideImages/Project-Tabs.png';
import CommissionTrackers from '../assets/UserGuideImages/Commission-Trackers.png';
import QueueColumn from '../assets/UserGuideImages/Queue-Column.png';
import DoneColumn from '../assets/UserGuideImages/Done-Column.png';
import {PiNoteFill} from "react-icons/pi";
import OverallGuideMenu from "../components/Base/OverallGuideMenu";

interface IUserGuideProjectBoards {

}

const UserGuideProjectBoards: React.FC<IUserGuideProjectBoards> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const userGuideProjectBoardsContainerRef = useRef<HTMLDivElement | null>(null);
    const userGuideProjectBoardsProjectTabsRef = useRef<HTMLDivElement | null>(null);
    const userGuideProjectBoardsAddingProjectRef = useRef<HTMLDivElement | null>(null);
    const userGuideProjectBoardsCommissionUsageTrackerRef = useRef<HTMLDivElement | null>(null);
    const userGuideProjectBoardsQueueColumnRef = useRef<HTMLDivElement | null>(null);
    const userGuideProjectBoardsColumnFeaturesRef = useRef<HTMLDivElement | null>(null);
    const userGuideProjectBoardsDoneColumnRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(function () {
            if (location.hash) {
                const section = location.hash.substring(1);
                switch (section) {
                    case "projectTabs":
                        userGuideProjectBoardsProjectTabsRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "addingProject":
                        userGuideProjectBoardsAddingProjectRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "commissionUsageTracker":
                        userGuideProjectBoardsCommissionUsageTrackerRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "queueColumn":
                        userGuideProjectBoardsQueueColumnRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "columnFeatures":
                        userGuideProjectBoardsColumnFeaturesRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    case "doneColumn":
                        userGuideProjectBoardsDoneColumnRef.current?.scrollIntoView({
                            block: "start",
                            behavior: 'smooth'
                        });
                        break;
                    default:
                        navigate(navPath.userGuideProjectBoards)
                        break;
                }
            }
        }, 1000);
    }, [])

    const scrollToSection = (id: string) => {
        const sectionName = document.getElementById(id);

        if (sectionName) {
            sectionName.scrollIntoView({block: "start", behavior: 'smooth', inline: 'start'});
        }
    };

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={userGuideProjectBoardsContainerRef}
            />
            <div className="user-guide-page-container" ref={userGuideProjectBoardsContainerRef}>
                <div className='user-guide-page-wrapper'>
                    <div className='userGuideSideBar'>
                        <div className='userGuideAnchorScrollList'>
                            <h2>Table of Contents</h2>
                            <ul>
                                <li onClick={() => scrollToSection('projectTabs')}>1. Project Tabs</li>
                                <li onClick={() => scrollToSection('addingProject')}>2. Adding a New Project</li>
                                <li onClick={() => scrollToSection('commissionUsageTracker')}>3. Commission Usage
                                    Trackers
                                </li>
                                <li onClick={() => scrollToSection('queueColumn')}>4. In Queue Column</li>
                                <li onClick={() => scrollToSection('columnFeatures')}>5. Column Features</li>
                                <li onClick={() => scrollToSection('doneColumn')}>6. Done Column</li>
                            </ul>
                        </div>
                        <OverallGuideMenu/>
                    </div>
                    <div className='user-guide-page-content-wrapper'>
                        <h1>Project Boards</h1>
                        <p className='biggerText'>Project boards are meant to help the seller organize their commissions
                            into categories as well as track their progress.</p>
                        <img src={ProjectBoard} className='smallImg' style={{marginBottom: "35px"}}
                             alt="user guide project board"/>

                        <section id="projectTabs" ref={userGuideProjectBoardsProjectTabsRef}>
                            <h2>1. Project Tabs</h2>
                            <img src={ProjectTabs} alt="user guide project boards project tabs"/>
                            <p>Think of <strong>Project Tabs</strong> like folders. Each tab can represent a specific
                                type of product or service that you provide. The numbers in the pink boxes,
                                keep track of how many commissions are inside of each project tab. To edit tabs, hover
                                over them and click the pencil icon. A pop-up should appear
                                that is named after the project. Editable features include:</p>
                            <ul>
                                <li>The project board background image</li>
                                <li>The project icon</li>
                                <li>The project icon colors</li>
                                <li>The project name</li>
                            </ul>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> There is an option to archive the
                                    project when you edit them. Archiving enables you to declutter your workspace by
                                    removing unused projects. Archived projects go to the <Link className="hyperlink"
                                                                                                to={navPath.userGuideArchiveBoards}>Archive
                                        Board</Link>.</p>
                            </div>
                        </section>

                        <section id="addingProject" ref={userGuideProjectBoardsAddingProjectRef}>
                            <h2>2. Adding a New Project</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/9Oyo26Xu1Pw"
                                    title="video project board adding projects demo"
                                    allowFullScreen></iframe>
                            <p>The short video above demonstrates the steps below on how to create a new project.</p>
                            <ol>
                                <li>
                                    Press the <strong>{`"+ Add Project"`}</strong> button under your project tabs. A
                                    pop-up
                                    should appear called <strong>{`"Create New Project"`}</strong>.
                                </li>
                                <li>
                                    Inside the pop-up, you will have the option to choose a board background from our
                                    default list of backgrounds.
                                </li>
                                <li>Next, choose an icon to represent your new project and give it some custom colors.
                                </li>
                                <li>Lastly, enter a name for your project and click <strong>{`"Create New Project
                                    Board"`}</strong>. An empty new project board should appear.
                                </li>
                            </ol>
                        </section>

                        <section id="commissionUsageTracker" ref={userGuideProjectBoardsCommissionUsageTrackerRef}>
                            <h2>3. Commission Usage Trackers</h2>
                            <img src={CommissionTrackers} className='smallImg'
                                 alt="user guide project board commission trackers"/>
                            <p>The tracker that says <strong>{`"Total Commissions Created: 0/50"`}</strong> keeps track
                                of
                                how many of your allotted commission cards you used so far.
                                Depending on your subscription tier, you will be limited on the amount of commissions
                                you can make and track on your boards.
                                The free tier will always give you five free cards to use a month.</p>
                            <p>The tracker that
                                says <strong>{`"26 day(s) until commission total reset!"`}</strong> keeps
                                track of how many days are left before your <strong>{`"Total Commissions
                                    Created"`}</strong> counter resets to zero.
                                The amount of time before a reset will vary depending on if you have purchased a monthly
                                or yearly subscription.</p>
                            <div className='guideNotes'>
                                <p><PiNoteFill size={20}/> <strong>Note:</strong> When a reset occurs, any previously
                                    created commission cards will still remain on your board(s).
                                    Any uncreated commissions will not carry over to the next cycle.</p>
                            </div>
                        </section>

                        <section id="queueColumn" ref={userGuideProjectBoardsQueueColumnRef}>
                            <h2>4. In Queue Column</h2>
                            <img src={QueueColumn} alt="user guide project boards queue column"/>
                            <p>The <strong>In Queue</strong>{` column is where all the new commissions you create start in the workflow. This column's title and position cannot be modified. 
                            To add a new commission to a board, just click the `}<strong>{`"+ Add Commission"`}</strong> button
                                in this column. Soon after, a card should appear, which you can edit by clicking on it.
                                If you reach the max amount of commissions allotted to you, then this button will be
                                disabled.</p>
                        </section>

                        <section id="columnFeatures" ref={userGuideProjectBoardsColumnFeaturesRef}>
                            <h2>5. Column Features</h2>
                            <iframe className="videoFrame" src="https://www.youtube.com/embed/F6vNiVXyyQ0"
                                    title="video project board column features demo"
                                    allowFullScreen></iframe>
                            <p><strong>Columns</strong> in CommComm are supposed to represent steps or milestones in
                                your projects. For example, a project for paintings may have steps like drafting, line
                                art, coloring, etc.
                                Column titles will be reflected in the progress bars that clients will be shown on
                                their <Link to={navPath.userGuideProgressBoards} className="hyperlink">Progress
                                    Board</Link>.
                                The maxiumum amount of columns that can be made per project is ten, this is including
                                the <strong>In Queue</strong> and <strong>Done</strong> columns.
                                Some of the key features of columns in CommComm are:</p>
                            <ol>
                                <li>You can rearrange columns by dragging them around.</li>
                                <li>You can rename them by clicking on their titles.</li>
                                <li>You can remove columns by clicking on the three dots located on each one. Keep in
                                    mind a column cannot be deleted if it contains commissions.
                                </li>
                            </ol>
                            <p>The short video above demonstrates these key features.</p>
                        </section>

                        <section id="doneColumn" ref={userGuideProjectBoardsDoneColumnRef}>
                            <h2>6. Done Column</h2>
                            <img src={DoneColumn} alt="user guide project board done column"/>
                            <p>The <strong>Done</strong>{` column is where all commissions go at the end of the workflow. This column's title and position cannot be modified. 
                            Please make sure the commissions you move here are completely done because the card's ability to move will be disabled. 
                            Cards that have been in the Done column for three days will automatically be sent to the `}<Link
                                to={navPath.userGuideArchiveBoards} className="hyperlink">Archive Board</Link>.</p>
                            <p>To add a new column to a board, just click
                                the <strong>{`"Add New Column to the Left"`}</strong> button in this column. After
                                entering a title, a new column should appear.</p>
                        </section>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UserGuideProjectBoards;