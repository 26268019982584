import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {navPath} from "../components/Helpers";
import CommComm from "../assets/CommCommLogo-Combo-White.png";

interface IUnsubscribe {

}

const Unsubscribe: React.FC<IUnsubscribe> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="App">
            <div className="unsubscribe-container">
                <div className='unsubscribe-content-wrapper'>
                    <Link to={navPath.home}>
                        <img className='commCommUnsubscribeLogo' src={CommComm} onClick={() => navigate(navPath.home)}
                             alt="commcomm unsubscribe link"/>
                    </Link>
                    <h1>Email Settings</h1>
                    <p>Want to remove <strong>choi.lexis@gmail.com</strong>{` from all CommComm marketing emails? We're
                        sad
                        to see you go, but you can opt back in any time in your account settings at `}<Link
                        to={navPath.home} className="hyperlink">https://www.commcomm.app</Link>.</p>
                    <div className='checkboxWrapper'>
                        <input style={{cursor: "pointer"}} type="checkbox"/>
                        <p>Unsubscribe from future CommComm marketing emails.</p>
                    </div>
                    <p className='unsubscribeNote'><em>{`By unsubscribing, you'll still receive customer service related
                        emails about your account subscriptions, account updates, and/or help requests.`}</em></p>
                    <button className='blueBtn' type="button">
                        Save Preference
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Unsubscribe;