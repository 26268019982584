import React, {useEffect, useRef, useState} from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import {
    AccountSubscriptionPlan,
    constantList, freeQuillContainer,
    freeQuillFormats,
    handleImageUpload,
    premiumQuillContainer,
    premiumQuillFormats
} from "../Helpers";
import Delta from "quill-delta";
// import DOMPurify from "dompurify";
//https://www.slatejs.org/examples/richtext
//https://patricknasralla.com/pages/slate-thesaurus-tutorial/
//https://portal.gitnation.org/contents/draftjs-editorjs-slatejs-choosing-the-best-text-editor-for-your-react-project
//Copy and Paste Issue with matchers in QUILL https://github.com/slab/quill/issues/4218
interface IRichTextEditorNotesProps {
    notes: string,
    setNotes: (text: string) => void
    setNotesLength: (length: number) => void;
    notesLength: number;
    acctSubscription: number;
    active: boolean
}

const RichTextCommissionNotes: React.FunctionComponent<IRichTextEditorNotesProps> = (props) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<Quill | null>(null);
    const isMounted = useRef(false);
    const [characterCount, setCharacterCount] = useState(props?.notes?.length ?? 0);

    const initializeQuillEditor = () => {
        if (editorRef.current) {
            quillRef.current = new Quill(editorRef.current, {
                theme: 'snow',
                formats: props.acctSubscription > AccountSubscriptionPlan.Basic && props.active ? premiumQuillFormats : freeQuillFormats,
                modules: {
                    toolbar: {
                        container: props.acctSubscription > AccountSubscriptionPlan.Basic && props.active ? premiumQuillContainer : freeQuillContainer,
                        handlers: {
                            image: () => handleImageUpload(quillRef?.current || new Quill(document.createElement('div')), "card_notes")
                        }
                    },
                    clipboard: {
                        matchers: [['img', (node: any, delta: Delta) => {
                            return new Delta();
                        }]]
                    }

                }
            });
            // Use clipboard API to set initial content if props.text is not empty
            quillRef.current.clipboard.dangerouslyPasteHTML(props.notes ?? "");
            // Add a matcher to the clipboard to prevent image pasting
            // quillRef.current.clipboard.addMatcher('IMG', function (node, delta) {
            //     console.log("image")
            //     // Return an empty delta to prevent the image from being inserted
            //     return new Delta();
            // });
            // quillRef.current.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
            //     let ops = delta.ops.filter(op => {
            //         if (op.insert && typeof op.insert === 'object' && op.insert.image) {
            //             return false; // Exclude image ops
            //         }
            //         return true; // Keep other ops
            //     });
            //     delta.ops = ops;
            //     return delta;
            // });
            quillRef.current.on('text-change', () => {
                // if (isInitialSetting.current) return;
                props.setNotes(quillRef.current?.root.innerHTML || '')
                setCharacterCount(quillRef.current?.root.innerHTML.length || 0)
                props.setNotesLength(quillRef.current?.root.innerHTML.length || 0);
            });
            quillRef.current?.enable(props.active)
        }

        return () => {
            // Clean up on unmount
            if (quillRef.current) {
                quillRef.current.off('text-change');
                quillRef.current = null;
            }
        };
    }

    useEffect(() => {
        // Fix for renders two toolbar
        if (!isMounted.current) {
            initializeQuillEditor();
            isMounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (quillRef.current && props.notes !== quillRef.current.root.innerHTML) {
            quillRef.current.clipboard.dangerouslyPasteHTML(props.notes);
        }
    }, [props.notes]);

    return (
        <div>
            <div ref={editorRef}/>
            <div
                hidden={characterCount < constantList.richTextLengthShowCounter}>{characterCount}/{constantList.richTextMaxLength}</div>
        </div>
    );
};

export default RichTextCommissionNotes;