import React, {useEffect, useState} from 'react';
import {formatDateQuarters, formatDateYear} from "../components/Helpers";
import {Card} from "../data/Card";
import ArchiveBoardCommissionsTopBar from "../components/ArchivesBoard/ArchiveBoardCommissionsTopBar";
import ViewArchivedCard from "../components/Base/Popup/ViewArchivedCard";
import {AccountData} from "../data/AccountData";
import {ProjectData} from "../data/ProjectData";
import {Client} from "../data/Client";
import {CardProgressHistoryDetails} from "../data/CardProgressHistoryDetails";
import {ArchiveCardDetails} from "../data/ArchiveCardDetails";
import StationaryArchiveCard from "../components/ArchivesBoard/StationaryArchiveCard";
import LoadingOverlay from "../components/Home/LoadingOverlay";

interface IArchiveCommissionsBoardProps {
    account: AccountData;
    projects: ProjectData[];
    clients: Client[];
    archiveCards: ArchiveCardDetails[];
    getCardComments: (cardId: string) => void;
    cardComments: CardProgressHistoryDetails[];
    isArchiveCommissionLoading: boolean
    setShowArchive: (show: boolean) => void;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
    isSideNavVisible: boolean;
}

// const refreshArchiveCards = () => {
//     return new Map<string, ArchiveCardDetails[]>([
//         ["January", []],
//         ["February", []],
//         ["March", []],
//         ["April", []],
//         ["May", []],
//         ["June", []],
//         ["July", []],
//         ["August", []],
//         ["September", []],
//         ["October", []],
//         ["November", []],
//         ["December", []],
//     ])
// }
const refreshArchiveCards = () => {
    return new Map<string, ArchiveCardDetails[]>([
        ["January - March", []],
        ["April - June", []],
        ["July - September", []],
        ["October - December", []]
    ])
}

//hold the selected card info in a variables, so you can pass projectId to functions.
const ArchiveCommissionsBoard: React.FunctionComponent<IArchiveCommissionsBoardProps> = (props) => {
    const [showCardModal, setShowCardModalCardModal] = useState(false);
    const [archiveCards, setArchiveCards] = useState<Map<string, ArchiveCardDetails[]>>(refreshArchiveCards());
    const [card, setCard] = useState<ArchiveCardDetails>(props.archiveCards[0] ?? new ArchiveCardDetails(new Card(), [], "", ""))
    const [year, setYear] = useState<number>(2024)

    useEffect(() => {
        props.setShowArchive(true)
    }, [])

    useEffect(() => {
        const newArchiveCards = refreshArchiveCards();
        if (props.archiveCards.length > 0) {
            for (let i = 0; i < props.archiveCards.length; i++) {
                let card = props.archiveCards[i];
                if (year === formatDateYear(card?.archiveDate ?? "")) {
                    if (!newArchiveCards.get(formatDateQuarters(card?.archiveDate ?? ""))!.includes(card)) {
                        newArchiveCards.get(formatDateQuarters(card?.archiveDate ?? ""))!.push(card);
                    }
                }
            }
            setArchiveCards(newArchiveCards);
            setCard(props.archiveCards[0] ?? new ArchiveCardDetails(new Card(), [], "", ""))
        }
    }, [props.archiveCards.length, year])

    const selectCard = (card: ArchiveCardDetails) => {
        setCard(card)
    };

    const toggleShowCardModal = () => {
        setShowCardModalCardModal(!showCardModal)
    };

    return (
        <>
            <div className="App">
                <div className='archiveBoardImgBg'>
                    <div className="archiveOverview" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <ArchiveBoardCommissionsTopBar
                            feedbackConfirmation={props.feedbackConfirmation}
                            setFeedbackConfirmation={props.setFeedbackConfirmation}
                            submitFeedback={props.submitFeedback}
                            setYear={setYear}/>
                        <div className="archive-content-wrapper">
                            {Array.from(archiveCards.keys()).map((month: string, indexMonth: number) => (
                                <div key={month + indexMonth} className="clientCommissionColumn">
                                    <div className="clientCommissionColumn-title">
                                        {month}
                                    </div>
                                    <div onClick={toggleShowCardModal}>
                                        <div className="card-wrapper">
                                            {archiveCards.has(month) && archiveCards.get(month)!.length > 0 && archiveCards.get(month)!
                                                .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
                                                .map((archiveCardDetails: ArchiveCardDetails, index) => (
                                                    <div key={index} style={{opacity: 0.65}}>
                                                        <StationaryArchiveCard
                                                            card={archiveCardDetails}
                                                            selectCard={selectCard}
                                                            selectedCard={card}/>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {card && <ViewArchivedCard
                    account={props.account}
                    isOpen={showCardModal}
                    toggleShowCardModal={toggleShowCardModal}
                    card={card}
                    getCardComments={props.getCardComments}
                    cardComments={props.cardComments}
                />}
            </div>
            <LoadingOverlay isLoading={props.isArchiveCommissionLoading}/>
        </>
    );
}

export default ArchiveCommissionsBoard;
