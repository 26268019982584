import {cancelTokenSource, requests} from './base/http-common'
import {InitialWebPageDto} from "../data/WebPageDto";

class InitialWebPageDataSource {

    BASEURL = "webpagedetails"

    Get = {
        InitialWebPageDetails: (accountId: string): Promise<InitialWebPageDto> => requests.get(`${this.BASEURL}/details/all/${accountId}`, cancelTokenSource.token)
    };
}

export default new InitialWebPageDataSource();