import React, {useEffect, useState} from 'react'
import {Modal, Form} from "react-bootstrap";
import "../../../scss/EditClientPopupModal.css"
import {SVGIconType} from "../../Base/SVGPicker/SVGIconType";
import {SVGIconPicker} from "../../Base/SVGPicker/SVGIconPicker";

interface IEditClientPopupModal {
    id: string
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    icon: SVGIconType;
    iconColor: string;
    iconBackgroundColor: string;
    handleClose: () => void;
    show: boolean;
    editClient: (id: string, firstName: string, lastName: string, email: string, phoneNumber: string, iconImage: string, iconColor: string, backgroundColor: string) => void;
    archiveClient: (clientId: string) => void;
}

export const EditClientPopupModal: React.FunctionComponent<IEditClientPopupModal> = (props) => {
    const [firstName, setFirstName] = useState(props.firstName);
    const [lastName, setLastName] = useState(props.lastName);
    const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
    const [email, setEmail] = useState(props.email);
    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [icon, setIcon] = useState<SVGIconType>(props.icon);
    const [color, setColor] = useState(props.iconColor);
    const [backgroundColor, setBackgroundColor] = useState(props.iconBackgroundColor);

    useEffect(() => {
        handleEmail(props.email);
        handlePhoneNumber(props.phoneNumber);
        handleFirstName(props.firstName);
        handleLastName(props.lastName);
    }, [props.show])

    const onIconChange = (icon: SVGIconType) => {
        setIcon(icon);
    }

    const handleClose = () => {
        setValidEmail(true);
        setValidFirstName(true);
        setValidLastName(true);
        setValidPhoneNumber(true);
        props.handleClose();
    };

    const handleFirstName = (input: string) => {
        setFirstName(input);
        if (new RegExp(/^[a-zA-Z .-]{1,51}$/).test(input)) {
            setValidFirstName(true);
        } else {
            setValidFirstName(false);
        }
    };

    const handleLastName = (input: string) => {
        setLastName(input);
        if (new RegExp(/^[a-zA-Z .-]{1,51}$/).test(input)) {
            setValidLastName(true);
        } else {
            setValidLastName(false);
        }
    };

    const handlePhoneNumber = (input: string) => {
        if ((new RegExp(/^\d{9,50}$/).test(input)) || input.length === 0) {
            setValidPhoneNumber(true);
        } else {
            setValidPhoneNumber(false);
        }
        setPhoneNumber(input);
    };

    const handleEmail = (input: string) => {
        if (new RegExp(/^[A-Za-z0-9_+.-]+@[A-Za-z0-9_.+-]+\.[A-Za-z0-9_.+-]{2,500}$/gm).test(input) || input.length === 0) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
        setEmail(input);
    };

    const handleSubmit = () => {
        let first = true;
        let last = true;
        let mail = true;
        let num = true;
        if (!(validFirstName && firstName.length > 0)) {
            first = false;
            setValidFirstName(false);
        }
        if (!(validLastName && lastName.length > 0)) {
            last = false;
            setValidLastName(false);
        }
        if (!(validEmail && email.length > 3)) {
            mail = false;
            setValidEmail(false);
        }
        if (!(validPhoneNumber && phoneNumber.length > 9)) {
            num = false;
            setValidPhoneNumber(false);
        }
        if (first && last && (mail || num)) {
            props.editClient(props.id, firstName, lastName, email, phoneNumber, icon.title, color, backgroundColor);
            props.handleClose();
        }
    };

    const handleArchive = () => {
        props.archiveClient(props.id)
        props.handleClose();
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                keyboard={false}
            >
                <div className={"edit-client-popup-background"}>
                    <Modal.Header className="text-center" closeButton>
                        <Modal.Title className="edit-client-header w-100">{firstName} {lastName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="editClientForm" className="edit-client-form"
                              validated={validFirstName && validLastName && (validEmail || validPhoneNumber)}>
                            <div className='editClientColumnWrapper'>
                                <div className='innerEditClientColumn'>
                                    <label className='edit-client-modal-label-header'>Client Name</label>
                                    <p className="fieldNote">Name is required.</p>

                                    <label className='edit-client-modal-label-subheader-one'
                                           htmlFor="clientEditFirstName"
                                           style={{color: validFirstName ? "black" : "red"}}>First Name<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="text" value={firstName} className="form-control"
                                           id="clientEditFirstName" onChange={(e) => {
                                        handleFirstName(e.target.value)
                                    }} placeholder="Tony"/>

                                    <label className='edit-client-modal-label-subheader-two'
                                           htmlFor="clientEditLastName"
                                           style={{color: validLastName ? "black" : "red"}}>Last Name<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="text" value={lastName} className="form-control" id="clientEditLastName"
                                           onChange={(e) => {
                                               handleLastName(e.target.value)
                                           }} placeholder="Stark"/>
                                </div>
                                <div className='innerEditClientColumn'>
                                    <label className='edit-client-modal-label-header'>Contact Info</label>
                                    <p className="fieldNote">You must list either a phone number or email to create a
                                        client.</p>

                                    <label className='edit-client-modal-label-subheader-one' htmlFor="clientEditEmail"
                                           style={{color: validEmail ? "black" : "red"}}>Email<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="email" value={email} className="form-control" id="clientEditEmail"
                                           onChange={(e) => {
                                               handleEmail(e.target.value)
                                           }} placeholder="TonyStart@StarkIndustries.com"/>

                                    <label className='edit-client-modal-label-subheader-two'
                                           htmlFor="clientEditPhoneNumber"
                                           style={{color: validPhoneNumber ? "black" : "red"}}>Phone Number<span
                                        style={{color: '#FF3347'}}>*</span></label>
                                    <input type="number" value={phoneNumber} className="form-control"
                                           id="clientEditPhoneNumber" onChange={(e) => {
                                        handlePhoneNumber(e.target.value)
                                    }} placeholder="1234567891"/>
                                </div>
                                <div className="innerCreateClientColumn">
                                    <label className='edit-client-modal-label-header'>Client Icon</label>
                                    <p className="fieldNote">Set an icon to differentiate your client.</p>
                                    <div className='clientIconRow'>
                                        <div className="clientIconColumn">
                                            <label className='add-project-modal-label'>Icon Image</label>
                                            <SVGIconPicker value={icon} onChange={onIconChange} color={color}
                                                           backgroundColor={backgroundColor}/>
                                        </div>
                                        <div className="clientIconColumn">
                                            <label className='add-project-modal-label'>Icon BG</label>
                                            <input type="color" id="backgroundColorPicker" className="pickerBox"
                                                   value={backgroundColor} aria-describedby="backgroundColorPickerHelp"
                                                   onChange={(e) => {
                                                       setBackgroundColor(e.target.value)
                                                   }}/>
                                        </div>
                                        <div className="clientIconColumn">
                                            <label className='add-project-modal-label'>Icon Color</label>
                                            <input type="color" id="colorPicker" className="pickerBox" value={color}
                                                   aria-describedby="colorPickerHelp" onChange={(e) => {
                                                setColor(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='clientBtnRow'>
                                    <button form="editClientForm" className="blueBtn" type="button"
                                            style={{width: '100%', margin: '10px 5px 0px 0px'}} onClick={(event) => {
                                        // event.preventDefault()
                                        handleSubmit()
                                    }}>Save Client
                                    </button>
                                    <button form="editClientForm" className="secondaryBtn" type="button"
                                            style={{width: '100%', marginTop: '10px'}} onClick={(event) => {
                                        handleArchive()
                                    }}>Archive Client
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}