import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {navPath} from "../components/Helpers";
import CommComm from "../assets/CommCommLogo-Combo-White.png";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {ReactComponent as SuccessCheck} from '../assets/Success-Check.svg';

interface ICheckoutSuccess {

}

const CheckoutSuccess: React.FC<ICheckoutSuccess> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="App">
            <div className="checkout-success-container">
                <div className='checkout-success-content-wrapper'>
                    <Link to={navPath.home}>
                        <img className='commCommUnsubscribeLogo' src={CommComm} onClick={() => navigate(navPath.home)}/>
                    </Link>
                    <h1>Payment Successful!</h1>
                    <p>A receipt from this purchase has been sent to your email. Click the button below to return
                        to the CommComm home page.</p>
                    <SuccessCheck/>
                    <button className='blueBtn' type="button" onClick={() => {
                        navigate(navPath.projects)
                    }}>
                        <AiOutlineArrowLeft/> Back to CommComm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutSuccess;