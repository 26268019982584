import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {ReactComponent as PinkNeonShape} from '../assets/pink-neon-sign-shape.svg'
import {ReactComponent as SmallPinkNeonShape} from '../assets/small-pink-neon-sign-shape.svg'
import {ReactComponent as MobilePinkNeonShape} from '../assets/mobile-pink-neon-sign-shape.svg'
import {ReactComponent as LeftHand} from '../assets/left-hand.svg'
import {ReactComponent as RightHand} from '../assets/right-hand.svg'
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import {navPath} from "../components/Helpers";
import GradientOverlay from '../assets/gradient-bg.jpg';
import WhyBoxOneImg from '../assets/why-box-one.png';
import WhyBoxTwoImg from '../assets/why-box-two.png';
import WhyBoxThreeImg from '../assets/why-box-three.png';
import WhyBoxFourImg from '../assets/why-box-four.png';
import ArtistIcon from '../assets/artist-icon.png';
import PhotographerIcon from '../assets/photographers-icon.png';
import ArtisanIcon from '../assets/artisans-icon.png';
import DesignerIcon from '../assets/designers-icon.png';
import MusicianIcon from '../assets/musicians-icon.png';
import CatererIcon from '../assets/caterers-icon.png';
import TailorIcon from '../assets/tailors-icon.png';
import JewellerIcon from '../assets/jewellers-icon.png';
import EventPlannerIcon from '../assets/event-planners-icon.png';
import BuilderIcon from '../assets/builders-icon.png';

//fa Icons by Font Awesome - https://fontawesome.com/

interface IHome {
    setLoginTab: (login: boolean) => void;
}

//https://stackoverflow.com/questions/36207398/not-getting-callback-after-adding-an-event-listener-for-scroll-event-in-react-js
const Home: React.FC<IHome> = (props) => {
    const navigate = useNavigate();
    // const [showProjectFeature, setShowProjectFeature] = useState(true);
    // const [showClientFeature, setShowClientFeature] = useState(false);
    // const [showClientViewFeature, setShowClientViewFeature] = useState(false);
    // const [showNotificationFeature, setShowNotificationFeature] = useState(false);
    // const [showCustomCardFeature, setShowCustomCardFeature] = useState(false);
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    const homeContainerRef = useRef<HTMLDivElement | null>(null); //This helps detect the scroll to change the top nav bg color.

    // useEffect(() => {
    //     // Simulate loading process
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 2000); // Change the timeout as per your actual loading process
    // }, []);

    // useEffect(() => {
    //     setIsLoading(false);
    // }, []);

    // const switchToProjectBoard = () => {
    //     setShowClientFeature(false);
    //     setShowProjectFeature(true);
    //     setShowClientViewFeature(false);
    //     setShowNotificationFeature(false);
    //     setShowCustomCardFeature(false);
    // }
    //
    // const switchToClientBoard = () => {
    //     setShowClientFeature(true);
    //     setShowProjectFeature(false);
    //     setShowClientViewFeature(false);
    //     setShowNotificationFeature(false);
    //     setShowCustomCardFeature(false);
    // }
    //
    // const switchToClientView = () => {
    //     setShowClientFeature(false);
    //     setShowProjectFeature(false);
    //     setShowClientViewFeature(true);
    //     setShowNotificationFeature(false);
    //     setShowCustomCardFeature(false);
    // }
    //
    // const switchToNotification = () => {
    //     setShowClientFeature(false);
    //     setShowProjectFeature(false);
    //     setShowClientViewFeature(false);
    //     setShowNotificationFeature(true);
    //     setShowCustomCardFeature(false);
    // }
    //
    // const switchToCustomCard = () => {
    //     setShowClientFeature(false);
    //     setShowProjectFeature(false);
    //     setShowClientViewFeature(false);
    //     setShowNotificationFeature(false);
    //     setShowCustomCardFeature(true);
    // }

    const redirectToSignup = () => {
        navigate(navPath.login);
        props.setLoginTab(false);
    }

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={homeContainerRef}/>
            <div className='home-container' ref={homeContainerRef}>
                <div className="call-to-action-section">
                    <div className="neon-sign-wrapper">
                        <PinkNeonShape/>
                        <SmallPinkNeonShape/>
                        <MobilePinkNeonShape/>
                        <div className="neon-sign-text">
                            <h1>Start building trust through workflow communication with CommComm.</h1>
                            <p>From Creation to Delivery, Every Step on Display!</p>
                        </div>
                    </div>
                    <div className='sign-up-btn-row'>
                        <LeftHand className='left-hand'/>
                        <button className="blueBtn" onClick={redirectToSignup} type="button">Sign Up Now!
                        </button>
                        <RightHand className='right-hand'/>
                    </div>
                    <p>Enjoy <b>UNLIMITED</b> access to our <b>FREE</b> plan. <b>NO</b> credit card required.</p>
                </div>
                {/* <div className="feature-info-section">
                    <img className='night-sky-overlay' src={NightSkyOverlay}/>
                    <div className='right-gear-wrapper'>
                        <RightGears/>
                    </div>
                    <h2>Click a Button To View a Key Feature</h2>
                    <div className='feature-menu'>
                        <div className='menu-btn'
                            style={{
                                backgroundColor: `${isProjectBtnHovered ? '#ede485' : '#FFF69B'}`,
                                border: `${showProjectFeature ? "6px solid #F171AB" : "none"}`
                            }} 
                            onMouseEnter={() => setIsProjectBtnHovered(true)}
                            onMouseLeave={() => setIsProjectBtnHovered(false)}
                            onClick={switchToProjectBoard}>
                            <AiFillProject size={20} style={{marginRight: "5px"}}/>
                            <p>Project Boards</p>
                        </div>
                        <div className='menu-btn'
                            style={{
                                backgroundColor: `${isClientBtnHovered ? '#dea0bb' : '#F6C2D9'}`,
                                border: `${showClientFeature ? "6px solid #F171AB" : "none"}`
                            }}
                            onMouseEnter={() => setIsClientBtnHovered(true)}
                            onMouseLeave={() => setIsClientBtnHovered(false)}
                            onClick={switchToClientBoard}>
                            <FaChalkboardTeacher size={20} style={{marginRight: "5px"}}/>
                            <p>Client Boards</p>
                        </div>
                        <div className='menu-btn'
                            style={{
                                backgroundColor: `${isProgressBtnHovered ? '#8ab1d1' : '#ABCEEB'}`,
                                border: `${showClientViewFeature ? "6px solid #F171AB" : "none"}`
                            }}
                            onMouseEnter={() => setIsProgressBtnHovered(true)}
                            onMouseLeave={() => setIsProgressBtnHovered(false)}
                            onClick={switchToClientView}>
                            <FaBarsProgress size={20} style={{marginRight: "5px"}}/>
                            <p>Progress Boards</p>
                        </div>
                        <div className='menu-btn' 
                            style={{
                                backgroundColor: `${isNotificationBtnHovered ? '#cfbaca' : '#E4DAE2'}`,
                                border: `${showNotificationFeature ? "6px solid #F171AB" : "none"}`
                            }} 
                            onMouseEnter={() => setIsNotificationBtnHovered(true)}
                            onMouseLeave={() => setIsNotificationBtnHovered(false)}
                            onClick={switchToNotification}>
                            <MdNotificationsActive size={20} style={{marginRight: "5px"}}/>
                            <p>Notifications</p>
                        </div>
                        <div className='menu-btn' 
                            style={{
                                backgroundColor: `${isCustomCardBtnHovered ? '#9bc2a9' : '#BCDFC9'}`,
                                border: `${showCustomCardFeature ? "6px solid #F171AB" : "none"}`
                            }} 
                            onMouseEnter={() => setIsCustomCardBtnHovered(true)}
                            onMouseLeave={() => setIsCustomCardBtnHovered(false)}
                            onClick={switchToCustomCard}>
                            <PiCardsFill size={20} style={{marginRight: "5px"}}/>
                            <p>Custom Cards</p>
                        </div>
                    </div>
                    {showProjectFeature && <div className='feature-content-wrapper'>
                        <iframe className="videoFrame" src="https://www.youtube.com/embed/9Oyo26Xu1Pw" allowFullScreen style={{animation: `${showProjectFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}></iframe>
                        <div className='feature-info'
                             style={{animation: `${showProjectFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}>
                            <div className='feature-info-header'>
                                <AiFillProject size={27} className='feature-info-icon'/>
                                <h3>Project Boards</h3>
                            </div>
                            <p>Categorize the multiple items you sell into project boards!
                                Move individual commissions through custom named columns to track your work progress.
                                A few highlights are:</p>
                            <ul>
                                <li>
                                    Real time notifications are sent to the client when a commission is moved.
                                </li>
                                <li>
                                    There is a counter attached to each board that indicates how many active commissions
                                    you have inside them.
                                </li>
                                <li>
                                    You can customize boards by assigning icons and background images.
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {showClientFeature && <div className='feature-content-wrapper'>
                        <iframe className="videoFrame" src="https://www.youtube.com/embed/ixnDRHuz_H0" allowFullScreen style={{animation: `${showClientFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}></iframe>
                        <div className='feature-info'
                             style={{animation: `${showClientFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}>
                            <div className='feature-info-header'>
                                <FaChalkboardTeacher size={27} className='feature-info-icon'/>
                                <h3>Client Boards</h3>
                            </div>
                            <p>Organize your buyers into client boards!
                                In each client's board, you can view their commissions and record any important
                                information about them.
                                A few highlights are:</p>
                            <ul>
                                <li>
                                    There's a list of commissions that the client has ever commissioned from you and an
                                    indicator of how many active commissions they have.
                                </li>
                                <li>
                                    There is a client notes section to record any specific information about the client.
                                </li>
                                <li>
                                    You can input their contact info and see whether they opted in or out of
                                    notifications.
                                </li>
                                <li>
                                    Clients can have custom icons to distingush them.
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {showClientViewFeature && <div className='feature-content-wrapper'>
                        <iframe className="videoFrame" src="https://www.youtube.com/embed/cXhPn0IfFtw" allowFullScreen style={{animation: `${showClientViewFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}></iframe>
                        <div className='feature-info'
                             style={{animation: `${showClientViewFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}>
                            <div className='feature-info-header'>
                                <FaBarsProgress size={27} className='feature-info-icon'/>
                                <h3>Progress Boards</h3>
                            </div>
                            <p>Clients have their own progress board where they can view all their active and past
                                commissions with you.
                                Any updates on their commissions will be reflected on their personal board.
                                A few highlights are:</p>
                            <ul>
                                <li>
                                    Clients can choose whether they want email and/or text notifications for any
                                    updates.
                                </li>
                                <li>
                                    Updates or messages on commissions are sent automatically to the client's progress
                                    board. No
                                    manual input needed.
                                </li>
                                <li>
                                    Clients can view your CommComm profile and price breakdown.
                                </li>
                                <li>
                                    Once commissions are done, clients are given a convenient option to tip.
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {showNotificationFeature && <div className='feature-content-wrapper'>
                        <iframe className="videoFrame" src="https://www.youtube.com/embed/nZgc5MEUMR8" allowFullScreen style={{animation: `${showNotificationFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}></iframe>
                        <div className='feature-info'
                             style={{animation: `${showNotificationFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}>
                            <div className='feature-info-header'>
                                <MdNotificationsActive size={27} className='feature-info-icon'/>
                                <h3>Notifications</h3>
                            </div>
                            <p>You no longer have to manually send emails or direct messages to your client.
                                If the client signs up, they can automatically get updates on their commissions.
                                A few highlights are:</p>
                            <ul>
                                <li>
                                    Depending on the subscription plan, clients can choose to receive emails or text
                                    notifications.
                                </li>
                                <li>
                                    In their client board, you can see their notification settings.
                                    If clients are not receiving notifications, you can check their settings here.
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {showCustomCardFeature && <div className='feature-content-wrapper'>
                        <iframe className="videoFrame" src="https://www.youtube.com/embed/G9F2WghcMWc" allowFullScreen style={{animation: `${showCustomCardFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}></iframe>
                        <div className='feature-info'
                             style={{animation: `${showCustomCardFeature ? "fadeIn 1s" : "fadeOut 1s"}`}}>
                            <div className='feature-info-header'>
                                <PiCardsFill size={27} className='feature-info-icon'/>
                                <h3>Custom cards</h3>
                            </div>
                            <p>You can customize cards to organize your commissions.
                                You can change their backgrounds or add labels to help you distinguish them.
                                A few highlights are:</p>
                            <ul>
                                <li>
                                    You can set start and end dates for commissions.
                                </li>
                                <li>
                                    You can take notes on the commission itself.
                                </li>
                                <li>
                                    You can send update comments about the commission, which will auto send to the
                                    client's progress view.
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {/* </div> */}
                {/* <LeftGears/> */}
                {/* </div> */}
                <div className="the-why-section">
                    <img className='gradient-overlay' src={GradientOverlay}/>
                    <h2>Why Use CommComm?</h2>
                    <div className='why-wrapper'>
                        <div className='why-row'>
                            <div className="why-box-one">
                                <h3>Effortless Client Updates</h3>
                                <p>{`Keep clients in the know with automated updates via CommComm as you track your
                                    commission's progress.`}</p>
                                <img src={WhyBoxOneImg}/>
                            </div>
                            <div className="why-box-two">
                                <h3>Easily Organize Your Commissions</h3>
                                <p>Sort your commissions into project folders and make personalized notes about your
                                    clients.</p>
                                <img src={WhyBoxTwoImg}/>
                            </div>
                        </div>
                        <div className='why-row'>
                            <div className="why-box-three">
                                <h3>Share Your Work Progress</h3>
                                <p>{`Clients can easily track their commissions' progress with unique shareable links
                                    provided by CommComm.`}</p>
                                <img src={WhyBoxThreeImg}/>
                            </div>
                            <div className="why-box-four">
                                <h3>Enhance Client Experience</h3>
                                <p>Boost client confidence by being transparent about your creation process with
                                    CommComm.</p>
                                <img src={WhyBoxFourImg}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="who-benefits-section">
                    <div className='benefits-info-container'>
                        <h2>Who is CommComm for?</h2>
                        <p>CommComm is good for keeping track of projects that take weeks or months.</p>
                        <h4>Jobs that may benefit from this include:</h4>
                        <div className='jobs-wrapper'>
                            <div className='jobs-row'>
                                <div className='job-box'>
                                    <img src={ArtistIcon}/>
                                    <p className='occupation'>Artists</p>
                                </div>
                                <div className='job-box'>
                                    <img src={PhotographerIcon}/>
                                    <p className='occupation'>Photographers</p>
                                </div>
                                <div className='job-box'>
                                    <img src={ArtisanIcon}/>
                                    <p className='occupation'>Artisans</p>
                                </div>
                                <div className='job-box'>
                                    <img src={DesignerIcon}/>
                                    <p className='occupation'>Designers</p>
                                </div>
                                <div className='job-box'>
                                    <img src={MusicianIcon}/>
                                    <p className='occupation'>Musicians</p>
                                </div>
                            </div>
                            <div className='jobs-row'>
                                <div className='job-box'>
                                    <img src={CatererIcon}/>
                                    <p className='occupation'>Caterers</p>
                                </div>
                                <div className='job-box'>
                                    <img src={TailorIcon}/>
                                    <p className='occupation'>Tailors</p>
                                </div>
                                <div className='job-box'>
                                    <img src={JewellerIcon}/>
                                    <p className='occupation'>Jewellers</p>
                                </div>
                                <div className='job-box'>
                                    <img src={EventPlannerIcon}/>
                                    <p className='occupation'>Event Planners</p>
                                </div>
                                <div className='job-box'>
                                    <img src={BuilderIcon}/>
                                    <p className='occupation'>Product Builders</p>
                                </div>
                            </div>
                        </div>
                        <p>Think CommComm is for you? Click the button below to create an account!</p>
                        <button className='bigBlueBtn' onClick={() => navigate(navPath.pricingTier)} type="button">Get
                            Started
                        </button>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
            {/* LoadingOverlay component used here */}
            {/* <LoadingOverlay isLoading={isLoading}/> */}
        </div>
    );
};

export default Home;