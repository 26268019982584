import React from 'react';
import Zorua from '../assets/zorua-profile-pic.jpeg';
import LoadingOverlay from "../components/Home/LoadingOverlay";
import {validUrl} from "../components/Helpers"; //example I made so I can see the profile image

interface IProfileView {
    firstName: string;
    lastName: string;
    biography: string;
    image: string;
    email: string;
    username: string;
    phoneNumber: string;
    shopLink: string;
    socialMedia1: string;
    socialMedia2: string;
    socialMedia3: string;
    venmo: string;
    isAccountLoading: boolean;
    displayRealName: boolean;
    isSideNavVisible: boolean;
}

const ProfileView: React.FC<IProfileView> = (props) => {

    return (
        <>
            <div className="App">
                <div className='editProfileImgBg'>
                    <div className="profile-view-wrapper" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <div className="profile-view-background-container">
                            <div className="profile-view-header">
                                {props.displayRealName ? <h1>{props.firstName} {props.lastName}</h1> :
                                    <h1>{props.username}</h1>}
                                {/* <img src={props.image} alt="Profile Pic" style={{width: "100px", height: "100px"}}/> */}
                                <img src={props.image === "" ? Zorua : props.image} alt="Profile Pic"/>
                            </div>
                            <div className="profile-view-account-info">
                                <div className="profile-view-row">
                                    <div className="profile-view-column">
                                        <label>Seller Bio</label>
                                        <p style={{marginBottom: "0px"}}>{props.biography}</p>
                                    </div>
                                </div>
                                <div className="profile-view-row">
                                    <div className="profile-view-column">
                                        <label>Business Link</label>
                                        {validUrl(props.shopLink) ?
                                            <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                               href={props.shopLink}>{props.shopLink}</a> :
                                            <p>{props.shopLink}</p>}
                                    </div>
                                </div>
                                <div className="profile-view-row">
                                    <div className="profile-view-column">
                                        <label>Social Media</label>
                                        {validUrl(props.socialMedia1) ?
                                            <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                               href={props.socialMedia1}>{props.socialMedia1}</a> :
                                            <p>{props.socialMedia1}</p>}
                                        {validUrl(props.socialMedia2) ?
                                            <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                               href={props.socialMedia2}>{props.socialMedia2}</a> :
                                            <p>{props.socialMedia2}</p>}
                                        {validUrl(props.socialMedia3) ?
                                            <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                               href={props.socialMedia3}>{props.socialMedia3}</a> :
                                            <p>{props.socialMedia3}</p>}
                                    </div>
                                </div>
                                <div className="profile-view-row">
                                    <div className="profile-view-column">
                                        <label>Email</label>
                                        <a className="hyperlink" href="mailto:{props.email}">{props.email}</a>
                                    </div>
                                </div>
                                <div className="profile-view-row">
                                    <div className="profile-view-column">
                                        <label>Phone Number</label>
                                        <a className="hyperlink" href="tel:{props.phoneNumber}">{props.phoneNumber}</a>
                                    </div>
                                </div>
                                <div className="profile-view-last-row">
                                    <div className="profile-view-column">
                                        <label>Venmo Handle</label>
                                        {props.venmo ?
                                            <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                               href={`https://account.venmo.com/u/${props.venmo}`}>https://account.venmo.com/u/{props.venmo}</a> :
                                            <p>N/A</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingOverlay isLoading={props.isAccountLoading}/>
        </>
    );
};

export default ProfileView;