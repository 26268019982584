import React, {useEffect, useState} from 'react';
import {CardProgressBar} from "./CardProgressBar";
import {CardProgressHistoryDetails} from "../../data/CardProgressHistoryDetails";
import {colorPicker, constantList, formatDateTime, getCurrentFormattedDateTime} from "../Helpers";
import {IconContext} from "react-icons";
import {MdAttachMoney, MdSend, MdCancel, MdMessage} from "react-icons/md";
import {CgDetailsLess, CgDetailsMore} from "react-icons/cg";

import parse from "html-react-parser";
import CommCommLogo from '../../assets/CommCommLogo-Black.png';
import {Card} from "../../data/Card";
import RichTextClientComments from "../Base/RichTextClientComments";
import {ClientDto} from "../../data/ClientDto";
import ComponentLoading from "../Base/Popup/ComponentLoading";
import DOMPurify from "dompurify";


interface IProjectProgressCard {
    card: Card;
    cardTitle: string;
    projectTitle: string;
    currentColumnId: string;
    columnNames: string[];
    columnIds: string[];
    isArchived: boolean;
    venmo: string;
    dueDate: string;
    completedDate: string;
    records: CardProgressHistoryDetails[];
    updateCardClientComments: (card: Card, comment: string) => void;
    client: ClientDto;
    isClientCommentSaving: boolean;
}

export const ProjectProgressCard: React.FunctionComponent<IProjectProgressCard> = (props) => {
    const [percentage] = useState(100 / props.columnIds.length);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activateTip, setActivateTip] = useState<boolean>(currentIndex !== props.columnNames.length - 1)
    const [showDetails, setShowDetails] = useState<boolean>(false)
    const [showOpenReply, setShowOpenReply] = useState<boolean>(false)
    const [isProfilePopupShown, setIsProfilePopupShown] = useState(false);
    const [showLoadingComponent, setShowLoadingComponent] = useState(false);
    const [replyText, setReplyText] = useState("");
    const [replyLength, setReplyLength] = useState(0);
    const [cardHistory, setCardHistory] = useState<CardProgressHistoryDetails[]>([]);

    useEffect(() => {
        setCardHistory(props.records)
    }, [])

    useEffect(() => {
        if (!props.isClientCommentSaving && showLoadingComponent) {
            setShowLoadingComponent(props.isClientCommentSaving)
            setShowDetails(true)
        }
    }, [props.isClientCommentSaving])

    useEffect(() => {
        const colIndex = props.columnIds.findIndex(c => c === props.currentColumnId)
        setCurrentIndex(colIndex);
        setActivateTip(colIndex !== props.columnIds.length - 1)
    }, [props.currentColumnId, props.columnIds])

    const sendReply = () => {
        if (replyLength <= constantList.richTextMaxLength && trimHtml(replyText).length > 0 && !props.isArchived) {
            props.updateCardClientComments(props.card, replyText);
            const history = CardProgressHistoryDetails.createWithDetails("", `${props.client.firstName} ${props.client.lastName}`, replyText, getCurrentFormattedDateTime())
            setCardHistory([history, ...cardHistory])
            setShowDetails(false)
            setReplyLength(0);
            setShowOpenReply(false);
            setShowLoadingComponent(true);
        }
    }

    const trimHtml = (text: string) => {
        return DOMPurify.sanitize(text, {USE_PROFILES: {html: false}}).trim()
    }

    const showActivity = () => {
        setShowDetails(!showDetails)
    }

    const showReplyBox = () => {
        setShowOpenReply(!showOpenReply)
    }

    const handleProfileClick = () => {
        setIsProfilePopupShown(!isProfilePopupShown);
    };

    return (
        <div className='project-progress-card'>
            <div className="project-title-date">
                <h2>{props.projectTitle} - {props.cardTitle}</h2>
                <p style={{backgroundColor: activateTip ? colorPicker.commCommYellow : colorPicker.confirmationGreen}}>{activateTip ? "Due: " + formatDateTime(props.dueDate) : "Completed: " + formatDateTime(props.completedDate)}</p>
            </div>
            <CardProgressBar segmentValue={percentage} columnNames={props.columnNames}
                             currentColumnIndex={currentIndex}/>
            <div className='project-button-row'>
                <div className='project-show-detail-btn'>
                    <button onClick={() => showActivity()}
                            type="button">{showDetails ? <><CgDetailsLess/> Hide Details</> : <><CgDetailsMore/> Show
                        Details</>}</button>
                </div>
                <div className='project-show-detail-btn'>
                    <button onClick={() => showReplyBox()} hidden={props.isArchived}
                            type="button">{showOpenReply ? <><MdCancel/> Cancel</> : <><MdMessage/> Reply</>}
                    </button>
                </div>
                <div className='project-client-comment-send-btn'>
                    <button hidden={!showOpenReply}
                            className={replyLength > constantList.richTextMaxLength || trimHtml(replyText).length === 0 ? "disabledBtn" : "greenBtn"}
                            disabled={replyLength > constantList.richTextMaxLength || trimHtml(replyText).length === 0}
                            onClick={() => sendReply()}
                            type="button"><MdSend/> Send
                    </button>
                </div>
                <IconContext.Provider value={{size: "1.2em"}}>
                    <a href={props.venmo && props.venmo.length > 0 ? `https://account.venmo.com/u/${props.venmo}` : "#"}>
                        <button style={{width: "fit-content"}}
                                className={activateTip ? "disabledBtn" : "blueBtn"}
                                disabled={activateTip} type="button">
                            <MdAttachMoney/> Leave Optional Tip
                        </button>
                    </a>
                </IconContext.Provider>
            </div>
            <div className="clientReply" hidden={!showOpenReply}>
                <RichTextClientComments onOpen={showOpenReply} replyText={replyText} setReply={setReplyText}
                                        setReplyLength={setReplyLength}/>
            </div>
            <ComponentLoading isLoadingComponent={showLoadingComponent}/>
            {props.records &&
                <div style={{display: showDetails ? "block" : "none", maxHeight: "300px", overflow: "auto"}}>
                    {cardHistory.map((details, index) => {
                        return (
                            <div key={index} className="history-details-container">
                                <img
                                    className="history-profile-image"
                                    src={details.profileUrl === null || details.profileUrl === "" ? CommCommLogo : details.profileUrl}
                                    alt="Account profile image."
                                    onClick={handleProfileClick}
                                />
                                <div className="history-details">
                                    <span className='history-author'>{details.editor}</span> {parse(details.detailText)}
                                    <div className='history-date'>
                                        {formatDateTime(details.createdAt)}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>}
        </div>
    );
};