import {Tag} from "./Tag";
import {Card} from "./Card";
import {CardDto} from "./CardDto";

export class ArchiveCardDetails extends CardDto {
    projectTitle: string = "";
    clientName: string = "";

    public constructor(card: Card, tags: Tag[], projectTitle: string, clientName: string) {
        super(card, tags);
        this.projectTitle = projectTitle;
        this.clientName = clientName;
    }
}