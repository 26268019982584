import React from 'react';
import '../../scss/LoadingOverlay.scss'; // Create a CSS file for styling the loading overlay
import LoadingCommComm from '../../assets/CommCommLogo-White.svg'

interface LoadingOverlayProps {
    isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
    return props.isLoading ? (
        <div className="loading-overlay">
            <div className="loading-svg-container">
                <img src={LoadingCommComm}/>
            </div>
        </div>
    ) : null;
};

export default LoadingOverlay;
