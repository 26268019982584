import {cancelTokenSource, requests} from './base/http-common'
import {Column} from "../data/Column";
import {Card} from "../data/Card";

class ColumnDataSource {

    BASEURL = "column"

    Post = {};

    Get = {
        fromId: (id: string): Promise<Column> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        allFromProject: (projectId: string): Promise<Column> => requests.get(`${this.BASEURL}/project/${projectId}`, cancelTokenSource.token),
    };

    Update = {
        addCard: (id: string, cardId: string): Promise<Column> =>
            requests.put(`${this.BASEURL}/addcard`, new Card({
                    id: cardId,
                    columnId: id
                }), cancelTokenSource.token
            ),
        lock: (id: string, isLocked: boolean): Promise<Column> =>
            requests.put(`${this.BASEURL}/lock`, new Column({
                    id: id,
                    isLocked: isLocked
                }), cancelTokenSource.token
            ),
        cardOrder: (id: string, acctId: string, order: string[]): Promise<Column> =>
            requests.put(`${this.BASEURL}/order`, new Column({
                    id: id,
                    accountId: acctId,
                    cardOrder: order
                }), cancelTokenSource.token
            ),
    };

    Delete = {
        remove: (id: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}`, cancelTokenSource.token).then(result => {
            return result === 200;
        })
    };
}

export default new ColumnDataSource();