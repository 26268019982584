import {cancelTokenSource, requests} from './base/http-common'
import {Client} from "../data/Client";
import {CardProgressDto} from "../data/CardProgressDto";

class ClientDataSource {

    BASEURL = "client"

    Post = {
        create: (accountId: string, firstName: string, lastName: string, email: string, phoneNumber: string, icon: string, iconColor: string, iconBackgroundColor: string): Promise<Client> =>
            requests.post(`${this.BASEURL}`, new Client({
                    accountId: accountId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phoneNumber: phoneNumber,
                    iconImage: icon,
                    iconColor: iconColor,
                    iconBackgroundColor: iconBackgroundColor
                }), cancelTokenSource.token
            )
    };

    Get = {
        fromId: (id: string): Promise<Client> => requests.get(`${this.BASEURL}/${id}`, cancelTokenSource.token),
        // allFromAccount: (accountId: string): Promise<ClientData[]> => requests.get(`${this.BASEURL}/all/${accountId}`, cancelTokenSource.token),
        // allDetailsFromAccount: (accountId: string): Promise<ClientScreenDto[]> => requests.get(`${this.BASEURL}/details/all/${accountId}`, cancelTokenSource.token),
        allArchivedFromAccount: (accountId: string): Promise<Client[]> => requests.get(`${this.BASEURL}/archive/all/${accountId}`, cancelTokenSource.token),
        allProjectCardsForClient: (accountId: string, clientId: string): Promise<CardProgressDto> => requests.get(`${this.BASEURL}/${clientId}/acct/${accountId}`, cancelTokenSource.token)
    };

    Update = {
        update: (id: string, acctId: string, firstName: string, lastName: string, email: string, phoneNumber: string, icon: string, iconColor: string, iconBackgroundColor: string): Promise<Client> =>
            requests.put(`${this.BASEURL}/update`, new Client({
                    id: id,
                    accountId: acctId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phoneNumber: phoneNumber,
                    iconImage: icon,
                    iconColor: iconColor,
                    iconBackgroundColor: iconBackgroundColor
                }), cancelTokenSource.token
            ),
        // firstName: (id: string, firstName: string): Promise<ClientData> =>
        //     requests.put(`${this.BASEURL}/${id}/fname`, new ClientData({
        //             id: id,
        //             firstName: firstName
        //         }), cancelTokenSource.token
        //     ),
        // lastName: (id: string, lastName: string): Promise<ClientData> =>
        //     requests.put(`${this.BASEURL}/${id}/lname`, new ClientData({
        //             id: id,
        //             lastName: lastName
        //         }), cancelTokenSource.token
        //     ),
        phoneNumber: (id: string, acctId: string, phoneNumber: string): Promise<Client> =>
            requests.put(`${this.BASEURL}/phone`, new Client({
                    id: id,
                    accountId: acctId,
                    phoneNumber: phoneNumber
                }), cancelTokenSource.token
            ),
        email: (id: string, acctId: string, email: string): Promise<Client> =>
            requests.put(`${this.BASEURL}/email`, new Client({
                    id: id,
                    accountId: acctId,
                    email: email
                }), cancelTokenSource.token
            ),
        notes: (id: string, acctId: string, notes: string): Promise<Client> =>
            requests.put(`${this.BASEURL}/notes`, new Client({
                    id: id,
                    accountId: acctId,
                    notes: notes
                }), cancelTokenSource.token
            ),
        archive: (id: string, acctId: string): Promise<Client> =>
            requests.put(`${this.BASEURL}/archive`, new Client({
                    id: id,
                    accountId: acctId,
                }), cancelTokenSource.token
            ),
        unarchive: (id: string, acctId: string): Promise<Client> =>
            requests.put(`${this.BASEURL}/unarchive`, new Client({
                    id: id,
                    accountId: acctId,
                }), cancelTokenSource.token
            ),
        // emailNotification: (id: string, acctId: string, flag: boolean): Promise<ClientData> => requests.put(`${this.BASEURL}/${id}/enotify`, new ClientData({
        //     id: id,
        //     accountId: acctId,
        //     receiveEmailNotification: flag
        // }), cancelTokenSource.token
        // ),
        // phoneNumberNotification: (id: string, acctId: string, flag: boolean): Promise<ClientData> => requests.put(`${this.BASEURL}/${id}/pnotify`, new ClientData({
        //     id: id,
        //     accountId: acctId,
        //     receivePhoneNumberNotification: flag
        // }), cancelTokenSource.token
        // ),
        // //Need to figure out how to retrieve id. Maybe get it from url.
        // emailSelf: (id: string, email: string): Promise<ClientData> => requests.put(`${this.BASEURL}/${id}/self/email`, new ClientData({
        //         id: id,
        //         email: email
        //     }), cancelTokenSource.token
        // ),
        // phoneNumberSelf: (id: string, phoneNumber: string): Promise<ClientData> => requests.put(`${this.BASEURL}/${id}/self/phonenumber`, new ClientData({
        //     id: id,
        //     phoneNumber: phoneNumber
        // }), cancelTokenSource.token
        // ),
        emailNotificationSelf: (id: string, notify: boolean): Promise<Client> => requests.put(`${this.BASEURL}/${id}/self/email/notify`, new Client({
                id: id,
                receiveEmailNotification: notify
            }), cancelTokenSource.token
        ),
        phoneNumberNotificationSelf: (id: string, notify: boolean): Promise<Client> => requests.put(`${this.BASEURL}/${id}/self/phonenumber/notify`, new Client({
                id: id,
                receivePhoneNumberNotification: notify
            }), cancelTokenSource.token
        ),
    };

    Delete = {
        remove: (id: string): Promise<boolean> => requests.delete(`${this.BASEURL}/${id}`, cancelTokenSource.token).then(result => {
            return result === 200;
        })
    };
}

export default new ClientDataSource();