import React, {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Tag} from "../../data/Tag";
import {CheckOptions} from "../../data/CheckOptions";
import {AiFillEdit} from "react-icons/ai";

interface ISideMultiselectPopupOptions {
    title: string;
    tagList: Tag[];
    selectedTagList: string[];
    closePopup: () => void;
    openCreateLabelPopup: () => void;
    openEditLabelPopup: (text: string, color: string, id: string) => void;
    saveText: string;
    selectOption: (option: string) => void;
    unSelectOption: (option: string) => void;
}

const SideMultiselectPopupOptions: React.FunctionComponent<ISideMultiselectPopupOptions> = (props) => {
    const [searchLabel, setSearchLabel] = useState("");
    const [options, setOptions] = useState<CheckOptions[]>([])

    useEffect(() => {
        let optionList = props.tagList.map((item) => {
            if (props.selectedTagList.includes(item.id)) {
                return new CheckOptions(item.id, item.name, item.color, true);
            } else {
                return new CheckOptions(item.id, item.name, item.color, false);
            }
        })
        setOptions(optionList);
    }, [props.tagList])

    useEffect(() => {
        if (searchLabel !== "") {
            let optionList = props.tagList.filter(t => t.name.toLowerCase().includes(searchLabel.toLowerCase())).map((item) => {
                if (options.find(o => o.id === item.id && o.checked)) {
                    return new CheckOptions(item.id, item.name, item.color, true);
                } else {
                    return new CheckOptions(item.id, item.name, item.color, false);
                }
            })
            setOptions(optionList);
        } else {
            let optionList = props.tagList.map((item) => {
                if (props.selectedTagList.includes(item.id)) {
                    return new CheckOptions(item.id, item.name, item.color, true);
                } else {
                    return new CheckOptions(item.id, item.name, item.color, false);
                }
            })
            setOptions(optionList);
        }
    }, [searchLabel])

    const handleCheck = (option: string) => {
        const newOptions = [...options];
        const optionIndex = newOptions.findIndex(x => x.id === option);
        newOptions[optionIndex] = {
            ...newOptions[optionIndex],
            checked: !newOptions[optionIndex].checked
        }
        setOptions(newOptions);
        if (newOptions[optionIndex].checked) {
            props.selectOption(newOptions[optionIndex].id)
        } else {
            props.unSelectOption(newOptions[optionIndex].id)
        }
    }

    return (
        <div className="sideActionBtnPopup">
            <div className="sideBtnPopupHeader">
                <h4>{props.title}</h4>
                <div style={{padding: "0px 0px 5px 5px"}} onClick={props.closePopup}><CloseIcon
                    style={{cursor: "pointer"}}/></div>
            </div>
            <p className="featureNote">Use the field below to search for a label.</p>
            <input className="form-control searchLabelInput" id="optionInput" value={searchLabel}
                   onChange={(e) => setSearchLabel(e.target.value)} placeholder="Search Labels..."/>
            <div className="radioBtnWrapper">
                {options && options.map((item, index) => {
                    return <div className="tagCheckboxOption" key={index}>
                        <input style={{cursor: "pointer"}} type="checkbox" name={item.id}
                               onChange={() => handleCheck(item.id)}
                               checked={item.checked}/>
                        <div className="editTagTitleAndIcon" style={{backgroundColor: item.color}}>
                            <span>{item.name}</span>
                            <AiFillEdit className="labelEditIcon" onClick={() => {
                                props.openEditLabelPopup(item.name, item.color, item.id)
                            }}/>
                        </div>
                    </div>
                })}
            </div>
            {/* <Button className="blueBtn" type="button" style={{width:'100%', margin:'15px 0px'}} onClick={() => {
                save()
            }}>
                {props.saveText}
            </Button> */}
            <button className="blueBtn" type="button" style={{width: '100%', margin: '10px 0px 5px'}} onClick={() => {
                props.openCreateLabelPopup()
            }}>
                Create New Label
            </button>
        </div>
    );
}

export default SideMultiselectPopupOptions
