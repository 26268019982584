import React from 'react';
import {CardDto} from "../../data/CardDto";
import {cardAgingWithCompletedDate, cardAgingWithDueDate, colorPicker, formatDate} from "../Helpers";
import {IoMdClock} from "react-icons/io";
import {BsFillCalendar2CheckFill} from "react-icons/bs";

interface IStationaryCard {
    card: CardDto;
    selectedCard: string;
    selectCard: (cardDetails: CardDto) => void;
    projectTitle: string
}

const StationaryClientCard: React.FunctionComponent<IStationaryCard> = (props) => {

    const setDueDateColor = (dueDate: string) => {
        if (new Date(dueDate).getTime() < new Date().getTime()) {
            if (props.card.completedDate) {
                const cardAging = cardAgingWithCompletedDate(props.card.startDate, props.card.dueDate, props.card.completedDate);
                if (cardAging >= 100) {
                    return "red"
                } else if (cardAging >= 75) {
                    return "orange"
                } else if (cardAging >= 50) {
                    return "yellow"
                } else if (cardAging >= 25) {
                    return "teal"
                } else {
                    return "green"
                }
            } else {
                return "red";
            }
        } else {
            const cardAging = cardAgingWithDueDate(props.card.startDate, props.card.dueDate);
            if (cardAging >= 75) {
                return "orange"
            } else if (cardAging >= 50) {
                return "yellow"
            } else if (cardAging >= 25) {
                return "teal"
            } else {
                return "green"
            }
        }
    }

    return (
        <>
            <div style={{padding: "0px 10px"}}
                 onClick={() => props.selectCard(props.card)}
            >
                <div
                    className={props.card.id === props.selectedCard ? "card-selected" : "card-view"}
                    style={{backgroundColor: `${props.card.cardColor}`, opacity: props.card.isArchived ? 0.6 : 1.0}}>

                    <div className="card-view-content-container">
                        <p className="card-title">
                            {props.card.title} ({props.card.quantity})
                        </p>

                        <div className="card-label-wrapper">
                            <p key={props.card.projectId} className="card-label-tag"
                               style={{backgroundColor: colorPicker.labelBlue, color: colorPicker.white}}>
                                {props.projectTitle}
                            </p>
                            {props.card.tagDetails && props.card.tagDetails.map((item, index) => (
                                <p key={item?.id ?? index} className="card-label-tag"
                                   style={{backgroundColor: item?.color ?? "", color: colorPicker.white}}>
                                    {item?.name ?? ""}
                                </p>
                            ))}
                        </div>
                        {props.card.dueDate && !props.card.completedDate && (
                            <div className="card-due-date">
                                <IoMdClock size={20}/>
                                <p>{formatDate(props.card.dueDate)}</p>
                            </div>
                        )}

                        {props.card.completedDate && (
                            <div className="card-completed-date">
                                <BsFillCalendar2CheckFill size={15}/>
                                <p>{formatDate(props.card.completedDate)}</p>
                            </div>
                        )}
                    </div>

                    <div style={{
                        height: "8px",
                        width: "100%",
                        backgroundColor: `${setDueDateColor(props.card.dueDate)}`,
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px"
                    }}>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StationaryClientCard