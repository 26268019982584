import axios, {AxiosResponse, CancelToken} from "axios";
import axiosRetry from 'axios-retry';
import UseToken from "../../context/UseToken";
import {auth} from '../../config/Firebase'

//https://stackoverflow.com/questions/75082367/how-to-refresh-token-in-axios
//https://stackoverflow.com/questions/69642504/what-is-the-best-way-to-send-firebase-user-token-from-frontend-to-backend-in-api
// class AxiosService {
//     private instance: AxiosInstance;
//
//     constructor() {
//         this.instance = axios.create({
//             baseURL: 'http://localhost:5283/api/',
//             headers: {
//                 "Content-type": "application/json",
//                 "Authorization": "Bearer " + sessionStorage.getItem('credentials')
//             }
//         });
//         this.initializeResponseInterceptor();
//         axiosRetry(this.instance, {
//             retries: 3,
//             retryDelay: (retryCount) => {
//                 return 1000
//             },
//             retryCondition: (error) => {
//                 if (error.response) {
//                     return error.response.status === 401 || error.response.status === 408 || error.response.status >= 500;
//                 }
//                 return false;
//             }
//         })
//     }
//
//     initializeResponseInterceptor = () => {
//         this.instance.interceptors.response.use(
//             function (response) {
//                 return response;
//             },
//             function (error) {
//                 return Promise.reject(error);
//             }
//         );
//         this.instance.interceptors.request.use(
//             function (config) {
//                 // const token = # Your token goes over here;
//                 // if (token) {
//                 //     config.headers.accessToken = token;
//                 // }
//                 // Do something before request is sent
//                 return config;
//             },
//             function (error) {
//                 // Do something with request error
//                 return Promise.reject(error);
//             }
//         )
//     };
//
//     handleResponse = ({data}: AxiosResponse) => data;
//
//     handleError = (error: any) => Promise.reject(error);
//
//     responseBody = (response: AxiosResponse) => response.data;
//     responseStatus = (response: AxiosResponse) => response.status;
//
//     get = (path: string) => this.instance.get(path, {
//         headers: {
//             'Authorization': `Bearer ${sessionStorage.getItem('credentials')}`
//         }
//     }).then(responseBody);
//
//     post = (path: string, payload?: any) => this.instance.post(path, payload, {
//         headers: {
//             'Authorization': `Bearer ${sessionStorage.getItem('credentials')}`
//         }
//     }).then(responseBody);
//
//     put = (path: string, payload?: any) => this.instance.put(path, payload, {
//         headers: {
//             'Authorization': `Bearer ${sessionStorage.getItem('credentials')}`
//         }
//     }).then(responseBody);
//
//     delete = (path: string) => this.instance.delete(path, {
//         headers: {
//             'Authorization': `Bearer ${sessionStorage.getItem('credentials')}`
//         }
//     }).then(responseStatus);
// }
//
// export default new AxiosService();


// export const AxiosHttp = () => {
//     const {user} = UserAuth();
//     const [token, setToken] = useState('')
//
//     useEffect(() => {
//         if (user) {
//             user.getIdToken().then((tokn) => {
//                 setToken(tokn)
//             })
//         }
//     }, [user])
//
//     const axiosInstance = axios.create({
//         baseURL: "http://localhost:5283/api/",
//         headers: {
//             "Content-type": "application/json",
//             // "Authorization": "Bearer " + user?.getIdToken()
//         }
//     });
//
//     axiosInstance.interceptors.request.use(
//         function (config) {
//             // const token = # Your token goes over here;
//             // if (token) {
//             //     config.headers.accessToken = token;
//             // }
//             // Do something before request is sent
//             return config;
//         },
//         function (error) {
//             // Do something with request error
//             return Promise.reject(error);
//         }
//     );
//
//     axiosInstance.interceptors.response.use(
//         function (response) {
//             return response;
//         },
//         function (error) {
//             return Promise.reject(error);
//         }
//     );
//
//     axiosRetry(axiosInstance, {
//         retries: 3,
//         retryDelay: (retryCount) => {
//             return 1000
//         },
//         retryCondition: (error) => {
//             if (error.response) {
//                 return error.response.status === 401 || error.response.status === 408 || error.response.status >= 500;
//             }
//             return false;
//         }
//     })
//
//     const responseBody = (response: AxiosResponse) => response.data;
//     const responseStatus = (response: AxiosResponse) => response.status;
//
//     return {
//         get: (url: string) => axiosInstance.get(url, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         }).then(responseBody),
//         post: (url: string, body: {}) => axiosInstance.post(url, body, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         }).then(responseBody),
//         put: (url: string, body: {}) => axiosInstance.put(url, body, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         }).then(responseBody),
//         delete: (url: string) => axiosInstance.delete(url, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         }).then(responseStatus),
//     };
// }


// const {user} = UserAuth();
//
// const getToken = function (): string {
//     if (user) {
//         user.getIdToken().then((token) => {
//             return token;
//         })
//     }
//     return '';
// };
export const cancelTokenSource = axios.CancelToken.source();

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "https://localhost:7229/api/",
    headers: {
        "Content-type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    function (config) {
        const token = sessionStorage.getItem('credentials');
        if (token) {
            if (config && config.headers) {
                config.headers["Authorization"] = `Bearer ${sessionStorage.getItem('credentials')}`;
            }
        }
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            auth.onIdTokenChanged((user) => {
                if (user) {
                    UseToken().setRefreshToken(user.refreshToken);
                    user.getIdToken().then((newToken) => {
                        UseToken().setToken(newToken)
                    })
                }
            })
            // const refresh = sessionStorage.getItem('refresh');
            // UseToken().setToken(refresh ?? "")
        }
        return Promise.reject(error);
    }
);

axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: (retryCount) => {
        return 1000
    },
    retryCondition: (error) => {
        if (error.response) {
            return error.response.status === 401 || error.response.status === 408 || error.response.status >= 500;
        }
        return false;
    }
})

const responseBody = (response: AxiosResponse) => response.data;
const responseStatus = (response: AxiosResponse) => response.status;

export const requests = {
    get: (url: string, cancelToken?: CancelToken) => axiosInstance.get(url, {
        // withCredentials: true,
        cancelToken: cancelToken
    }).then(responseBody).catch(error => {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Request error', error.message);
            throw new Error(error.message);
        }
    }),
    post: (url: string, body: {}, cancelToken?: CancelToken) => axiosInstance.post(url, body, {
        // withCredentials: true,
        cancelToken: cancelToken
    }).then(responseBody).catch(error => {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Request error', error.message);
            throw new Error(error.message);
        }
    }),
    put: (url: string, body: {}, cancelToken?: CancelToken) => axiosInstance.put(url, body, {
        // withCredentials: true,
        cancelToken: cancelToken
    }).then(responseBody).catch(error => {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Request error', error.message);
            throw new Error(error.message);
        }
    }),
    delete: (url: string, cancelToken?: CancelToken) => axiosInstance.delete(url, {
        // withCredentials: true,
        cancelToken: cancelToken
    }).then(responseStatus).catch(error => {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Request error', error.message);
            throw new Error(error.message);
        }
    }),
};