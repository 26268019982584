import React, {useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";

interface IPrivacyPolicy {

}

const PrivacyPolicy: React.FC<IPrivacyPolicy> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const privacyPolicyContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={privacyPolicyContainerRef}
            />
            <div className="privacy-policy-container" ref={privacyPolicyContainerRef}>
                <div className='privacy-policy-content-wrapper'>
                    <h1>CommComm Privacy Policy</h1>
                    <p>Your privacy is important to FooBear LLC. Please read this privacy notice carefully as it
                        contains
                        important information on who we are, how and why we collect, store, use, and share personal
                        information,
                        your rights in relation to your personal information and on how to contact us and supervisory
                        authorities
                        in the event you have a complaint.</p>
                    <p>By using our website <b><a href="https://commcomm.app/"
                                                  className='hyperlink'>www.commcomm.app</a></b>, you signify your
                        consent to the terms of our Privacy Policy. If you do not agree with any terms of this Privacy
                        Policy,
                        please do not use this site or submit any personal information to us.</p>
                    <br/>

                    <h2>1. Who We Are</h2>
                    <p>FooBear LLC (doing business as CommComm) and operating at <b><a href="https://commcomm.app/"
                                                                                       className='hyperlink'>www.commcomm.app</a></b> collects,
                        uses and is responsible for
                        certain personal information about you.</p>
                    <br/>

                    <h2>{`2. Children's Online Privacy Protection Act`}</h2>
                    <p>This website and any products and services offered herein are not intended for persons under the
                        age of 18.
                        CommComm does not knowingly collect information from anyone under 18 years of age. CommComm
                        prohibits children under
                        the age of 18 from using all interactive portions of this website and app, including leaving any
                        comments,
                        sending in-app messages, filling out forms, or otherwise submitting information. CommComm will
                        not knowingly
                        collect personally identifiable information from children under 18. If CommComm learns it has
                        any information or
                        content from anyone under the age of 18, it will delete that information.</p>
                    <br/>

                    <h2>3. The Personal Information We Collect and Use</h2>
                    <div className='indent'>
                        <h3>a. Information Collected by Us</h3>
                        <div className='indent'>
                            <p>CommComm may collect, use, and is responsible for certain personal information that you
                                provide when you voluntarily
                                sign up for e-mails or free gifts, create an app account, fill out any type of form, or
                                otherwise contact CommComm via
                                an online form or e-mail. The information collected may include your name, e-mail,
                                address, and/or phone number.
                                You are not required to provide any personally identifiable information to merely access
                                or visit this website and
                                app. When you provide personal information through our website, the information may be
                                sent to servers located in
                                the United States and other countries around the world.</p>
                            <p>CommComm may collect domain information, your numerical IP address, the type of
                                browser/phone you use, which pages you
                                view, and the files you request. We may also use “cookies” (small files saved on your
                                hard drive by your web browser)
                                to analyze website performance, track user patterns, save information from your previous
                                visits and customize your
                                experience.</p>
                            <p>{`If your browser sends a "Do Not Track" signal, only a generic cookie will be placed on your device while the website
                            is accessed.`}</p>
                            <p>{`We will ask for your consent to allow us to use cookies. CommComm or its third-party vendors may collect non-personal
                            information through the use of these technologies. Non-personal information might include the browser you use, the
                            type of computer you use, and technical information about your means of connection to our website and app such as
                            the operating systems and the Internet service providers utilized, and other similar information. CommComm's systems
                            may also automatically gather information about the areas you visit and search terms you use on this website and app
                            and about the links you may select from within this website and app to other areas of the Internet.`}</p>
                            <p>If you are located in the European Economic Area (“EEA”), we are regulated under the
                                General Data Protection Regulation
                                which applies across the European Union and we are responsible as controller of that
                                personal information for the
                                purposes of those laws.</p>
                            <p>If you are located in the United Kingdom (“UK”), we are regulated under UK data
                                regulations known as “UK GDPR.”</p>
                        </div>
                        <h3>b. Information Collected from Other Sources</h3>
                        <div className='indent'>
                            <p>We also obtain emails and donor information from other sources, such as Mail Chimp and
                                Donor Box.</p>
                        </div>
                        <h3>c. How we use your personal information</h3>
                        <div className='indent'>
                            <p>CommComm collects such information in order to send e-mails, provide services and
                                products, oversee contests and promotions
                                and improve website and app performance and customer service.
                            </p>
                        </div>
                        <h3>d. Who We Share Your Personal Information With</h3>
                        <div className='indent'>
                            <p>CommComm respects your privacy and will never sell, trade, or transfer your personally
                                identifiable information to third parties
                                (beyond what is necessary for fulfilling a customer transaction or for the basic
                                functionality of an online service) without
                                your consent.</p>
                            <p>CommComm may release personal information to enforce its Website and App Terms and
                                Conditions of Use, other Terms and Conditions,
                                manage its business, protect users or the general public, or to otherwise comply with
                                legal obligations.</p>
                            <p>If you give CommComm your permission, it may also use personal identification information
                                for internal or external marketing and
                                promotional purposes.</p>
                            <p>On occasion, CommComm may collect personal identification information from you in
                                connection with optional contests, special
                                offers, or promotions. CommComm will share such information with necessary third parties
                                for the purpose of carrying out the contest,
                                special offer, or promotion. We will ask for your consent to such disclosure and use of
                                such information, prior to your participation
                                in the contest, special offer, or promotion.</p>
                            <p>We reserve the right to transfer personal information in the event that we merge with or
                                are acquired by a third party. We also may
                                disclose your personal information for any other purpose permitted by law or to which
                                you consent.</p>
                            <p>We will not share your personal information with any other third party.</p>
                        </div>
                        <h3>e. Whether Information Has to Be Provided by You and Why</h3>
                        <div className='indent'>
                            <p>The provision of a name, username, email, phone number and date of birth is required from
                                you to enable us to create an account on
                                this app on your behalf: We do not require you to provide any personal data in order to
                                access our website. We will inform you when
                                we collect it whether you are required to provide the information to us.</p>
                        </div>
                        <h3>f. How Long Your Personal Information Will Be Kept</h3>
                        <div className='indent'>
                            <p>We will hold your name, username, email, phone number and date of birth, and contact
                                details until you let us know you would like
                                for us to delete it or unsubscribe from our marketing contacts, which you are free to do
                                at any time. We will hold all client and
                                customer personal data in our files for six years.</p>
                        </div>
                        <h3>g. Reasons We Can Collect and Use Your Personal Information</h3>
                        <div className='indent'>
                            <p>CommComm collects and uses your personal information to send e-mails, deliver services
                                and products, oversee contests and promotions, and
                                improve website and app performance and customer service.</p>
                        </div>
                    </div>
                    <br/>
                    <h2>4. Use and Transfer of Your Information Out of the EEA, UK</h2>
                    <p>This website and app is operated in the United States and third parties with whom we might share
                        your personal information as explained
                        above are also located in the United States. If you are located in the EEA, the UK, or elsewhere
                        outside of the United States, please be
                        aware that <b>any information you provide will be transferred to the United States</b>. By using
                        this website and app, participating in
                        any of their services, and/or providing your information, you consent to this transfer.</p>
                    <p>These countries do not have the same data protection laws as the EEA or UK. While the European
                        Commission has not given a formal decision that
                        the United States provides an adequate level of data protection similar to those which apply in
                        the EEA, any transfer of your personal information
                        will be subject to the derogation in Article 49 permitting non-repetitive transfers that concern
                        only a limited number of data subjects, as permitted
                        by Article 49 of the General Data Protection Regulation that is designed to help safeguard your
                        privacy rights and give you remedies in the unlikely
                        event of a misuse of your personal information.</p>
                    <p>If you would like further information, (see “How to contact us” below. We will not otherwise
                        transfer your personal data outside of the EEA or UK,
                        or to any organization (or subordinate bodies) governed by public international law or which is
                        set up under any agreement between two or more countries.</p>
                    <br/>
                    <h2>5. Your Rights</h2>
                    <p>If you want to unsubscribe from receiving e-mails from CommComm, you may do so at any time. Each
                        e-mail from CommComm includes instructions for unsubscribing
                        from these e-mail communications.</p>
                    <p>If you are covered by the General Data Protection Regulation, or other relevant privacy
                        regulations, you have a number of important rights free of charge.
                        In summary, those include rights to:</p>
                    <ul>
                        <li>Fair processing of information and transparency over how we use your use personal
                            information
                        </li>
                        <li>Access to your personal information and to certain other supplementary information that this
                            Privacy Notice is already designed to address
                        </li>
                        <li>Require us to correct any mistakes in your information which we hold</li>
                        <li>Require the erasure of personal information concerning you in certain situations</li>
                        <li>Receive the personal information concerning you which you have provided to us, in a
                            structured, commonly used, and machine-readable format and have the
                            right to transmit those data to a third party in certain situations
                        </li>
                        <li>Object at any time to processing of personal information concerning you for direct
                            marketing
                        </li>
                        <li>Object to decisions being taken by automated means which produce legal effects concerning
                            you or similarly significantly affect you
                        </li>
                        <li>Object in certain other situations to our continued processing of your personal
                            information
                        </li>
                        <li>Otherwise restrict our processing of your personal information in certain circumstances</li>
                    </ul>
                    <p>You may also have the right to claim compensation for damages caused by our breach of any data
                        protection laws.</p>
                    <p>For further information on each of those rights, including the circumstances in which they apply,
                        visit <b><a href="www.gdpr.eu" className='hyperlink'>www.gdpr.eu</a></b>;
                        <b><a
                            href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
                            className='hyperlink'>https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a></b>;
                        or <b><a href="https://www.priv.gc.ca/en"
                                 className='hyperlink'>https://www.priv.gc.ca/en</a></b>.</p>
                    <p>If you would like to exercise any of those rights, please:</p>
                    <ul>
                        <li>Email, call, or write to us</li>
                        <li>Provide us enough information to identify you (e.g., first and last name, user name,
                            e-mail)
                        </li>
                        <li>Provide us proof of your identity and address (a copy of your driver’s license or passport
                            and a recent utility or credit card bill)
                        </li>
                        <li>Provide us with the information to which your request relates</li>
                    </ul>
                    <br/>
                    <h2>6. Keeping Your Personal Information Secure</h2>
                    <p>We have appropriate security measures in place to prevent personal information from being
                        accidentally lost, used, or accessed in an unauthorized way.
                        We limit access to your personal information to those who have a genuine business need to know
                        it. Those processing your information will do so only in
                        an authorized manner and may be subject to a duty of confidentiality.</p>
                    <p>We also have procedures in place to deal with any suspected data security breach. We will notify
                        you and any applicable authorities of a suspected data
                        security breach where we are legally required to do so.</p>
                    <p>It is important to understand that no security measures are absolute. We cannot guarantee the
                        safety of any information you provide to us.</p>
                    <p>Please note that any comments or information that you post on the website and app, including the
                        CommComm social media pages or groups, become public and
                        third parties may use your information. CommComm is not responsible for any unauthorized uses by
                        third parties in such context. You disclose such information
                        at your own risk.</p>
                    <br/>
                    <h2>7. Links to Other Sites</h2>
                    <p>You may see advertising or other content on this website that links to the sites and services of
                        our partners, suppliers, advertisers, sponsors, licensors,
                        or other third parties. Any products or services reached through a third-party link are subject
                        to separate privacy policies. CommComm is not responsible for
                        or liable for any content on or actions taken by such third-party websites.</p>
                    <br/>
                    <h2>8. How to Complain</h2>
                    <p>We hope that we can resolve any question or concern you raise about our use of your
                        information.</p>
                    <p>If you are covered by the General Data Protection Regulation or UK GDPR, you may lodge a
                        complaint with a supervisory authority, in particular in the UK or
                        European Union (or European Economic Area) state where you work, normally live, or where any
                        alleged infringement of data protection laws occurred.</p>
                    <br/>
                    <h2>9. Changes to This Privacy Notice</h2>
                    <p>This policy is effective as of November 19th, 2021. We may change, modify or update this Privacy
                        Policy at any time and will notify you of any fundamental
                        changes by email or postal mail. Otherwise, we will reflect any such modifications to this
                        Privacy Policy on our website and app. We suggest that you periodically
                        consult this Privacy Policy. Your continued use of our website after any such changes
                        constitutes your acceptance of this Privacy Policy, as revised.</p>
                    <br/>
                    <h2>10. How to Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, the information we hold about
                        you, or you wish to change your personal information in our records,
                        please contact us <b><a href="https://commcomm.app/contact-us"
                                                className='hyperlink'>here</a></b> or write to us at the address below.
                    </p>
                    <br/>
                    <p>FooBear LLC</p>
                    <p>Attn: Privacy Policy Inquiry</p>
                    <p>9105C SW 19th PL</p>
                    <p>Davie, FL 33324</p>
                    <br/>
                    <p>{`You can confidentially contact CommComm's Data Protection Officer at `}<b><a
                        href="mailto:dpo@commcomm.app" className='hyperlink'>dpo@commcomm.app</a></b>.</p>
                    <br/>
                    <h2>11. Do You Need Extra Help?</h2>
                    <p>If you would like this notice in another format (for example: audio, large print, braille) please
                        contact us (see “How to contact us” above).</p>
                    <br/>
                    <p className="update-text">Policy Last Updated November 16th, 2023</p>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default PrivacyPolicy;