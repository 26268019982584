import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {navPath} from "../components/Helpers";
import CommComm from "../assets/CommCommLogo-Combo-White.png";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {ReactComponent as ErrorCheckout} from '../assets/Error-Checkout.svg';

interface ICheckoutError {

}

const CheckoutError: React.FC<ICheckoutError> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="App">
            <div className="checkout-success-container">
                <div className='checkout-success-content-wrapper'>
                    <Link to={navPath.home}>
                        <img className='commCommUnsubscribeLogo' src={CommComm} onClick={() => navigate(navPath.home)}/>
                    </Link>
                    <h1>Something Went Wrong</h1>
                    <p>You might be having a network connection problem, or the payment provider cannot be reached at
                        the moment.</p>
                    <ErrorCheckout/>
                    <button className='blueBtn' type="button" onClick={() => navigate(navPath.manageSubscription)}>
                        <AiOutlineArrowLeft/> Back to CommComm subscriptions
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutError;