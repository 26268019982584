import React, {PropsWithChildren} from "react";
import {Modal} from "react-bootstrap";
import {BiSolidErrorAlt} from "react-icons/bi";
import {colorPicker} from "../../Helpers";


interface IModalErrorReloadMessageProps extends PropsWithChildren {
    isOpen: boolean;
    toggle: (show: boolean) => void;
    modalTitle: string;
    reload: () => void;
}

export const ModalErrorReloadMessage: React.FunctionComponent<IModalErrorReloadMessageProps> = ({
                                                                                                    children,
                                                                                                    ...props
                                                                                                }) => (
    <div>
        <Modal
            show={props.isOpen}
            onHide={() => props.toggle(!props.isOpen)}
        >
            <div className={"modalErrorMessageBackground"}>
                <Modal.Header closeButton aria-label="Hide" closeVariant="white">
                </Modal.Header>
                <Modal.Body>
                    <div className="modalWarningMessageContentWrapper">
                        <div className="modalMessageRow">
                            <BiSolidErrorAlt style={{color: colorPicker.errorRed, width: "100px"}} size={130}/>
                            <div className='modalErrorMessageColumn'>
                                <div className="modalErrorMessageHeader">{props.modalTitle}</div>
                                {children}
                            </div>
                        </div>
                        <div className="modalMessageRow">
                            <button className='blueBtn' onClick={props.reload}
                                    type="button">Refresh Page
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    </div>
);