import {Outlet} from 'react-router-dom';
import React from "react";
import TopNavBar from "./TopNavBar";
import {Client} from "../../../data/Client";
import {AccountData} from "../../../data/AccountData";

export interface INavigationTopBarLayout {
    selectedClient: Client,
    changeEmailNotification: (clientId: string, notify: boolean) => void,
    changePhoneNotification: (clientId: string, notify: boolean) => void,
    setUnprotectedAccountId: (acct: string) => void
    account: AccountData | undefined;
}

export const NavigationTopBarLayout = (props: INavigationTopBarLayout) => (
    <>
        <TopNavBar setUnprotectedAccountId={props.setUnprotectedAccountId}
                   selectedClient={props.selectedClient}
                   changeEmailNotification={props.changeEmailNotification}
                   account={props.account}
                   changePhoneNotification={props.changePhoneNotification}/>
        <Outlet/>
    </>
);