import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import CommComm from "../../assets/CommCommLogo-Combo-White.png";
import {AiFillYoutube} from "react-icons/ai";
import {navPath} from "../Helpers";
import packageJson from '../../../package.json';

interface ICommCommFooterProps {

}

const CommCommFooter: React.FC<ICommCommFooterProps> = () => {
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="footer">
                <div className="footer-content-wrapper">
                    <div className='logo-and-links-wrapper'>
                        <Link to={navPath.home}>
                            <img className='navCommCommLogo' src={CommComm} onClick={() => navigate(navPath.home)}/>
                        </Link>
                        <div className='footer-links'>
                            <ul>
                                <li>© 2023 FooBear LLC</li>
                                <li onClick={() => navigate(navPath.contactus)}> | <Link to={navPath.contactus}>Contact
                                    Us</Link></li>
                                <li onClick={() => navigate(navPath.privacypolicy)}> | <Link to={navPath.privacypolicy}>Privacy
                                    Policy</Link></li>
                                <li onClick={() => navigate(navPath.termsandconditions)}> | <Link
                                    to={navPath.termsandconditions}>Terms &
                                    Conditions</Link></li>
                            </ul>
                            <p>v{packageJson.version}</p>
                        </div>
                    </div>
                    <div className="social-media">
                        <AiFillYoutube onClick={() => window.open("https://www.youtube.com/@CommCommApp", "_blank")}
                                       size={33}/>
                        {/* <AiFillInstagram size={28}/>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommCommFooter;