import React, {useEffect, useState} from 'react';
import {ProjectData} from "../../data/ProjectData";
import {Client} from "../../data/Client";
import {SVGIconType} from "./SVGPicker/SVGIconType";
import ComponentLoading from "./Popup/ComponentLoading";

interface IArchiveClientCard {
    item: ProjectData | Client;
    titleName: string;
    selectedItemId: string;
    selectItem: (itemDetails: any) => void;
    unarchive: (projectId: string) => void;
    iconColor: string;
    icon: SVGIconType;
    iconBackgroundColor: string;
    unarchivingClientId: string;
}

const ArchiveClientCard: React.FunctionComponent<IArchiveClientCard> = (props) => {
    const [item, setItem] = useState<ProjectData | Client>(props.item)
    const [titleName, setTitleName] = useState("");
    const [loadingAnimation, setLoadingAnimation] = useState(false);
    // const [selectedItem, setSelectedItem] = useState<string>(props.selectedItemId)

    useEffect(() => {
        setItem(props.item);
        setTitleName(props.titleName)
        // setSelectedItem(props.selectedItemId);
    }, [props.selectedItemId, props.item])

    useEffect(() => {
        setLoadingAnimation(props.item.id === props.unarchivingClientId)
    }, [props.unarchivingClientId])

    return (
        <div key={item.id} style={{padding: "0px 10px"}}
             onClick={() => props.selectItem(item)}
        >
            <div
                className={"archived-card-view"}
                style={{backgroundColor: '#1B0821', padding: "15px"}}>
                <div className="archived-card-view-row">
                    <div className={"nav-list-item-icon"}
                         style={{backgroundColor: `${props.iconBackgroundColor}`}}>
                        {/*<IconContext.Provider*/}
                        {/*    value={{color: item.iconColor, size: '1.4em'}}>*/}
                        {/*    {React.createElement(props.icon.icon)}*/}
                        {/*</IconContext.Provider>*/}
                        <props.icon.icon width='20px' height='20px' fill={props.iconColor}/>
                    </div>
                    <span className={"nav-list-item-font"}>{titleName}</span>
                </div>
                <ComponentLoading isLoadingComponent={loadingAnimation}/>
                <button className='blueBtn' style={{width: "55%"}} type="button"
                        hidden={loadingAnimation}
                        onClick={() => props.unarchive(item.id)}>Unarchive
                </button>
            </div>
        </div>
    );
};

export default ArchiveClientCard