import {Column} from "./Column";
import {CardDto} from "./CardDto";

export class ColumnDto extends Column {
    cardDtos: CardDto[] = []

    public constructor(column: Column, data: CardDto[]) {
        super(column)
        this.cardDtos = data
    }
}