import React, {useEffect, useState} from 'react';
import CommCommFooter from "../components/Home/CommCommFooter";
import Zorua from '../assets/zorua-profile-pic.jpeg';
import {useParams} from "react-router-dom";
import {AccountData} from "../data/AccountData";
import {validUrl} from "../components/Helpers";

interface IUnprotectedProfileView {
    account: AccountData | undefined;
    setUnprotectedAccountId: (accountId: string) => void;
    isUnprotectedProfileLoading: boolean;
}

const UnprotectedProfileView: React.FC<IUnprotectedProfileView> = (props) => {
    const [useDefaultImage, setUseDefaultImage] = useState(true);

    const {accountId} = useParams();

    const handleImageError = () => {
        // Handle the image loading error here
        setUseDefaultImage(false);
    };

    useEffect(() => {
        if (accountId) {
            props.setUnprotectedAccountId(accountId)
        }
    }, [accountId])

    useEffect(() => {
        setUseDefaultImage(props.account?.image === null || props.account?.image === "")
    }, [props.account?.image])

    return (
        <div className="App">
            <div className="unprotected-profile-view-wrapper">
                <div className="unprotected-profile-view-background-container">
                    <div className="profile-view-header">
                        {props.account?.displayRealName ?
                            <h1>{props.account?.firstName ?? ""} {props.account?.lastName ?? ""}</h1> :
                            <h1>{props.account?.username}</h1>}
                        {useDefaultImage ? (
                            // Render the image with the onError event handler.
                            <img
                                src={Zorua}
                                alt="Default profile image of our cat Zorua."
                                onError={handleImageError}
                            />
                        ) : (
                            // Display user chosen image when there is no error.
                            <img src={props.account?.image ?? ""} alt="Profile Pic"/>
                        )}
                    </div>
                    <div className="profile-view-account-info">
                        <div className="profile-view-row">
                            <div className="profile-view-column">
                                <label htmlFor="seller-bio">Seller Bio</label>
                                <p style={{marginBottom: "0px"}}>{props.account?.biography ?? ""}</p>
                            </div>
                        </div>
                        <div className="profile-view-row">
                            <div className="profile-view-column">
                                <label htmlFor="business-link">Business Link</label>
                                {validUrl(props.account?.shopLink ?? "") ? <a className="hyperlink"
                                                                              href={props.account?.shopLink ?? ""}>{props.account?.shopLink ?? ""}</a> :
                                    <p>{props.account?.shopLink ?? ""}</p>}
                            </div>
                        </div>
                        <div className="profile-view-row">
                            <div className="profile-view-column">
                                <label htmlFor="social-media">Social Media</label>
                                {validUrl(props.account?.socialMedia1 ?? "") ?
                                    <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                       href={props.account?.socialMedia1 ?? ""}>{props.account?.socialMedia1 ?? ""}</a> :
                                    <p>{props.account?.socialMedia1 ?? ""}</p>}
                                {validUrl(props.account?.socialMedia2 ?? "") ?
                                    <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                       href={props.account?.socialMedia2 ?? ""}>{props.account?.socialMedia2 ?? ""}</a> :
                                    <p>{props.account?.socialMedia2 ?? ""}</p>}
                                {validUrl(props.account?.socialMedia3 ?? "") ?
                                    <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                       href={props.account?.socialMedia3 ?? ""}>{props.account?.socialMedia3 ?? ""}</a> :
                                    <p>{props.account?.socialMedia3 ?? ""}</p>}
                            </div>
                        </div>
                        <div className="profile-view-row">
                            <div className="profile-view-column">
                                <label htmlFor="e-mail">Email</label>
                                <a className="hyperlink" href="mailto:{account?.email}">{props.account?.email ?? ""}</a>
                            </div>
                        </div>
                        <div className="profile-view-row">
                            <div className="profile-view-column">
                                <label htmlFor="phone-number">Phone Number</label>
                                <a className="hyperlink"
                                   href="tel:{account?.phoneNumber}">{props.account?.phoneNumber ?? ""}</a>
                            </div>
                        </div>
                        <div className="profile-view-last-row">
                            <div className="profile-view-column">
                                <label htmlFor="venmo-handle">Venmo Handle</label>
                                {props.account?.venmoHandle ?
                                    <a className="hyperlink" target="_blank" rel="noopener noreferrer"
                                       href={`https://account.venmo.com/u/${props.account?.venmoHandle}`}>https://account.venmo.com/u/{props.account?.venmoHandle}</a> :
                                    <p>N/A</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default UnprotectedProfileView;