import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import {IconContext} from "react-icons";
import {IoHelpCircle} from "react-icons/io5";
import {Menu, MenuItem} from "@mui/material";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {colorPicker} from "../Helpers";
import HelpBtn from '../../assets/Help-Btn-Img.png';
import ExampleFolder from '../../assets/Example-Folder.png';
import UserGuide from '../../assets/User-Guide.png';
import {Feedback} from "./Popup/Feedback";

interface IHelpDropDownProps {
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
}

export const HelpDropDown: React.FC<IHelpDropDownProps> = (props) => {
    const [anchorHelpEl, setAnchorHelpEl] = useState<null | HTMLElement>(null);
    const [showFeedbackModal, setFeedbackModal] = useState(false);
    const [showGetStartedModal, setGetStartedModal] = useState(false);
    const openHelp = Boolean(anchorHelpEl);
    const [showFeedbackSentConfirmation, setFeedbackSentConfirmation] = useState(false);

    useEffect(() => {
        if (props.feedbackConfirmation) {
            setFeedbackSentConfirmation(true);
            setTimeout(function () {
                setFeedbackSentConfirmation(false);
            }, 5000);
        }
    }, [props.feedbackConfirmation])

    const handleFeedbackModalClose = () => {
        setFeedbackModal(false);
    };

    const handleGetStartedModalClose = () => {
        setGetStartedModal(false);
    };

    //fix this to have actual response to feedback
    const handleFeedbackConfirmationShow = (text: string, type: string) => {
        props.submitFeedback(text.trim(), Number(type));
        setFeedbackModal(false);
        setFeedbackSentConfirmation(true);
        setTimeout(function () {
            setFeedbackSentConfirmation(false);
        }, 6000);
    };

    const handleFeedbackModalShow = () => {
        setFeedbackModal(true);
        props.setFeedbackConfirmation(false);
        handleHelpClose();
    };

    const handleGetStartedModalShow = () => {
        setGetStartedModal(true);
        handleHelpClose();
    };

    const handleHelpClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        setAnchorHelpEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setAnchorHelpEl(null);
    }

    const toggleFeedbackSentConfirmation = () => setFeedbackSentConfirmation(!showFeedbackSentConfirmation);

    return (
        <div className='help-menu-wrapper'>
            <IconContext.Provider value={{size: "1.4em"}}>
                <button className="purpleBtn" type="button" onClick={(event) => {
                    handleHelpClick(event)
                }}><IoHelpCircle/> Help
                </button>
            </IconContext.Provider>
            <Menu
                className={"help-options-menu"}
                anchorEl={anchorHelpEl}
                open={openHelp}
                onClose={handleHelpClose}
            >
                <MenuItem onClick={() => {
                    handleFeedbackModalShow()
                }}>Feedback</MenuItem>
                <MenuItem onClick={() => {
                    handleGetStartedModalShow()
                }}>Get Started</MenuItem>
                <MenuItem onClick={() => {
                    handleHelpClose()
                }}><a href="https://commcomm.app/contact-us" target="_blank" rel="noreferrer">Contact
                    Us</a></MenuItem>
            </Menu>
            <Toast show={showFeedbackSentConfirmation} onClose={toggleFeedbackSentConfirmation}>
                <Toast.Header closeButton aria-label="Hide" closeVariant="white">
                    <BsFillCheckCircleFill size={23} color={colorPicker.confirmationGreen}/>
                    <strong className="savedConfirmationText">Thank you! Your feedback was sent successfully.</strong>
                </Toast.Header>
            </Toast>

            <Feedback
                show={showFeedbackModal}
                onHide={handleFeedbackModalClose}
                submitFeedback={handleFeedbackConfirmationShow}/>

            <Modal
                size="lg"
                show={showGetStartedModal}
                onHide={handleGetStartedModalClose}
                keyboard={false}
            >
                <div className={"create-client-popup-background"}>
                    <Modal.Header className="text-center" closeButton>
                        <Modal.Title className="create-client-header w-100">Get Started</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="getStartedRow">
                            <div className="getStartedColumn"
                                 style={{borderRight: "3px solid #F171AB", paddingRight: "20px", paddingTop: "10px"}}>
                                <img src={ExampleFolder}/>
                                <p>Your account comes with an example project and client named after you for
                                    practice.</p>
                            </div>
                            <div className="getStartedColumn"
                                 style={{borderRight: "3px solid #F171AB", padding: "10px 20px 0px"}}>
                                <img src={UserGuide}/>
                                <p>For help with using the website and its features, look at our <a
                                    className="hyperlink" href="https://commcomm.app/user-guide" target="_blank"
                                    rel="noreferrer">user guide</a>.</p>
                            </div>
                            <div className="getStartedColumn" style={{paddingLeft: "20px", paddingTop: "10px"}}>
                                <img src={HelpBtn}/>
                                <p>For more general assistance, select the help button in the top right.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>);
};

export default HelpDropDown