import React, {useEffect, useState} from 'react';
import {
    DragDropContext,
    Droppable,
    DropResult,
} from '@hello-pangea/dnd';
import {ColumnInfo} from "../components/ProjectOverviewBoard/ColumnInfo";
import {Card} from "../data/Card";
import {ProjectDetails} from "../data/ProjectDetails";
import {ColumnDto} from "../data/ColumnDto";
import {CardDto} from "../data/CardDto";
import {ClientDto} from "../data/ClientDto";
import {Tag} from "../data/Tag";
import {AccountData} from "../data/AccountData";
import ProjectOverviewTopBar from "../components/ProjectOverviewBoard/ProjectOverviewTopBar";
import {ModalCongratsMessage} from "../components/Base/Popup/ModalCongratsMessage";
import {ModalConfirmMessage} from "../components/Base/Popup/ModalConfirmMessage";
import {ModalWarningMessage} from "../components/Base/Popup/ModalWarningMessage";
import {CardProgressHistoryDetails} from "../data/CardProgressHistoryDetails";
import CommComm from '../assets/CommComm-Background.png';
import LoadingOverlay from "../components/Home/LoadingOverlay";
import EditCard from "../components/Base/Popup/EditCard";


//https://github.com/abeaudoin2013/react-beautiful-dnd-multi-list-typescript-example/blob/master/src/App.tsx
//https://github.com/alexreardon/react-beautiful-dnd-flow-example/blob/master/src/App.js
//https://codepen.io/bootpen/pen/jbbaRa
//https://stackoverflow.com/questions/43583165/how-to-open-a-bootstrap-modal-from-the-right
//https://medium.com/create-a-clocking-in-system-on-react/create-a-react-app-displaying-the-current-date-and-time-using-hooks-21d946971556

//Date Code
export const DateTime = () => {

    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000)
        return function cleanup() {
            clearInterval(timer)
        }

    });

    return (
        <div>
            <p>{date.toLocaleDateString()} {date.toLocaleTimeString()}</p>
        </div>
    )
}

// const grid = 8;

// const getListStyle = (isDraggingOver: boolean) => ({
//     background: isDraggingOver ? 'lightblue' : 'lightgrey',
//     padding: grid,
//     width: 250
// });

interface IProjectWorkflowBoard {
    account: AccountData;
    projects: ProjectDetails[];
    clients: ClientDto[];
    selectedProject: ProjectDetails;
    incrementProjectCards: (id: string) => void;
    decrementProjectCards: (id: string) => void;
    deleteCard: (cardData: Card) => void;
    addTag: (cardData: Card, tag: string, color: string) => void;
    tags: Tag[];
    updateTag: (cardData: CardDto, tagId: string, name: string, color: string) => void;
    deleteTag: (tagId: string, projectId: string) => void;
    addTagOnCard: (cardId: string, tagId: string) => void;
    removeTagOnCard: (cardId: string, tagId: string) => void;
    updateCardNotes: (cardId: string, note: string) => void;
    updateCardTitle: (cardId: string, title: string) => void;
    updateCardQuantity: (cardId: string, count: number) => void;
    updateCardDueDate: (cardId: string, date: string) => void;
    updateCardStartDate: (cardId: string, date: string) => void;
    updateCardColor: (cardId: string, color: string) => void;
    saveClient: (cardData: Card, oldClientId: string) => void;
    saveProject: (cardData: Card, oldProjectId: string) => void;
    updateCardComments: (card: Card, comment: string) => void;
    cardsUsed: number;
    cardMax: number;
    onDrag: (result: DropResult) => void;
    addCard: (title: string) => void;
    addColumn: (text: string) => void;
    editColumn: (columnId: string, title: string) => void;
    removeColumn: (columnId: string) => void;
    moveCardViaButton: (card: CardDto, sourceId: string, destinationId: string) => void;
    archiveCommission: (card: CardDto) => void;
    showDoneColumnWarning: boolean;
    showDoneColumnCongrats: boolean;
    setShowDoneColumnWarning: (show: boolean) => void;
    setShowDoneColumnCongrats: (show: boolean) => void;
    cancelDoneCardMovement: () => void;
    confirmDoneCardMovement: () => void;
    moveCardToDoneColumnViaButton: (card: CardDto) => void
    showInQueueColumnWarning: boolean;
    setShowInQueueColumnWarning: (show: boolean) => void;
    cancelInQueueCardMovement: () => void;
    showArchiveProjectWarning: boolean;
    setShowArchiveProjectWarning: (show: boolean) => void;
    cancelArchiveProjectWarning: () => void;
    getCardComments: (cardId: string) => void;
    cardComments: CardProgressHistoryDetails[];
    isProjectLoading: boolean;
    displayCommission: CardDto;
    selectCard: (card: CardDto) => void;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
    isSideNavVisible: boolean;
    loadSavedCard: boolean;
}

const ProjectWorkflowBoard: React.FunctionComponent<IProjectWorkflowBoard> = (props) => {
    // const {id} = useParams()
    const [showCardModal, setShowCardModalCardModal] = useState(false);
    const toggleShowCardModal = () => setShowCardModalCardModal(!showCardModal);

    const handleConfirmDoneCardMovement = () => {
        props.confirmDoneCardMovement();
    };

    return (
        <>
            <div className="App">
                <div className='projectBoardImgBg'
                     style={{backgroundImage: `url(${props.selectedProject?.backgroundImage ?? CommComm})`}}>
                    <div className="projectOverview"
                         style={{
                             marginLeft: props.isSideNavVisible ? '260px' : '70px',
                             transition: 'margin 0.3s ease-in-out'
                         }}>
                        <ProjectOverviewTopBar
                            account={props.account}
                            cardsTotal={props.cardMax}
                            cardsUsed={props.cardsUsed}
                            submitFeedback={props.submitFeedback}
                            feedbackConfirmation={props.feedbackConfirmation}
                            setFeedbackConfirmation={props.setFeedbackConfirmation}/>
                        <div className="project-overview-content-wrapper">
                            <DragDropContext onDragEnd={result => props.onDrag(result)}>
                                <Droppable
                                    droppableId='all-columns'
                                    direction='horizontal'
                                    type='column'
                                >
                                    {provided => (
                                        <div
                                            style={{display: 'flex', columnGap: '15px'}}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {props.selectedProject.columnDetails && props.selectedProject.columnDetails.map((columnDetail: ColumnDto, index: number) => {
                                                return (
                                                    <div className={`project-column-${index}`} key={columnDetail.id}>
                                                        <ColumnInfo
                                                            account={props.account}
                                                            column={columnDetail}
                                                            projects={props.projects}
                                                            clients={props.clients}
                                                            cards={columnDetail.cardDtos}
                                                            removeColumn={props.removeColumn}
                                                            index={index}
                                                            columnCount={props.selectedProject?.columnDetails.length}
                                                            editColumn={props.editColumn}
                                                            addCard={props.addCard}
                                                            addColumn={props.addColumn}
                                                            toggleShowCardModal={toggleShowCardModal}
                                                            selectCard={props.selectCard}
                                                            addCommissionIsHidden={props.cardMax <= props.cardsUsed}
                                                            loadSavedCard={props.loadSavedCard}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <ModalCongratsMessage
                                isOpen={props.showDoneColumnCongrats}
                                modalTitle={"Congratulations!"}
                                toggle={props.setShowDoneColumnCongrats}>
                                <p>{`We're proud of you for completing this commission.`}</p>
                            </ModalCongratsMessage>
                            <ModalConfirmMessage
                                isOpen={props.showDoneColumnWarning}
                                cancel={props.cancelDoneCardMovement}
                                action={handleConfirmDoneCardMovement}
                                // action={props.confirmDoneCardMovement}
                                submitText={"Confirm"}
                                cancelText={"Cancel"}
                                modalTitle={"Moving Card to Done Warning"}
                                toggleShow={props.setShowDoneColumnWarning}>
                                <p>Once you move this commission to <span>Done</span>,
                                    you <span>WILL NOT</span> be able to move
                                    it to a previous column anymore. Make sure all tasks are done before completing this
                                    commission.</p>
                            </ModalConfirmMessage>
                            <ModalWarningMessage
                                isOpen={props.showInQueueColumnWarning}
                                cancel={props.cancelInQueueCardMovement}
                                cancelText={'OK'}
                                modalTitle={"Missing Important Fields"}
                                toggle={props.setShowInQueueColumnWarning}>
                                <p>To move your commission out of <span>In Queue</span>, please assign
                                    a <span>Start Date</span>,
                                    <span> Due Date</span> and/or <span>Client</span> to it.</p>
                            </ModalWarningMessage>
                            <ModalWarningMessage
                                isOpen={props.showArchiveProjectWarning}
                                cancel={props.cancelArchiveProjectWarning}
                                cancelText={'OK'}
                                modalTitle={"Archive Project Error"}
                                toggle={props.setShowArchiveProjectWarning}>
                                <p>Your project still has active commissions attached to it. You can only
                                    archive
                                    projects with no active commissions.</p>
                            </ModalWarningMessage>
                        </div>
                    </div>
                </div>
            </div>
            {props.displayCommission && <EditCard account={props.account}
                                                  columnId={props.displayCommission.columnId}
                                                  isOpen={showCardModal}
                                                  toggleShowCardModal={toggleShowCardModal}
                                                  card={props.displayCommission}
                                                  deleteCard={props.deleteCard}
                                                  tags={props.tags}
                                                  addTag={props.addTag}
                                                  updateTag={props.updateTag}
                                                  addTagOnCard={props.addTagOnCard}
                                                  removeTagOnCard={props.removeTagOnCard}
                                                  deleteTag={props.deleteTag}
                                                  updateCardTitle={props.updateCardTitle}
                                                  updateCardQuantity={props.updateCardQuantity}
                                                  updateCardDueDate={props.updateCardDueDate}
                                                  updateCardStartDate={props.updateCardStartDate}
                                                  updateCardColor={props.updateCardColor}
                                                  updateCardNotes={props.updateCardNotes}
                                                  updateCardComments={props.updateCardComments}
                                                  projects={props.projects} clients={props.clients}
                                                  saveProject={props.saveProject}
                                                  saveClient={props.saveClient}
                                                  moveCardViaButton={props.moveCardViaButton}
                                                  archiveCommission={props.archiveCommission}
                                                  moveCardToDoneColumnViaButton={props.moveCardToDoneColumnViaButton}
                                                  getCardComments={props.getCardComments}
                                                  cardComments={props.cardComments}
            />}
            <LoadingOverlay isLoading={props.isProjectLoading}/>
        </>
    );
}

export default ProjectWorkflowBoard;
