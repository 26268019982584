export const config = {
    firebaseConfig: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID as string,
        appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string
    },
    stripePriceIdConfig: {
        ProYearly: process.env.REACT_APP_STRIPE_PRO_YEARLY_PLAN as string,
        ProMonthly: process.env.REACT_APP_STRIPE_PRO_MONTHLY_PLAN as string,
        StandardYearly: process.env.REACT_APP_STRIPE_STANDARD_YEARLY_PLAN as string,
        StandardMonthly: process.env.REACT_APP_STRIPE_STANDARD_MONTHLY_PLAN as string,
        BasicYearly: process.env.REACT_APP_STRIPE_BASIC_YEARLY_PLAN as string,
        BasicMonthly: process.env.REACT_APP_STRIPE_BASIC_MONTHLY_PLAN as string
    },
    stripePriceConfig: {
        ProYearly: '$359.99',
        ProMonthly: '$29.99',
        StandardYearly: '$239.99',
        StandardMonthly: '$19.99',
        BasicYearly: '$119.99',
        BasicMonthly: '$9.99',
        FreeMonthly: '$0.00'
    },
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
};