import React, {useState} from "react";
import {Menu, MenuItem} from "@mui/material";
import HelpDropDown from "../Base/HelpDropDown";

interface IArchiveBoardProjectTopBar {
    setYear: (year: number) => void;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
}

const ArchiveBoardProjectsTopBar: React.FunctionComponent<IArchiveBoardProjectTopBar> = (props) => {
    const [anchorFilterEl, setAnchorFilterEl] = React.useState<null | HTMLElement>(null);
    const openFilter = Boolean(anchorFilterEl);
    const [year, setYear] = useState(2024)
    // const [search, setSearch] = useState("")

    // const handleFilterClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    //     setAnchorFilterEl(event.currentTarget);
    // };
    const handleFilterClose = (input: number) => {
        setYear(input)
        props.setYear(input)
        setAnchorFilterEl(null);
    }

    // const handleChange = (text: string) => {
    //     setSearch(text)
    // }

    return (
        <div className="clientOverviewTopBar">

            {/* <input
                type="text"
                value={search}
                onChange={(e) => handleChange(e.target.value)}
                placeholder="Search all Projects"
            />
            <button className="purpleBtn" type="button">
                Search Projects
            </button> */}

            <div id="archivedProjectYearPicker" className='projectTopBarBtnWrapper'>
                <Menu
                    className={"help-options-menu"}
                    anchorEl={anchorFilterEl}
                    open={openFilter}
                    onClose={() => handleFilterClose(year)}
                >
                    <MenuItem onClick={() => {
                        handleFilterClose(2024)
                    }}>2024</MenuItem>
                    <MenuItem onClick={() => {
                        handleFilterClose(2025)
                    }}>2025</MenuItem>
                </Menu>
            </div>
            <div className='projectTopBarBtnWrapper'>
                <HelpDropDown
                    setFeedbackConfirmation={props.setFeedbackConfirmation}
                    feedbackConfirmation={props.feedbackConfirmation}
                    submitFeedback={props.submitFeedback}/>
            </div>
        </div>
    );
}

export default ArchiveBoardProjectsTopBar;