export class CardProgressHistoryDetails {
    profileUrl: string = "";
    editor: string = "";
    detailText: string = "";
    createdAt: string = "";

    public constructor(init?: Partial<CardProgressHistoryDetails>) {
        Object.assign(this, init);
    }

    static createWithDetails(url: string, author: string, text: string, timestamp: string): CardProgressHistoryDetails {
        return new CardProgressHistoryDetails({
            profileUrl: url,
            editor: author,
            detailText: text,
            createdAt: timestamp
        });
    }
}