import React, {useEffect, useState} from 'react';
import {ProjectDetails} from "../data/ProjectDetails";
import {AccountData} from "../data/AccountData";
import ArchiveBoardProjectsTopBar from "../components/ArchivesBoard/ArchiveBoardProjectsTopBar";
import {ProjectData} from "../data/ProjectData";
import LoadingOverlay from "../components/Home/LoadingOverlay";
import {colorPicker} from "../components/Helpers";
import {SVGIconList} from "../components/Base/SVGPicker/SVGIconList";
import ArchiveProjectCard from "../components/Base/ArchiveProjectCard";

interface IArchiveProjectsBoardProps {
    account: AccountData;
    projects: ProjectData[];
    archivedProjects: ProjectData[];
    unarchive: (projectId: string) => void;
    isArchiveProjectLoading: boolean;
    setShowArchive: (show: boolean) => void;
    submitFeedback: (description: string, feedbackType: number) => void;
    feedbackConfirmation: boolean;
    setFeedbackConfirmation: (show: boolean) => void;
    isSideNavVisible: boolean;
    unarchivingProjectId: string;
}

const projectAE: string = "Projects A-E";
const projectFJ: string = "Projects F-J";
const projectKO: string = "Projects K-O";
const projectPT: string = "Projects P-T";
const projectUZ: string = "Projects U-Z";
const otherProjects: string = "Other Projects";

const refreshCards = () => {
    return new Map<string, ProjectData[]>([
        [projectAE, []],
        [projectFJ, []],
        [projectKO, []],
        [projectPT, []],
        [projectUZ, []],
        [otherProjects, []]
    ])
}

//hold the selected card info in a variables, so you can pass projectId to functions.
const ArchiveProjectsBoard: React.FunctionComponent<IArchiveProjectsBoardProps> = (props) => {
    const [archiveProjects, setArchiveProjects] = useState<Map<string, ProjectData[]>>(refreshCards());
    const [project, setProject] = useState<ProjectData>(props.archivedProjects[0] ?? new ProjectData())
    const [year, setYear] = useState<number>(2023)

    useEffect(() => {
        props.setShowArchive(true)
    }, [])

    useEffect(() => {
        const newArchiveProjects = refreshCards();
        for (let i = 0; i < props.archivedProjects.length; i++) {
            let proj = props.archivedProjects[i];
            let letter = proj.title.charAt(0).toUpperCase();
            if (letter >= 'A' && letter <= 'E') {
                if (!newArchiveProjects.get(projectAE)!.includes(proj)) {
                    newArchiveProjects.get(projectAE)!.push(proj);
                }
            } else if (letter >= 'F' && letter <= 'J') {
                if (!newArchiveProjects.get(projectFJ)!.includes(proj)) {
                    newArchiveProjects.get(projectFJ)!.push(proj);
                }
            } else if (letter >= 'K' && letter <= 'O') {
                if (!newArchiveProjects.get(projectKO)!.includes(proj)) {
                    newArchiveProjects.get(projectKO)!.push(proj);
                }
            } else if (letter >= 'P' && letter <= 'T') {
                if (!newArchiveProjects.get(projectPT)!.includes(proj)) {
                    newArchiveProjects.get(projectPT)!.push(proj);
                }
            } else if (letter >= 'U' && letter <= 'Z') {
                if (!newArchiveProjects.get(projectUZ)!.includes(proj)) {
                    newArchiveProjects.get(projectUZ)!.push(proj);
                }
            } else {
                if (!newArchiveProjects.get(otherProjects)!.includes(proj)) {
                    newArchiveProjects.get(otherProjects)!.push(proj);
                }
            }
        }
        setArchiveProjects(newArchiveProjects);
    }, [props.archivedProjects.length, year])

    const setProjectDetails = (project: ProjectDetails) => {
        setProject(project)
    }


    return (
        <>
            <div className="App">
                <div className='archiveBoardImgBg'>
                    <div className="archiveOverview" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <ArchiveBoardProjectsTopBar
                            feedbackConfirmation={props.feedbackConfirmation}
                            setFeedbackConfirmation={props.setFeedbackConfirmation}
                            submitFeedback={props.submitFeedback}
                            setYear={setYear}/>
                        <div className="archive-content-wrapper">
                            {Array.from(archiveProjects.keys()).map((key: string, indexKey: number) => (
                                <div key={key + indexKey} className="clientCommissionColumn">
                                    <div className="clientCommissionColumn-title">
                                        {key}
                                    </div>
                                    <div>
                                        {archiveProjects.has(key) && archiveProjects.get(key)!.length > 0 &&
                                            <div className="card-wrapper">
                                                {archiveProjects.get(key)!.length > 0 && archiveProjects.get(key)!.sort(
                                                    (p1, p2) => {
                                                        return p1.title.localeCompare(p2.title);
                                                    }).map((projectData: ProjectData, index) => (
                                                    <div key={index}>
                                                        <ArchiveProjectCard
                                                            item={projectData}
                                                            account={props.account}
                                                            projects={props.projects}
                                                            selectedItemId={project.id}
                                                            iconColor={projectData?.iconColor ?? colorPicker.defaultBlue}
                                                            iconBackgroundColor={projectData?.iconBackgroundColor ?? colorPicker.commCommPink}
                                                            icon={SVGIconList.filter(x => x.title === projectData.iconImage)[0] ?? SVGIconList[0]}
                                                            unarchive={props.unarchive}
                                                            titleName={projectData.title}
                                                            unarchivingProjectId={props.unarchivingProjectId}
                                                            selectItem={setProjectDetails}/>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <LoadingOverlay isLoading={props.isArchiveProjectLoading}/>
        </>
    );
}

export default ArchiveProjectsBoard;
