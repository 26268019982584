import {AggregateBase} from "./AggregateBase";

export class Feedback extends AggregateBase {
    accountId: string = "";
    message: string = "";
    feedbackType: number = 0;

    public constructor(init?: Partial<Feedback>) {
        super();
        Object.assign(this, init);
    }
}