import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import {ProjectBreakdown} from "../data/ProjectBreakdown";
import {ProjectDetails} from "../data/ProjectDetails";
import CurrencyInput from 'react-currency-input-field';
import {AccountSubscriptionPlan, formatCurrency} from '../components/Helpers';
import ProjectBreakdownPreview from "../components/Base/ProjectBreakdownPreview";
import SaveConfirmationToast from "../components/Base/SaveConfirmationToast";
import ComponentLoading from "../components/Base/Popup/ComponentLoading";

//https://www.npmjs.com/package/react-currency-input-field#separators
interface IPriceBreakdown {
    isSideNavVisible: boolean;
    projectBreakdowns: ProjectBreakdown[];
    saveBreakdown: (breakdown: ProjectBreakdown) => void;
    deleteBreakdown: (breakdown: ProjectBreakdown) => void;
    projects: ProjectDetails[];
    projectBreakdownDeleteConfirmation: string;
    projectBreakdownUpdatedConfirmation: string;
    accountSubscription: number;
    isBreakdownSaving: boolean;
}

const PriceBreakdown: React.FC<IPriceBreakdown> = (props) => {
    const [selectedBreakdown, setSelectedBreakdown] = useState<ProjectBreakdown>(new ProjectBreakdown());
    const [selectedProjectTitle, setProjectTitle] = useState("");
    const [laborCount, setLaborCount] = useState(0);
    const [materialsCount, setMaterialsCount] = useState(0);
    const [shippingCount, setShippingCount] = useState(0);
    const [otherCostsCount, setOtherCostsCount] = useState(0);
    const [laborText, setLaborText] = useState("");
    const [materialText, setMaterialText] = useState("");
    const [shippingText, setShippingText] = useState("");
    const [otherCostsText, setOtherCostsText] = useState("");
    const [laborMinText, setLaborMinText] = useState("");
    const [laborMaxText, setLaborMaxText] = useState("");
    const [materialMinText, setMaterialMinText] = useState("");
    const [materialMaxText, setMaterialMaxText] = useState("");
    const [shippingMinText, setShippingMinText] = useState("");
    const [shippingMaxText, setShippingMaxText] = useState("");
    const [otherCostsMinText, setOtherCostsMinText] = useState("");
    const [otherCostsMaxText, setOtherCostsMaxText] = useState("");
    const [updatedIndex, setUpdatedIndex] = useState(-1)
    const [breakdownUpdated, setBreakdownUpdated] = useState(false);
    const [breakdownUpdatedSuccessful, setBreakdownUpdatedSuccessful] = useState(false)
    const [breakdownDeletedSuccessful, setBreakdownDeletedSuccessful] = useState(false)

    useEffect(() => {
        //refreshes breakdown by pulling from breakdown list again
        if (breakdownUpdated) {
            setBreakdownUpdated(false);
            setSelectedProjectBreakdown(updatedIndex.toString());
            setBreakdownDeletedSuccessful(true);
            setTimeout(function () {
                setBreakdownDeletedSuccessful(false);
            }, 4000);
        }
    }, [props.projectBreakdownDeleteConfirmation])

    useEffect(() => {
        if (breakdownUpdated) {
            setBreakdownUpdated(false);
            setSelectedProjectBreakdown(updatedIndex.toString());
            setBreakdownUpdatedSuccessful(true);
            setTimeout(function () {
                setBreakdownUpdatedSuccessful(false);
            }, 4000);
        }
    }, [props.projectBreakdownUpdatedConfirmation]);

    const toggleBreakdownSavedConfirmation = () => setBreakdownUpdatedSuccessful(!breakdownUpdatedSuccessful);
    const toggleBreakdownDeletedConfirmation = () => setBreakdownDeletedSuccessful(!breakdownDeletedSuccessful);

    const setSelectedProjectBreakdown = (index: string) => {
        if (index !== "" && index !== "-1") {
            let breakdownIndex = props.projectBreakdowns.findIndex(p => p.projectId === props.projects[Number(index)].id);
            setUpdatedIndex(Number(index));
            if (breakdownIndex >= 0) {
                setProjectTitle(props.projects[Number(index)].title);
                setSelectedBreakdown(new ProjectBreakdown(props.projectBreakdowns[breakdownIndex]));
                setLaborCount(props.projectBreakdowns[breakdownIndex].labor.length);
                setMaterialsCount(props.projectBreakdowns[breakdownIndex].materials.length);
                setShippingCount(props.projectBreakdowns[breakdownIndex].shipping.length);
                setOtherCostsCount(props.projectBreakdowns[breakdownIndex].otherCosts.length);
                setLaborMinText(props.projectBreakdowns[breakdownIndex].laborMin.toString())
                setLaborMaxText(props.projectBreakdowns[breakdownIndex].laborMax.toString())
                setMaterialMinText(props.projectBreakdowns[breakdownIndex].materialsMin.toString())
                setMaterialMaxText(props.projectBreakdowns[breakdownIndex].materialsMax.toString())
                setShippingMinText(props.projectBreakdowns[breakdownIndex].shippingMin.toString())
                setShippingMaxText(props.projectBreakdowns[breakdownIndex].shippingMax.toString())
                setOtherCostsMinText(props.projectBreakdowns[breakdownIndex].otherCostsMin.toString())
                setOtherCostsMaxText(props.projectBreakdowns[breakdownIndex].otherCostsMax.toString())

            } else {
                setProjectTitle(props.projects[Number(index)].title);
                setSelectedBreakdown(new ProjectBreakdown({
                    projectId: props.projects[Number(index)].id,
                    accountId: props.projects[Number(index)].accountId
                }));
                resetFields()
            }
        } else {
            setProjectTitle("");
            setSelectedBreakdown(new ProjectBreakdown());
            resetFields()
        }
    }

    const resetFields = () => {
        setLaborCount(0);
        setMaterialsCount(0);
        setShippingCount(0);
        setOtherCostsCount(0);
        setLaborMinText("0.00");
        setLaborMaxText("0.00");
        setMaterialMinText("0.00")
        setMaterialMaxText("0.00")
        setShippingMinText("0.00")
        setShippingMaxText("0.00")
        setOtherCostsMinText("0.00")
        setOtherCostsMaxText("0.00")
    }

    const saveBreakdown = () => {
        setBreakdownUpdated(true);
        props.saveBreakdown({
            ...selectedBreakdown,
            laborMin: Number(laborMinText),
            laborMax: Number(laborMaxText),
            materialsMin: Number(materialMinText),
            materialsMax: Number(materialMaxText),
            shippingMin: Number(shippingMinText),
            shippingMax: Number(shippingMaxText),
            otherCostsMin: Number(otherCostsMinText),
            otherCostsMax: Number(otherCostsMaxText)
        });
    }

    const deleteBreakdown = () => {
        setBreakdownUpdated(true);
        props.deleteBreakdown(selectedBreakdown);
    }

    const addLabor = (labor: string) => {
        if (laborCount < 5 && labor.length > 0) {
            setLaborText("");
            setLaborCount(laborCount + 1);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    labor: [...selectedBreakdown.labor, labor]
                })
        }
    }

    const removeLabor = (labor: string) => {
        if (laborCount > 0) {
            setLaborCount(laborCount - 1);
            let arr = selectedBreakdown.labor.filter(x => x !== labor);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    labor: arr
                })
        }
    }

    const addMaterials = (material: string) => {
        if (materialsCount < 5 && material.length > 0) {
            setMaterialText("");
            setMaterialsCount(materialsCount + 1);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    materials: [...selectedBreakdown.materials, material]
                })
        }
    }

    const removeMaterials = (material: string) => {
        if (materialsCount > 0) {
            setMaterialsCount(materialsCount - 1);
            let arr = selectedBreakdown.materials.filter(x => x !== material);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    materials: arr
                })
        }
    }

    const addShipping = (shipping: string) => {
        if (shippingCount < 5 && shipping.length > 0) {
            setShippingText("");
            setShippingCount(shippingCount + 1);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    shipping: [...selectedBreakdown.shipping, shipping]
                })
        }
    }

    const removeShipping = (shipping: string) => {
        if (shippingCount > 0) {
            setShippingCount(shippingCount - 1);
            let arr = selectedBreakdown.shipping.filter(x => x !== shipping);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    shipping: arr
                })
        }
    }

    const addOtherCosts = (otherCosts: string) => {
        if (otherCostsCount < 10 && otherCosts.length > 0) {
            setOtherCostsText("");
            setOtherCostsCount(otherCostsCount + 1);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    otherCosts: [...selectedBreakdown.otherCosts, otherCosts]
                })
        }
    }

    const removeOtherCosts = (otherCosts: string) => {
        if (otherCostsCount > 0) {
            setOtherCostsCount(otherCostsCount - 1);
            let arr = selectedBreakdown.otherCosts.filter(x => x !== otherCosts);
            setSelectedBreakdown(
                {
                    ...selectedBreakdown,
                    otherCosts: arr
                })
        }
    }

    // Handles Enter key press for inner fields
    const handleBreakdownEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            switch (document.activeElement?.getAttribute('data-action')) {
                case 'addLabor':
                    addLabor(laborText);
                    break;
                case 'addMaterial':
                    addMaterials(materialText);
                    break;
                case 'addShipping':
                    addShipping(shippingText);
                    break;
                case 'addOtherCost':
                    addOtherCosts(otherCostsText);
                    break;
                default:
                    break;
            }
        }
    };

    const saveBreakdownText = selectedBreakdown.id ? 'Update Breakdown' : 'Save Breakdown';

    return (
        <div className="App">
            <div className='editProfileImgBg'>
                <div className="edit-profile-wrapper" style={{
                    marginLeft: props.isSideNavVisible ? '260px' : '70px',
                    transition: 'margin 0.3s ease-in-out'
                }}>
                    <SaveConfirmationToast
                        confirmationText="Your breakdown has been saved!"
                        toggleUpdateConfirmation={toggleBreakdownSavedConfirmation}
                        updateConfirmation={breakdownUpdatedSuccessful}
                    />
                    <SaveConfirmationToast
                        confirmationText="Your breakdown has been deleted!"
                        toggleUpdateConfirmation={toggleBreakdownDeletedConfirmation}
                        updateConfirmation={breakdownDeletedSuccessful}
                    />
                    <div className="price-breakdown-container">
                        <div className="price-breakdown-header">
                            <h1>Project Price Breakdowns</h1>
                            <p>Breakdowns are intended as approximate monetary assessments of the different
                                projects/services you provide.
                                They are accessible to all clients and will give them a better idea of what they are
                                paying for. {props.accountSubscription <= AccountSubscriptionPlan.Free ?
                                    <b style={{color: '#FF3347'}}>Editing & Saving Project Price Breakdown is not
                                        available on the Free Tier.</b> : ""}</p>
                        </div>
                        <Form id="breakdownForm" className="breakdown-form">
                            <div className="breakdown-column">
                                <label>Project Title<span style={{color: '#FF3347'}}> *</span></label>
                                <p>Choose from existing project boards.</p>
                                <Form.Select style={{width: 'max-content'}} aria-label="Select project breakdown"
                                             onChange={(e) => {
                                                 setSelectedProjectBreakdown(e.target.value)
                                             }}>
                                    <option value="-1">Choose a Project</option>
                                    {props.projectBreakdowns && props.projects.map((project, index) => {
                                        return <option key={project.id} value={index}>{project.title}</option>
                                    })}
                                </Form.Select>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="labor">Labor<span style={{color: '#FF3347'}}> *</span></label>
                                <p>List and describe the labor that you do for the
                                    project. <strong>{5 - laborCount} inputs
                                        left.</strong></p>
                                <div className='breakdown-row'>
                                    <input
                                        className="input-tag-creator form-control"
                                        disabled={laborCount >= 5}
                                        type="text"
                                        placeholder="Ex. Knitting, Assembly, Shipping, etc."
                                        value={laborText}
                                        onChange={(e) => {
                                            setLaborText(e.target.value)
                                        }}
                                        onKeyDown={handleBreakdownEnterKey}
                                        data-action="addLabor"
                                    />
                                    <button disabled={laborCount >= 5} className="secondaryBtn"
                                            onClick={() => addLabor(laborText)}
                                            type="button">Add Labor
                                    </button>
                                </div>
                                <div className='tag-breakdown-row'
                                     style={{display: selectedBreakdown.labor && selectedBreakdown.labor.length > 0 ? 'flex' : 'none'}}>
                                    {selectedBreakdown.labor && selectedBreakdown.labor.map((labor, index) => {
                                        return <div className='breakdown-tag' key={labor + index}>
                                            <p>{labor}</p>
                                            <CloseIcon onClick={() => removeLabor(labor)}
                                                       style={{cursor: "pointer", width: "18px"}}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="laborPrice">Labor Price<span
                                    style={{color: '#FF3347'}}> *</span></label>
                                <p>Enter a range for the cost of your labor relative to the final price.</p>
                                <div className='min-max-row'>
                                    <div className='min-max-column'>
                                        <label htmlFor="laborMin">Minimum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setLaborMinText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$5.00"
                                            value={laborMinText ?? 0}
                                        />
                                    </div>
                                    <div className='min-max-column'>
                                        <label htmlFor="laborMax">Maximum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setLaborMaxText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$15.00"
                                            value={laborMaxText ?? 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="materials">Materials<span style={{color: '#FF3347'}}> *</span></label>
                                <p>List out the general materials you use for your
                                    project. <strong>{5 - materialsCount} inputs
                                        left.</strong></p>
                                <div className='breakdown-row'>
                                    <input
                                        className="input-tag-creator form-control"
                                        disabled={materialsCount >= 5}
                                        type="text"
                                        placeholder="Ex. Paper, Yarn, Glue, etc."
                                        value={materialText}
                                        onChange={(e) => {
                                            setMaterialText(e.target.value)
                                        }}
                                        onKeyDown={handleBreakdownEnterKey}
                                        data-action="addMaterial"
                                    />
                                    <button disabled={materialsCount >= 5} className="secondaryBtn"
                                            onClick={() => addMaterials(materialText)}
                                            type="button">Add Material
                                    </button>
                                </div>
                                <div className='tag-breakdown-row'
                                     style={{display: selectedBreakdown.materials && selectedBreakdown.materials.length > 0 ? 'flex' : 'none'}}>
                                    {selectedBreakdown.materials && selectedBreakdown.materials.map((material, index) => {
                                        return <div className='breakdown-tag' key={material + index}>
                                            <p>{material}</p>
                                            <CloseIcon style={{cursor: "pointer", width: "18px"}}
                                                       onClick={() => removeMaterials(material)}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="materialPrice">Material Price<span style={{color: '#FF3347'}}> *</span></label>
                                <p>Enter a range for the cost of all your materials relative to the final price.</p>
                                <div className='min-max-row'>
                                    <div className='min-max-column'>
                                        <label htmlFor="materialMin">Minimum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setMaterialMinText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$5.00"
                                            value={materialMinText ?? 0}
                                        />
                                    </div>
                                    <div className='min-max-column'>
                                        <label htmlFor="materialMax">Maximum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setMaterialMaxText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$15.00"
                                            value={materialMaxText ?? 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="shipping">Shipping<span style={{color: '#FF3347'}}> *</span></label>
                                <p>List out the items used for shipping your
                                    project. <strong>{5 - shippingCount} inputs left.</strong>
                                </p>
                                <div className='breakdown-row'>
                                    <input
                                        className="input-tag-creator form-control"
                                        disabled={shippingCount >= 5}
                                        type="text"
                                        placeholder="Ex. Packaging, Envelope, Tape, etc."
                                        value={shippingText}
                                        onChange={(e) => {
                                            setShippingText(e.target.value)
                                        }}
                                        onKeyDown={handleBreakdownEnterKey}
                                        data-action="addShipping"
                                    />
                                    <button disabled={shippingCount >= 5} className="secondaryBtn"
                                            onClick={() => addShipping(shippingText)}
                                            type="button">Add Shipping Item
                                    </button>
                                </div>
                                <div className='tag-breakdown-row'
                                     style={{display: selectedBreakdown.shipping && selectedBreakdown.shipping.length > 0 ? 'flex' : 'none'}}>
                                    {selectedBreakdown.shipping && selectedBreakdown.shipping.map((shipping, index) => {
                                        return <div className='breakdown-tag' key={shipping + index}>
                                            <p>{shipping}</p>
                                            <CloseIcon style={{cursor: "pointer", width: "18px"}}
                                                       onClick={() => removeShipping(shipping)}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="shippingPrice">Shipping Price<span style={{color: '#FF3347'}}> *</span></label>
                                <p>Enter a range for the cost of your shipping relative to the final price.</p>
                                <div className='min-max-row'>
                                    <div className='min-max-column'>
                                        <label htmlFor="shippingMin">Minimum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setShippingMinText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$5.00"
                                            value={shippingMinText ?? 0}
                                        />
                                    </div>
                                    <div className='min-max-column'>
                                        <label htmlFor="shippingMax">Maximum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setShippingMaxText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$15.00"
                                            value={shippingMaxText ?? 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="otherCosts">Other Costs<span
                                    style={{color: '#FF3347'}}> *</span></label>
                                <p>List out any other costs applicable to the final
                                    price. <strong>{10 - otherCostsCount} inputs
                                        left.</strong></p>
                                <div className='breakdown-row'>
                                    <input
                                        className="input-tag-creator form-control"
                                        disabled={otherCostsCount >= 10}
                                        type="text"
                                        placeholder="Ex. Fees, Software, Gas, etc."
                                        value={otherCostsText}
                                        onChange={(e) => {
                                            setOtherCostsText(e.target.value)
                                        }}
                                        onKeyDown={handleBreakdownEnterKey}
                                        data-action="addOtherCost"
                                    />
                                    <button disabled={otherCostsCount >= 10}
                                            onClick={() => addOtherCosts(otherCostsText)}
                                            className="secondaryBtn" type="button">Add Other Cost
                                    </button>
                                </div>
                                <div className='tag-breakdown-row'
                                     style={{display: selectedBreakdown.otherCosts && selectedBreakdown.otherCosts.length > 0 ? 'flex' : 'none'}}>
                                    {selectedBreakdown.otherCosts && selectedBreakdown.otherCosts.map((otherCost, index) => {
                                        return <div className='breakdown-tag' key={otherCost + index}>
                                            <p>{otherCost}</p>
                                            <CloseIcon style={{cursor: "pointer", width: "18px"}}
                                                       onClick={() => removeOtherCosts(otherCost)}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="breakdown-column">
                                <label htmlFor="shippingPrice">Other Costs Price<span
                                    style={{color: '#FF3347'}}> *</span></label>
                                <p>Enter a range for the price of all your other costs relative to the final price.</p>
                                <div className='min-max-row'>
                                    <div className='min-max-column'>
                                        <label htmlFor="otherMin">Minimum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setOtherCostsMinText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$5.00"
                                            value={otherCostsMinText ?? 0}
                                        />
                                    </div>
                                    <div className='min-max-column'>
                                        <label htmlFor="otherMax">Maximum Price</label>
                                        <CurrencyInput
                                            className="input-cost-value form-control"
                                            onValueChange={(value, name) => {
                                                setOtherCostsMaxText(value ?? "0")
                                            }}
                                            prefix="$"
                                            decimalScale={2}
                                            placeholder="$15.00"
                                            value={otherCostsMaxText ?? 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className='estimatedTotal'>Range Estimate of Total
                                Cost: {formatCurrency(Number(materialMinText) + Number(shippingMinText) + Number(otherCostsMinText) + Number(laborMinText))} - {formatCurrency(Number(materialMaxText) + Number(shippingMaxText) + Number(otherCostsMaxText) + Number(laborMaxText))}</p>
                            <ComponentLoading isLoadingComponent={props.isBreakdownSaving}/>
                            <button className="blueBtn" type="button"
                                    hidden={props.isBreakdownSaving || props.accountSubscription <= AccountSubscriptionPlan.Free}
                                    style={{width: 'fit-content', margin: '15px 0px 20px'}}
                                    onClick={() => saveBreakdown()}
                            >{saveBreakdownText}
                            </button>
                        </Form>
                        <div className="public-breakdown-section">
                            <ProjectBreakdownPreview
                                projectBreakdown={selectedBreakdown}
                                title={selectedProjectTitle}
                            />
                            <button className='redBtn' type="button" style={{width: 'fit-content'}}
                                    onClick={() => deleteBreakdown()}>Delete Breakdown
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PriceBreakdown;